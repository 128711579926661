import React from 'react'

function ErrorPopup({errorMessage, closePopup}) {
  return (
    <div className="published_Deative_transprant_body">
    <div className="published_Deative_preview_container">
      <div className="col-12 deactivate_msg delInfo">Error!</div>
      <div className="col-12 deactivate_msg">
        <img
          alt=""
          src="/images/cross-circle3.svg"
          className="crossIconImgQue"
          onClick={(e) => closePopup()}
        />
        {errorMessage ? errorMessage : "Something went wrong !"}{" "}
      </div>
      <div className="row col-12 publish_deactive_btnBox">
        <button
          onClick={() => closePopup()}
          className="btn col-sm-5 my-1 deactive_sure_btn buttonHover"
        >
          Okay
        </button>

      </div>
    </div>
  </div>
  )
}

export default ErrorPopup
