import React from 'react'

export default function TeacherTableSchool(props) {
    return (

        <tr >
            <td>
                <img src={props.profile_pic} alt='profilePhoto' className="me-2 table_detail_school"></img>
                {props.Name}
            </td>
            <td>{props.School_Email}</td>
            <td>{props.Personal_Email}</td>
            <td>{props.Mobile}</td>
            <td>{props.Co_ordinator}</td>
            <td>{props.Class_teacher}</td>
        </tr>

    )
}
