import React, { useEffect, useState } from "react";
import "./UseCaseSecond.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { BsPlusCircle } from "react-icons/bs";
import AddNewAuth from "./AddNewAuth";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import AddMasterData from "./MasterData/AddMasterData";
import axios from "../../axios";
import { useSchoolContext } from "../../schoolContext";
import { IP } from "../../internalServer";
import { Link, useNavigate } from "react-router-dom";
import LogOutButton from "../UseCaseFirstTime/LogOutButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigDataThunk } from "../../Store/Store";
import ErrorPopup from "../../ErrorPopup";
var lookup = require("country-data").lookup;

export default function BasicInformation() {
  const [click, setClick] = useState({
    addAuth: false,
    masterData: false,
  });
  const history = useNavigate();
  const [dataModified, setDataModified] = useState(false);
  const [picModified, setPicModified] = useState(false);
  const [basicInfoID, setBAsicInfoID] = useState(null);
  const [basicInfoObj, setBasicInfoObj] = useState(null);
  const [addressID, setAddressID] = useState(null);
  const [profile_image, setProfile_image] = useState(null);
  const [firstTime, setFirstTime] = useState(false);
  let { schoolAuthId, setSchoolId } = useSchoolContext();
  const [topManagementFormValidation, setTopManagementFormValidation] =
    useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  if (!schoolAuthId) {
    const schoolExtractedId = localStorage.getItem("schoolAuth");

    if (schoolExtractedId) {
      try {
        schoolAuthId = schoolExtractedId;
      } catch (error) {
        console.error("Error parsing schoolAuthId from localStorage:", error);
        handleError(error.response.data.message);
      }
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 56,
      width: "100%",
      borderRadius: "8px",
      background: "#FFFFFF",
      padding: " 5px",
      border: "1px solid #99ADBB",
      fontFamily: "MinecrafterReg.ttf",
    }),
  };
  const [formValues, setFormValues] = useState({
    name: null,
    email: null,
    code: null,
    mobileNumber: null,
    landlineNumber: null,
    alternateNumber: null,
  });

  const [address, setAddress] = useState({
    buildingNumber: null,
    line2: null,
    line1: null,
    city: null,
    state: null,
    zipcode: null,
    country: null,
  });

  const [topManagement, setTopManagement] = useState([
    {
      role: "Principal",
      name: "",
      email: "",
      mobile: "",
    },
    {
      role: "Vice Principal",
      name: "",
      email: "",
      mobile: "",
    },
    {
      role: "Director",
      name: "",
      email: "",
      mobile: "",
    },
  ]);

  useEffect(() => {
    const isTMFilled = topManagement.some(
      (TM) => TM.name === "" || TM.email === "" || TM.mobile === ""
    );
    setTopManagementFormValidation(isTMFilled);
  }, [topManagement]);

  const [profile, setProfile] = useState("");
  const [isValidFileType, setIsValidFileType] = useState(true);

  const validateFileType = (filePath) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (
      !allowedExtensions.exec(filePath.name) ||
      filePath.size > 10 * 1024 * 1024
    ) {
      setIsValidFileType(false);
      return false;
    }
    setIsValidFileType(true);
    return true;
  };

  const imageHandler = (e) => {
    let totalSize = e.target.files[0]?.size;
    if (totalSize <= 10 * 1024 * 1024 && validateFileType(e.target.files[0])) {
      setProfile(e.target.files[0]);
    }
    setPicModified(true);
  };

  const handleCountryChange = (selectedOption) => {
    setAddress((prevFormValues) => ({
      ...prevFormValues,
      country: selectedOption,
    }));
    setDataModified(true);
  };

  const handleStateChange = (selectedOption) => {
    setAddress((prev) => ({
      ...prev,
      state: selectedOption,
    }));
    setDataModified(true);
  };

  const handleCityChange = (selectedOption) => {
    setAddress((prevFormValues) => ({
      ...prevFormValues,
      city: selectedOption,
    }));
    setDataModified(true);
  };

  const handleNameChange = (e, index) => {
    const updatedManagement = [...topManagement];
    updatedManagement[index].name = e.target.value;
    setTopManagement(updatedManagement);
    setDataModified(true);
  };

  const handleEmailChange = (e, index) => {
    const updatedManagement = [...topManagement];
    updatedManagement[index].email = e.target.value;
    setTopManagement(updatedManagement);
    setDataModified(true);
  };

  const handleMobileChange = (e, index) => {
    const updatedManagement = [...topManagement];
    updatedManagement[index].mobile = e.target.value;
    setTopManagement(updatedManagement);
    setDataModified(true);
  };

  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.code,
    label: country.name,
  }));

  const stateOptions = State.getStatesOfCountry("IN").map((state) => ({
    label: state.name,
    value: state.id,
  }));

  const cityOptions = City.getCitiesOfCountry("IN").map((city) => {
    return { value: city.name, label: city.name };
  });

  const validateForm = () => {
    const {
      name,
      landlineNumber,
      selectedCity,
      mobileNumber,
      email,
      alternateNumber,
      code,
    } = formValues;

    const { zipcode, line1, line2 } = address;

    const addressRegex = /^[a-zA-Z0-9\s,'-]{4,150}$/;
    const zipcodeRegex = /^(?![.])(?=.*[A-Z\d])[A-Z\d]{1,10}$/;
    const nameRegex = /^[a-zA-Z0-9\s,'-]{3,50}$/;
    const landlineNumberRegex = /^(?!\.)\d{0,11}$/;
    const PhoneNoRegex = /^\d{10}$/;
    const emailRegex = /\S+@\S+\.\S+/;
    const alternateNumberRegex = /^\d{10}$/;

    const errors = {};

    if (line1 === "") {
      errors.line1 = "Address is required";
    } else if (!addressRegex.test(line1) && line1) {
      errors.line1 = "Please enter a valid address";
    }

    if (line2 === "") {
      errors.line2 = "Address is required";
    } else if (!addressRegex.test(line2) && line2) {
      errors.line2 = "Please enter a valid address";
    }

    if (zipcode === "") {
      errors.zipcode = "Pin code is required ";
    } else if (!zipcodeRegex.test(zipcode) && zipcode) {
      errors.zipcode = "Please enter a valid pin code";
    }

    if (code === "") {
      errors.code = "code is required ";
    }

    // if (name === "") {
    //   errors.name = " School name is required";
    // } else if (!nameRegex.test(name) && name) {
    //   errors.name = "Please enter a valid school name";
    // }

    if (landlineNumber === "") {
      errors.landlineNumber = "Landline number is required";
    } else if (!landlineNumberRegex.test(landlineNumber) && landlineNumber) {
      errors.landlineNumber = "Please enter 11 digit landline number";
    }

    if (mobileNumber === "") {
      errors.mobileNumber = "Phone number is required";
    } else if (!PhoneNoRegex.test(mobileNumber) && mobileNumber) {
      errors.mobileNumber = "Please enter 10 digit phone number";
    }

    // if (alternateNumber === "") {
    //   errors.alternateNumber = "Phone number is required";
    // } else if (!alternateNumberRegex.test(alternateNumber) && alternateNumber) {
    //   errors.alternateNumber = "Please enter 10 digit phone number";
    // }

    if (email === "") {
      errors.email = "Email is required";
    } else if (!emailRegex.test(email) && email) {
      errors.email = "Please enter a valid email address";
    }

    if (selectedCity === null) {
      errors.selectedCity = "Please select a city";
    }

    if (
      topManagement.some((TM) => !emailRegex.test(TM.email) && TM.email !== "")
    ) {
      errors.topManagementError = "Enter a valid email address";
    } else if (
      topManagement.some(
        (TM) => !alternateNumberRegex.test(TM.mobile) && TM.mobile !== ""
      )
    ) {
      errors.topManagementError = "Please enter 10 digit phone number";
    }

    return errors;
  };

  const errors = validateForm();

  const isFormValid = Object.keys(errors).length === 0;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfig = async () => {
      if (schoolAuthId) {
        const getSchoolCountry = async () => {
          const response = await axios.get(
            `/api/v1/school/get/${schoolAuthId}`
          );
          const country = response.data.data.address.country;
          var countryCode = lookup
            .countries({ name: country })[0]
            .countryCallingCodes[0].slice(1);
          return countryCode;
        };
        dispatch(fetchConfigDataThunk(await getSchoolCountry()));
      }
    };

    try {
      fetchConfig();
    } catch (error) {
      handleError(error.message);
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/v1/school/get/${schoolAuthId}`);
        response && setBasicInfoObj(response.data.data);
        response && response.data.status && setFirstTime(true);
        response &&
          response.data.status &&
          setBAsicInfoID(response.data.data.id);
        response && response.data.status && setSchoolId(response.data.data.id);
        response &&
          response.data.status &&
          localStorage.setItem(
            "schoolId",
            JSON.stringify(response.data.data.id)
          );
        response &&
          response.data.status &&
          setAddressID(response.data.data.address.id);
        const responseData = response.data.data;

        setProfile_image(responseData?.profile_pic);

        setFormValues({
          name: responseData.name,
          email: responseData.email,
          code: responseData.code,
          mobileNumber: responseData.mobile_number,
          landlineNumber: responseData.landline_number,
          alternateNumber: responseData.alternate_number,
        });

        setAddress({
          buildingNumber: responseData.address.building_number,
          line1: responseData.address.line1,
          line2: responseData.address.line2,
          city: {
            value: responseData.address.city,
            label: responseData.address.city,
          },
          state: {
            value: responseData.address.state,
            label: responseData.address.state,
          },
          zipcode: responseData.address.zipcode,
          country: {
            value: responseData.address.country,
            label: responseData.address.country,
          },
        });

        setTopManagement(
          responseData.topManagement.map((management) => ({
            role: management.role.name,
            name: management.name,
            email: management.email,
            mobile: management.mobile,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        handleError(error.response.data.message);
      }
    };

    fetchData();
  }, [schoolAuthId]);
  const b4sConfigData = useSelector((state) => state.configuration.configData);
  console.log(b4sConfigData);
  const updateAPI = async () => {
    if ((dataModified || picModified) && basicInfoID) {
      let profile_picture;
      if (picModified && profile) {
        const formData = new FormData();
        formData.append("name", profile.name);
        formData.append("uploadSchoolImage", profile);
        try {
          const res = await axios.post(
            `/api/v1/school/uploadAttachment`,
            formData
          );
          profile_picture = res.data.data;
        } catch (error) {
          console.log(error);
          handleError(error.response.data.message);
        }
      }
      if (isFormValid) {
        try {
          const body = {
            ...formValues,
            id: basicInfoID,
            profilePic: picModified ? profile_picture : profile_image,
            school_id: schoolAuthId,
            address: {
              id: addressID,
              ...address,
              city: address.city.label,
              state: address.state.label,
              country: address.country.label,
            },
            topManagement: [...topManagement],
            //We need to change this hardcoded value to actual ones #IMPORTANT(20-04-2024)
            b4sRegionalCode: "91",
            b4sEdtechSegment: "school",
          };

          const res = await axios.put(
            `/api/v1/school/update/${basicInfoID}`,
            body
          );
          res.data.status &&
            localStorage.setItem("schoolId", JSON.stringify(res.data.data.id));
        } catch (error) {
          console.log(error);
          handleError(error.response.data.message);
        }
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let profile_picture;
    if (!firstTime) {
      if (profile) {
        const formData = new FormData();
        formData.append("name", profile.name);
        formData.append("uploadSchoolImage", profile);
        try {
          const res = await axios.post(
            `/api/v1/school/uploadAttachment`,
            formData
          );
          profile_picture = res.data.data;
        } catch (error) {
          handleError(error.response.data.message);
          console.log(error);
        }
      }
      if (isFormValid) {
        try {
          const body = {
            ...formValues,
            profile_pic: profile_picture,
            school_id: schoolAuthId,
            address: {
              ...address,
              city: address.city.label,
              state: address.state.label,
              country: address.country.label,
            },
            topManagement: [...topManagement],
          };

          // const response = await axios.post(`/api/v1/school/create`, body);
          // response.data.status &&
          //   localStorage.setItem(
          //     "schoolId",
          //     JSON.stringify(response.data.data.id)
          //   );
        } catch (error) {
          handleError(error.response.data.message);
          console.log(error);
        }
      }
      setClick((prev) => ({ ...prev, masterData: true }));
      history("/AddMasterData");
    } else {
      updateAPI();
      setClick((prev) => ({ ...prev, masterData: true }));
      history("/AddMasterData");
    }
  };

  return (
    <>
      {!click.masterData && (
        <div className="Total_container_BasicInfo ">
          <div className="d-flex justify-content-between align-items-center">
            <img
              className="SchoolIcon_basicInfo mb-2"
              src="/OnBoard/SchoolIcon.png"
              alt="banner img"
            />
            <LogOutButton />
          </div>
          <div className="steps mt-5 bottomMargin">Steps 1/5</div>
          <div className="d-flex  mt-4">
            <span className="d-flex  justify-content-center">
              <span className="Active_circle"></span>
            </span>
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            {/* <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div> */}
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            {/* <span className="innerDiv"></span>
            <div className="d-flex justify-content-center ">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center ">
              <div className="NonActive_circle"></div>
            </div> */}
          </div>

          <div className="d-flex">
            <span className="d-flex justify-content-center">
              <span className="Active_circle_name ">Basic</span>
            </span>

            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-3">Master Data</div>
            </div>
            {/* <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div>Permission</div>
            </div> */}
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-3 pr-1">Class</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4 pl-3">Teachers</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4">Students</div>
            </div>
            {/* <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-2">Engagement</div>
            </div>

            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4">Query</div>
            </div> */}
          </div>

          <div className="schoolInfo_head">School Information</div>
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="SchoolInfoTopPart">
                <div className=" d-flex flex-wrap heightProp align-items-center">
                  <img
                    src={`${
                      profile
                        ? URL.createObjectURL(profile)
                        : profile_image
                        ? `${IP + profile_image?.replace(/^\./, "")}`
                        : "/OnBoard/uploadImg.png"
                    }`}
                    className="uploadImg"
                    alt="uploadimg"
                  />
                  <div className="uploadBtn_Subcontainer">
                    <div>
                      <div className="addProfile_title ml-4">
                        Add Profile Picture
                      </div>
                      <div className="addProfileText ml-3">
                        (Logo or School Image)
                      </div>
                      <div>
                        <div>
                          <input
                            type="file"
                            id="attachFile"
                            onChange={imageHandler}
                            style={{ display: "none" }}
                          />
                        </div>
                        <label htmlFor="attachFile">
                          <span className="uploadFileBtn">Upload file</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="basicInfoSubContainer">
                  <div>
                    <div className="basicInput_labels ">
                      School name <span className="important_input">*</span>{" "}
                    </div>
                    <input
                      className="BasicInfo_input "
                      type="text"
                      name="name"
                      value={formValues.name}
                      readOnly
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormValues((prev) => ({
                          ...prev,
                          name: value,
                        }));
                        setDataModified(true);
                      }}
                      placeholder="School name"
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                  <div>
                    <div className="basicInput_labels ">
                      Education Board <span className="important_input">*</span>{" "}
                    </div>
                    <input
                      className="BasicInfo_input "
                      type="text"
                      name="name"
                      value={
                        basicInfoObj?.educationBoard?.name
                          ? basicInfoObj?.educationBoard?.name
                          : "-"
                      }
                      readOnly
                      maxLength={50}
                      placeholder="Education Board"
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                  <div>
                    <div className="basicInput_labels ">
                      Landline number <span className="important_input">*</span>{" "}
                    </div>
                    <input
                      className="BasicInfo_input"
                      type="number"
                      name="landlineNumber"
                      value={formValues.landlineNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormValues((prev) => ({
                          ...prev,
                          landlineNumber: value,
                        }));
                        setDataModified(true);
                      }}
                      placeholder="Landline number"
                    />
                    {errors.landlineNumber && (
                      <span className="error">{errors.landlineNumber}</span>
                    )}
                  </div>
                  <div>
                    <div className="basicInput_labels mt-2">
                      Mobile number<span className="important_input">*</span>{" "}
                    </div>
                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        height: "56px",
                        borderRadius: "8px",
                        pointerEvents: "none",
                      }}
                      country={"in"}
                      disableDropdown
                      readOnly
                      value={
                        formValues.mobileNumber &&
                        !formValues.mobileNumber.startsWith("91")
                          ? `91${formValues.mobileNumber}`
                          : formValues.mobileNumber
                      }
                      enableAreaCodes={true}
                      placeholder="Mobile number"
                      maxLength={12}
                      // onChange={(phone) => {
                      //   setFormValues((prevFormValues) => ({
                      //     ...prevFormValues,
                      //     mobileNumber: phone,
                      //   }));
                      //   setDataModified(true);
                      // }}
                    />
                    {errors.mobileNumber && (
                      <span className="error">{errors.mobileNumber}</span>
                    )}
                  </div>
                </div>
              </div>
              {!isValidFileType && (
                <span className="errorImage">
                  Please upload a file with a valid extension (JPG, JPEG, PNG)
                  and file size should be less then 10MB.
                </span>
              )}
              <div className="SchoolInfoTopPart mt-5">
                <div className="heightPropNext basicInfoSubContainer">
                  <div>
                    <div className="basicInput_labels_address">
                      Email <span className="important_input">*</span>{" "}
                    </div>
                    <input
                      className="BasicInfo_input"
                      type="text"
                      name="email"
                      readOnly
                      value={formValues.email}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormValues((prev) => ({
                          ...prev,
                          email: value,
                        }));
                        setDataModified(true);
                      }}
                      placeholder="example@office.com"
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                  {/* <div>
                    <div className="basicInput_labels_address">
                      Alternate Number{" "}
                    </div>
                    <input
                      className="BasicInfo_input"
                      type="text"
                      name="AlternateNumber"
                      value={formValues.alternateNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormValues((prev) => ({
                          ...prev,
                          alternateNumber: value,
                        }));
                        setDataModified(true);
                      }}
                      placeholder="Alternate Number"
                    />
                    {errors.alternateNumber && (
                      <span className="error">{errors.alternateNumber}</span>
                    )}
                  </div> */}
                  <div>
                    <div className="basicInput_labels_address  ">
                      Address 1 <span className="important_input">*</span>{" "}
                    </div>
                    <input
                      className="BasicInfo_input"
                      type="text"
                      name="line1"
                      maxLength={150}
                      value={address.line1}
                      onChange={(e) => {
                        const value = e.target.value;
                        setAddress((prev) => ({
                          ...prev,
                          line1: value,
                        }));
                        setDataModified(true);
                      }}
                      placeholder="Address 1"
                    />
                    {errors.line1 && (
                      <span className="error">{errors.line1}</span>
                    )}
                  </div>
                  <div>
                    <div className="basicInput_labels ">
                      Pin Code <span className="important_input">*</span>{" "}
                    </div>
                    <input
                      className="BasicInfo_input"
                      type="text"
                      name="zipcode"
                      readOnly
                      value={address.zipcode}
                      maxLength={10}
                      onChange={(e) => {
                        const value = e.target.value;
                        setAddress((prev) => ({
                          ...prev,
                          zipcode: value.toUpperCase(),
                        }));
                        setDataModified(true);
                      }}
                      placeholder="Pin Code"
                    />
                    {errors.zipcode && (
                      <span className="error">{errors.zipcode}</span>
                    )}
                  </div>
                  <div>
                    <div className="basicInput_labels_address ">
                      State<span className="important_input">*</span>{" "}
                    </div>
                    <Select
                      options={stateOptions}
                      value={address.state}
                      onChange={handleStateChange}
                      placeholder="State"
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="basicInfoSubContainer">
                  <div>
                    <div className="basicInput_labels_address">
                      Code <span className="important_input">*</span>{" "}
                    </div>
                    <input
                      className="BasicInfo_input"
                      type="text"
                      name="code"
                      readOnly
                      value={formValues.code}
                      maxLength={10}
                      onChange={(e) => {
                        const value = e.target.value.slice(0, 10);
                        setFormValues((prev) => ({
                          ...prev,
                          code: value,
                        }));
                        setDataModified(true);
                      }}
                      placeholder="School Code"
                    />
                  </div>
                  <div>
                    <div className="basicInput_labels_address  ">Address 2</div>
                    <input
                      className="BasicInfo_input"
                      type="text"
                      name="line2"
                      maxLength={150}
                      value={address.line2}
                      onChange={(e) => {
                        const value = e.target.value;
                        setAddress((prev) => ({
                          ...prev,
                          line2: value,
                        }));
                        setDataModified(true);
                      }}
                      placeholder="Address 2"
                    />
                    {errors.line2 && (
                      <span className="error">{errors.line2}</span>
                    )}
                  </div>
                  <div>
                    <div className="basicInput_labels ">
                      City <span className="important_input">*</span>{" "}
                    </div>
                    <Select
                      options={cityOptions}
                      value={address.city}
                      onChange={handleCityChange}
                      styles={customStyles}
                      placeholder="City"
                    />
                  </div>
                  <div>
                    <div className="basicInput_labels ">
                      Country <span className="important_input">*</span>{" "}
                    </div>
                    <Select
                      options={countryOptions}
                      value={address.country}
                      onChange={handleCountryChange}
                      styles={customStyles}
                      placeholder="Country"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="TopMangment_header ">Top Level Management</div>

            <div className="d-flex justify-content-between flex-wrap ">
              {topManagement.map((person, index) => (
                <div className="continer_topInputs" key={index}>
                  <div className="principal_div mt-4">{person.role}</div>
                  <div>
                    <input
                      className="TopManagment_inputs mt-2"
                      placeholder="Name"
                      maxLength={50}
                      value={person.name}
                      onChange={(e) => handleNameChange(e, index)}
                    />
                  </div>
                  <div>
                    <input
                      className="TopManagment_inputs mt-3"
                      placeholder="Email"
                      maxLength={50}
                      value={person.email}
                      onChange={(e) => handleEmailChange(e, index)}
                    />
                  </div>
                  <div>
                    <input
                      className="TopManagment_inputs mt-3"
                      placeholder="Mobile"
                      maxLength={10}
                      value={person.mobile}
                      onChange={(e) => handleMobileChange(e, index)}
                    />
                  </div>
                </div>
              ))}
              {errors.topManagementError && (
                <span
                  className="error m-4"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  {errors.topManagementError}
                </span>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="button"
                onClick={(e) => {
                  setClick((prevClick) => ({
                    ...prevClick,
                    addAuth: true,
                    masterData: false,
                  }));
                }}
                className="AddNew_Auth"
              >
                <BsPlusCircle /> &nbsp;Add New Authority
              </button>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
              <div>
                <span className="footer_text1">
                  {/* For bulk upload please use this template */}
                </span>
                {/* <span className="footer_text2">Download template</span> */}
              </div>
              <div className="d-flex gap-4 ">
                {/* <div className="uploadFileBtn_footer">
                  <input
                    type="file"
                    id="attachFile"
                    style={{ display: "none" }}
                  />
                  <label htmlFor="attachFile">
                    <span>
                      <BsPlusCircle /> &nbsp;Upload file
                    </span>
                  </label>
                </div> */}

                {/* <Link to="/AddMasterData"> */}
                <button
                  id="NextBtn_school"
                  type="submit"
                  disabled={!isFormValid || topManagementFormValidation}
                >
                  Next
                </button>
                {/* </Link> */}
              </div>
            </div>
          </form>
        </div>
      )}
      {click.addAuth && (
        <AddNewAuth
          setClick={setClick}
          setTopManagement={setTopManagement}
          setDataModified={setDataModified}
        />
      )}
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
      {/* {click.masterData && <AddMasterData />} */}
    </>
  );
}
