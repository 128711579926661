import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
//also some css from usecaseSecond.css , masterdata.css
import "../Class/ClassComp.css";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import DatePicker from "react-datepicker";
import { fireEvent } from "@testing-library/react";
import { useSchoolContext } from "../../../schoolContext";
import axios from "../../../axios";
import { IP } from "../../../internalServer";
import ErrorPopup from "../../../ErrorPopup";
import RemoveErrorPopup from "../../../removePopUp";

export default function StudentInfo({ setAddStudentComp, editId }) {
  const [ayDropdown, setAyDropdown] = useState([]);
  const [ClassOptions, setClassOptions] = useState([]);
  const [SectionOptions, setSectionOptions] = useState([]);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [createErrors, setCreateErrors] = useState(null);
  const [fieldsAddClass, setFieldsAddClass] = useState([]);
  const [aysDropdown, setAysDropdown] = useState([]);

  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.code,
    label: country.name,
  }));

  const stateOptions = State.getStatesOfCountry("IN").map((state) => ({
    label: state.name,
    value: state.id,
  }));

  const cityOptions = City.getCitiesOfCountry("IN").map((city) => {
    return { value: city.name, label: city.name };
  });

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };
  async function  removeDuplicateObjectsStd(arr) {
    const uniqueArray = [];
    arr.forEach(obj => {
      const isDuplicate = uniqueArray.some(item => item.standard.id === obj.standard.id);
      if (!isDuplicate) {
        uniqueArray.push(obj);
      }
    });
    return uniqueArray;
  }
  async function  removeDuplicateObjectsSec(arr) {
    const uniqueArray = [];
    arr.forEach(obj => {
      const isDuplicate = uniqueArray.some(item => item.section.id === obj.section.id);
      if (!isDuplicate) {
        uniqueArray.push(obj);
      }
    });
    return uniqueArray;
  }
  useEffect(() => {
    async function fetchClassessData() {
      try {
        const res = await axios.get(
          `/api/v1/school/standardSection/get/school/${schoolId}`
        );
        if (res && res.data && res.data.data) {
          if (res.data.data.length > 0) {
            let mappedFormsData = res.data.data;
            console.log(mappedFormsData);
            setFieldsAddClass(mappedFormsData);
            let finalSec=await removeDuplicateObjectsSec(mappedFormsData)
            setSectionOptions(finalSec);
            let finalStd=await removeDuplicateObjectsStd(mappedFormsData);
            setClassOptions(finalStd);
          } else {
            setFieldsAddClass([]);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchClassessData();
  }, [schoolId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (schoolId) {
          const response = await axios.get(
            `/api/v1/school/academicYear/list/get/school/${schoolId}`
          );
          const dataModified = response.data.data.map((data, index) => ({
            value: data.id,
            label: data.name,
          }));
          if (response.data.data.length > 0) {
            setAyDropdown(dataModified);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching sessions:", error);
      }
    };

    fetchData();
  }, [schoolId]);

  // useEffect(() => {
  //   async function fetchClass() {
  //     try {
  //       const res = await axios.get(
  //         `/api/v1/school/standard/get/school/${schoolId}`
  //       );
  //       if (res && res.data && res.data.data) {
  //         const mappedClass = res.data.data.map((classItem) => {
  //           return {
  //             value: classItem.id,
  //             label: classItem.name,
  //           };
  //         });
  //         setClassOptions(mappedClass);
  //       }
  //     } catch (error) {}
  //   }
  //   fetchClass();
  // }, [schoolId]);

  // useEffect(() => {
  //   async function fetchSection() {
  //     try {
  //       const res = await axios.get(
  //         `/api/v1/school/section/get/school/${schoolId}`
  //       );
  //       if (res && res.data && res.data.data) {
  //         const mappedSection = res.data.data.map((sec) => {
  //           return {
  //             value: sec.id,
  //             label: sec.name,
  //           };
  //         });
  //         setSectionOptions(mappedSection);
  //       }
  //     } catch (error) {}
  //   }
  //   fetchSection();
  // }, [schoolId]);

  const fetchData = async (academicYearSession, id) => {
    try {
      if (academicYearSession.value) {
        const response = await axios.get(
          `/api/v1/school/schoolSession/getByAcademicSession?academicYearSession=${academicYearSession.value}`
        );
        const createdSessions = response.data.data.map(
          (createdSession, index) => ({
            value: createdSession.timeTableSession,
            label: createdSession.remark,
          })
        );
        if (createdSessions.length > 0) {
          const newFields = [...fieldsAddStudent];
          const index = newFields.findIndex((field) => field.id === id);
          newFields[index].timeTableOptions = createdSessions;
          setFieldsAddStudent(newFields);
          return createdSessions;
        }
        return [];
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error fetching sessions:", error);
    }
  };
  const fetchDataOptions = async (academicYearSession) => {
    try {
      if (academicYearSession.value) {
        const response = await axios.get(
          `/api/v1/school/schoolSession/getByAcademicSession?academicYearSession=${academicYearSession.value}`
        );
        const createdSessions = response.data.data.map(
          (createdSession, index) => ({
            value: createdSession.timeTableSession,
            label: createdSession.remark,
          })
        );
        return createdSessions;
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error fetching sessions:", error);
    }
  };

  const DropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#99ADBB",
      minHeight: "30px",
      padding: "11px",
      borderRadius: " 8px",
      boxShadow: state.isFocused ? null : null,
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };
  console.log(ClassOptions);
  // code for Student add fields more
  const [fieldsAddStudent, setFieldsAddStudent] = useState([
    {
      id: 1,
      Fname: "",
      NewDate: null,
      SchoolEmail: "",
      FatherName: "",
      EmailID: "",
      academicYear: "",
      Mnumber: "",
      gender: "",
      SmobileNumber: "",
      date_of_joining: "",
      emergencyContactName: "",
      studentEmail: "",
      admissionNumber: "",
      parent: true,
      Occupation: "",
      MotherName: "",
      EmailId2: "",
      Mnumber2: "",
      Occupation2: "",
      MDM: "",
      Bgroup: "",
      Mtongue: "",
      code: "",
      academicSession: "",
      timeTableSession: "",
      SiblingInfo: "",
      EmergencyNO: "",
      Bgroup2: "",
      Class: "",
      Section: "",
      SpecialAbled: false,
      teacherInfo: [],
      address: {
        buildingNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
    },
  ]);
  const handleAddFieldAddStudent = () => {
    const newFields = [...fieldsAddStudent];
    newFields.push({
      id: fieldsAddStudent.length + 1,
      Fname: "",
      NewDate: null,
      SchoolEmail: "",
      FatherName: "",
      studentEmail: "",
      EmailID: "",
      Mnumber: "",
      SmobileNumber: "",
      gender: "",
      date_of_joining: "",
      academicYear: "",
      admissionNumber: "",
      Occupation: "",
      MotherName: "",
      parent: true,
      EmailId2: "",
      code: "",
      timeTableSession: "",
      Mnumber2: "",
      Occupation2: "",
      MDM: "",
      Bgroup: "",
      Mtongue: "",
      SiblingInfo: "",
      emergencyContactName: "",
      EmergencyNO: "",
      Bgroup2: "",
      Class: "",
      Section: "",
      teacherInfo: [],
      SpecialAbled: false,
      address: {
        buildingNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
    });
    setFieldsAddStudent(newFields);
  };

  const findTeacherInfo = async (standard, section, timetableSession) => {
    // debugger;
    try {
      const res = await axios.get(
        `/api/v1/school/teacher/getTeacherInfoByStandardSectionId?section_id=${section}&standard_id=${standard}&timetableSession=${timetableSession}`
      );
      return res.data.data.filter((obj) => obj.teacher);
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 56,
      width: "100%",
      borderRadius: "8px",
      background: "#FFFFFF",
      padding: " 5px",
      border: "1px solid #99ADBB",
      fontFamily: "MinecrafterReg.ttf",
    }),
  };

  useEffect(() => {
    async function fetchClass() {
      try {
        const res = await axios.get(
          `/api/v1/school/student/get/school/${schoolId}`
        );
        if (res && res.data && res.data.data && res.data.data.length > 0) {
          const responseData = res.data.data.filter(
            (data) => data?.active !== false
          );
          Promise.all(
            responseData.map(async (item, index) => {
              return {
                studentId: item.id,
                id: index + 1,
                Fname: item.name,
                NewDate: new Date(item.dateOfBirth),
                profile_picture: item.profile_picture,
                SchoolEmail: item.schoolEmail,
                FatherName: item.father.name,
                EmailID: item.father.email,
                academicYear: {
                  label: item.academicYear.name,
                  value: item.academicYear.id,
                },
                Mnumber: item.father.mobileNumber,
                gender: item.gender,
                SmobileNumber: item.mobileNumber,
                date_of_joining: new Date(item.joiningDate),
                emergencyContactName: item.emergencyContactName,
                studentEmail: item.email,
                admissionNumber: item.admissionNumber,
                SpecialAbled: item.specialAbled,
                parent: item.parent,
                speciallyAbledCertificate: item.speciallyAbledCertificate,
                Occupation: item.father.occupation,
                MotherName: item.mother.name,
                EmailId2: item.mother.email,
                Mnumber2: item.mother.mobileNumber,
                Occupation2: item.mother.occupation,
                MDM: item.mdmId,
                Bgroup: item.bloodGroup,
                Mtongue: item.motherTongue,
                code: item.code,
                academicSession: {
                  value: item?.academicYearSession?.id,
                  label: item?.academicYearSession?.name,
                },
                timeTableSession: {
                  label: item.timetableSession.name,
                  value: item.timetableSession.id,
                },
                SiblingInfo: item.siblingInformation,
                EmergencyNO: item.emergencyContactNumber,
                Bgroup: item.bloodGroup,
                Class: item?.standard
                  ? { label: item?.standard?.name, value: item?.standard?.id }
                  : "",
                Section: item?.section
                  ? { label: item?.section?.name, value: item?.section?.id }
                  : "",
                teacherInfo: await findTeacherInfo(
                  item?.standard?.id,
                  item?.section?.id,
                  item?.timetableSession?.id
                ),
                address: {
                  addressId: item.address.id,
                  buildingNumber: item.address.buildingNumber,
                  addressLine1: item.address.line1,
                  addressLine2: item.address.line2,
                  city: {
                    value: item.address.city,
                    label: item.address.city,
                  },
                  state: {
                    value: item.address.state,
                    label: item.address.state,
                  },
                  zipcode: item.address.zipcode,
                  country: {
                    value: item.address.country,
                    label: item.address.country,
                  },
                },
              };
            })
          )
            .then(async (transformedData) => {
              let finalArray = [];
              if (editId) {
                finalArray.push(
                  transformedData.find((obj) => obj.studentId === editId)
                );
              } else {
                finalArray = transformedData;
              }
              await Promise.all(
                finalArray.map(async (obj, i) => {
                  const timetableSessionOptions = await fetchDataOptions(
                    obj?.academicSession
                  );
                  finalArray[i].timeTableOptions = timetableSessionOptions;
                  fetchAcademicYearSession(obj?.academicYear);
                })
              );
              setFieldsAddStudent(finalArray);
            })
            .catch((error) => {
              console.error("Error transforming data:", error);
            });
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchClass();
  }, [schoolId]);
  const [field, setField] = useState();
  const [field_id, setField_Id] = useState();
  const [showRemovePopUp, setShowRemovePopUp] = useState(false);
  const handleRemoveFieldAddStudent = async () => {
    try {
      if (field.studentId) {
        await axios.delete(`/api/v1/school/student/delete/${field.studentId}`);
      }
    } catch (error) {
      handleError(error.response.data.message);
    }
    const newFields = fieldsAddStudent.filter((field) => field.id !== field_id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsAddStudent(renumberedFields);
  };
  const fetchAcademicYearSession = async (value, id) => {
    const response = await axios.get(
      `/api/v1/school/academicYearSession/get/academicYear/${value?.value}`
    );
    if (response.data.data.length > 0) {
      const mappedAcademicSession = response.data.data.map((classItem) => {
        return {
          value: classItem.id,
          label: classItem.name,
        };
      });
      setAysDropdown(mappedAcademicSession);
    }
  };
  const handleInputAddStudent = (id, name, value) => {
    if (name === "academicYear") {
      fetchAcademicYearSession(value, id);
    }
    if (name === "academicSession") {
      fetchData(value, id);
    }
    const newFields = [...fieldsAddStudent];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    newFields[index].modified = true;
    setFieldsAddStudent(newFields);
  };

  const handleInputAddStudentparentRadio = (id, value) => {
    const newFields = [...fieldsAddStudent];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index].parent = value === "true";
    newFields[index].modified = true;
    setFieldsAddStudent(newFields);
  };

  const handleInputAddStudentGenderRadio = (id, value) => {
    const newFields = [...fieldsAddStudent];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index].gender = value;
    newFields[index].modified = true;
    setFieldsAddStudent(newFields);
  };

  const handleInputAddTeacher = async (field) => {
    // debugger;
    const id = field.id;
    if (field.Class !== "" && field.Section !== "") {
      const res = await axios.get(
        `/api/v1/school/teacher/getTeacherInfoByStandardSectionId?section_id=${field.Section.value}&standard_id=${field.Class.value}&timetableSession=${field?.timeTableSession?.value}`
      );
      const newFields = [...fieldsAddStudent];
      const index = newFields.findIndex((obj) => obj.id === id);
      newFields[index].teacherInfo = res.data.data.filter((obj) => obj.teacher);
      setFieldsAddStudent(newFields);
    }
  };

  const handleInputAddStudentSpeciallyAbled = (id, value) => {
    const newFields = [...fieldsAddStudent];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index].SpecialAbled = value.checked;
    newFields[index].modified = true;
    setFieldsAddStudent(newFields);
  };

  const handleInputAddressChange = (id, name, value) => {
    const newFields = [...fieldsAddStudent];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index].address[name] = value;
    newFields[index].modified = true;
    setFieldsAddStudent(newFields);
  };

  const isfieldsAddStudent = fieldsAddStudent.some(
    (field) =>
      field.Fname === "" ||
      field.SchoolEmail === "" ||
      field.FatherName === "" ||
      field.gender === "" ||
      field.EmailID === "" ||
      field.Mnumber === "" ||
      field.academicYear === "" ||
      field.Occupation === "" ||
      field.studentEmail === "" ||
      field.MotherName === "" ||
      field.EmailId2 === "" ||
      field.timeTableSession === "" ||
      field.SmobileNumber === "" ||
      field.Mnumber2 === "" ||
      field.code === "" ||
      field.emergencyContactName === "" ||
      field.admissionNumber === "" ||
      field.date_of_joining === "" ||
      field.Occupation2 === "" ||
      // field.MDM === "" ||
      field.Mtongue === "" ||
      field.Bgroup === "" ||
    //  field.SiblingInfo === "" ||
      field.EmergencyNO === "" ||
      // field.Bgroup2 === "" ||
      field.Section === "" ||
      field.Class === "" ||
      field.address.buildingNumber === "" ||
      field.address.addressLine1 === "" ||
      field.address.addressLine2 === "" ||
      field.address.city === "" ||
      field.address.state === "" ||
      field.address.zipcode === "" ||
      field.address.country === "" ||
      !field.academicSession ||
      !field.timeTableSession ||
      fieldErrors.length !== 0
  );

  const handleCreateStudent = async () => {
    try {
      for (const student of fieldsAddStudent) {
        // debugger;
        const body = {
          school: schoolId,
          name: student.Fname,
          profile_picture: student.profile_picture
            ? student.profile_picture
            : null,
          gender: student.gender,
          date_of_birth: student.NewDate,
          schoolEmail: student.SchoolEmail,
          speciallyAbledCertificate: student.speciallyAbledCertificate,
          specialAbled: student.SpecialAbled,
          parent: student.parent,
          father: {
            name: student.FatherName,
            Email: student.EmailID,
            mobileNumber: student.Mnumber,
            occupation: student.Occupation,
          },
          mother: {
            name: student.MotherName,
            Email: student.EmailId2,
            mobileNumber: student.Mnumber2,
            occupation: student.Occupation2,
          },
          mdmId: student.MDM,
          blood_group: student?.Bgroup,
          mother_tongue: student.Mtongue,
          sibling_information: student.SiblingInfo,
          academic_year: student.academicYear.value,
          emergency_contact_name: student.emergencyContactName,
          emergency_contact_number: student.EmergencyNO,
          standard: student.Class.value,
          section: student.Section.value,
          academicYearSession:
            student.academicSession && student.academicSession.value,
          timetableSession: student.timeTableSession.value,
          address: {
            id: student.address?.addressId,
            building_number: student.address.buildingNumber,
            line1: student.address.addressLine1,
            line2: student.address.addressLine2,
            city: student.address.city.label,
            state: student.address.state.label,
            zipcode: student.address.zipcode,
            country: student.address.country.label,
          },
          date_of_joining: student.date_of_joining,
          admission_number: student.admissionNumber,
          code: student.code,
          student_email: student.studentEmail,
          mobile_number: student.SmobileNumber,
          teacher_information: student?.teacherInfo?.map((obj) => obj.id),
          // profile_picture: "profile-picture.jpg",
        };
        if (!student.studentId) {
          await axios.post("/api/v1/school/student/create", body);
        } else {
          const reqBody = {
            ...body,
            id: student.studentId,
            modified: student.modified ? true : false,
          };
          if (reqBody.modified) {
            console.log("ASDADS");
            const dat = await axios.put(
              `/api/v1/school/student/update/${student.studentId}`,
              reqBody
            );
            console.log(dat);
          }
        }
      }
      setAddStudentComp({
        editableId: null,
        StudentList: true,
      });
    } catch (error) {
      console.log(error);
      handleError(error.response.data.message);
      setCreateErrors(error?.response?.data?.message);
    }
  };

  const today = new Date();

  const validateFileType = (filePath) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(filePath.name)) {
      return false;
    }
    return true;
  };

  const handleProfileAddImage = async (id, e) => {
    let profile_picture;
    let profile = e.target.files[0];
    const isValid = validateFileType(profile);
    if (profile.size > 10 * 1024 * 1024 || !isValid) {
      window.alert(
        "The File size should be Less than 10 MB And Type must be JPG, JPEG, PNG"
      );
      return;
    }
    if (profile) {
      const formData = new FormData();
      formData.append("name", profile.name);
      formData.append("uploadStudentProfileImage", profile);
      try {
        const res = await axios.post(
          `/api/v1/school/student/profilePicture/school/${schoolId}`,
          formData
        );
        // debugger;
        profile_picture = res.data.data;
        const newFields = [...fieldsAddStudent];
        const index = newFields.findIndex((field) => field.id === id);
        newFields[index].profile_picture = profile_picture;
        newFields[index].modified = true;
        setFieldsAddStudent(newFields);
      } catch (error) {
        handleError(error.response.data.message);
        console.log(error);
      }
    }
  };

  const handleCertificateAddImage = async (id, e) => {
    let profile_picture;
    let profile = e.target.files[0];
    if (profile.size > 10 * 1024 * 1024 || !validateFileType(profile)) {
      window.alert(
        "The File size should be Less than 10 MB And Type must be JPG, JPEG, PNG"
      );
      return;
    }
    if (profile) {
      const formData = new FormData();
      formData.append("name", profile.name);
      formData.append("uploadDisabledCertificate", profile);
      try {
        const res = await axios.post(
          `/api/v1/school/student/certificate/school/${schoolId}`,
          formData
        );
        // debugger;
        profile_picture = res.data.data;
        const newFields = [...fieldsAddStudent];
        const index = newFields.findIndex((field) => field.id === id);
        newFields[index].speciallyAbledCertificate = profile_picture;
        newFields[index].modified = true;
        setFieldsAddStudent(newFields);
      } catch (error) {
        handleError(error.response.data.message);
        console.log(error);
      }
    }
  };

  const validateFields = () => {
    const errors = [];

    fieldsAddStudent.forEach((field) => {
      const fullNameRegex = /^[a-zA-Z\s]+$/;
      // if (!fullNameRegex.test(field.Fname.trim()) && field.Fname !== "") {
      //   errors.push({
      //     id: field.id,
      //     field: "Fname",
      //     message: "This field cannot contain special characters",
      //   });
      // }

      if (
        !fullNameRegex.test(field.FatherName.trim()) &&
        field.FatherName !== ""
      ) {
        errors.push({
          id: field.id,
          field: "FatherName",
          message: "This field cannot contain special characters",
        });
      }

      if (
        !fullNameRegex.test(field.MotherName.trim()) &&
        field.MotherName !== ""
      ) {
        errors.push({
          id: field.id,
          field: "MotherName",
          message: "This field cannot contain special characters",
        });
      }

      if (
        !fullNameRegex.test(field.emergencyContactName.trim()) &&
        field.emergencyContactName !== ""
      ) {
        errors.push({
          id: field.id,
          field: "emergencyContactName",
          message: "This field cannot contain special characters",
        });
      }

      if (
        !fullNameRegex.test(field.Occupation.trim()) &&
        field.Occupation !== ""
      ) {
        errors.push({
          id: field.id,
          field: "Occupation",
          message: "This field cannot contain special characters",
        });
      }

      if (!fullNameRegex.test(field.Mtongue.trim()) && field.Mtongue !== "") {
        errors.push({
          id: field.id,
          field: "Mtongue",
          message: "This field cannot contain special characters",
        });
      }

      if (
        !fullNameRegex.test(field.Occupation2.trim()) &&
        field.Occupation2 !== ""
      ) {
        errors.push({
          id: field.id,
          field: "Occupation2",
          message: "This field cannot contain special characters",
        });
      }

      // if (
      //   !fullNameRegex.test(field.address.city.trim()) &&
      //   field.address.city !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "city",
      //     message: "This field cannot contain special characters",
      //   });
      // }
      // if (
      //   !fullNameRegex.test(field.address.state.trim()) &&
      //   field.address.state !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "state",
      //     message: "This field cannot contain special characters",
      //   });
      // }
      // if (
      //   !fullNameRegex.test(field.address.country.trim()) &&
      //   field.address.country !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "country",
      //     message: "This field cannot contain special characters",
      //   });
      // }

      if (!isValidEmail(field.SchoolEmail) && field.SchoolEmail !== "") {
        errors.push({
          id: field.id,
          field: "SchoolEmail",
          message: "Invalid Email",
        });
      }

      if (!isValidEmail(field.studentEmail) && field.studentEmail !== "") {
        errors.push({
          id: field.id,
          field: "studentEmail",
          message: "Invalid Email",
        });
      }

      if (!isValidEmail(field.EmailID) && field.EmailID !== "") {
        errors.push({
          id: field.id,
          field: "EmailID",
          message: "Invalid Email",
        });
      }

      if (!isValidEmail(field.EmailId2) && field.EmailId2 !== "") {
        errors.push({
          id: field.id,
          field: "EmailId2",
          message: "Invalid Email",
        });
      }

      if (!isValidnumber(field.SmobileNumber) && field.SmobileNumber !== "") {
        errors.push({
          id: field.id,
          field: "SmobileNumber",
          message: "Invalid Number",
        });
      }

      if (!isValidnumber(field.EmergencyNO) && field.EmergencyNO !== "") {
        errors.push({
          id: field.id,
          field: "EmergencyNO",
          message: "Invalid Number",
        });
      }

      if (!isValidnumber(field.Mnumber) && field.Mnumber !== "") {
        errors.push({
          id: field.id,
          field: "Mnumber",
          message: "Invalid Number",
        });
      }

      if (!isValidnumber(field.Mnumber2) && field.Mnumber2 !== "") {
        errors.push({
          id: field.id,
          field: "Mnumber2",
          message: "Invalid Number",
        });
      }

      // const schoolEmailRegex = /^stud\d{4}-\d{4}$/;
      // if (
      //   !schoolEmailRegex.test(field?.admissionNumber?.trim()) &&
      //   field.admissionNumber !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "admissionNumber",
      //     message: "Invalid format",
      //   });
      // }
      if (field.Bgroup) {
        const bloodGroupRegex = /^(A|B|AB|O)([+-]VE)?$/i;

      const match = field.Bgroup?.match(bloodGroupRegex);

        if (!match && field.Bgroup !== "") {
          errors.push({
            id: field.id,
            field: "Bgroup",
            message: "Invalid blood group format",
          });
        }
      }
    });

    setFieldErrors(errors);
    return errors.length === 0;
  };

  const isValidEmail = (email) => {
    if (email.indexOf("@") !== -1 && email.indexOf(".") !== -1) {
      const atIndex = email.indexOf("@");
      if (atIndex > 0) {
        const dotIndex = email.indexOf(".", atIndex);
        if (dotIndex > atIndex + 1) {
          if (dotIndex < email.length - 1) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const isValidnumber = (number) => {
    const digitRegex = /^\d{10}$/;
    return digitRegex.test(number);
  };

  useEffect(() => {
    validateFields();
  }, [fieldsAddStudent]);

  const handleSubmit = async (e) => {
    try {
      let employeeId = [];
      fieldsAddStudent.forEach((obj) => {
        if (!obj.studentId && obj?.modified) {
          employeeId.push(obj?.admissionNumber);
        }
      });
      if (employeeId.length > 0) {
        let duplicateBody = {
          persona: "student",
          schoolId: schoolId,
          userIds: employeeId,
        };
        let response = await axios.post(
          `/api/v1/school/verifyTeacherStudentEmployeeId`,
          duplicateBody
        );
        if (response.data.data.length === 0) {
          handleCreateStudent();
        } else {
          setErrorOccurred(true);
          setErrorMessage(
            `You have entered the duplicate studentId: ${response.data.data}`
          );
        }
      } else {
        handleCreateStudent();
      }
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  const DOBRef = useRef([]);
  const dateOfAdmissionRef = useRef([]);

  const handleDOBClick = (index) => {
    if (DOBRef.current[index]) {
      DOBRef.current[index].setOpen(true);
    }
  };

  const handleDateOfAdmissionRefClick = (index) => {
    if (dateOfAdmissionRef.current[index]) {
      dateOfAdmissionRef.current[index].setOpen(true);
    }
  };

  return (
    <>
      <div>
        <div className="schoolInfo_head">Student infomation</div>
        <div className="heading_belowText">
          Provide below details to update class information.
        </div>

        <div className="d-flex gap-4 MainContainer_addClassInputs mt-4">
          {fieldsAddStudent.map((field, index) => (
            <div key={field.id} className="classInput_container  mr-2">
              <div className="d-flex justify-content-between ">
                <div className="classinfo_subHeader ">
                  Student info- 0{field.id}
                </div>
                {fieldsAddStudent.length !== 1 && (
                  <div
                    className="remove"
                    onClick={() => {
                      setField(field);
                      setField_Id(field?.id);
                      setShowRemovePopUp(true);
                    }}
                  >
                    Remove
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center AddCertificate_Container mt-3">
                <div className="CertificateName">
                  {field.profile_picture
                    ? field.profile_picture.match(/_(\w+\.\w+)$/) &&
                      field.profile_picture.match(/_(\w+\.\w+)$/)[1]
                    : "Add Profile Picture *"}
                </div>
                <div>
                  <input
                    type="file"
                    id={"attachFile" + field.id}
                    // disabled={!field.SpecialAbled}
                    onChange={(e) => handleProfileAddImage(field.id, e)}
                    style={{ display: "none" }}
                  />
                  <label htmlFor={"attachFile" + field.id}>
                    <span className="uploadLabel">
                      {field.profile_picture
                        ? "Change Profile Picture"
                        : "Upload"}{" "}
                    </span>
                  </label>
                </div>
              </div>
              <div className="mt-4">
                <input
                  className="inputSchool_blocks"
                  value={field.Fname}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "Fname", e.target.value)
                  }
                  placeholder="Full Name *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Fname" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="d-flex gap-4 mt-3 flex-wrap">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={field.gender === "M"}
                    value="M"
                    name={"gender_" + field.id}
                    onChange={(e) =>
                      handleInputAddStudentGenderRadio(field.id, e.target.value)
                    }
                    id="flexRadioDefault1"
                  />
                  <label className="sepcialAbled">Male</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={field.gender === "F"}
                    value="F"
                    name={"gender_" + field.id}
                    onChange={(e) =>
                      handleInputAddStudentGenderRadio(field.id, e.target.value)
                    }
                    id="flexRadioDefault2"
                  />
                  <label className="sepcialAbled">Female</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={"gender_" + field.id}
                    value="O"
                    onChange={(e) =>
                      handleInputAddStudentGenderRadio(field.id, e.target.value)
                    }
                    checked={field.gender === "O"}
                    id="flexRadioDefault1"
                  />
                  <label className="sepcialAbled">Other</label>
                </div>
              </div>

              <div className="d-flex container_dateselect_holidays mt-3">
                <DatePicker
                  selected={field.NewDate}
                  onChange={(date) =>
                    handleInputAddStudent(field.id, "NewDate", date)
                  }
                  showYearDropdown
                  scrollableYearDropdown
                  dateFormat="dd/MM/yyyy"
                  className="SelectDateStudent"
                  maxDate={today}
                  endDate={today}
                  placeholderText="DOB *"
                  ref={(ref) => (DOBRef.current[index] = ref)}
                />
                <img
                  src="/OnBoard/BlackcalenderIcon.png"
                  alt="calender Icon"
                  onClick={() => handleDOBClick(index)}
                  style={{ cursor: "pointer" }}
                ></img>
              </div>

              <div className="d-flex container_dateselect_holidays mt-3">
                <DatePicker
                  selected={field.date_of_joining}
                  onChange={(date) =>
                    handleInputAddStudent(field.id, "date_of_joining", date)
                  }
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  scrollableYearDropdown
                  className="SelectDateStudent"
                  maxDate={today}
                  endDate={today}
                  ref={(ref) => (dateOfAdmissionRef.current[index] = ref)}
                  placeholderText="Date Of Admission *"
                />
                <img
                  onClick={() => handleDateOfAdmissionRefClick(index)}
                  src="/OnBoard/BlackcalenderIcon.png"
                  alt="calender Icon"
                  style={{ cursor: "pointer" }}
                ></img>
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.SchoolEmail}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "SchoolEmail",
                      e.target.value
                    )
                  }
                  placeholder="School email id *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "SchoolEmail" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.studentEmail}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "studentEmail",
                      e.target.value
                    )
                  }
                  placeholder="Student Email *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "studentEmail" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.SmobileNumber}
                  maxLength={10}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "SmobileNumber",
                      e.target.value
                    )
                  }
                  placeholder="Student mobile number *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "SmobileNumber" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.admissionNumber}
                  maxLength={30}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "admissionNumber",
                      e.target.value
                    )
                  }
                  placeholder="Admission Number (EX: stud0000-0000) *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "admissionNumber" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.code}
                  maxLength={10}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "code", e.target.value)
                  }
                  placeholder="Code *"
                />
              </div>

              <div className="d-flex gap-4 mt-3 flex-wrap">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`parent_${field.id}`}
                    checked={field.parent === true}
                    onChange={(e) =>
                      handleInputAddStudentparentRadio(field.id, e.target.value)
                    }
                    id="exampleRadios1"
                    value={true}
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Parent
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`parent_${field.id}`}
                    checked={field.parent === false}
                    onChange={(e) =>
                      handleInputAddStudentparentRadio(field.id, e.target.value)
                    }
                    disabled
                    value={false}
                    id="exampleRadios2"
                  />
                  <label className="form-check-label" for="exampleRadios2">
                    Guardian
                  </label>
                  <span style={{ color: "#668598" }}>(Coming Soon)</span>
                </div>
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.FatherName}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "FatherName",
                      e.target.value
                    )
                  }
                  maxLength={50}
                  placeholder="Father Name *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "FatherName" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.EmailID}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "EmailID", e.target.value)
                  }
                  placeholder="Email ID *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "EmailID" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Mnumber}
                  maxLength={10}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "Mnumber", e.target.value)
                  }
                  placeholder="Mobile number *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Mnumber" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Occupation}
                  maxLength={10}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "Occupation",
                      e.target.value
                    )
                  }
                  placeholder="Occupation *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Occupation" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.MotherName}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "MotherName",
                      e.target.value
                    )
                  }
                  placeholder="Mother name *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "MotherName" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.EmailId2}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "EmailId2", e.target.value)
                  }
                  placeholder="Email id *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "EmailId2" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Mnumber2}
                  maxLength={10}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "Mnumber2", e.target.value)
                  }
                  placeholder="Mobile number *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Mnumber2" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Occupation2}
                  maxLength={10}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "Occupation2",
                      e.target.value
                    )
                  }
                  placeholder="Occupation *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Occupation2" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.MDM}
                  maxLength={60}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "MDM", e.target.value)
                  }
                  disabled
                  placeholder="MDM ID / IMEI (Coming Soon) "
                />
              </div>
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Bgroup}
                  maxLength={5}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "Bgroup", e.target.value)
                  }
                  placeholder="Blood Group (EX: A+ve)"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Bgroup" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3 d-flex align-items-center">
                <input
                  type="checkbox"
                  className=""
                  name={"SpecialAbled_" + field.id}
                  value={field.SpecialAbled === true}
                  checked={field.SpecialAbled}
                  onChange={(e) =>
                    handleInputAddStudentSpeciallyAbled(field.id, e.target)
                  }
                />
                <label className="sepcialAbled ml-2 pt-2 ">Special Abled</label>
              </div>

              {/* {field.SpecialAbled && ( */}
              <div className="d-flex justify-content-between align-items-center AddCertificate_Container mt-3">
                <div className="CertificateName">
                  {field?.speciallyAbledCertificate
                    ? field.speciallyAbledCertificate.match(/_(\w+\.\w+)$/) &&
                      field.speciallyAbledCertificate.match(/_(\w+\.\w+)$/)[1]
                    : "Special Abled Certificate"}
                </div>
                <div>
                  <input
                    type="file"
                    id={`attachFile_${field.id}`}
                    onChange={(e) => handleCertificateAddImage(field.id, e)}
                    disabled={!field.SpecialAbled}
                    style={{ display: "none" }}
                  />
                  <label htmlFor={`attachFile_${field.id}`}>
                    <span className="uploadLabel">
                      {field?.speciallyAbledCertificate
                        ? "Re Upload Certificate"
                        : "Upload"}{" "}
                    </span>
                  </label>
                </div>
              </div>
              {/* )} */}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Mtongue}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "Mtongue", e.target.value)
                  }
                  placeholder="Mother Tongue *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Mtongue" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.SiblingInfo}
                  maxLength={100}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "SiblingInfo",
                      e.target.value
                    )
                  }
                  placeholder="Sibling Information "
                />
              </div>
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.emergencyContactName}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "emergencyContactName",
                      e.target.value
                    )
                  }
                  placeholder="Emergency Contact Name *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id &&
                error.field === "emergencyContactName" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.EmergencyNO}
                  onChange={(e) =>
                    handleInputAddStudent(
                      field.id,
                      "EmergencyNO",
                      e.target.value
                    )
                  }
                  placeholder="Emergency Contact Number *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "EmergencyNO" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <Select
                  options={ayDropdown}
                  styles={DropdownStyle}
                  placeholder="Academic Year"
                  value={field.academicYear}
                  onChange={(selectedOptions) => {
                    handleInputAddStudent(
                      field.id,
                      "academicYear",
                      selectedOptions
                    );
                  }}
                />
              </div>
              <div className="mt-3">
                <Select
                  options={aysDropdown}
                  styles={DropdownStyle}
                  value={field.academicSession}
                  placeholder="Academic Session *"
                  onChange={(selectedOptions) => {
                    handleInputAddStudent(
                      field.id,
                      "academicSession",
                      selectedOptions
                    );
                  }}
                />
              </div>
              <div className="mt-3">
                <Select
                  options={field.academicYear ? field.timeTableOptions : []}
                  styles={DropdownStyle}
                  placeholder="Time Table Session *"
                  value={field.timeTableSession}
                  onChange={(selectedOptions) => {
                    handleInputAddStudent(
                      field.id,
                      "timeTableSession",
                      selectedOptions
                    );
                  }}
                />
              </div>
              <div className="mt-3">
                <Select
                  options={
                    ClassOptions.length > 0 &&
                    ClassOptions.filter(
                      (option) =>
                        option?.timetableSessions?.id ===
                        field.timeTableSession.value
                    ).map((option) => option.standard)
                  }
                  styles={DropdownStyle}
                  placeholder="Class *"
                  value={field.Class}
                  onChange={(selectedOptions) => {
                    handleInputAddStudent(field.id, "Class", selectedOptions);
                    handleInputAddTeacher(field);
                  }} // update the state based on the selected options
                />
              </div>

              <div className="mt-3">
                <Select
                  options={SectionOptions.filter(
                    (option) =>
                      option?.timetableSessions?.id ===
                      field.timeTableSession.value
                  ).map((option) => option.section)}
                  styles={DropdownStyle}
                  placeholder="Section *"
                  value={field.Section} // add value prop and set it based on the state
                  onChange={(selectedOptions) => {
                    handleInputAddStudent(field.id, "Section", selectedOptions);
                    handleInputAddTeacher(field);
                  }} // update the state based on the selected options
                />
              </div>
              {/* <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Bgroup}
                  onChange={(e) =>
                    handleInputAddStudent(field.id, "Bgroup2", e.target.value)
                  }
                  placeholder="Blood Group"
                />
              </div> */}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.buildingNumber}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "buildingNumber",
                      e.target.value
                    )
                  }
                  maxLength={30}
                  placeholder="Building Number"
                />
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.addressLine1}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "addressLine1",
                      e.target.value
                    )
                  }
                  maxLength={150}
                  placeholder="Address Line 1 *"
                />
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.addressLine2}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "addressLine2",
                      e.target.value
                    )
                  }
                  maxLength={150}
                  placeholder="Address Line 2 *"
                />
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.zipcode}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "zipcode",
                      e.target.value
                    )
                  }
                  maxLength={10}
                  placeholder="Zipcode *"
                />
              </div>

              <div className="mt-3">
                {/* <input
                  className="inputSchool_blocks"
                  value={field.address.state}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "state", e.target.value)
                  }
                  maxLength={20}
                  placeholder="State *"
                /> */}
                <Select
                  options={stateOptions}
                  value={field.address.state}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "state", e)
                  }
                  placeholder="State *"
                  styles={customStyles}
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "state" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                {/* <input
                  className="inputSchool_blocks"
                  value={field.address.city}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "city", e.target.value)
                  }
                  maxLength={20}
                  placeholder="City *"
                /> */}
                <Select
                  options={cityOptions}
                  value={field.address.city}
                  onChange={(selectedOption) =>
                    handleInputAddressChange(field.id, "city", selectedOption)
                  }
                  styles={customStyles}
                  placeholder="City *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "city" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                {/* <input
                  className="inputSchool_blocks"
                  value={field.address.country}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "country",
                      e.target.value
                    )
                  }
                  maxLength={20}
                  placeholder="Country *"
                /> */}
                <Select
                  options={countryOptions}
                  value={field.address.country}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "country", e)
                  }
                  styles={customStyles}
                  placeholder="Country *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "country" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              {field?.teacherInfo?.length > 0 && (
                <div className="totalClassNames_container mt-3">
                  {field.teacherInfo.map(
                    (obj) =>
                      obj.isClassTeacher && (
                        <div className="d-flex justify-content-between mt-2 classTeacherNamesContainer ">
                          <div className="d-flex gap-2 align-items-center">
                            {/* <img
                              src="/OnBoard/TeacherTableImg.png"
                              alt="profile img"
                            /> */}
                            <img
                              src={
                                obj.teacher.profilePicture
                                  ? `${
                                      IP +
                                      obj.teacher.profilePicture?.replace(
                                        /^\./,
                                        ""
                                      )
                                    }`
                                  : "/OnBoard/TeacherTableImg.png"
                              }
                              className="imagesSize"
                              alt="profile img"
                            />
                            <div className="pt-1"> {obj.teacher.name}</div>
                          </div>

                          <div className="pt-1"> Class teacher</div>
                        </div>
                      )
                  )}
                  <div className="header_subjectText mt-2">Subject Teacher</div>
                  <div className="d-flex gap-4 flex-wrap mt-3">
                    {field.teacherInfo
                      .reduce((accumulator, teacherInfo) => {
                        const { teacher, subjects } = teacherInfo;
                        const { name: teacherName, profilePicture: profile } =
                          teacher;
                        const subjectDetails = subjects.map((subject) => ({
                          name: teacherName,
                          subject: subject.name,
                          profilePic: profile,
                        }));
                        return [...accumulator, ...subjectDetails];
                      }, [])
                      .map((teacher, index) => (
                        <div className="d-flex gap-2 align-items-center teacherWidth">
                          <div>
                            {/* <img
                              src="/OnBoard/TeacherTableImg.png"
                              alt="profile img"
                            /> */}
                            <img
                              src={
                                teacher.profilePic
                                  ? `${
                                      IP +
                                      teacher.profilePic?.replace(/^\./, "")
                                    }`
                                  : "/OnBoard/TeacherTableImg.png"
                              }
                              className="imagesSize"
                              alt="profile img"
                            />
                          </div>
                          <div>
                            <div className="name_STeacher">{teacher.name}</div>
                            <div className="Tsubject_name">
                              {teacher.subject}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ))}

          {!editId && (
            <div className="addClassContainer ">
              <button
                onClick={handleAddFieldAddStudent}
                className="AddClassBtn"
              >
                <BsPlusCircle /> Add Student
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
        {/* <div>
          <span className="footer_text1">
            For bulk upload please use this template
          </span>
          <span className="footer_text2">Download template</span>
        </div> */}

        <div>
          {" "}
          {createErrors && (
            <span style={{ color: "red" }}>*{createErrors}</span>
          )}
        </div>

        <div className="d-flex flex-wrap gap-3">
          {/* <div className="uploadFileBtn_MasterData">
            <input type="file" id="attachFile" style={{ display: "none" }} />
            <label htmlFor="attachFile">
              <span>
                <BsPlusCircle /> &nbsp;Upload file
              </span>
            </label>
          </div> */}

          <button
            id="NextBtn_school_masterData"
            onClick={handleSubmit}
            disabled={isfieldsAddStudent}
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
      {showRemovePopUp && (
        <RemoveErrorPopup
          closePopup={() => {
            setShowRemovePopUp(false);
          }}
          handleDelete={handleRemoveFieldAddStudent}
        />
      )}
    </>
  );
}
