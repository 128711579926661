import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import { useSchoolContext } from "../../../schoolContext";
import axios from "../../../axios";
import DeletePopup from "./DeletePopup";
import InActivePopup from "./InActivePopup";
import { Link } from "react-router-dom";
import { IP } from "../../../internalServer";
import ErrorPopup from "../../../ErrorPopup";
import { useSelector } from "react-redux";

export default function ClassList({ setAddClassComp, addclassComp }) {
  const b4sData = useSelector((state) => state.configuration.configData);
  const [classList, setClassList] = useState({
    historyBtn: "classInfo",
  });
  const [classesData, setClassesData] = useState(new Map());
  const [showSubjects, setShowSubjects] = useState(false);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isActive, setisActive] = useState(false);

  const [ElementId, setElementId] = useState(null);
  const [toggler, setyToggler] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  console.log(classesData);
  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  const handleDeleteClick = (id) => {
    setDeletePopupOpen(true);
    setElementId(id);
  };

  const handleInActiveClick = (item) => {
    setisActive(true);
    setElementId(item);
  };

  useEffect(() => {
    setLoading(!addclassComp?.isLoading);
  }, [addclassComp.isLoading]);

  const handleEdit = (e, id) =>
    setAddClassComp((prev) => ({
      ...prev,
      classinfo: true,
      classList: false,
      editableId: id,
    }));

  useEffect(() => {
    async function fetchSections() {
      try {
        const standardsMap = new Map();
        const sortQueryParam = (() => {
          switch (selectedOption) {
            case "LastModified":
              return "Modified";
            case "InactiveState":
              return "InActive";
            case "ActiveState":
              return "Active";
            case "Alphabetically":
              return "Alphabetically";
            default:
              return "";
          }
        })();
        const res = await axios.get(
          `/api/v1/school/standardSection/get/school/standards/${schoolId}?sort=${sortQueryParam}`
        );
        const fetchedData = res.data.data;

        for (const data of fetchedData) {
          if (!standardsMap.has(data?.standard?.value)) {
            standardsMap.set(data?.standard?.value, [data]);
          } else {
            standardsMap.get(data?.standard?.value).push(data);
          }
        }
        setClassesData(standardsMap);
      } catch (error) {}
    }
    fetchSections();
  }, [schoolId, isDeletePopupOpen, isActive, selectedOption]);

  useEffect(() => {
    async function fetchHistory() {
      try {
        const res = await axios.get(
          `/api/v1/school/standardSection/getUploadHistory/school/${schoolId}`
        );
        res.data.data && setHistoryData(res.data.data);
      } catch (error) {}
    }
    fetchHistory();
  }, [schoolId]);

  function remarkEllipses(value) {
    const lengthOfString = value.length;
    if (lengthOfString > 50) {
      return value.slice(0, 47) + "...";
    }
    return value;
  }

  const handleDownload = (path) => {
    const downloadUrl = `${IP}${path.substring(path.indexOf("/"))}`;
    const downloadAnchor = document.createElement("a");
    downloadAnchor.style.display = "none";
    document.body.appendChild(downloadAnchor);
    downloadAnchor.href = downloadUrl;
    downloadAnchor.setAttribute("download", "Timetable_template.xlsx");
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  };

  // API call function
    const createLibraryForClass = async (data) => {
      try {
        const subjects = data?.subjects;
        if (subjects && subjects.length > 0) {
          for (const item of subjects) {
            await axios.post(
              "/api/v1/cm/library/book/addBookToSchoolStandardSectionSubject",
              {
                school_id: data.section.schoolId,
                standard_id: data.standard.id,
                section_id: data.section.id,
                subject_id: item.id,
                bookInfo_id: b4sData?.bookInfo_for_None[0]?.id,
                timetable_session: data.timetableSessions._id,
              }
            );
          }
        }
      } catch (error) {
        if(error.response.data.message!=="Provided Book is already added to mentioned record in DB"){
          handleError(error.response.data.message);
        }
        console.log(error);
      }
    };

    // Iterating over the Map and calling the API
    const callCreateLibraryForAllClasses = async () => {
      for (const [standardId, sections] of classesData.entries()) {
        for (const sectionData of sections) {
          await createLibraryForClass(sectionData);
        }
      }
    };

    useEffect(()=>{
      if(classesData){
        callCreateLibraryForAllClasses();
      }
    },[classesData])

  return loading ? (
    <div>
      <div className="schoolInfo_head">Class infomation</div>
      <div className="heading_belowText"></div>
      <Popup
        on="click"
        closeOnDocumentClick={true}
        trigger={
          // <div style={{ marginRight: "30px" }}>
          //   <span className="heading_belowText">Sort by</span> &nbsp;
          //   <img src="/OnBoard/DownWardArrow.png" alt="down arrow" />
          // </div>
          // <div className="filterIcon">
          <div className=" filter_button filterIcon">
            <img src="/images/filter_icon.svg" alt="" />
            <span>Filter</span>
          </div>
          // </div>
        }
        content={
          <div className="container_threedot">
            <div
              className={`PopupThreedots ${
                selectedOption === "All" ? "active" : ""
              }`}
              onClick={() => handleOptionClick("All")}
            >
              All
            </div>
            <div
              className={`PopupThreedots ${
                selectedOption === "LastModified" ? "active" : ""
              }`}
              onClick={() => handleOptionClick("LastModified")}
            >
              Sort by Last Modified
            </div>
            <div
              className={`PopupThreedots ${
                selectedOption === "ActiveState" ? "active" : ""
              }`}
              onClick={() => handleOptionClick("ActiveState")}
            >
              Active State
            </div>
            <div
              className={`PopupThreedots ${
                selectedOption === "Alphabetically" ? "active" : ""
              }`}
              onClick={() => handleOptionClick("Alphabetically")}
            >
              Sort by Alphabetically
            </div>

            <div
              className={`PopupThreedots ${
                selectedOption === "InactiveState" ? "active" : ""
              }`}
              onClick={() => handleOptionClick("InactiveState")}
            >
              In-Active State
            </div>
          </div>
        }
        position="bottom center"
      />

      <div className="mt-4 ">
        <button
          onClick={(e) =>
            setClassList({ ...classList, historyBtn: "classInfo" })
          }
          className={` ${
            classList.historyBtn === "classInfo"
              ? "active classInfoBtn"
              : "uploadHistoryBtn"
          }`}
        >
          Class information
        </button>
        <button
          onClick={(e) =>
            setClassList({ ...classList, historyBtn: "HistoryBtn" })
          }
          className={` ${
            classList.historyBtn === "HistoryBtn"
              ? "active classInfoBtn"
              : "uploadHistoryBtn"
          }`}
        >
          Upload history
        </button>
      </div>

      {classList.historyBtn === "classInfo" && (
        <div className="ClassesContainer mt-4">
          {[...classesData.keys()]
            .sort((a, b) =>
              a[0].standard?.label.localeCompare(b[0].standard?.label)
            )
            .map((standard, outerIndex) => (
              <div className="classListDetail mt-4 p-4">
                <div className="classNumber">
                  {classesData.get(standard)[0].standard?.label}
                </div>
                {classesData.get(standard).map((item, index) => (
                  <div
                    className="w-100 d-flex mt-3"
                    style={{
                      ...(item?.active === false && {
                        backgroundColor: "rgba(225, 29, 52, 0.2)",
                        padding: "10px",
                        paddingRight: "0px",
                        borderRadius: "8px",
                        transform: "translateX(-10px)",
                      }),
                    }}
                  >
                    <div className="detail_containers1">
                      <div className="listHeading">Section</div>
                      <div className="listDetail">
                        {item.section.label} - Section
                      </div>
                    </div>
                    <div className="detail_containers2">
                      <div className="listHeading">Block</div>
                      <div className="listDetail">
                        {item.block.label} - Block
                      </div>
                    </div>
                    <div className="detail_containers3">
                      <div className="listHeading">Subjects</div>
                      <div className="d-flex flex-wrap gap-3">
                        {item.subjects.slice(0, 3).map((subject) => (
                          <div className="subjectListContainer">
                            {subject?.label}
                          </div>
                        ))}
                        {(!showSubjects ||
                          toggler !==
                            outerIndex.toString() + index.toString()) &&
                          item.subjects.slice(3).length > 0 && (
                            <div
                              className="subjectListContainer"
                              onClick={() => {
                                setyToggler((prev) =>
                                  prev
                                    ? null
                                    : outerIndex.toString() + index.toString()
                                );
                                console.log(
                                  toggler ===
                                    outerIndex.toString() + index.toString(),
                                  "+"
                                );
                                setShowSubjects(!showSubjects);
                              }}
                            >
                              +{item.subjects.slice(3).length}
                            </div>
                          )}
                        {showSubjects &&
                          toggler ===
                            outerIndex.toString() + index.toString() &&
                          item.subjects.slice(3).map((subject) => (
                            <div
                              className="subjectListContainer"
                              onClick={() => {
                                console.log(
                                  toggler ===
                                    outerIndex.toString() + index.toString(),
                                  "AdS"
                                );

                                setyToggler(null);
                                setShowSubjects(!showSubjects);
                              }}
                            >
                              {subject?.label}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="detail_containers4">
                      <div className="listHeading">Class teacher</div>
                      <div className="listDetail">--</div>
                    </div>
                    <div className="detail_containers5">
                      <div className="listHeading">Class coordinator</div>
                      <div className="listDetail">--</div>
                    </div>
                    <div className="detail_containers6 ml-3">
                      <div className="listHeading">Remarks</div>
                      <div
                        className="listDetail remarksDetail"
                        title={item.remarks}
                      >
                        {remarkEllipses(item.remarks)}
                      </div>
                    </div>
                    <div className="detail_containers8">
                      <div className="listHeading">Academic Session</div>
                      <div
                        className="listDetail remarksDetail"
                        title={item?.academicYearSessions?.name}
                      >
                        {item?.academicYearSessions?.name}
                      </div>
                    </div>
                    <div className="detail_containers8">
                      <div className="listHeading">Timetable Session</div>
                      <div
                        className="listDetail remarksDetail"
                        title={item?.timetableSessions?.name}
                      >
                        {item?.timetableSessions?.name}
                      </div>
                    </div>
                    <div
                      className="detail_containers7 d-flex justify-content-center align-items-center"
                      style={{
                        transform:
                          item?.active === false
                            ? "translateX(10px)"
                            : undefined,
                      }}
                    >
                      <Popup
                        on="click"
                        closeOnDocumentClick={true}
                        trigger={
                          <img
                            src="/OnBoard/HorizontalThreedot.png"
                            alt="three dot"
                          />
                        }
                        content={
                          <div className="container_threedot">
                            {item.active && (
                              <div
                                className="PopupThreedots"
                                onClick={(e) => handleEdit(e, item.id)}
                              >
                                Edit
                              </div>
                            )}
                            <div
                              className="PopupThreedots"
                              onClick={() => handleDeleteClick(item.id)}
                            >
                              Delete
                            </div>
                            <div
                              className="PopupThreedots"
                              onClick={() => handleInActiveClick(item)}
                            >
                              {item?.active === false
                                ? "Activate"
                                : "In-Active"}
                            </div>
                          </div>
                        }
                        position="bottom center"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      )}

      {classList.historyBtn === "HistoryBtn" && (
        <div className="mt-4">
          <table class="table table-borderless">
            <thead>
              <tr className="Heading_tableContainer">
                <th className="Name">Document Name</th>
                <th>Date</th>
                <th>Total Classes</th>
                <th>Accepted</th>
                <th>Rejected</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {historyData.length > 0 &&
                historyData.map((data, index) => (
                  <tr key={index}>
                    <td className="TeacherTableDetails">
                      {data.document_name}
                    </td>
                    <td className="TeacherTableDetails">
                      {new Date(data.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </td>
                    <td className="TeacherTableDetails">{data.total}</td>
                    <td className="TeacherTableDetails">{data.accepted}</td>
                    <td className="TeacherTableDetails">{data.rejected}</td>
                    <td
                      className="TeacherTableDetails"
                      onClick={() => handleDownload(data.document_url)}
                    >
                      <img src="/images/DownloadButton.svg"></img>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {isDeletePopupOpen && (
        <DeletePopup
          setDeletePopupOpen={setDeletePopupOpen}
          ElementId={ElementId}
        />
      )}
      {isActive && (
        <InActivePopup setisActive={setisActive} ElementId={ElementId} />
      )}
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
      <div className="d-flex flex-wrap justify-content-end align-items-center mt-4 footer_container">
        <Link to="/AddTeacher">
          <button
            id="NextBtn_school_masterData"
            className="NextButtonPosition"
            // onClick={(e) =>
            //   setAddClassComp({
            //     addTeacher: true,
            //   })
            // }
            type="submit"
          >
            Next
          </button>
        </Link>
      </div>
    </div>
  ) : (
    <div className="loader">
      <img className="leader_loader" src="/images/LoaderSpinner.gif" alt="" />
    </div>
  );
}
