import React, { useState } from 'react'
import Table from "react-bootstrap/Table";
import { ResponsivePie } from '@nivo/pie';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { ResponsiveBar } from '@nivo/bar';
import ChangeTeacher from './ChangeTeacher'

export default function TeacherDetail({ setTeacherBoard }) {
    const [tacherDetail, setTeacherDetail] = useState({
        editPersonalDetail: true,
        emailIdTB: 'dilip@kendra_vidyala-teacher.com',
        personalEmailTB: 'dilipkumar@gmail.com',
        mobileNoTB: '97112 12456',
        emailIdError: '',
        personalEmailError: '',
        mobileNoError: '',
        changeTeacher:false
    })
    const isValidSaveBtn = tacherDetail.emailIdError === '' && tacherDetail.personalEmailError === '' && tacherDetail.mobileNoError === '';
    console.log(!isValidSaveBtn)

    const validateEmail = (email) => {
        // check if the email is valid
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return regex.test(email)
    }

    const validateMobileNo = (mobileNo) => {
        // check if the mobile number is valid
        const regex = /^(\d{10}|\d{5}\s\d{5})$/g;
        return regex.test(mobileNo)
    }

    const handleInputChange = (e, key) => {
        const { value } = e.target
        if (key === 'emailIdTB') {
            if (validateEmail(value)) {
                setTeacherDetail({ ...tacherDetail, emailIdTB: value, emailIdError: '' })
            } else {
                setTeacherDetail({ ...tacherDetail, emailIdTB: value, emailIdError: 'Invalid email address' })
            }
        } else if (key === 'personalEmailTB') {
            if (validateEmail(value)) {
                setTeacherDetail({ ...tacherDetail, personalEmailTB: value, personalEmailError: '' })
            } else {
                setTeacherDetail({ ...tacherDetail, personalEmailTB: value, personalEmailError: 'Invalid email address' })
            }
        } else {
            if (validateMobileNo(value)) {
                setTeacherDetail({ ...tacherDetail, mobileNoTB: value, mobileNoError: '' })
            } else {
                setTeacherDetail({ ...tacherDetail, mobileNoTB: value, mobileNoError: 'Invalid mobile number' })
            }
        }
    }
    const data = [
        { id: 'A', value: 60 },
        { id: 'B', value: 20 },
        { id: 'C', value: 20 },
    ];

    const data2 = [
        { id: 'Jan', Month: 10 },
        { id: 'Feb', Month: 70 },
        { id: 'Mar', Month: 30 },
        { id: 'Apr', Month: 100 },
        { id: 'May', Month: 80 },
        { id: 'Jun', Month: 50 },
        { id: 'Jul', Month: 40 },
        { id: 'Aug', Month: 20 },
        { id: 'Sep', Month: 90 },
        { id: 'Oct', Month: 50 },
        { id: 'Nov', Month: 60 },
        { id: 'Dec', Month: 90 },
    ];

    const [editable, setEditable] = useState(false);
    const [tabledata, setTableData] = useState([
        { class: 'Class 10', division: 'A', subjects: 'English, Maths, Science, Social Science, Hindi' },
        { class: 'Class 09', division: 'D', subjects: 'English, Maths, Science, Social Science, Hindi' },
        { class: 'Class 05', division: 'E', subjects: 'English, Maths, Science, Social Science, Hindi' }
    ]);

    const handleEditClick = () => setEditable(!editable);

    const handleInputChangeTable = (event, index, key) => {
        const newData = [...tabledata];
        newData[index][key] = event.target.value;
        setTableData(newData);
    };

    // it for role 
    const [editableRole, setEditableRole] = useState(false);
    const [roles, setRoles] = useState([
        'Class teacher - 9 B',
        'Culturals head',
        'School Judge'
    ]);

    const handleEditClickRole = () => setEditableRole(!editableRole);

    const handleInputChangeRole = (event, index) => {
        const newRoles = [...roles];
        newRoles[index] = event.target.value;
        setRoles(newRoles);
    };

    // create calender
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const [date, setDate] = useState(new Date());

    const handlePrevWeek = () => {
        setDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7));
    };

    const handleNextWeek = () => {
        setDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7));
    };

    const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1);
    const endOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - date.getDay()) + 1);
    const days = [];

    for (let d = new Date(startOfWeek); d <= endOfWeek; d.setDate(d.getDate() + 1)) {
        if (d >= startOfWeek && d <= endOfWeek) {
            days.push(new Date(d));
        }
    }



    return (
        <div>
            <div>
                <div className='d-flex flex-wrap justify-content-between'>
                    <div className='d-flex gap-3'>
                        <div className='SBoard' onClick={(e) => setTeacherBoard({ teacherDetail: false })}>
                            <IoIosArrowBack />
                        </div>
                        <div className='SBoard' onClick={(e) => setTeacherBoard({ teacherDetail: false })}>Teacher board</div>
                        <div> /</div>
                        <div className='StudentInformationPath'>Dilip Kumar</div>
                    </div>

                    <div>
                        <button  onClick={(e) => setTeacherDetail({ ...tacherDetail, changeTeacher:true })} className='changeTeacherBtn'>Change Teacher</button>
                    </div>
                </div>

                <div className='d-flex flex-wrap gap-3 mt-2'>
                    <div className='feedbackContainer d-flex p-4'>
                        <div>
                            <p className='feedbackName'>Parents feedback</p>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/BlueprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>60% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/PinkprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>20% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/OrangeprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>20% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                        </div>
                        <div style={{ width: '50%', height: '100%' }}>
                            <ResponsivePie
                                data={data}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                colors={["#6d6fe2", "#fda2c0", "#f7e286"]}
                                enableArcLinkLabels={false}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: "color" }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: "color",
                                    modifiers: [["darker", 2]],
                                }}

                                borderColor={{
                                    from: "color",
                                    modifiers: [["darker", 0.2]],
                                }}
                                defs={[
                                    {
                                        id: "dots",
                                        type: "patternDots",
                                        background: "inherit",
                                        color: "rgba(255, 255, 255, 0.3)",
                                        size: 4,
                                        padding: 1,
                                        stagger: true,
                                    },
                                    {
                                        id: "lines",
                                        type: "patternLines",
                                        background: "inherit",
                                        color: "rgba(255, 255, 255, 0.3)",
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10,
                                    },
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: "ruby",
                                        },
                                        id: "dots",
                                    },
                                    {
                                        match: {
                                            id: "c",
                                        },
                                        id: "dots",
                                    },
                                    {
                                        match: {
                                            id: "go",
                                        },
                                        id: "dots",
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className='feedbackContainer d-flex p-4'>
                        <div>
                            <p className='feedbackName'>Student feedback</p>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/BlueprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>60% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/PinkprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>20% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/OrangeprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>20% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                        </div>
                        <div style={{ width: '50%', height: '100%' }}>
                            <ResponsivePie
                                data={data}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                colors={["#6d6fe2", "#fda2c0", "#f7e286"]}
                                enableArcLinkLabels={false}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: "color" }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: "color",
                                    modifiers: [["darker", 2]],
                                }}

                                borderColor={{
                                    from: "color",
                                    modifiers: [["darker", 0.2]],
                                }}
                                defs={[
                                    {
                                        id: "dots",
                                        type: "patternDots",
                                        background: "inherit",
                                        color: "rgba(255, 255, 255, 0.3)",
                                        size: 4,
                                        padding: 1,
                                        stagger: true,
                                    },
                                    {
                                        id: "lines",
                                        type: "patternLines",
                                        background: "inherit",
                                        color: "rgba(255, 255, 255, 0.3)",
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10,
                                    },
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: "ruby",
                                        },
                                        id: "dots",
                                    },
                                    {
                                        match: {
                                            id: "c",
                                        },
                                        id: "dots",
                                    },
                                    {
                                        match: {
                                            id: "go",
                                        },
                                        id: "dots",
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className='feedbackContainer d-flex p-4'>
                        <div>
                            <p className='feedbackName'>Lecture feedback</p>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/BlueprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>60% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/PinkprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>20% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                            <div>
                                <span className='mr-2'><img src='/LandingPageImg/OrangeprogressDot.png' alt='dots icon' /></span>
                                <span className='mr-1 progressPercentage'>20% </span>
                                <span className='ProgressGradde'> Excellent</span>
                            </div>
                        </div>
                        <div style={{ width: '50%', height: '100%' }}>
                            <ResponsivePie
                                data={data}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                colors={["#6d6fe2", "#fda2c0", "#f7e286"]}
                                enableArcLinkLabels={false}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: "color" }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: "color",
                                    modifiers: [["darker", 2]],
                                }}

                                borderColor={{
                                    from: "color",
                                    modifiers: [["darker", 0.2]],
                                }}
                                defs={[
                                    {
                                        id: "dots",
                                        type: "patternDots",
                                        background: "inherit",
                                        color: "rgba(255, 255, 255, 0.3)",
                                        size: 4,
                                        padding: 1,
                                        stagger: true,
                                    },
                                    {
                                        id: "lines",
                                        type: "patternLines",
                                        background: "inherit",
                                        color: "rgba(255, 255, 255, 0.3)",
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10,
                                    },
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: "ruby",
                                        },
                                        id: "dots",
                                    },
                                    {
                                        match: {
                                            id: "c",
                                        },
                                        id: "dots",
                                    },
                                    {
                                        match: {
                                            id: "go",
                                        },
                                        id: "dots",
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className='row mt-4 '>
                    <div className=' col-md-8'>
                        <div className='studentNameContianer p-4'>

                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center gap-2'>
                                    <div><img src='/StudentImages/table pic1.svg' alt='profile img' /></div>
                                    <div className='nameTeacher'>Dilip Kumar</div>
                                    <div><img src='/LandingPageImg/RatingStar.png' alt='start icon' /><span className='ratingNo ml-2 mr-2'>4.2</span><span className='RatingTxt'>Rating</span></div>
                                </div>
                                {tacherDetail.editPersonalDetail ? <div className='editText'
                                    onClick={(e) =>
                                        setTeacherDetail({
                                            ...tacherDetail,
                                            editPersonalDetail: false
                                        })
                                    }
                                >
                                    Edit
                                </div> :
                                    <div className={` ${!isValidSaveBtn ? "disabled" : "editText"}`}
                                        onClick={(e) =>
                                            setTeacherDetail({
                                                ...tacherDetail,
                                                editPersonalDetail: true
                                            })
                                        }
                                    >Save</div>
                                }
                            </div>

                            <div className='d-flex row mt-4'>
                                <div className='col-md-5'>
                                    <div className='SchoolEmailHead'>School email id</div>
                                    <input disabled={tacherDetail.editPersonalDetail} className='emailIdTB' value={tacherDetail.emailIdTB} onChange={(e) => handleInputChange(e, 'emailIdTB')} />
                                    {tacherDetail.emailIdError && <div className='error'>{tacherDetail.emailIdError}</div>}
                                </div>
                                <div className='col-md-4'>
                                    <div className='SchoolEmailHead'>Personal email</div>
                                    <input disabled={tacherDetail.editPersonalDetail} className='emailIdTB' value={tacherDetail.personalEmailTB} onChange={(e) => handleInputChange(e, 'personalEmailTB')} />
                                    {tacherDetail.personalEmailError && <div className='error'>{tacherDetail.personalEmailError}</div>}
                                </div>
                                <div className='col-md-3'>
                                    <div className='SchoolEmailHead'>Mobile number</div>
                                    <input disabled={tacherDetail.editPersonalDetail} className='emailIdTB' value={tacherDetail.mobileNoTB} onChange={(e) => handleInputChange(e, 'mobileNoTB')} />
                                    {tacherDetail.mobileNoError && <div className='error'>{tacherDetail.mobileNoError}</div>}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='d-flex flex-wrap justify-content-between mt-4'>
                                <div className='nameTeacher'>Class division & subject</div>
                                <div className='editText' onClick={handleEditClick}>{editable ? 'Save' : 'Edit'}</div>
                            </div>
                            <Table responsive className="teacherTable mt-3" borderless>
                                <thead className='tableHeaderBox'>
                                    <tr>
                                        <th className='tableHeading1'>Class</th>
                                        <th className='tableHeading1'>Division</th>
                                        <th className='tableHeading'>Subjects</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tabledata.map((item, index) => (
                                        <tr key={index}>
                                            <td className='tableDetails'>
                                                {editable ? <input className='tableDetailInput' type='text' value={item.class} onChange={(e) => handleInputChangeTable(e, index, 'class')} /> : item.class}
                                            </td>
                                            <td className='tableDetails'>
                                                {editable ? <input className='tableDetailInput' type='text' value={item.division} onChange={(e) => handleInputChangeTable(e, index, 'division')} /> : item.division}
                                            </td>
                                            <td className='tableDetails'>
                                                {editable ? <input className='tableDetailInput' type='text' value={item.subjects} onChange={(e) => handleInputChangeTable(e, index, 'subjects')} /> : item.subjects}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                        <div className='studentNameContianer p-4 mt-3'>
                            <div className='d-flex justify-content-between'>
                                <div className='nameTeacher'>
                                    Role
                                </div>
                                <div className='editText' onClick={handleEditClickRole}>
                                    {editableRole ? 'Save' : 'Edit'}
                                </div>
                            </div>
                            <div className=' d-flex flex-wrap mt-3 gap-3'>
                                {roles.map((role, index) => (
                                    <div className='roleDetails d-flex justify-content-center align-items-center' key={index}>
                                        {editableRole ? (
                                            <input className='roleDetails' type='text' value={role} onChange={(e) => handleInputChangeRole(e, index)} />
                                        ) : role}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='studentNameContianer mt-3 p-4'>
                            <div className='d-flex justify-content-between'>
                                <div className='nameTeacher'>Improvement</div>
                                <div>
                                    <span className='dateImprovement mr-3'>FY 2021</span>
                                    <img src='/OnBoard/DownWardArrow.png' alt='down arrow' />
                                </div>
                            </div>

                            <div style={{ height: '400px', width: "100%" }}>
                                <ResponsiveBar
                                    data={data2}
                                    keys={['Month']}
                                    indexBy='id'
                                    margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
                                    padding={0.6}
                                    // innerPadding={10}
                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    axisTop={null}
                                    axisRight={null}
                                    colors={['#F77F00']}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '2021',
                                        legendPosition: 'start',
                                        legendOffset: 40,
                                    }}
                                    axisLeft={{
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        tickSize: 5,
                                        legend: '',
                                        tickValues: [0, 20, 40, 60, 80, 100],
                                        format: (value) => `${value}%`, // change the format to show the percentage symbol
                                    }}
                                    labelTextColor={({ value }) => 'none'}

                                />
                            </div>
                        </div>
                        <div className='studentNameContianer mt-3 p-4'>
                            <div className='d-flex justify-content-between'>
                                <div className='feedback' >Feedback</div>
                                <div>
                                    <span className='dateImprovement mr-2'>ALL</span>
                                    <img src='/OnBoard/DownWardArrow.png' alt='down arrow' />
                                </div>
                            </div>
                            {/* this below feedback part css comes from studentsection.css */}
                            <div className='feedBackContainer_sub'>
                                <div className=' recomdaded_sub_containerSecond mt-4'>
                                    <span className='recomended_teacher mr-2'>Jocelyn Dias</span>
                                    <span className='recomended_sub mr-2'>Student</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>John Deo</span>
                                    <span className='recomended_sub mr-2'>Parent</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>Jocelyn Dias</span>
                                    <span className='recomended_sub mr-2'>Parent</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>John Deo</span>
                                    <span className='recomended_sub mr-2'>Student</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>Jocelyn Dias</span>
                                    <span className='recomended_sub mr-2'>Parent</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 '>

                        <div className='clenderContainerTeacherBoard'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IoIosArrowBack className='monthArrowCalender' onClick={handlePrevWeek} />
                                <h2 className='monthSelected' style={{ flexGrow: 1, textAlign: 'center' }}>{date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h2>
                                <IoIosArrowForward className='monthArrowCalender' onClick={handleNextWeek} />
                            </div>

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <table style={{ width: '100%', marginLeft: '23px' }} >
                                    <thead className='w-100' >
                                        <tr>
                                            {daysOfWeek.map(day => {
                                                const isCurrentDay = day === new Date().toLocaleString('en-us', { weekday: 'short' });
                                                const className = isCurrentDay ? 'current-day' : 'remaining-days';
                                                return <th key={day} className={className}>{day}</th>;
                                            })}
                                        </tr>

                                    </thead>
                                    <tbody className='mt-2'>
                                        <tr>
                                            {days.map(day => {
                                                const isCurrentDate = day.toDateString() === new Date().toDateString();
                                                const className = isCurrentDate ? 'current_date' : (day < new Date() ? 'prev_date' : 'upcoming_date');
                                                // const color = isCurrentDate ? '#F77F00' : (day < new Date() ? '#45BF55' : '#000A11');
                                                return <td key={day.toLocaleDateString()} className={className} >{day.getDate()}</td>
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='d-flex justify-content-between mt-2 p-4'>
                                <div className='d-flex gap-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex  align-items-center'>
                                            <img src='/LandingPageImg/PresentDot.png' alt='presentDot'></img>&nbsp;
                                        </div>
                                        <div className='present_absent'>2 Present</div>
                                    </div>
                                    <div className='d-flex  align-items-center'>
                                        <div className='d-flex  align-items-center'>
                                            <img src='/LandingPageImg/AbsentDot.png' alt='absentDot'></img>&nbsp;
                                        </div>
                                        <div className='present_absent '>1 Absent</div>
                                    </div>
                                </div>
                                <div className='present_absent'>
                                    List of Holidays
                                </div>
                            </div>

                            <div className='p-3 UpcomingClasses'>Upcoming Classes</div>

                            <div className='m-3'>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center gap-3 p-3'>
                                    <div>
                                        <img src='/LandingPageImg/ScienceLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Science</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center  mt-2 gap-3 p-3'>
                                    <div>
                                        <img src='/LandingPageImg/SocialScienceLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Social Science</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center gap-3 mt-2 p-3'>
                                    <div>
                                        <img src='/LandingPageImg/MathLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Math</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>

                            </div>








                        </div>
                    </div>
                </div>

            </div>
            {tacherDetail.changeTeacher && <ChangeTeacher setTeacherDetail={setTeacherDetail}/>}
        </div>
    )
}
