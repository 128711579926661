import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import "./ClassComp.css";
import Select from "react-select";
import axios from "../../../axios";
import { useSchoolContext } from "../../../schoolContext";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../ErrorPopup";
import RemoveErrorPopup from "../../../removePopUp";

export default function ClassInfo({ setAddClassComp, editId }) {
  const [fieldsAddClass, setFieldsAddClass] = useState([
    {
      id: 1,
      class: "",
      section: "",
      blockN: null,
      classF: "",
      subjects: "",
      remarks: "",
      roomN: "",
      academicSession: "",
      timetableSession: "",
    },
  ]);
  const [ClassOptions, setClassOptions] = useState([]);
  const [SectionOptions, setSectionOptions] = useState([]);
  const [BlockNameOptions, setBlockNameOptions] = useState([]);
  const [hasDuplicate, setHasDuplicate] = useState({
    room: false,
    section: false,
  });
  const [SubjectOptions, setSubjectOptions] = useState([]);
  const [Blocks, setBlocks] = useState([]);

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const b4sData = useSelector((state) => state.configuration.configData);

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [academicYearId, setAcademicYearId] = useState("");
  const [academicSession, setAcademicSession] = useState([]);
  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  useEffect(() => {
    async function fetchClass() {
      try {
        const res = await axios.get(
          `/api/v1/school/standard/get/school/${schoolId}`
        );
        if (res && res.data && res.data.data) {
          const mappedClass = res.data.data.map((classItem) => {
            return {
              value: classItem.id,
              label: classItem.name,
            };
          });
          setClassOptions(mappedClass);
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    async function fetchAcademicYear() {
      try {
        const res = await axios.get(
          `/api/v1/school/academicYear/get/school/${schoolId}`
        );
        if (res?.data?.data?.id) {
          setAcademicYearId(res.data.data.id);
          const response = await axios.get(
            `/api/v1/school/academicYearSession/get/academicYear/${res?.data?.data?.id}`
          );
          if (response.data.data.length > 0) {
            const mappedAcademicSession = response.data.data.map(
              (classItem) => {
                return {
                  value: classItem.id,
                  label: classItem.name,
                };
              }
            );
            setAcademicSession(mappedAcademicSession);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchClass();
    fetchAcademicYear();
  }, [schoolId]);
  useEffect(() => {}, []);

  useEffect(() => {
    async function fetchSection() {
      try {
        const res = await axios.get(
          `/api/v1/school/section/get/school/${schoolId}`
        );
        if (res && res.data && res.data.data) {
          const mappedSection = res.data.data.map((sec) => {
            return {
              value: sec.id,
              label: sec.name,
            };
          });
          setSectionOptions(mappedSection);
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchSection();
  }, [schoolId]);

  useEffect(() => {
    async function fetchBlock() {
      try {
        const res = await axios.get(
          `/api/v1/school/block/get/school/${schoolId}`
        );
        if (res && res.data && res.data.data) {
          const mappedBlocks = res.data.data.map((block) => {
            return {
              value: block.id,
              label: block.name,
            };
          });
          setBlocks(res.data.data);
          setBlockNameOptions(mappedBlocks);
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchBlock();
  }, [schoolId]);

  useEffect(() => {
    async function fetchSubject() {
      try {
        const res = await axios.get(
          `/api/v1/school/subject/get/school/${schoolId}`
        );
        if (res && res.data && res.data.data) {
          const mappedSubjects = res.data.data.map((sub) => {
            return {
              value: sub.id,
              label: sub.name,
            };
          });
          setSubjectOptions(mappedSubjects);
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchSubject();
  }, [schoolId]);

  useEffect(() => {
    async function fetchClassessData() {
      try {
        const res = await axios.get(
          `/api/v1/school/standardSection/get/school/${schoolId}`
        );
        let finalData = [];
        if (res && res.data && res.data.data) {
          if (res.data.data.length > 0) {
            let mappedFormsData = res.data.data
              .filter((data) => data?.active !== false)
              .map((data, index) => {
                return {
                  id: index + 1,
                  standardSectionId: data.id,
                  class: data.standard,
                  section: data.section,
                  blockN: data.block,
                  classF: data.floor,
                  subjects: data.subjects,
                  remarks: data.remarks,
                  roomN: data.room,
                  academicSession: {
                    label: data?.academicYearSessions?.name,
                    value: data?.academicYearSessions?.id,
                  },
                  timetableSession: {
                    label: data?.timetableSessions?.name,
                    value: data?.timetableSessions?.id,
                  },
                };
              });

            if (editId) {
              finalData.push(
                mappedFormsData.find((obj) => obj.standardSectionId === editId)
              );
            } else {
              finalData = mappedFormsData;
            }
            await Promise.all(
              finalData.map(async (obj, i) => {
                const timetableSessionOptions = await fetchSchoolSessionOptions(
                  obj?.academicSession
                );
                finalData[i].timetableSessionOptions = timetableSessionOptions;
              })
            );

            setFieldsAddClass(finalData);
          } else {
            setFieldsAddClass([
              {
                id: 1,
                class: "",
                section: "",
                blockN: null,
                classF: "",
                subjects: "",
                remarks: "",
                roomN: "",
              },
            ]);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchClassessData();
  }, [schoolId]);

  const createLibraryForClass = async (data) => {
    try {
      const subjects = data?.subjects;
      if (subjects && subjects.length > 0) {
        for (const item of subjects) {
          await axios.post(
            "/api/v1/cm/library/book/addBookToSchoolStandardSectionSubject",
            //need to pass timetablesession id here.
            {
              school_id: schoolId,
              standard_id: data.standard,
              section_id: data.section,
              subject_id: item,
              bookInfo_id: b4sData?.bookInfo_for_None[0]?.id,
              timetable_session: data?.timetableSessions,
            }
          );
        }
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.log(error);
    }
  };

  const handleCreateClassroom = async () => {
    try {
      setAddClassComp((prev) => ({
        ...prev,
        isLoading: true,
      }));
      let res;
      for (const classes of fieldsAddClass) {
        const body = {
          schoolId,
          standard: classes.class.value,
          section: classes.section.value,
          remarks: classes.remarks,
          room: classes.roomN,
          block: classes.blockN.value,
          floor: classes.classF.value,
          subjects: classes.subjects.map((subject) => subject.value),
          academicYearSessions: classes?.academicSession?.value,
          timetableSessions: classes?.timetableSession?.value,
        };
        if (!classes.standardSectionId) {
          //Standard Section creation api call.
          res = await axios.post("/api/v1/school/standardSection/create", body);
          res && createLibraryForClass(res?.data?.data);
        } else {
          const reqBody = {
            ...body,
            id: classes.standardSectionId,
            modified: classes.modified ? true : false,
          };
          if (reqBody.modified) {
            res = await axios.post(
              `/api/v1/school/standardSection/update/${classes.standardSectionId}`,
              reqBody
            );
            res && createLibraryForClass(res?.data?.data);
          }
        }
      }
      setAddClassComp({
        editableId: null,
        classList: true,
        isLoading: false,
      });
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  const DropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#99ADBB",
      minHeight: "30px",
      padding: "11px",
      borderRadius: " 8px",
      boxShadow: state.isFocused ? null : null,
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  const handleAddFieldAddClass = () => {
    const newFields = [...fieldsAddClass];
    newFields.push({
      id: fieldsAddClass.length + 1,
      value: "",
      class: "",
      section: "",
      blockN: "",
      classF: "",
      subjects: "",
      remarks: "",
      roomN: "",
    });
    setFieldsAddClass(newFields);
  };
  const [field, setField] = useState();
  const [field_id, setField_Id] = useState();
  const [showRemovePopUp, setShowRemovePopUp] = useState(false);
  const handleRemoveFieldAddClass = async () => {
    try {
      if (field.standardSectionId) {
        await axios.delete(
          `/api/v1/school/standardSection/delete/${field.standardSectionId}`
        );
      }
      const newFields = fieldsAddClass.filter((field) => field.id !== field_id);
      // Re-count the field IDs
      const renumberedFields = newFields.map((field, index) => {
        return {
          ...field,
          id: index + 1,
        };
      });
      setFieldsAddClass(renumberedFields);
    } catch (error) {
      handleError(error.response.data.message);
    }
  };
  //
  const fetchSchoolSession = async (academicYearSession, id) => {
    try {
      let response = await axios.get(
        `/api/v1/school/schoolSession/getByAcademicSession?academicYearSession=${academicYearSession.value}`
      );
      const createdSessions = response.data.data.map(
        (createdSession, index) => ({
          value: createdSession.timeTableSession,
          label: createdSession?.remark,
        })
      );
      const newFields = [...fieldsAddClass];
      const index = newFields.findIndex((field) => field.id === id);
      newFields[index]["timetableSessionOptions"] = createdSessions;
      setFieldsAddClass(newFields);
    } catch (error) {
      throw error;
    }
  };
  const fetchSchoolSessionOptions = async (academicYearSession) => {
    try {
      let response = await axios.get(
        `/api/v1/school/schoolSession/getByAcademicSession?academicYearSession=${academicYearSession.value}`
      );
      const createdSessions = response.data.data.map((createdSession) => ({
        value: createdSession.timeTableSession,
        label: createdSession?.remark,
      }));
      return createdSessions;
    } catch (error) {
      throw error;
    }
  };
  const [showError, setError] = useState(false);
  console.log(showError);
  function checkLastObjectMatch(array) {
    const lastObject = array[array.length - 1];
    const {
      class: lastClass,
      section: lastSection,
      timetableSession: lastTimetableSession,
    } = lastObject;
    for (let i = 0; i < array.length - 1; i++) {
      const obj = array[i];
      if (
        obj.class.value === lastClass.value &&
        obj.section.value === lastSection.value &&
        obj.timetableSession.value === lastTimetableSession.value
      ) {
        setError(true);
        return;
      }
    }
    setError(false);
  }

  const handleInputAddClass = (id, name, value) => {
    if (name === "academicSession") {
      fetchSchoolSession(value, id);
    }
    const newFields = [...fieldsAddClass];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    newFields[index].modified = true;
    setFieldsAddClass(newFields);
  };
  console.log(fieldsAddClass);
  const isFieldsaddclass = fieldsAddClass.some(
    (field) =>
      field.class === "" ||
      field.blockN === "" ||
      field.classF === "" ||
      field.section === "" ||
      field.subjects === "" ||
      field.roomN === "" ||
      !field.academicSession ||
      !field.timetableSession
  );

  function hasDuplicateFloor() {
    const roomNumberByFloor = {};
    for (const room of fieldsAddClass) {
      const { classF, roomN } = room;
      if (!roomNumberByFloor[classF]) {
        roomNumberByFloor[classF] = new Set();
      }

      if (roomNumberByFloor[classF].has(roomN)) {
        return true;
      }

      roomNumberByFloor[classF].add(roomN);
    }
    return false;
  }

  function hasDuplicateSections() {
    const sectionStandard = {};
    // debugger;
    for (const sec of fieldsAddClass) {
      const { section } = sec;
      const classes = sec?.class?.value?.toString();
      if (!sectionStandard[classes]) {
        sectionStandard[classes] = new Set();
      }

      if (sectionStandard[classes].has(section?.value?.toString())) {
        return true;
      }

      sectionStandard[classes].add(section?.value?.toString());
    }

    return false;
  }

  useEffect(() => {
    const hasDuplicateRoom = hasDuplicateFloor();
    const hasDuplicateSection = hasDuplicateSections();
    if (hasDuplicateRoom) {
      setHasDuplicate((prev) => ({ ...prev, room: true }));
    } else {
      setHasDuplicate((prev) => ({ ...prev, room: false }));
    }
    if (hasDuplicateSection) {
      setHasDuplicate((prev) => ({ ...prev, section: true }));
    } else {
      setHasDuplicate((prev) => ({ ...prev, section: false }));
    }
  }, [fieldsAddClass]);

  const handleSubmit = (e) => {
    try {
      handleCreateClassroom();
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  return (
    <>
      <div>
        <div className="schoolInfo_head">Class infomation</div>
        <div className="heading_belowText">
          Provide below details to update class information.
        </div>

        <div className="d-flex gap-4 MainContainer_addClassInputs mt-4">
          {fieldsAddClass.map((field) => (
            <div key={field.id} className="classInput_container  mr-2">
              <div className="d-flex justify-content-between ">
                <div className="classinfo_subHeader ">
                  Class info-0{field.id}
                </div>
                {fieldsAddClass.length !== 1 && (
                  <div
                    className="remove"
                    onClick={() => {
                      setField(field);
                      setField_Id(field?.id);
                      setShowRemovePopUp(true);
                    }}
                  >
                    Remove
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Select
                  styles={DropdownStyle}
                  options={academicSession}
                  placeholder="Academic Sessions *"
                  value={field.academicSession}
                  onChange={(selectedOption) => {
                    handleInputAddClass(
                      field.id,
                      "academicSession",
                      selectedOption
                    );
                    checkLastObjectMatch(fieldsAddClass);
                  }}
                />
              </div>
              <div className="mt-4">
                <Select
                  styles={DropdownStyle}
                  options={
                    field.academicSession ? field?.timetableSessionOptions : []
                  }
                  placeholder="Timetable Sessions *"
                  value={field.timetableSession}
                  onChange={(selectedOption) => {
                    handleInputAddClass(
                      field.id,
                      "timetableSession",
                      selectedOption
                    );
                    checkLastObjectMatch(fieldsAddClass);
                  }}
                />
              </div>
              <div className="mt-4">
                <Select
                  styles={DropdownStyle}
                  options={ClassOptions}
                  placeholder="Class *"
                  value={field.class}
                  onChange={(selectedOption) => {
                    handleInputAddClass(field.id, "class", selectedOption);
                    checkLastObjectMatch(fieldsAddClass);
                  }} // update the state based on the selected option
                />
              </div>
              <div className="mt-3">
                <Select
                  options={SectionOptions}
                  styles={DropdownStyle}
                  placeholder="Section *"
                  value={field.section} // add value prop and set it based on the state
                  onChange={(selectedOption) => {
                    handleInputAddClass(field.id, "section", selectedOption);
                    checkLastObjectMatch(fieldsAddClass);
                  }} // update the state based on the selected option
                />
              </div>
              <div className="mt-3">
                <Select
                  options={BlockNameOptions}
                  styles={DropdownStyle}
                  placeholder="Block Name *"
                  value={field.blockN} // add value prop and set it based on the state
                  onChange={(selectedOption) => {
                    handleInputAddClass(field.id, "blockN", selectedOption);
                  }} // update the state based on the selected option
                />
              </div>
              <div className="mt-3">
                <Select
                  options={
                    field?.blockN?.value
                      ? Blocks.find(
                          (block) => block.id === field.blockN.value
                        )?.floors?.map((floor) => ({
                          value: floor.id,
                          label: floor.name,
                        }))
                      : []
                  }
                  styles={DropdownStyle}
                  placeholder="Class Floor *"
                  value={field.classF} // add value prop and set it based on the state
                  onChange={(selectedOption) => {
                    handleInputAddClass(field.id, "classF", selectedOption);
                  }} // update the state based on the selected option
                />
              </div>
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.roomN}
                  onChange={(e) => {
                    {
                      handleInputAddClass(field.id, "roomN", e.target.value);
                    }
                  }}
                  placeholder="Room Number *"
                  maxLength={6}
                />
              </div>
              <div className="mt-3">
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={SubjectOptions}
                  styles={DropdownStyle}
                  placeholder="Subject *"
                  value={field.subjects}
                  onChange={(selectedOptions) => {
                    handleInputAddClass(field.id, "subjects", selectedOptions);
                  }}
                />
              </div>
              <div className="mt-3">
                <textarea
                  className="AddRemark"
                  value={field.remarks}
                  maxLength={250}
                  onChange={(e) =>
                    handleInputAddClass(field.id, "remarks", e.target.value)
                  }
                  placeholder="Add remarks"
                />
              </div>
            </div>
          ))}

          {!editId && (
            <div className="addClassContainer ">
              <button onClick={handleAddFieldAddClass} className="AddClassBtn">
                <BsPlusCircle /> Add Class
              </button>
            </div>
          )}
        </div>
      </div>
      {/* This I am commenting because I dont find the scope to implement my logic as it will take time. */}
      {showError && (
        <span className="error">
          Duplicate Entry For Standard & Section for same timetableSession.
        </span>
      )}

      <div className="d-flex flex-wrap justify-content-end align-items-center mt-4 footer_container">
        <button
          id="NextBtn_school_masterData"
          onClick={handleSubmit}
          disabled={
            isFieldsaddclass || showError
            // || hasDuplicate.room || hasDuplicate.section
          }
          type="submit"
        >
          Save
        </button>
      </div>
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
      {showRemovePopUp && (
        <RemoveErrorPopup
          closePopup={() => {
            setShowRemovePopUp(false);
          }}
          handleDelete={handleRemoveFieldAddClass}
        />
      )}
    </>
  );
}
