import React, { useState } from 'react'
import { BiArrowBack } from 'react-icons/bi';
import Select from 'react-select';

export default function AsignRole({ setPermision }) {


    const DropdownStyle = {
        control: (provided, state) => ({
            ...provided,
            height: '56px',
            background: '#fff',
            borderColor: '#99ADBB',
            minHeight: '30px',
            borderRadius: ' 8px',
            boxShadow: state.isFocused ? null : null,
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };

    const AddMemeberOptions = [
        { value: 'Vice - Principle', label: 'Vice - Principle' },
        { value: 'Director', label: 'Director' },
        { value: 'Class Teacher', label: 'Class Teacher' },
        { value: 'Subject Teacher', label: 'Subject Teacher' },
        { value: ' Coordinator', label: 'Coordinator' },
    ]
    const [inputs, setInputs] = useState([{ name: '', email: '', role: '' }]);

    const handleNameChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].name = value;
        setInputs(newInputs);
    };

    const handleEmailChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].email = value;
        setInputs(newInputs);
    };

    const handleRoleChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].role = value;
        setInputs(newInputs);
    };

    const handleAddInputs = () => {
        setInputs([...inputs, { name: '', email: '', role: '' }]);
    };



    function canSave() {
        for (const input of inputs) {
            if (!input.name || !input.email || !input.role) {
                return false;
            }
            if (
                !/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(input.name) ||
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.email)
            ) {
                return false;
            }
        }
        return true;
    }


    return (
        <div>
            <div className='d-flex gap-2 pl-3 ml-4'>
                <div className='permishionPath'>Permission Access</div>
                <div>/</div>
                <div className='AsignRolePath'>Assign Role</div>
            </div>
            <div className='d-flex align-items-center gap-3 mt-2'>
                <div
                    onClick={(e) => setPermision({
                        asignRole: false
                    })}
                ><BiArrowBack className='arrowAsign' /></div>
                <div className='AsignRoleeading'>Assign role</div>
            </div>
            {/* <form > */}
            <div>
                {inputs.map((input, index) => (
                    <div key={index} className='d-flex gap-2 flex-wrap pl-3 ml-4 mt-4'>
                        <div className='inputsContainerAsignRole'>
                            <input
                                type="text"
                                name="name"
                                placeholder='Name'
                                className='InputAsignRole'
                                value={input.name}
                                onChange={e => handleNameChange(index, e.target.value)}
                            />
                            {input.name && !/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(input.name) && (
                                <div className="error">Invalid Name</div>
                            )}
                        </div>
                        <div className='inputsContainerAsignRole'>
                            <input
                                type="text"
                                name="email"
                                placeholder='Email'
                                className='InputAsignRole'
                                value={input.email}
                                onChange={e => handleEmailChange(index, e.target.value)}
                            />
                            {input.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.email) && (
                                <div className="error">Invalid email address</div>
                            )}
                        </div>
                        <div className='inputsContainerAsignRole'>
                            <Select
                                closeMenuOnSelect={false}
                                options={AddMemeberOptions}
                                styles={DropdownStyle}
                                placeholder='Role'
                                value={input.role}
                                onChange={value => handleRoleChange(index, value)}
                            />
                        </div>
                    </div>
                ))}
                <div className='addAsignRole ml-4 pl-3 mt-4' onClick={handleAddInputs}>+ Assign role</div>
                <div className='d-flex justify-content-end gap-4 SaveRestBtn mb-4 mr-4'>
                    <button className='nextBtn' disabled={!canSave()}>
                        Save
                    </button>
                </div>
            </div>
            {/* </form> */}
        </div>
    )
}
