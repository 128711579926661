import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import SecondPart from "./SecondPart";
import ThirdPart from "./ThirdPart";
import FourPart from "./FourPart";
import FivePart from "./FivePart";
import { BsPlusCircle } from "react-icons/bs";
import "./MasterData.css";
import axios from "../../../axios";
import TimePicker from "react-time-picker";
import { useSchoolContext } from "../../../schoolContext";
import { DateTimePicker } from "@mui/lab";
import TimeClockValue from "./TimeClockValue";
import { useDispatch } from "react-redux";
import { setCountryCode } from "../../../Store/Store";
import ErrorPopup from "../../../ErrorPopup";

export default function FirstPart({ closeAllPrev }) {
  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModifiedAY, setISModifiedAY] = useState(false);
  const [academicYearID, setAcademicYearID] = useState(null);
  const [previousStartYear, setPreviousStartYear] = useState(null);
  const [previousEndYear, setPreviousEndYear] = useState(null);
  const [holidayCategories, setHolidayCategories] = useState([]);
  const [EditPerform, setEditPerform] = useState(false);
  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCountryCode());
  }, []);

  const [part, setPart] = useState({
    activePart: "firstPart",
    activeButton: "firstPart",
  });

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  // calenderIcon Clickable
  const startYearRef = useRef();
  const endYearRef = useRef();
  const handleStartIconClick = () => {
    console.log(startYearRef);
    startYearRef.current.setOpen(true);
  };

  const handleEndIconClick = () => {
    endYearRef.current.setOpen(true);
  };

  const startDateRef = useRef();
  const endDateRef = useRef();
  const handleStartDateIconClick = () => {
    startDateRef.current.setOpen(true);
  };

  const handleEndDateIconClick = () => {
    endDateRef.current.setOpen(true);
  };

  // code for Academic Session add fields more
  const [fieldsAcademicSession, setFieldsAcademicSession] = useState([
    { id: 1, session: "", startDate: null, endDate: null },
  ]);

  const handleAddFieldAcademicSession = () => {
    const newFields = [...fieldsAcademicSession];
    newFields.push({
      id: fieldsAcademicSession.length + 1,
      session: "",
      startDate: null,
      endDate: null,
    });
    setFieldsAcademicSession(newFields);
  };

  const handleRemoveFieldAcademicSession = async (field, id) => {
    if (field.AYSid) {
      await axios.delete(
        `/api/v1/school/academicYearSession/delete/${field.AYSid}`
      );
    }
    const newFields = fieldsAcademicSession.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsAcademicSession(renumberedFields);
  };

  const handleInputAcademicSession = (id, name, session) => {
    const newFields = [...fieldsAcademicSession];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = session;
    setFieldsAcademicSession(newFields);
  };

  const handleInputSession = (id, name, session) => {
    const newFields = [...fieldsSchoolShift];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = session;
    setFieldsSchoolShift(newFields);
  };

  // for SchoolShift add fields more
  const [fieldsSchoolShift, setFieldsSchoolShift] = useState([
    { id: 1, remark: "", value: "", startDate: null, endDate: null },
  ]);
  const handleAddFieldSchoolShift = () => {
    const newFields = [...fieldsSchoolShift];
    newFields.push({
      id: fieldsSchoolShift.length + 1,
      value: "",
      remark: "",
      startDate: null,
      endDate: null,
    });
    setFieldsSchoolShift(newFields);
  };
  const handleRemoveFieldSchoolShift = async (field, id) => {
    if (field.shiftId) {
      await axios.delete(
        `/api/v1/school/schoolSession/delete/${field.shiftId}`
      );
    }
    const newFields = fieldsSchoolShift.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsSchoolShift(renumberedFields);
  };
  // const handleInputSchoolShift = (id, name, value) => {
  //     const newFields = [...fieldsSchoolShift];
  //     const index = newFields.findIndex((field) => field.id === id);
  //     newFields[index][name] = value;
  //     setFieldsSchoolShift(newFields);
  // };

  // code for list holidays add fields more
  const [fieldsListHolidays, setFieldsListHolidays] = useState([
    {
      id: 1,
      name: "",
      startDate: null,
      endDate: null,
      category: "",
      remarks: "",
    },
  ]);

  const handleAddFieldListHolidays = () => {
    const newFields = [...fieldsListHolidays];
    newFields.push({
      id: fieldsListHolidays.length + 1,
      name: "",
      startDate: null,
      endDate: null,
      category: "",
      remarks: "",
    });
    setFieldsListHolidays(newFields);
  };

  const handleRemoveFieldListHolidays = async (field, id) => {
    if (field.holidayId) {
      await axios.delete(`/api/v1/school/holiday/delete/${field.holidayId}`);
    }
    const newFields = fieldsListHolidays.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsListHolidays(renumberedFields);
  };

  const handleInputListHolidays = (id, name, value) => {
    const newFields = [...fieldsListHolidays];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    setFieldsListHolidays(newFields);
  };

  // code for AcademicPlanner add fields more
  const [fieldsAcademicPlanner, setFieldsAcademicPlanner] = useState([
    { id: 1, value: "", startDate: null, endDate: null },
  ]);
  const handleAddFieldAcademicPlanner = () => {
    const newFields = [...fieldsAcademicPlanner];
    newFields.push({
      id: fieldsAcademicPlanner.length + 1,
      value: "",
      startDate: null,
      endDate: null,
    });
    setFieldsAcademicPlanner(newFields);
  };

  const handleRemoveFieldAcademicPlanner = async (field, id) => {
    if (field.plannerId) {
      await axios.delete(
        `/api/v1/school/academicPlanner/delete/${field.plannerId}`
      );
    }
    const newFields = fieldsAcademicPlanner.filter((field) => field.id !== id);
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsAcademicPlanner(renumberedFields);
  };

  const handleInputAcademicPlanner = (id, name, value) => {
    const newFields = [...fieldsAcademicPlanner];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    setFieldsAcademicPlanner(newFields);
  };

  const validateFirstPart = () => {
    const errors = {};

    if (startDate && endDate) {
      const startDate2 = new Date(startDate);
      const endDate2 = new Date(endDate);

      if (new Date(startYear) > new Date(endYear)) {
        errors.AY = "End year must be after start.";
      } else {
        const monthsDiff =
          (endDate2.getFullYear() - startDate2.getFullYear()) * 12 +
          (endDate2.getMonth() - startDate2.getMonth());
        if (monthsDiff < 10) {
          errors.AY = "Duration should be at least 10 months.";
        }
      }
    }
    if (previousEndYear && isModifiedAY) {
      const startYearDate = new Date(startDate);
      const previousEndDate = new Date(previousEndYear);

      if (startYearDate < previousEndDate && startDate) {
        errors.AY =
          "Selected Year should start after the previous Academic Year's end.";
      }

      if (!startDate || !endDate) {
        errors.AY = "Dates have been reset, Please select some date.";
      }
    }

    const isUniqueAYS = fieldsAcademicSession.every((element, index, array) => {
      return (
        array.findIndex(
          (el) => el.session.trim() === element.session.trim()
        ) === index
      );
    });

    if (!isUniqueAYS) {
      errors.AYS = "Session names must be unique.";
    }

    for (const shift of fieldsSchoolShift) {
      const isValidStart = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
        shift.startDate
      );
      const isValidEnd = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
        shift.endDate
      );

      if (shift.startDate && shift.endDate) {
        if (!isValidStart || !isValidEnd) {
          errors.SHIFT = "Time must be in this format, HH:MM";
          break;
        }
      }
    }

    if (
      fieldsAcademicSession.some(
        (FAS) =>
          FAS.startDate !== null &&
          FAS.endDate !== null &&
          (FAS.startDate < startDate || FAS.endDate > endDate)
      )
    ) {
      errors.AYS = "Date selected must fall under the Academic Year.";
    }

    if (
      fieldsListHolidays.some(
        (holiday) =>
          holiday.startDate !== null &&
          holiday.endDate !== null &&
          (holiday.startDate < startDate || holiday.endDate > endDate)
      )
    ) {
      errors.holiday = "Date selected must fall under the Academic Year.";
    }

    if (
      fieldsAcademicSession.some(
        (FAS) =>
          FAS.startDate > FAS.endDate &&
          FAS.startDate !== null &&
          FAS.endDate !== null
      )
    ) {
      errors.AYS = "End Date can't be after Start date.";
    }

    if (
      fieldsListHolidays.some(
        (holiday) =>
          holiday.startDate > holiday.endDate &&
          holiday.startDate !== null &&
          holiday.endDate !== null
      )
    ) {
      errors.holiday = "End Date can't be after Start date.";
    }

    if (
      fieldsAcademicPlanner.some(
        (AP) =>
          AP.startDate !== null &&
          AP.endDate !== null &&
          (AP.startDate < startDate || AP.endDate > endDate)
      )
    ) {
      errors.AP = "Date selected must fall under the Academic Year.";
    }

    if (
      fieldsAcademicPlanner.some(
        (AP) =>
          AP.startDate > AP.endDate &&
          AP.startDate !== null &&
          AP.endDate !== null
      )
    ) {
      errors.AP = "End Date can't be after Start date.";
    }

    function hasDuplicates(array, property) {
      const arrayToSet = new Set();
      for (const obj of array) {
        const value = obj[property]?.toString();
        if (arrayToSet.has(value)) {
          return true;
        }
        arrayToSet.add(value);
      }
      return false;
    }

    if (hasDuplicates(fieldsAcademicSession, "startDate")) {
      errors.AYS = "Two sessions cant start on the same day";
    }

    return errors;
  };

  const errors = validateFirstPart();

  const isFormValid = Object.keys(errors).length === 0;

  const isFieldsAcademicSession = fieldsAcademicSession.some(
    (field) => !field.session || !field.startDate || !field.endDate
  );
  const isFieldsShift = fieldsSchoolShift.some(
    (field) =>
      !field.value || !field.startDate || !field.endDate || !field.remark
  );
  const isFieldListHolidays = fieldsListHolidays.some(
    (field) =>
      !field.name ||
      !field.startDate ||
      !field.endDate ||
      !field.category ||
      !field.remarks
  );
  const isFieldAcademicPlanner = fieldsAcademicPlanner.some(
    (field) => !field.value || !field.startDate || !field.endDate
  );
  const isYearSelectEmpty = !startDate || !endDate;

  const isButtonDisabled =
    isFieldsAcademicSession ||
    isFieldListHolidays ||
    isYearSelectEmpty ||
    isFieldsShift ||
    !isFormValid;

  // handel activepart
  const handleSetActivePart = (newActivePart) => {
    setPart((prevPart) => ({
      ...prevPart,
      activePart: newActivePart,
    }));
  };

  // calnder icon click acedmec session
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);

  const handleStartDateClick = () => {
    if (startDatePickerRef.current) {
      startDatePickerRef.current.setOpen(true);
    }
  };

  const handleEndDateClick = () => {
    console.log(endDatePickerRef.current);
    if (endDatePickerRef.current) {
      endDatePickerRef.current.setOpen(true);
    }
  };

  const timePickerRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenPicker = () => {
    if (timePickerRef.current) {
      timePickerRef.current.setOpen(true);
    }
  };

  // calnder icon click acedmec session
  const startDatePickerRefholidays = useRef(null);
  const endDatePickerRefholidays = useRef(null);
  const handleStartDateClickholidays = () => {
    startDatePickerRefholidays.current.setOpen(true);
  };
  const handleEndDateClickholidays = () => {
    endDatePickerRefholidays.current.setOpen(true);
  };
  // calnder icon click acedmec Planner
  const startDatePickerRefAcedemicPlanner = useRef(null);
  const endDatePickerRefAcedemicPlanner = useRef(null);
  const handleStartDateClickAcedemicPlanner = () => {
    startDatePickerRefAcedemicPlanner.current.setOpen(true);
  };
  const handleEndDateClickAcedemicPlanner = () => {
    endDatePickerRefAcedemicPlanner.current.setOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (schoolId) {
          const res = await axios.get(
            `/api/v1/school/academicYear/get/school/${schoolId}`
          );
          //Need to add toISO at the Date
          if (res && res.data && res.data.data) {
            const fetchedStartYear = new Date(res.data.data.start_date);
            fetchedStartYear.setMonth(0);
            fetchedStartYear.setDate(1);

            const fetchedEndYear = new Date(res.data.data.end_date);
            fetchedEndYear.setMonth(0);
            fetchedEndYear.setDate(1);

            const fetchedStartDate = new Date(res.data.data.start_date);
            const fetchedEndDate = new Date(res.data.data.end_date);

            setAcademicYearID(res.data.data.id);
            setStartYear(fetchedStartYear);
            setEndYear(fetchedEndYear);
            setStartDate(fetchedStartDate);
            setEndDate(fetchedEndDate);
            setPreviousStartYear(fetchedStartDate);
            setPreviousEndYear(fetchedEndDate);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.log(error);
      }
    };

    fetchData();
  }, [schoolId, part]);

  useEffect(() => {
    const fetchAcademicSessionData = async () => {
      try {
        if (academicYearID) {
          const response = await axios.get(
            `/api/v1/school/academicYearSession/get/academicYear/${academicYearID}`
          );
          const createdSessions = response.data.data.map(
            (createdSession, index) => ({
              id: index + 1,
              AYSid: createdSession.id,
              session: createdSession.name,
              startDate: new Date(createdSession.start_date),
              endDate: new Date(createdSession.end_date),
            })
          );
          if (response.data.data.length > 0) {
            setFieldsAcademicSession(createdSessions);
          }
        }
      } catch (error) {
        console.error("Error fetching academic session data:", error);
        handleError(error.response.data.message);
      }
    };

    fetchAcademicSessionData();
  }, [academicYearID, part]);

  useEffect(() => {
    const fetchAcademicPlannerData = async () => {
      try {
        if (academicYearID) {
          const response = await axios.get(
            `/api/v1/school/academicPlanner/get/${academicYearID}`
          );
          const createdSessions = response.data.data.map(
            (createdSession, index) => ({
              id: index + 1,
              plannerId: createdSession.id,
              value: createdSession.name,
              startDate: new Date(createdSession.start_date),
              endDate: new Date(createdSession.end_date),
            })
          );
          if (response.data.data.length > 0) {
            setFieldsAcademicPlanner(createdSessions);
          }
        }
      } catch (error) {
        console.error("Error fetching academic planner data:", error);
        handleError(error.response.data.message);
      }
    };

    fetchAcademicPlannerData();
  }, [academicYearID, part]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (academicYearID) {
          const response = await axios.get(
            `/api/v1/school/schoolSession/get/academicYear/${academicYearID}`
          );
          const createdSessions = response.data.data.map(
            (createdSession, index) => ({
              id: index + 1,
              shiftId: createdSession.id,
              remark: createdSession.remark,
              startDate: createdSession.start_time,
              endDate: createdSession.end_time,
              value: createdSession.academicYearSessionId.name,
            })
          );
          if (response.data.data.length > 0) {
            setFieldsSchoolShift(createdSessions);
          }
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
        handleError(error.response.data.message);
      }
    };

    fetchData();
  }, [academicYearID, part]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (academicYearID) {
          const response = await axios.get(
            `/api/v1/school/holiday/get/academicYear/${academicYearID}`
          );

          const mappedData = response.data.data.map((holiday, index) => ({
            id: index + 1,
            holidayId: holiday.id,
            name: holiday.name,
            startDate: new Date(holiday.start_date),
            endDate: new Date(holiday.end_date),
            remarks: holiday.description,
            category: holiday.category.id,
          }));

          if (response.data.data.length > 0) {
            setFieldsListHolidays(mappedData);
          }
        }
      } catch (error) {
        console.error("Error fetching holiday data:", error);
        // handleError(error.response.data.message);
      }
    };

    fetchData();
  }, [academicYearID, part]);

  useEffect(() => {
    if (previousEndYear && previousStartYear) {
      console.log(startDate !== previousStartYear, endDate !== previousEndYear);
      if (
        startDate !== previousStartYear ||
        endDate !== previousEndYear ||
        startYear.getFullYear() !== previousStartYear.getFullYear() ||
        endYear.getFullYear() !== previousEndYear.getFullYear()
      ) {
        setISModifiedAY(true);
      }
    }
  }, [
    startYear,
    endYear,
    previousStartYear,
    previousEndYear,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    const fetchHolidayCategories = async () => {
      try {
        const response = await axios.get("/api/v1/school/holiday/categories");

        if (!response.data) {
          throw new Error("Failed to fetch holiday categories");
        }

        setHolidayCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching holiday categories:", error.message);
        handleError(error.response.data.message);
      }
    };

    fetchHolidayCategories();
  }, []);

  const createAY = async () => {
    try {
      if (startYear && endYear) {
        let body = {
          start_year: new Date(startYear).getFullYear(),
          end_date: endDate,
          start_date: startDate,
          end_year: new Date(endYear).getFullYear(),
          school: schoolId,
        };
        const res = await axios.post(
          "/api/v1/school/academicYear/create",
          body
        );
        setAcademicYearID(res.data.data.id);
        return res.data.data.id;
      }
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  const updateAY = async () => {
    try {
      if (startYear && endYear) {
        let body = {
          id: academicYearID,
          start_year: new Date(startYear).getFullYear(),
          end_date: endDate,
          start_date: startDate,
          end_year: new Date(endYear).getFullYear(),
          school: schoolId,
        };
        console.log(body);
        const res = await axios.put(
          `/api/v1/school/academicYear/update/${academicYearID}`,
          body
        );
        console.log(res);
      }
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  const createAcademicSessions = async (AYID) => {
    const url = "/api/v1/school/academicYearSession/create";
    const AYSIds = [];
    try {
      for (const session of fieldsAcademicSession) {
        const requestBody = {
          academic_year_id: AYID ? AYID : academicYearID,
          name: session.session,
          start_date: session.startDate,
          end_date: session.endDate,
        };

        if (session.AYSid) {
          const updateUrl = "/api/v1/school/academicYearSession/update";
          const requestBodyUpdate = {
            ...requestBody,
            id: session.AYSid,
          };

          const res = await axios.put(updateUrl, requestBodyUpdate);
          AYSIds.push({ session: res.data.data.name, id: res.data.data.id });
        } else {
          const response = await axios.post(url, requestBody);
          AYSIds.push({
            session: response.data.data.name,
            id: response.data.data.id,
          });
        }
      }
      return AYSIds;
    } catch (error) {
      console.error("Error creating AYS:", error);
      handleError(error.response.data.message);
    }
  };

  const createHoliday = async (AYID) => {
    const url = "/api/v1/school/holiday/create";
    try {
      for (const holiday of fieldsListHolidays) {
        const requestBody = {
          academic_year: AYID ? AYID : academicYearID,
          name: holiday.name,
          description: holiday.remarks,
          start_date: new Date(holiday.startDate),
          end_date: new Date(holiday.endDate),
          category: holiday.category,
          schoolId,
        };

        if (holiday.holidayId) {
          const updateUrl = `/api/v1/school/holiday/update/${holiday.holidayId}`;
          const requestBodyUpdate = {
            ...requestBody,
            id: holiday.holidayId,
          };

          await axios.put(updateUrl, requestBodyUpdate);
        } else {
          await axios.post(url, requestBody);
        }
      }
    } catch (error) {
      console.error("Error creating AYS:", error);
      handleError(error.response.data.message);
    }
  };

  const createSchoolSession = async (AYSIds, AYID) => {
    //Timetable Session api.
    const url = "/api/v1/school/schoolSession/create";
    try {
      for (const shift of fieldsSchoolShift) {
        const matchingAYS = AYSIds.find((AYS) => AYS.session === shift.value);

        const requestBody = {
          academic_year_id: AYID ? AYID : academicYearID,
          academicYearSessionId: matchingAYS.id,
          start_time: shift.startDate,
          end_time: shift.endDate,
          remark: shift.remark,
          schoolId,
        };
        if (shift.shiftId) {
          const updateURL = `/api/v1/school/schoolSession/update/${shift.shiftId}`;
          const requestUpdateBody = {
            id: shift.shiftId,
            ...requestBody,
          };
          await axios.put(updateURL, requestUpdateBody);
        } else {
          await axios.post(url, requestBody);
        }
      }
    } catch (error) {
      console.error("Error creating SHIFT:", error);
      handleError(error.response.data.message);
    }
  };

  const createAcademicPlanner = async (AYID) => {
    const url = "/api/v1/school/academicPlanner/create";
    try {
      for (const planner of fieldsAcademicPlanner) {
        const requestBody = {
          academic_year_id: AYID ? AYID : academicYearID,
          name: planner.value,
          start_date: planner.startDate,
          end_date: planner.endDate,
        };

        if (planner.plannerId) {
          const updateUrl = `/api/v1/school/academicPlanner/update/${planner.plannerId}`;
          const requestBodyUpdate = {
            ...requestBody,
            id: planner.plannerId,
          };

          await axios.put(updateUrl, requestBodyUpdate);
        } else {
          await axios.post(url, requestBody);
        }
      }
    } catch (error) {
      console.error("Error creating AP:", error);
      handleError(error.response.data.message);
    }
  };
  const submitHandler = async (e) => {
    try {
      const currentDate = new Date();
      const isWithinPreviousAY =
        currentDate >= new Date(previousStartYear) &&
        currentDate <= new Date(previousEndYear);

      const isAYAllowed =
        startDate.getFullYear() > new Date(previousStartYear).getFullYear();
      let AYID = null;
      if (!previousStartYear || !previousEndYear) {
        AYID = await createAY();
      } else if (isModifiedAY && isWithinPreviousAY) {
        await updateAY();
      } else if (isModifiedAY && isAYAllowed) {
        AYID = await createAY();
      }

      const AYSIds = await createAcademicSessions(AYID);
      await createSchoolSession(AYSIds, AYID);
      await createHoliday(AYID);
     // await createAcademicPlanner(AYID);

      setPart({
        ...part,
        activePart: "secondPart",
      });
    } catch (error) {
      console.error("Error in submitHandler:", error);
      handleError(error.response.data.message);
    }
  };

  // if (isModifiedAY && !isAYExpired) {
  //   updateAY();
  // } else  {
  //   createAY();
  // // } else  {
  // //   // setPart({
  // //   //   ...part,
  // //   //   activePart: "secondPart",
  // //   // });
  // // }
  // }

  const handleTimeChange = (newTime, field) => {
    const newValue = newTime.format("HH:mm");
    setFieldsSchoolShift((prevFields) =>
      prevFields.map((fieldV) =>
        fieldV.id === field.id ? { ...field, startDate: newValue } : fieldV
      )
    );
  };

  const handleTimeChangeEndTime = (newTime, field) => {
    const newValue = newTime.format("HH:mm");
    setFieldsSchoolShift((prevFields) =>
      prevFields.map((fieldV) =>
        fieldV.id === field.id ? { ...field, endDate: newValue } : fieldV
      )
    );
  };

  return (
    <div className="container_part1">
      <div className="d-flex flex-wrap justify-content-between">
        <div className="masterdata_heading">
          Master Data -Part&nbsp;
          {part.activePart === "firstPart" && 1}
          {part.activePart === "secondPart" && 2}
          {part.activePart === "thirdPart" && 3}
          {part.activePart === "fourPart" && 4}
          {part.activePart === "fivePart" && 4}
        </div>

        <div>
          <button
            className={` ${
              part.activePart === "firstPart"
                ? "active button_PartActive"
                : "button_part"
            }`}
            onClick={(e) => {
              if (
                part.activePart === "firstPart" ||
                part.activePart === "secondPart" ||
                part.activePart === "thirdPart" ||
                part.activePart === "fourPart" ||
                part.activePart === "fivePart"
              )
                setPart({
                  ...part,
                  activePart: "firstPart",
                });
            }}
          >
            Part 1
          </button>

          <button
            className={` ${
              part.activePart === "secondPart"
                ? "active button_PartActive"
                : "button_part"
            }`}
            onClick={(e) => {
              if (
                part.activePart === "secondPart" ||
                part.activePart === "thirdPart" ||
                part.activePart === "fourPart" ||
                part.activePart === "fivePart"
              )
                setPart({
                  ...part,
                  activePart: "secondPart",
                });
            }}
          >
            Part 2
          </button>

          {/* <button
            className={` ${
              part.activePart === "thirdPart"
                ? "active button_PartActive"
                : "button_part"
            }`}
            onClick={(e) => {
              if (
                part.activePart === "thirdPart" ||
                part.activePart === "fourPart" ||
                part.activePart === "fivePart"
              )
                setPart({
                  ...part,
                  activePart: "thirdPart",
                });
            }}
          >
            Part 3
          </button> */}

          {/* <button
            className={` ${
              part.activePart === "fourPart"
                ? "active button_PartActive"
                : "button_part"
            }`}
            onClick={(e) => {
              setPart({
                ...part,
                activePart: "fourPart",
              });
            }}
          >
            Part 4
          </button> */}

          <button
            className={` ${
              part.activePart === "fivePart"
                ? "active button_PartActive"
                : "button_part"
            }`}
            // onClick={(e) => {
            //     setPart({
            //       ...part,
            //       activePart: "fivePart",
            //     });
            // }}
          >
            Part 3
          </button>
        </div>
      </div>

      {part.activePart === "firstPart" && (
        <>
          <div className="academic_heading">Academic Session Information</div>
          <div className="d-flex justify-content-center">
            <div>
              <div className="d-flex justify-content-center overAll_academic">
                Overall Academic Year
              </div>
              <div className="d-flex justify-content-around gap-3">
                <div className="inputHoilidays_container2 mt-3">
                  <div className="start_year">
                    Start Year <span className="important_input">*</span>
                  </div>
                  <div className="d-flex container_dateselect_holidays">
                    <div className="date-picker-wrapper">
                      <DatePicker
                        showYearPicker
                        selected={startYear}
                        onChange={(date) => {
                          setStartYear(date);
                          setStartDate(null);
                          setEndDate(null);
                        }}
                        dateFormat="yyyy"
                        className="SelectDate"
                        placeholderText="Start Year"
                        ref={startYearRef}
                      />
                    </div>
                    <span onClick={handleStartIconClick}>
                      <img
                        style={{ cursor: "pointer" }}
                        src="/OnBoard/calenderIcon.png"
                        alt="calender Icon"
                      />
                    </span>
                  </div>
                </div>

                <div className="inputHoilidays_container2 mt-3">
                  <div className="start_year">
                    End Year <span className="important_input">*</span>
                  </div>
                  <div className="d-flex container_dateselect_holidays">
                    <DatePicker
                      selected={endYear}
                      onChange={(date) => {
                        setEndYear(date);
                        setStartDate(null);
                        setEndDate(null);
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      className="SelectDate"
                      placeholderText="End Year"
                      ref={endYearRef}
                    />
                    <span onClick={handleEndIconClick}>
                      <img
                        style={{ cursor: "pointer" }}
                        src="/OnBoard/calenderIcon.png"
                        alt="calender Icon"
                      />
                    </span>
                  </div>
                </div>
                <div className="inputHoilidays_container2 mt-3">
                  <div className="start_year">
                    Start Date <span className="important_input">*</span>
                  </div>
                  <div className="d-flex container_dateselect_holidays">
                    <DatePicker
                      dateFormat="dd/MM/yyy"
                      maxDate={new Date(endYear?.getFullYear(), 11, 31)}
                      endDate={new Date(endYear?.getFullYear(), 11, 31)}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={startYear}
                      startDate={startYear}
                      showYearDropdown={false}
                      className="SelectDate"
                      placeholderText="DD:MM:YYYY"
                      ref={startDateRef}
                    />
                    <span onClick={handleStartDateIconClick}>
                      <img
                        style={{ cursor: "pointer" }}
                        src="/OnBoard/calenderIcon.png"
                        alt="calender Icon"
                      />
                    </span>
                  </div>
                </div>

                <div className="inputHoilidays_container2 mt-3">
                  <div className="start_year">
                    End Date <span className="important_input">*</span>
                  </div>
                  <div className="d-flex container_dateselect_holidays">
                    <DatePicker
                      dateFormat="dd/MM/yyy"
                      ref={endDateRef}
                      maxDate={new Date(endYear?.getFullYear(), 11, 31)}
                      endDate={new Date(endYear?.getFullYear(), 11, 31)}
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      minDate={startYear}
                      startDate={startYear}
                      showYearDropdown={false}
                      className="SelectDate"
                      placeholderText="DD:MM:YYYY"
                    />
                    <span onClick={handleEndDateIconClick}>
                      <img
                        style={{ cursor: "pointer" }}
                        src="/OnBoard/calenderIcon.png"
                        alt="calender Icon"
                      />
                    </span>
                  </div>
                </div>
              </div>
              {errors.AY && <span className="error">{errors.AY}</span>}
            </div>
          </div>

          <div className="partFirst_subContainer">
            <div className="list_of_SchoolShift_container">
              {/* <div className='d-flex flex-wrap justify-content-end flex-wrap'>
                            <button className='save_btn_firstpart'>Start</button>
                        </div> */}
              <div className="d-flex justify-content-between flex-wrap">
                <div className="inputHoilidays_container1  ">
                  <div className="Academic_heading">
                    Academic Session <span className="important_input">*</span>
                  </div>
                </div>
                <div className="dates_totalCotainer">
                  <div className="inputHoilidays_container2 ">
                    <div className="input_block_hedding ">
                      Start <span className="important_input">*</span>
                    </div>
                  </div>
                  <div className="inputHoilidays_container3 ">
                    <div className="input_block_hedding ">
                      End <span className="important_input">*</span>
                    </div>
                  </div>
                </div>

                <div className="total_plusDelete"></div>
              </div>
              {fieldsAcademicSession.map((field) => (
                <div
                  key={field.id}
                  className="d-flex justify-content-between flex-wrap"
                >
                  <div className="inputHoilidays_container1 mt-3">
                    {/* <div className="input_block_hedding mb-2">Academic Planner 1</div> */}
                    <input
                      className="inputSchool_blocks"
                      placeholder="Session"
                      value={field.session}
                      maxLength={50}
                      onChange={(e) =>
                        handleInputAcademicSession(
                          field.id,
                          "session",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="dates_totalCotainer">
                    <div className="inputHoilidays_container2 mt-3">
                      <div className="d-flex container_dateselect_holidays">
                        <DatePicker
                          ref={startDatePickerRef}
                          selected={field.startDate}
                          onChange={(date) =>
                            handleInputAcademicSession(
                              field.id,
                              "startDate",
                              date
                            )
                          }
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          scrollableYearDropdown
                          className="SelectDate"
                          placeholderText="DD:MM:YYYY"
                        />
                        <img
                          src="/OnBoard/calenderIcon.png"
                          alt="calendar Icon"
                          style={{ cursor: "pointer" }}
                          onClick={handleStartDateClick}
                        />
                      </div>
                    </div>
                    <div className="inputHoilidays_container3 mt-3">
                      <div className="d-flex container_dateselect_holidays">
                        <DatePicker
                          ref={endDatePickerRef}
                          selected={field.endDate}
                          showYearDropdown
                          scrollableYearDropdown
                          onChange={(date) =>
                            handleInputAcademicSession(
                              field.id,
                              "endDate",
                              date
                            )
                          }
                          dateFormat="dd/MM/yyyy"
                          className="SelectDate"
                          placeholderText="DD:MM:YYYY"
                        />
                        <img
                          src="/OnBoard/calenderIcon.png"
                          alt="calendar Icon"
                          style={{ cursor: "pointer" }}
                          onClick={handleEndDateClick}
                        />
                      </div>
                    </div>
                  </div>

                  {field.id !== 1 ? (
                    <div
                      className="total_plusDeleteIcon"
                      onClick={() =>
                        handleRemoveFieldAcademicSession(field, field.id)
                      }
                    >
                      <img src="/OnBoard/Dustbin.png" alt="dustbin Icon"></img>
                    </div>
                  ) : (
                    <div className="total_plusDeleteIcon"></div>
                  )}
                </div>
              ))}
              {errors.AYS && <span className="error">{errors.AYS}</span>}
              {!isFieldsAcademicSession && (
                <div className="total_plusDelete">
                  <img
                    src="/OnBoard/plusIcon_part1.png"
                    alt="plus Icon"
                    className="plusDimensions"
                    onClick={handleAddFieldAcademicSession}
                  ></img>
                  <p className="AddRowText">Add Row</p>
                </div>
              )}
            </div>

            {/* List of school shifts */}

            <div className="list_of_SchoolShift_container">
              <div className="Academic_heading">List of school shifts</div>
              {fieldsSchoolShift.map((field) => (
                <div key={field.id}>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="inputHoilidays_container1 mt-4">
                      <select
                        className="form-select inputSchool_blocks"
                        value={field.value}
                        onChange={(e) => {
                          const selectedSession = e.target.value;
                          handleInputSession(
                            field.id,
                            "value",
                            selectedSession
                          );
                        }}
                      >
                        <option selected value="">
                          Select Session
                        </option>
                        {fieldsAcademicSession.map((session) => (
                          <option key={session.id} value={session.session}>
                            {session.session}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="inputHoilidays_container4 mt-4">
                      <input
                        className="inputSchool_blocks"
                        placeholder={"Enter Shift Name " + field.id}
                        value={field.remark}
                        maxLength={50}
                        onChange={(e) => {
                          const newRemark = e.target.value;
                          handleInputSession(field.id, "remark", newRemark);
                        }}
                      />
                    </div>
                    <div className="plusIcon_blocks">
                      {/* this use for we need sapce  */}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="inputHoilidays_container1 mt-4">
                      <div className="input_block_hedding mb-2">
                        Start <span className="important_input">*</span>
                      </div>
                      <div className="d-flex container_dateselect_holidays">
                        {/* <DatePicker
                                               
                                                selected={field.startDate}
                                                onChange={(date) => handleInputListHolidays(field.id, 'startDate', date)}
                                                dateFormat='dd/MM/yyyy'
                                                className='SelectDate'
                                                placeholderText='DD:MM:YYYY1'
                                            /> */}
                        {/* <input
                          placeholder="HH:MM"
                          value={field.startDate}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setFieldsSchoolShift((prevFields) =>
                              prevFields.map((fieldV) =>
                                fieldV.id === field.id
                                  ? { ...field, startDate: newValue }
                                  : fieldV
                              )
                            );
                          }}
                          className="startTimeInputSchoolShift"
                          maxLength={5}
                        /> */}

                        <TimeClockValue
                          ref={timePickerRef}
                          key={field.id}
                          selectedTime={field.startDate}
                          onChange={(newTime) =>
                            handleTimeChange(newTime, field)
                          }
                        />

                        {/* <img
                          src="/OnBoard/selectTimeIcon.png"
                          alt="selectTimeIcon"
                          onClick={handleOpenPicker}
                        ></img> */}
                      </div>
                    </div>
                    <div className="inputHoilidays_container4 mt-4">
                      <div className="input_block_hedding mb-2">
                        End <span className="important_input">*</span>
                      </div>
                      <div className="d-flex container_dateselect_holidays">
                        {/* <DatePicker
                                                
                                                selected={field.startDate}
                                                onChange={(date) => handleInputSchoolShift(field.id, 'startDate', date)}
                                                dateFormat='dd/MM/yyyy'
                                                className='SelectDate'
                                                placeholderText='DD:MM:YYYY'
                                            /> */}
                        {/* <input
                          placeholder="HH:MM"
                          value={field.endDate}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setFieldsSchoolShift((prevFields) =>
                              prevFields.map((fieldV) =>
                                fieldV.id === field.id
                                  ? { ...field, endDate: newValue }
                                  : fieldV
                              )
                            );
                          }}
                          className="startTimeInputSchoolShift"
                          maxLength={5}
                        /> */}
                        <TimeClockValue
                          key={field.id + "second"}
                          selectedTime={field.endDate}
                          onChange={(newTime) =>
                            handleTimeChangeEndTime(newTime, field)
                          }
                        />
                        {/* <img
                          src="/OnBoard/selectTimeIcon.png"
                          alt="selectTimeIcon"
                        ></img> */}
                      </div>
                    </div>

                    {field.id === 1 ? (
                      <div className="total_plusDeleteIcon"></div>
                    ) : (
                      <div
                        className="plusIcon_blocks mt-4 pt-4"
                        onClick={() =>
                          handleRemoveFieldSchoolShift(field, field.id)
                        }
                      >
                        <img
                          src="/OnBoard/Dustbin.png"
                          alt="dustbin Icon"
                        ></img>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {errors.SHIFT && <span className="error">{errors.SHIFT}</span>}
              {!isFieldsShift && (
                <div className="total_plusDelete">
                  <img
                    src="/OnBoard/plusIcon_part1.png"
                    alt="plus Icon"
                    className="plusDimensions"
                    onClick={handleAddFieldSchoolShift}
                  ></img>
                  <p className="AddRowText">Add Row</p>
                </div>
              )}
            </div>

            {/* code for List of holidays */}
            <div className="list_of_SchoolShift_container">
              <div className="Academic_heading">List of holidays</div>

              {fieldsListHolidays.map((field) => (
                <div key={field.id}>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="inputHoilidays_container1 mt-4">
                      <div className="input_block_hedding mb-2">
                        Holiday {field.id}{" "}
                        <span className="important_input">*</span>
                      </div>
                      <input
                        className="inputSchool_blocks"
                        value={field.name}
                        maxLength={50}
                        onChange={(e) =>
                          handleInputListHolidays(
                            field.id,
                            "name",
                            e.target.value
                          )
                        }
                        placeholder="Enter Name"
                      />
                    </div>
                    <div className="dates_totalCotainer">
                      <div className="inputHoilidays_container2 mt-4">
                        <div className="input_block_hedding mb-2">
                          Start <span className="important_input">*</span>
                        </div>
                        <div className="d-flex container_dateselect_holidays">
                          <DatePicker
                            ref={startDatePickerRefholidays}
                            selected={field.startDate}
                            onChange={(date) =>
                              handleInputListHolidays(
                                field.id,
                                "startDate",
                                date
                              )
                            }
                            dateFormat="dd/MM/yyyy"
                            className="SelectDate"
                            placeholderText="DD:MM:YYYY"
                          />
                          <img
                            onClick={handleStartDateClickholidays}
                            src="/OnBoard/calenderIcon.png"
                            style={{ cursor: "pointer" }}
                            alt="calender Icon"
                          ></img>
                        </div>
                      </div>
                      <div className="inputHoilidays_container3 mt-4">
                        <div className="input_block_hedding mb-2">
                          End <span className="important_input">*</span>
                        </div>
                        <div className="d-flex container_dateselect_holidays">
                          <DatePicker
                            ref={endDatePickerRefholidays}
                            selected={field.endDate}
                            onChange={(date) =>
                              handleInputListHolidays(field.id, "endDate", date)
                            }
                            dateFormat="dd/MM/yyyy"
                            className="SelectDate"
                            placeholderText="DD:MM:YYYY"
                          />
                          <img
                            onClick={handleEndDateClickholidays}
                            src="/OnBoard/calenderIcon.png"
                            style={{ cursor: "pointer" }}
                            alt="calender Icon"
                          ></img>
                        </div>
                      </div>
                    </div>

                    <div className="plusIcon_blocks">
                      {/* this use for we need sapce  */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="inputHoilidays_container1 mt-4">
                      <select
                        className="form-select dropdownInner"
                        value={field.category}
                        onChange={(e) =>
                          handleInputListHolidays(
                            field.id,
                            "category",
                            e.target.value
                          )
                        }
                      >
                        <option value="">
                          Select Category{" "}
                          <span className="important_input">*</span>
                        </option>
                        {holidayCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="inputHoilidays_container4 mt-4">
                      <input
                        className="inputSchool_blocks"
                        value={field.remarks}
                        maxLength={150}
                        onChange={(e) =>
                          handleInputListHolidays(
                            field.id,
                            "remarks",
                            e.target.value
                          )
                        }
                        placeholder="Add Remark"
                      />
                    </div>

                    {field.id === 1 ? (
                      <div className="total_plusDeleteIcon"></div>
                    ) : (
                      <div
                        className="plusIcon_blocks mt-4"
                        onClick={() =>
                          handleRemoveFieldListHolidays(field, field.id)
                        }
                      >
                        <img
                          src="/OnBoard/Dustbin.png"
                          alt="dustbin Icon"
                        ></img>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {errors.holiday && (
                <span className="error">{errors.holiday}</span>
              )}
              {!isFieldListHolidays && (
                <div className="total_plusDelete">
                  <img
                    src="/OnBoard/plusIcon_part1.png"
                    alt="plus Icon"
                    className="plusDimensions"
                    onClick={handleAddFieldListHolidays}
                  ></img>
                  <p className="AddRowText">Add Row</p>
                </div>
              )}
            </div>

            {/* code for Academic Planner */}
          </div>

          <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
            <div>
              <span className="footer_text1">
                {/* For bulk upload please use this template */}
              </span>
              {/* <span className="footer_text2">Download template</span> */}
            </div>

            <div className="d-flex flex-wrap gap-3">
              {/* <div className="uploadFileBtn_MasterData">
                <input
                  type="file"
                  id="attachFile"
                  style={{ display: "none" }}
                />
                <label htmlFor="attachFile">
                  <span>
                    <BsPlusCircle /> &nbsp;Upload file
                  </span>
                </label>
              </div> */}
              <button
                id="NextBtn_school_masterData"
                onClick={submitHandler}
                disabled={isButtonDisabled}
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}

      {part.activePart === "secondPart" && (
        <SecondPart setActivePart={handleSetActivePart} />
      )}
      {part.activePart === "thirdPart" && (
        <ThirdPart setActivePart={handleSetActivePart} />
      )}
      {part.activePart === "fourPart" && (
        <FourPart setActivePart={handleSetActivePart} />
      )}
      {part.activePart === "fivePart" && (
        <FivePart closeAllPrev={closeAllPrev} />
      )}
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
    </div>
  );
}
