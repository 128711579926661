import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.breathe4school.in",
  //baseURL: "http://3.110.173.91",
  //baseURL: "http://103.189.89.190",
  //baseURL: "http://34.131.185.191:3002",
  //baseURL: "http://64.227.164.241:3002",
  //baseURL: "http://localhost:3002"
});

export default instance;
