import React from "react";

function RemoveErrorPopup({ closePopup, handleDelete }) {
  return (
    <div className="published_Deative_transprant_body">
      <div className="published_Deative_preview_container">
        <div className="col-12 deactivate_msg delInfo">Important!</div>
        <div className="col-12 deactivate_msg">
          <img
            alt=""
            src="/images/cross-circle3.svg"
            className="crossIconImgQue"
            onClick={(e) => closePopup()}
          />
          Are you sure want to remove this?.
        </div>
        <div className="row col-12 publish_deactive_btnBox">
          <button
            onClick={() => {
              handleDelete();
              closePopup();
            }}
            className="btn col-sm-5 my-1 deactive_sure_btn buttonHover"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
}

export default RemoveErrorPopup;
