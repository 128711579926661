import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useSchoolContext } from "../../../schoolContext";
import axios from "../../../axios";
import Select from "react-select";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../ErrorPopup";

export default function ThirdPart({ setActivePart }) {
  // below code for adding fields value
  const [items, setItems] = useState({
    Board: [],
    Subject1: [],
    Subject2: [],
  });

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  const { b4sSubject } = useSelector((state) => state.configuration.configData);

  console.log(b4sSubject);

  const subjects = b4sSubject?.map((obj) => ({
    label: obj.b4sName,
    value: obj.id,
  }));

  // [
  //   { value: "#FF3419", label: "English" },
  //   { value: "#FF41BE", label: "Hindi" },
  //   { value: "#128084", label: "Environmental Studies" },
  //   { value: "#45BF55", label: "Maths" },
  //   { value: "#3873FE", label: "Science" },
  //   { value: "#003354", label: "Physics" },
  //   { value: "#93CB39", label: "Biology" },
  //   { value: "#A55CDE", label: "Chemistry" },
  //   { value: "#37DAF9", label: "Geography" },
  //   { value: "#AF6152", label: "History" },
  //   { value: "#006A1E", label: "Sanskrit" },
  //   { value: "#E9B500", label: "Social Science" },
  //   { value: "#99003B", label: "Political Science" },
  //   { value: "#27F190", label: "Statistics" },
  //   { value: "#F99933", label: "Kannada" },
  // ];

  const DropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#99ADBB",
      minHeight: "30px",
      padding: "11px",
      borderRadius: " 8px",
      boxShadow: state.isFocused ? null : null,
    }),
    indicatorSeparator: () => ({ display: "none" }),
    group: (provided) => ({
      ...provided,
      fontWeight: 400,
      fontSize: "14px",
      color: "#333",
    }),
  };

  const addItem = async (property, value) => {
    try {
      let createdItemId;

      if (property === "Board") {
        const url = "/api/v1/educationBoard/create";
        const body = {
          schoolId,
          name: value,
        };
        const response = await axios.post(url, body);
        createdItemId = response.data.data.id;
      } else if (property === "Subject2") {
        const url = "/api/v1/contentType/create";
        const body = {
          schoolId,
          name: value,
        };
        const response = await axios.post(url, body);
        createdItemId = response.data.data.id;
      }
      setItems((prevItems) => ({
        ...prevItems,
        [property]: [
          ...prevItems[property],
          { name: value, id: createdItemId },
        ],
      }));
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error in addItem:", error);
    }
  };

  function generateRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const deleteItem = async (property, index, id) => {
    if (property === "Board") {
      const url = `/api/v1/educationBoard/delete/${id}`;
      await axios.delete(url);
    } else if (property === "Subject2") {
      const url = `/api/v1/contentType/delete/${id}`;
      await axios.delete(url);
    }
    // else if (property === "Subject1") {
    //   const url = `/api/v1/school/subject/delete/${id}`;
    //   await axios.delete(url);
    // }
    setItems((prevItems) => ({
      ...prevItems,
      [property]: prevItems[property].filter((_, i) => i !== index),
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Education Boards
        const boardResponse = await axios.get(
          `/api/v1/educationBoard/get/school/${schoolId}`
        );
        console.log(boardResponse);
        const boardData = boardResponse.data.data.map((board) => ({
          name: board.name,
          id: board.id,
        }));

        const subject1Response = await axios.get(
          `/api/v1/school/subject/get/school/${schoolId}`
        );
        const subject1Data = subject1Response.data.data.map((subject) => ({
          label: subject.name,
          value: subject.b4sSubjectId,
          id: subject.id,
        }));

        const subject2Response = await axios.get(
          `/api/v1/contentType/get/school/${schoolId}`
        );
        const subject2Data = subject2Response.data.data.map((subject) => ({
          name: subject.name,
          id: subject.id,
        }));

        setItems({
          ...items,
          Board: boardData,
          Subject1: subject1Data,
          Subject2: subject2Data,
        });
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [schoolId]);

  const handleAdd = (property) => {
    const inputElement = document.getElementById(property);
    const value = inputElement.value.trim();
    const alphanumericWithSpaceRegex = /^[a-zA-Z0-9\s]+$/;

    if (alphanumericWithSpaceRegex.test(value)) {
      addItem(property, value);
      inputElement.value = "";
    } else {
      alert(
        "Invalid characters. Please enter alphanumeric characters and spaces only."
      );
    }
  };

  const handleAddItem = (property, event) => {
    if (event.key === "Enter" || event.type === "click") {
      const inputElement = document.getElementById(property);
      const value = inputElement.value.trim();
      handleAdd(property, value);
    }
  };

  const isBtnValid =
    // items.Board.length === 0 ||
    items.Subject1.length === 0;
  // ||
  // items.Subject2.length === 0;

  // handle active part Four
  const handleClick = async () => {
    try {
      // debugger;
      if (items.Subject1.length > 0) {
        for (const subject of items.Subject1) {
          if (!subject?.id) {
            const url = "/api/v1/school/subject/create";
            const body = {
              schoolId,
              name: subject.label,
              subject_color: generateRandomColor(),
              b4sSubjectId: subject.value,
            };
            const response = await axios.post(url, body);
            // let createdItemId = response.data.data.id;
            // let property = "Subject1";
            // setItems((prevItems) => ({
            //   ...prevItems,
            //   [property]: [
            //     ...prevItems[property],
            //     { value: subject.value, label: subject.label, id: createdItemId },
            //   ],
            // }));
          }
        }
        setActivePart("fivePart");
      }
    } catch (error) {
      console.log(error);
      handleError(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="subHeading_part5 mt-4">
        List of all supported Subjects
      </div>
      <div className="part5_container mt-2 p-4">
        <Select
          closeMenuOnSelect={false}
          options={subjects}
          styles={DropdownStyle}
          placeholder="Select Subjects"
          value={items.Subject1}
          onChange={(selectedOptions) => {
            setItems((prev) => ({ ...prev, Subject1: selectedOptions }));
          }}
          isMulti
        />
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
        <div>
          <span className="footer_text1">
            {/* For bulk upload please use this template */}
          </span>
          {/* <span className="footer_text2">Download template</span> */}
        </div>

        <div className="d-flex flex-wrap gap-3">
          <button
            id="NextBtn_school_masterData"
            onClick={handleClick}
            disabled={isBtnValid}
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
    </div>
  );
}
