import React, { useState } from 'react'
import '../../../OnBoarding/UseCaseSecondTime/CreateRole/Permission.css'
import { BsPlusCircle } from 'react-icons/bs';
// all css comes from Permission.css 
import './PermissionAcess.css'
import AddRole from './AddRole';
import AsignRole from './AsignRole';

export default function PermissionAcess() {

    const [permision, setPermision] = useState({
        staf: 'Principal',
        addRole: false,
        asignRole: false

    })
    return (
        <>
            {!permision.asignRole &&
                <div className='TotalContainerPermissionAcess'>



                    <div className='d-flex mt-4 flex-wrap justify-content-between align-items-center'>
                        <div className='PermissionHeading '>Permission access</div>
                        <div >
                            <button className='AsignRoleBtn'
                                onClick={(e) => setPermision({
                                    ...permision,
                                    asignRole: true
                                })}
                            ><BsPlusCircle />&nbsp; Assign Role</button>
                        </div>
                    </div>

                    <div className='d-flex flex-wrap gap-2 mt-4 pt-2'>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'Principal' }) }} className={` ${permision.staf === "Principal" ? "active Staf_buttonsActive" : "Staf_buttons"}`}>Principal</div>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'VicePrincipal' }) }} className={` ${permision.staf === "VicePrincipal" ? "active Staf_buttonsActive" : "Staf_buttons"}`}>Vice - Principal</div>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'Director' }) }} className={` ${permision.staf === "Director" ? "active Staf_buttonsActive" : "Staf_buttons"}`}>Director</div>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'HeadAdminitrative' }) }} className={` ${permision.staf === "HeadAdminitrative" ? "active Staf_buttonsActive" : "Staf_buttons"}`}>Head Adminitrative</div>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'Coodinator' }) }} className={` ${permision.staf === "Coodinator" ? "active Staf_buttonsActive" : "Staf_buttons"}`}>Coodinator</div>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'ClassTeacher' }) }} className={` ${permision.staf === "ClassTeacher" ? "active Staf_buttonsActive" : "Staf_buttons"}`}>Class Teacher</div>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'SubjectTeacher' }) }} className={` ${permision.staf === "SubjectTeacher" ? "active Staf_buttonsActive" : "Staf_buttons"}`}>Subject Teacher</div>
                        <div onClick={(e) => { setPermision({ ...permision, staf: 'addStaf', addRole: true }) }} className={` ${permision.staf === "addStaf" ? "active AddStaf_buttonsActive gap-2" : "Staf_buttons_add gap-2"}`}><BsPlusCircle /> Add Role</div>

                    </div>

                    <div className='d-flex justify-content-between modules_container pb-2 mt-4'>
                        <div className='moduleNames d-flex justify-content-start'>Modules</div>
                        <div className='d-flex'>
                            <div className='moduleNames d-flex justify-content-center gap-2 '>All<div><img src='/OnBoard/CreateModule.png' alt='module icon' /></div></div>
                            <div className='moduleNames d-flex justify-content-center gap-2'>Create<div><img src='/OnBoard/CreateModule.png' alt='module icon' /></div></div>
                            <div className='moduleNames d-flex justify-content-center gap-2'>Read<div><img src='/OnBoard/CreateModule.png' alt='module icon' /></div></div>
                            <div className='moduleNames  d-flex justify-content-center gap-2'>Update <div><img src='/OnBoard/CreateModule.png' alt='module icon' /></div></div>
                            <div className='moduleNames  d-flex justify-content-center gap-2'>Delete <div><img src='/OnBoard/CreateModule.png' alt='module icon' /></div></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='d-flex justify-content-between mt-4'>
                            <div className='SubmoduleNames d-flex justify-content-start'>Student Information</div>
                            <div className='d-flex'>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className='SubmoduleNames d-flex justify-content-start'>Class Information</div>
                            <div className='d-flex'>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className='SubmoduleNames d-flex justify-content-start'>Teacher Information</div>
                            <div className='d-flex'>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className='SubmoduleNames d-flex justify-content-start'>Timetable</div>
                            <div className='d-flex'>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className='SubmoduleNames d-flex justify-content-start'>Content Library</div>
                            <div className='d-flex'>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                                <div className='moduleNames d-flex justify-content-center gap-2 '><input type='checkbox' /></div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-end gap-4 mt-3 SaveRestBtn'>
                        <button className='resetBtn'>Discard changes</button>
                        <button className='nextBtn'
                        //    onClick={(e) => setPermision({
                        //     ...permision,
                        //     addClass: true
                        //   })}
                        >Save </button>
                    </div>

                </div>}

            {permision.addRole && <AddRole setPermision={setPermision} />}
            {permision.asignRole && <AsignRole setPermision={setPermision} />}


        </>

    )
}
