import React, { useEffect, useState } from "react";
import "./CreateAccount.css";
import { useForm } from "react-hook-form";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import SetPassWordSchool from "./SetPassWordSchool";
import axios from "../../axios";
import BasicInformation from "../UseCaseSecondTime/BasicInformation";
import { useSchoolContext, useschoolContext } from "../../schoolContext";
import { Link, useNavigate } from "react-router-dom";

export default function CreateAccount() {
  const queryParams = new URLSearchParams(window.location.search);
  const loginNow = queryParams.get('LoginNow');
  const [click, setClick] = useState();
  localStorage.removeItem("schoolId");
  const [loginError, setLoginError] = useState(false);
  const [basicInfo, setBasicInfo] = useState(false);
  const history = useNavigate();
  const [registerState, setRegisterState] = useState({
    username: null,
    passcode: null,
    loginBtn: true,
  });

  const { setSchoolAuthId } = useSchoolContext();

  const {
    formState: { errors },
  } = useForm();

  const [registerStateValidate, setRegisterStateValidate] = useState({
    isusernameValid: null,
    usernameValidationMsg: "",

    isPasscodeValid: null,
    passcodeValidationMsg: "",
  });

  const validateRegistration = () => {
    const usernameRE = /^[a-zA-Z0-9_-]+$/;

    let isvalid = false;
    // for username validation
    if (!registerState.username) {
      setRegisterStateValidate({
        ...registerStateValidate,
        isusernameValid: false,
        usernameValidationMsg: "usernameReq",
      });
      isvalid = false;
    } else if (
      registerState.username &&
      !usernameRE.test(registerState.username)
    ) {
      setRegisterStateValidate({
        ...registerStateValidate,
        isusernameValid: false,
        usernameValidationMsg: "usernamePattern",
      });
      isvalid = false;
    } else {
      setRegisterStateValidate({
        ...registerStateValidate,
        isusernameValid: true,
        usernameValidationMsg: "",
      });
      isvalid = true;
    }
    return isvalid;
  };

  const validateRegistrationEmail = () => {
    const passcodeRejex = /^.*$/;

    let isvalid = false;

    if (!registerState.passcode) {
      setRegisterStateValidate({
        ...registerStateValidate,
        isPasscodeValid: false,
        passcodeValidationMsg: "passcodeRequired",
      });
      isvalid = false;
    } else if (
      registerState.passcode &&
      !passcodeRejex.test(registerState.passcode)
    ) {
      setRegisterStateValidate({
        ...registerStateValidate,
        isPasscodeValid: false,
        passcodeValidationMsg: "passcodePattern",
      });
      isvalid = false;
    } else {
      setRegisterStateValidate({
        ...registerStateValidate,
        isPasscodeValid: true,
        passcodeValidationMsg: "",
      });
      isvalid = true;
    }
    return isvalid;
  };

  useEffect(() => {
    validateRegistration();
  }, [registerState.username]);

  useEffect(() => {
    validateRegistrationEmail();
  }, [registerState.passcode]);

  const handleSubmit = async () => {
    if (validateRegistration() && validateRegistrationEmail()) {
      try {
        const body = {
          username: registerState.username,
          password: registerState.passcode,
        };
        const res = await axios.post("/api/v1/schoolID/login", body);
        if (res) {
          setClick(res?.data?.passwordReset);
          if (!res?.data?.passwordReset) {
            setBasicInfo(!res?.data?.passwordReset);
            history("/BasicInformation");
            setSchoolAuthId(res?.data?.userId);
            localStorage.setItem("schoolAuth", res?.data?.userId);
          }
        }
      } catch (error) {
        console.error("This school Does not exists" + error);
        setLoginError(true);
      }
    }
  };

  useEffect(() => {
    setRegisterStateValidate({
      ...registerStateValidate,
      isusernameValid: true,
      usernameValidationMsg: "",

      isPasscodeValid: true,
      passcodeValidationMsg: "",
    });
  }, []);

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
      setRegisterState({
        ...registerState,
        type: true,
      });
    } else {
      setpassword("password");
      seteye(true);
      setRegisterState({
        ...registerState,
        type: false,
      });
    }
  };

  return (
    <>
      {!click && (
        <div className="Total_container_CreateAcc row">
          <div className="col-lg-3 col-md-5 leftSide_container_createAcc">
            <img
              className="BannerImgSchool"
              src="/OnBoard/SchoolBanner.png"
              alt="banner img"
            />
          </div>
          <div className="col-lg-9 col-md-7 RightSide_container_createAcc">
            <div>
              <form>
                <div className="d-flex justify-content-center">
                  <img
                    className="SchoolIcon"
                    src="/OnBoard/SchoolIcon.png"
                    alt="banner img"
                  />
                </div>
                <div className="form_heading d-flex justify-content-center">
                  Xploresense SMS
                </div>

                <div className="label_inputs mb-2">User name</div>
                <div>
                  <input
                    id="EnterUserName"
                    className={
                      registerStateValidate.isusernameValid === false
                        ? `${errors.name && "invalid"}`
                        : ""
                    }
                    onChange={(e) =>
                      setRegisterState({
                        ...registerState,
                        username: e.target.value,
                      })
                    }
                    maxLength={30}
                    autocomplete="off"
                  />
                </div>
                {registerStateValidate.isusernameValid === false &&
                  registerStateValidate.usernameValidationMsg ===
                    "usernameReq" && (
                    <small className="text-danger">
                      {"Username is Required"}
                    </small>
                  )}
                {registerStateValidate.isusernameValid === false &&
                  registerStateValidate.usernameValidationMsg ===
                    "usernamePattern" && (
                    <small className="text-danger">
                      {"Enter Vaild Username"}
                    </small>
                  )}

                <div className="mt-3  label_inputs mb-2">{loginNow? "Password" : "Password"}</div>
                <div className="total_containerFor_passcode">
                  <input
                    className={
                      registerStateValidate.isPasscodeValid === false
                        ? `${errors.name && "invalid"}`
                        : ""
                    }
                    onChange={(e) =>
                      setRegisterState({
                        ...registerState,
                        passcode: e.target.value,
                      })
                    }
                    id={eye ? "Passcode" : "passowrdOnEye"}
                    type={password}
                    maxLength={30}
                    autoComplete="off"
                  ></input>

                  {registerState.passcode && <span onClick={Eye}>
                    {eye ? (
                      <IoEyeOffOutline className="eyeBall" />
                    ) : (
                      <IoEyeOutline className="eyeBall" />
                    )}
                  </span>}
                </div>
                {registerStateValidate.isPasscodeValid === false &&
                  registerStateValidate.passcodeValidationMsg ===
                    "passcodeRequired" && (
                    <small className="text-danger">
                      {"Passcode is Required"}
                    </small>
                  )}
                {registerStateValidate.isPasscodeValid === false &&
                  registerStateValidate.passcodeValidationMsg ===
                    "passcodePattern" && (
                    <small className="text-danger">
                      {"Enter Only Numbers"}
                    </small>
                  )}
              </form>

              <div>
                <button
                  class="btn btn"
                  id="Register_login_btn"
                  disabled={
                    registerState.username && registerState.passcode
                      ? false
                      : true
                  }
                  type="button"
                  onClick={handleSubmit}
                >
                  Login{" "}
                </button>
              </div>
              {loginError && (
                <small className="text-danger">
                  {"Username and password combination is wrong."}
                </small>
              )}
            </div>
          </div>
        </div>
      )}
      {click && (
        <SetPassWordSchool
          userId={registerState.username}
          setSchoolAuthId={setSchoolAuthId}
        />
      )}
      {basicInfo && <BasicInformation />}
    </>
  );
}
