import React from 'react'

function OnBoardingSuccess() {
    setTimeout(() => {
        window.location.replace("/BasicInformation");
      }, 3000);
  return (
    <div className='completeContainer'>
      <div className='overallData'>
        <img src='/images/LoginSuccess.svg' className='successImage'/>
        <h1 className='bigText'>You have successfully finished school on boarding</h1>
        <p className='underTecxt'>Redirecting you to the basic information page...</p>
      </div>
    </div>
  )
}

export default OnBoardingSuccess
