import React, { useState } from 'react';
import './TeacherBoard.css';
import { ResponsivePie } from '@nivo/pie';
import Table from "react-bootstrap/Table";
import { Popup } from "semantic-ui-react";
import { IoIosArrowBack } from "react-icons/io";
import TeacherTablePropsSchool from './TeacherTableSchool/TeacherTablePropsSchool';
import Select from 'react-select';
import TeacherDetail from './TeacherDetail';

// table css coes for StudentSection.css

export default function TeacherBoard() {

  const [teacherBoard, setTeacherBoard] = useState({
    viewall: false,
    teacherDetail: false,
  })
  const data = [
    { id: 'A', value: 60 },
    { id: 'B', value: 20 },
    { id: 'C', value: 20 },
  ];
  const stylesTable = {

    height: teacherBoard.viewall ? '100%' : '300px',
    overflow: 'scroll',
  };

  const TeacherOptions = [
    { value: 'Teacher 1', label: 'Teacher 1' },
    { value: 'Teacher 2', label: 'Teacher 2' },
    { value: 'Teacher 3', label: 'Teacher 3' },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const DropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#668598',
      height: '39px',
      width: '171px',
      borderRadius: ' 8px',
      fontSize: '14px',
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (provided, state) => ({
      ...provided,
      // width: state.selectProps.menuWidth || 'auto', // Set the width to 10px when menuWidth is set
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      fontSize: '12px',
      color: 'black', // Change the background color when the option is hovered over
      '&:hover': {
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
        color: '#F77F00',
        borderRadius: '5px',
        fontWeight: '600'

      },
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };
  return (
    <div>
      {teacherBoard.teacherDetail ?
    
        <div>
          <TeacherDetail setTeacherBoard={setTeacherBoard} />
        </div>
        :



        <div>
          {!teacherBoard.viewall && <>
            <div className='overviewHeading'>Overview</div>
            <div className='d-flex gap-3 mt-2'>
              <div className='feedbackContainer d-flex p-4'>
                <div>
                  <p className='feedbackName'>Parents feedback</p>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/BlueprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>60% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/PinkprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>20% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/OrangeprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>20% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                </div>
                <div style={{ width: '50%', height: '100%' }}>
                  <ResponsivePie
                    data={data}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    colors={["#6d6fe2", "#fda2c0", "#f7e286"]}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}

                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: "ruby",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "c",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "go",
                        },
                        id: "dots",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className='feedbackContainer d-flex p-4'>
                <div>
                  <p className='feedbackName'>Student feedback</p>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/BlueprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>60% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/PinkprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>20% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/OrangeprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>20% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                </div>
                <div style={{ width: '50%', height: '100%' }}>
                  <ResponsivePie
                    data={data}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    colors={["#6d6fe2", "#fda2c0", "#f7e286"]}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}

                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: "ruby",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "c",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "go",
                        },
                        id: "dots",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className='feedbackContainer d-flex p-4'>
                <div>
                  <p className='feedbackName'>Lecture feedback</p>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/BlueprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>60% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/PinkprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>20% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                  <div>
                    <span className='mr-2'><img src='/LandingPageImg/OrangeprogressDot.png' alt='dots icon' /></span>
                    <span className='mr-1 progressPercentage'>20% </span>
                    <span className='ProgressGradde'> Excellent</span>
                  </div>
                </div>
                <div style={{ width: '50%', height: '100%' }}>
                  <ResponsivePie
                    data={data}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    colors={["#6d6fe2", "#fda2c0", "#f7e286"]}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}

                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: "ruby",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "c",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "go",
                        },
                        id: "dots",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </>}


          {teacherBoard.viewall &&
            <div className='d-flex gap-3'>
              <div className='SBoard' onClick={(e) => setTeacherBoard({ ...teacherBoard, viewall: false })}>
                <IoIosArrowBack />
              </div>
              <div className='SBoard' onClick={(e) => setTeacherBoard({ ...teacherBoard, viewall: false })}>Teacher board</div>
              <div> /</div>
              <div className='StudentInformationPath'>Teacher information</div>
            </div>
          }
          <div className='d-flex flex-wrap justify-content-between mt-4'>
            <div className='student_info_heading'>Teacher information</div>
            <div className='d-flex flex-wrap gap-3'>
              <div className=" px-0  School_search_box">
                <img className="search_icon_school" alt='serch icon' src="/StudentImages/search icon.svg" />
                <input type="text" placeholder="Search" className="search_box_school" />
              </div>

              <Select
                styles={DropdownStyle}
                Value={selectedOption}
                onChange={setSelectedOption}
                options={TeacherOptions}
                placeholder='Select Teacher'
              />

              <Popup
                on="click"
                className="popUpFilter"
                trigger={
                  <div className="school_filter_box">
                    <img className="filter_icon_school" alt='filter icon' src="/StudentImages/filter icon.svg" />&nbsp;
                    <span >Filter</span>
                  </div>
                }
                content={
                  <div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label class="form-check-label" for="flexCheckDefault">
                        class
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label class="form-check-label" for="flexCheckDefault">
                        Division
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label class="form-check-label" for="flexCheckDefault">
                        Teacher
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label class="form-check-label" for="flexCheckDefault">
                        Co-ordinator
                      </label>
                    </div>
                  </div>
                }
                position="bottom center"
              />
              <div>
                {!teacherBoard.viewall && <button className='ViewAll_school' onClick={(e) => setTeacherBoard({
                  ...teacherBoard,
                  viewall: true
                })}
                >View all</button>}
              </div>
            </div>
          </div>
          <div style={stylesTable} className="mt-4"  >
            <Table responsive className="student_profile_table_school" borderless>
              <thead>
                <tr>
                  <th className="">Name</th>
                  <th>School email</th>
                  <th>Personal email</th>
                  <th>Mobile</th>
                  <th>Co-ordinator</th>
                  <th>Class</th>

                </tr>
              </thead>

              <tbody
                onClick={(e) => setTeacherBoard({
                  ...teacherBoard,
                  teacherDetail: true
                })}
              >
                <TeacherTablePropsSchool />
              </tbody>

            </Table>
          </div>
        </div>


      }
    </div>
  )
}