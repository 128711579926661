

import React, { useState } from 'react'
import { BsPlusCircle } from 'react-icons/bs';
//also some css from usecaseSecond.css , masterdata.css
import '../../../OnBoarding/UseCaseSecondTime/Class/ClassComp.css'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import '../SchoolAdmin.css'


export default function StudentEditSA({ setStudentList }) {

    const ClassOptions = [
        { value: 'Class 1', label: 'Class 1' },
        { value: 'Class 2', label: 'Class 2' },
        { value: ' Class 3', label: 'Class 3' },
    ]

    const SectionOptions = [
        { value: 'Section A', label: 'Section A' },
        { value: 'Section B', label: 'Section B' },
        { value: 'Section C', label: 'Section C' },
        { value: 'Section D', label: 'Section D' },
        { value: 'Section E ', label: 'Section E' },
    ]


    const DropdownStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#99ADBB',
            minHeight: '30px',
            padding: '11px',
            borderRadius: ' 8px',
            boxShadow: state.isFocused ? null : null,
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };


    // code for Student add fields more 
    const [fieldsAddStudent, setFieldsAddStudent] = useState([{ id: 1, Fname: '', NewDate: null, SchoolEmail: '', FatherName: '', EmailID: '', Mnumber: '', Occupation: '', MotherName: '', EmailId2: '', Mnumber2: '', Occupation2: '', MDM: '', Bgroup: '', Mtongue: '', SiblingInfo: '', EmergencyNO: '', Bgroup2: '', Class: '', Section: '' }]);
    const handleAddFieldAddStudent = () => {
        const newFields = [...fieldsAddStudent];
        newFields.push({ id: fieldsAddStudent.length + 1, Fname: '', NewDate: null, SchoolEmail: '', FatherName: '', EmailID: '', Mnumber: '', Occupation: '', MotherName: '', EmailId2: '', Mnumber2: '', Occupation2: '', MDM: '', Bgroup: '', Mtongue: '', SiblingInfo: '', EmergencyNO: '', Bgroup2: '', Class: '', Section: '' });
        setFieldsAddStudent(newFields);
    };

    const handleRemoveFieldAddStudent = (id) => {
        const newFields = fieldsAddStudent.filter((field) => field.id !== id);
        // Re-count the field IDs
        const renumberedFields = newFields.map((field, index) => {
            return {
                ...field,
                id: index + 1
            };
        });
        setFieldsAddStudent(renumberedFields);
    };
    const handleInputAddStudent = (id, name, value) => {
        const newFields = [...fieldsAddStudent];
        const index = newFields.findIndex((field) => field.id === id);
        newFields[index][name] = value;
        setFieldsAddStudent(newFields);
    };

    const isfieldsAddStudent = fieldsAddStudent.some((field) => (
        field.Fname === '' || field.SchoolEmail === '' || field.FatherName === '' || field.EmailID === '' || field.Mnumber === '' || field.Occupation === '' || field.MotherName === '' || field.EmailId2 === '' || field.Mnumber2 === '' || field.Occupation2 === '' || field.MDM === '' || field.Mtongue === '' || field.Bgroup === '' || field.SiblingInfo === '' || field.EmergencyNO === '' || field.Bgroup2 === '' || field.Section === '' || field.Class === ''
    ));




    return (
        <>
            <div>

                <div className='d-flex gap-4  mt-4'>
                    {fieldsAddStudent.map((field) => (
                        <div key={field.id} className='classInput_container  mr-2'>
                            <div className='d-flex justify-content-between '>
                                <div className='classinfo_subHeader '>Student info- 0{field.id}</div>
                                {field.id !== 1 && <div className='remove' onClick={() => handleRemoveFieldAddStudent(field.id)}>Remove</div>}
                            </div>
                            <div className='mt-4'>
                                <input className='inputSchool_blocks'
                                    value={field.Fname}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Fname", e.target.value)}
                                    placeholder='Full Name' />
                            </div>

                            <div className='d-flex gap-4 mt-3 flex-wrap'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className='sepcialAbled' >
                                        Male
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className='sepcialAbled' >
                                        Female
                                    </label>
                                </div>
                            </div>

                            <div className='d-flex container_dateselect_holidays mt-3'>
                                <DatePicker
                                    selected={field.NewDate}
                                    onChange={(date) => handleInputAddStudent(field.id, 'NewDate', date)}
                                    dateFormat='dd/MM/yyyy'
                                    className='SelectDateStudent'
                                    placeholderText='DOB'
                                />
                                <img src='/OnBoard/BlackcalenderIcon.png' alt='calender Icon'></img>
                            </div>

                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.SchoolEmail}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "SchoolEmail", e.target.value)}
                                    placeholder='School email id' />
                            </div>

                            <div className='d-flex gap-4 mt-3 flex-wrap'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                    <label className="form-check-label" for="exampleRadios1">
                                        Parent
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                                    <label className="form-check-label" for="exampleRadios2">
                                        Guardian
                                    </label>
                                </div>
                            </div>

                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.FatherName}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "FatherName", e.target.value)}
                                    placeholder='Father Name' />
                            </div>

                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.EmailID}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "EmailID", e.target.value)}
                                    placeholder='Email ID' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Mnumber}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Mnumber", e.target.value)}
                                    placeholder='Mobile number' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Occupation}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Occupation", e.target.value)}
                                    placeholder='Occupation' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.MotherName}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "MotherName", e.target.value)}
                                    placeholder='Mother name' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.EmailId2}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "EmailId2", e.target.value)}
                                    placeholder='Email id' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Mnumber2}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Mnumber2", e.target.value)}
                                    placeholder='Mobile number' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Occupation2}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Occupation2", e.target.value)}
                                    placeholder='Occupation' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.MDM}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "MDM", e.target.value)}
                                    placeholder='MDM ID / IMEI ' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Bgroup}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Bgroup", e.target.value)}
                                    placeholder='Blood Group' />
                            </div>

                            <div className='mt-3 d-flex align-items-center'>
                                <input
                                    type='checkbox'
                                    className=''
                                    value={field.SpecialAbled}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "SpecialAbled", e.target.value)}
                                />
                                <label className='sepcialAbled ml-2 pt-2 '>Special Abled</label>
                            </div>

                            <div className='d-flex justify-content-between align-items-center AddCertificate_Container mt-3' >
                                <div className='CertificateName'>Special Abled Certificate</div>
                                <div>
                                    <input type="file" id="attachFile" style={{ display: "none" }} />
                                    <label htmlFor="attachFile">
                                        <span className='uploadLabel'>Upload </span>
                                    </label>
                                </div>
                            </div>

                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Mtongue}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Mtongue", e.target.value)}
                                    placeholder='Mother Tongue ' />
                            </div>

                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.SiblingInfo}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "SiblingInfo", e.target.value)}
                                    placeholder='Sibling Information ' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.EmergencyNO}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "EmergencyNO", e.target.value)}
                                    placeholder='Emergency Contact Number' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.BGroup2}
                                    onChange={(e) =>
                                        handleInputAddStudent(field.id, "Bgroup2", e.target.value)}
                                    placeholder='Blood Group' />
                            </div>

                            <div className='mt-3'>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={ClassOptions}
                                    styles={DropdownStyle}
                                    placeholder='Class'
                                    value={field.Class} // add value prop and set it based on the state
                                    onChange={(selectedOptions) => {
                                        handleInputAddStudent(field.id, 'Class', selectedOptions);
                                    }} // update the state based on the selected options
                                />
                            </div>
                            <div className='mt-3'>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={SectionOptions}
                                    styles={DropdownStyle}
                                    placeholder='Section'
                                    value={field.Section} // add value prop and set it based on the state
                                    onChange={(selectedOptions) => {
                                        handleInputAddStudent(field.id, 'Section', selectedOptions);
                                    }} // update the state based on the selected options
                                />
                            </div>

                        </div>
                    ))}


                    <div className='addClassContainer '>
                        <button onClick={handleAddFieldAddStudent} className='AddClassBtn'><BsPlusCircle /> Add Student</button>
                    </div>
                </div>
                <div className='totalClassNames_container mt-3'>
                    <div className='d-flex justify-content-between classTeacherNamesContainer '>
                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' />
                        <div className='pt-1'> Class teacher</div>
                    </div>
                    <div className='header_subjectText mt-2'>Subject Teacher</div>
                    <div className='d-flex gap-4 flex-wrap mt-3'>
                        <div className='d-flex gap-2 align-items-center'>
                            <div>
                                <img src='/OnBoard/TeacherTableImg.png' alt='profile img' />
                            </div>
                            <div>
                                <div className='name_STeacher'>Kianna Herwitz</div>
                                <div className='Tsubject_name'>English</div>
                            </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <div>
                                <img src='/OnBoard/TeacherTableImg.png' alt='profile img' />
                            </div>
                            <div>
                                <div className='name_STeacher'>Kianna Herwitz</div>
                                <div className='Tsubject_name'>English</div>
                            </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <div>
                                <img src='/OnBoard/TeacherTableImg.png' alt='profile img' />
                            </div>
                            <div>
                                <div className='name_STeacher'>Kianna Herwitz</div>
                                <div className='Tsubject_name'>English</div>
                            </div>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <div>
                                <img src='/OnBoard/TeacherTableImg.png' alt='profile img' />
                            </div>
                            <div>
                                <div className='name_STeacher'>Kianna Herwitz</div>
                                <div className='Tsubject_name'>English</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div className='d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container'>
                <div>
                    <span className='footer_text1'>For bulk upload please use this template</span>
                    <span className='footer_text2'>Download template</span>
                </div>

                <div className='d-flex flex-wrap gap-3'>
                    <div className='uploadFileBtn_MasterData'>
                        <input type="file" id="attachFile" style={{ display: "none" }} />
                        <label htmlFor="attachFile">
                            <span><BsPlusCircle /> &nbsp;Upload file</span>
                        </label>
                    </div>
                    <button id='NextBtn_school_masterData'
                        onClick={(e) => setStudentList({
                            editStudent: false,
                            historyBtn:'classInfo'
                        })}
                        disabled={isfieldsAddStudent}
                        type="submit" >Next</button>
                </div>
            </div>


        </>
    )
}
