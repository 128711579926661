import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import Permission from "../CreateRole/Permission";
import FirstPart from "./FirstPart";
import "./MasterData.css"; //also some css from usecaseSecond.css
import BasicInformation from "../BasicInformation";
import { Link } from "react-router-dom";
import LogOutButton from "../../UseCaseFirstTime/LogOutButton";

export default function AddMasterData() {
  const [master, setMaster] = useState({
    firstPart: true,
    permissions: false,
    basininfo: false,
  });

  const CloseallMasterComp = (newActivePart) => {
    setMaster((master) => ({
      ...master,
      permissions: newActivePart,
    }));
  };

  return (
    <>
      {!master.permissions && (
        <div className="Total_container_BasicInfo">
          <div className="d-flex justify-content-between align-items-center">
            <img
              className="SchoolIcon_basicInfo mb-2"
              src="/OnBoard/SchoolIcon.png"
              alt="banner img"
            />
            <LogOutButton/>
          </div>
          <div className="steps mt-4">Steps 2/5</div>
          <div className="d-flex  mt-4">
            <Link to="/BasicInformation">
              <span className="d-flex  justify-content-center">
                <span className="Completed_circle"></span>
              </span>
            </Link>
            <span className="CompletedDiv"></span>

            <div className="d-flex justify-content-center">
              <div className="Active_circle"></div>
            </div>
            {/* <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div> */}
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            {/* <span className="innerDiv"></span>
            <div className="d-flex justify-content-center ">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>
            <div className="d-flex justify-content-center ">
              <div className="NonActive_circle"></div>
            </div> */}
          </div>

          <div className="d-flex">
            <span
              className="d-flex justify-content-center"
              onClick={(e) =>
                setMaster({
                  ...master,
                  basininfo: true,
                  permissions: false,
                  firstPart: false,
                })
              }
            >
              <span className="Active_circle_name ">Basic</span>
            </span>

            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-2">Master Data</div>
            </div>
            {/* <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4">Permission</div>
            </div> */}
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-2 pl-1">Class</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4 pl-2">Teachers</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4">Students</div>
            </div>
            {/* <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-3">Engagement</div>
            </div>

            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-3">Query</div>
            </div> */}
          </div>

          {!master.firstPart && (
            <>
              <div className="AddData_container">
                <div>
                  <div className="addMasterData_heading mb-2">
                    Add Master Data
                  </div>
                  <p className="heading_belowText">
                    Add master data information with either a pre-filled
                    template file or add individually.
                  </p>

                  <div className="d-flex flex-wrap gap-4 justify-content-center ">
                    <button
                      id="NextBtn_school_masterData"
                      type="submit"
                      onClick={(e) =>
                        setMaster({
                          ...master,
                          firstPart: true,
                        })
                      }
                    >
                      Feed Manually
                    </button>
                    <div className="uploadFileBtn_MasterData">
                      <input
                        type="file"
                        id="attachFile"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="attachFile">
                        <span>
                          <BsPlusCircle /> &nbsp;Upload file
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
                <div>
                  <span className="footer_text1">
                    For bulk upload please use this template
                  </span>
                  <span className="footer_text2">Download template</span>
                </div>
              </div>
            </>
          )}

          {master.firstPart && <FirstPart closeAllPrev={CloseallMasterComp} />}
        </div>
      )}
      {master.permissions && <Permission />}
      {/* {master.basininfo && <BasicInformation />} */}
    </>
  );
}
