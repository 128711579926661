import React, { useState, useRef } from 'react'
import './Query.css'
import QueryResonse from './QueryResonse';
import { Dropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';


export default function Query() {
    const [query, setQuery] = useState({
        queryBtn: 'all',
        editQuery: false,
        queryResponse: false

    })
    const datePickerRef = useRef(null);

    const handleDropdownShow = (event) => {
        if (datePickerRef.current) {
            datePickerRef.current.setFocus();
        }
    };


    return (
        <div className='h-100 w-100'>
            {!query.queryResponse &&
                <>
                    <div className='QueryHeading'>Query</div>

                    <div className='d-flex justify-content-between  align-items-center'>
                        <div className=' d-flex gap-2 mt-4'>
                            <div>
                                <button onClick={(e) => { setQuery({ ...query, queryBtn: 'all' }) }} className={` ${query.queryBtn === "all" ? "active QueryBtnActive" : "QueryBtn"}`}>All</button>
                            </div>
                            <div>
                                <button onClick={(e) => { setQuery({ ...query, queryBtn: 'open' }) }} className={` ${query.queryBtn === "open" ? "active QueryBtnActive" : "QueryBtn"}`}>Active</button>
                            </div>
                            <div>
                                <button onClick={(e) => { setQuery({ ...query, queryBtn: 'close' }) }} className={` ${query.queryBtn === "close" ? "active QueryBtnActive" : "QueryBtn"}`}>Resolved</button>
                            </div>
                        </div>

                        <div className='serchQueryContainer p-2  d-flex align-items-center'>
                            <input className='searchInput' placeholder='Search Name' />
                            <img src='/Engagement/Searchicon.png' alt='search icon'></img>
                        </div>
                    </div>

                    {/* All Query  */}
                    {query.queryBtn === 'all' && <div className='mt-4'>
                        <table class="table table-borderless">
                            <thead>
                                <tr className='Heading_tableContainer'>
                                    <th className='tableHeaderText pb-3' > Name   </th>
                                    <th className='tableHeaderText pb-3'>Subjects</th>
                                    <th >
                                        <Dropdown className='pl-0' >
                                            <Dropdown.Toggle id='QueryTableDrodown' >
                                                Tag
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >Annual Event Function</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >School Fees Query</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable'  >Transport Department</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th>
                                        <Dropdown >
                                            <Dropdown.Toggle id='QueryTableDrodown'>
                                                Status
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >Active</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Resolved</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th>
                                        <Dropdown
                                            popperConfig={{
                                                modifiers: {
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: 'viewport',
                                                    },
                                                },
                                                onCreate: (data) => {
                                                    data.instance.reference = data.originalReference;
                                                },
                                            }}
                                            onShow={handleDropdownShow}
                                        >
                                            <Dropdown.Toggle id='QueryTableDrodown' >Date</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div >
                                                    <DatePicker
                                                        ref={datePickerRef}
                                                        inline
                                                        showPopperArrow={false}
                                                        onChange={(date) => console.log(date)}
                                                    />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>

                                    <th>
                                        <Dropdown>
                                            <Dropdown.Toggle id='QueryTableDrodown'>
                                                Priority
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >High</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Medium</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Low</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr onClick={(e) => { setQuery({ ...query, queryResponse: true }) }} >
                                    <td className='TeacherTableDetails pt-4'>
                                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                        <span>Dilip Kumar</span>
                                    </td>
                                    <td className='TeacherTableDetails  pt-4'>Plesae send the november month school bill</td>
                                    <td className='queryDetail  pt-4'>Fees Department</td>
                                    <td className='queryDetail  pt-4'>Resolved</td>
                                    <td className='queryDetail  pt-4'>22 Feb 2021</td>
                                    <td className='higheQuery pt-4'>
                                        High
                                    </td>
                                </tr>
                                <tr>
                                    <td className='TeacherTableDetails pt-4'>
                                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                        <span>Dilip Kumar</span>
                                    </td>
                                    <td className='TeacherTableDetails  pt-4'>Plesae send the november month school bill</td>
                                    <td className='queryDetail  pt-4'>Fees Department</td>
                                    <td className='queryDetail  pt-4'>Active</td>
                                    <td className='queryDetail  pt-4'>22 Feb 2021</td>
                                    <td className='mediumQuery pt-4'>
                                        Medium
                                    </td>
                                </tr >
                                <tr >
                                    <td className='TeacherTableDetails pt-4'>
                                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                        <span>Dilip Kumar</span>
                                    </td>
                                    <td className='TeacherTableDetails  pt-4'>Plesae send the november month school bill</td>
                                    <td className='queryDetail  pt-4'>Fees Department</td>
                                    <td className='queryDetail  pt-4'>Active</td>
                                    <td className='queryDetail  pt-4'>22 Feb 2021</td>
                                    <td className='LowQuery pt-4'>
                                        low
                                    </td>
                                </tr >
                                <tr >
                                    <td className='TeacherTableDetails pt-4'>
                                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                        <span>Dilip Kumar</span>
                                    </td>
                                    <td className='TeacherTableDetails  pt-4'>Plesae send the november month school bill</td>
                                    <td className='queryDetail  pt-4'>Fees Department</td>
                                    <td className='queryDetail  pt-4'>Resolved</td>
                                    <td className='queryDetail  pt-4'>22 Feb 2021</td>
                                    <td className='higheQuery pt-4'>
                                        High
                                    </td>
                                </tr >

                            </tbody>

                        </table>
                    </div>}

                    {/* Open Query  */}
                    {query.queryBtn === 'open' && <div className='mt-4'>
                        <table class="table table-borderless">
                            <thead>
                                <tr className='Heading_tableContainer'>
                                    <th className='tableHeaderText pb-3' > Name   </th>
                                    <th className='tableHeaderText pb-3'>Subjects</th>
                                    <th >
                                        <Dropdown className='pl-0' >
                                            <Dropdown.Toggle id='QueryTableDrodown' >
                                                Tag
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >Annual Event Function</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >School Fees Query</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable'  >Transport Department</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th>
                                        <Dropdown >
                                            <Dropdown.Toggle id='QueryTableDrodown'>
                                                Status
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >Active</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Resolved</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th>
                                        <Dropdown
                                            popperConfig={{
                                                modifiers: {
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: 'viewport',
                                                    },
                                                },
                                                onCreate: (data) => {
                                                    data.instance.reference = data.originalReference;
                                                },
                                            }}
                                            onShow={handleDropdownShow}
                                        >
                                            <Dropdown.Toggle id='QueryTableDrodown' >Date</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div >
                                                    <DatePicker
                                                        ref={datePickerRef}
                                                        inline
                                                        showPopperArrow={false}
                                                        onChange={(date) => console.log(date)}
                                                    />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>

                                    <th>
                                        <Dropdown>
                                            <Dropdown.Toggle id='QueryTableDrodown'>
                                                Priority
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >High</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Medium</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Low</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                </tr>
                            </thead>
                            <tbody >

                                <tr >
                                    <td className='TeacherTableDetails pt-4'>
                                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                        <span>Dilip Kumar</span>
                                    </td>
                                    <td className='TeacherTableDetails  pt-4'>Plesae send the november month school bill</td>
                                    <td className='queryDetail  pt-4'>Fees Department</td>
                                    <td className='queryDetail  pt-4'>Active</td>
                                    <td className='queryDetail  pt-4'>22 Feb 2021</td>
                                    <td className='higheQuery pt-4'>
                                        High
                                    </td>
                                </tr >

                            </tbody>

                        </table>
                    </div>}

                    {/* Close Query  */}
                    {query.queryBtn === 'close' && <div className='mt-4'>
                        <table class="table table-borderless">
                            <thead>
                                <tr className='Heading_tableContainer'>
                                    <th className='tableHeaderText pb-3' > Name   </th>
                                    <th className='tableHeaderText pb-3'>Subjects</th>
                                    <th >
                                        <Dropdown className='pl-0' >
                                            <Dropdown.Toggle id='QueryTableDrodown' >
                                                Tag
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >Annual Event Function</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >School Fees Query</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable'  >Transport Department</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th>
                                        <Dropdown >
                                            <Dropdown.Toggle id='QueryTableDrodown'>
                                                Status
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >Active</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Resolved</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th>
                                        <Dropdown
                                            popperConfig={{
                                                modifiers: {
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: 'viewport',
                                                    },
                                                },
                                                onCreate: (data) => {
                                                    data.instance.reference = data.originalReference;
                                                },
                                            }}
                                            onShow={handleDropdownShow}
                                        >
                                            <Dropdown.Toggle id='QueryTableDrodown' >Date</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div >
                                                    <DatePicker
                                                        ref={datePickerRef}
                                                        inline
                                                        showPopperArrow={false}
                                                        onChange={(date) => console.log(date)}
                                                    />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>

                                    <th>
                                        <Dropdown>
                                            <Dropdown.Toggle id='QueryTableDrodown'>
                                                Priority
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu id='MenuQueryDropdown'>
                                                <Dropdown.Item id='menuItemsQueryTable' >High</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Medium</Dropdown.Item>
                                                <Dropdown.Item id='menuItemsQueryTable' >Low</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                </tr>
                            </thead>
                            <tbody >

                                <tr >
                                    <td className='TeacherTableDetails pt-4'>
                                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                        <span>Dilip Kumar</span>
                                    </td>
                                    <td className='TeacherTableDetails  pt-4'>Plesae send the november month school bill</td>
                                    <td className='queryDetail  pt-4'>Fees Department</td>
                                    <td className='queryDetail  pt-4'>Resolved</td>
                                    <td className='queryDetail  pt-4'>22 Feb 2021</td>
                                    <td className='LowQuery pt-4'>
                                        low
                                    </td>
                                </tr >
                                <tr >
                                    <td className='TeacherTableDetails pt-4'>
                                        <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                        <span>Dilip Kumar</span>
                                    </td>
                                    <td className='TeacherTableDetails  pt-4'>Plesae send the november month school bill</td>
                                    <td className='queryDetail  pt-4'>Fees Department</td>
                                    <td className='queryDetail  pt-4'>Resolved</td>
                                    <td className='queryDetail  pt-4'>22 Feb 2021</td>
                                    <td className='higheQuery pt-4'>
                                        High
                                    </td>
                                </tr >

                            </tbody>

                        </table>
                    </div>}

                </>
            }
            {query.queryResponse && <QueryResonse setQuery={setQuery} />}
        </div >
       
    )
}
