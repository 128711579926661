import React, { useState } from 'react'
import { BsPlusCircle } from 'react-icons/bs';
import ChapterContentPopup from './ChapterContentPopup';
import { MultiSelect } from "react-multi-select-component";
import { Popup } from "semantic-ui-react";
import Select from 'react-select';


const options = [
  { label: "English", value: "English" },
  { label: "Math", value: "Math" },
  { label: "Hindi", value: "Hindi" },
  { label: "Social Science", value: "Social Science" },
  { label: " Science", value: " Science" },
];

export default function FourPart({ setActivePart }) {
  const [selected, setSelected] = useState([]);


  // handle active part Four 
  const handleClick = () => {
    setActivePart('fivePart');
  };

  const [partFour, setPartFour] = useState({
    showMore: false,
    addChapter: false,
    subject: 'subject1',
    remark: ''

  })
  const handleShowMore = () => {
    if (!partFour.showMore) {
      setPartFour({
        ...partFour,
        showMore: true
      })
    } else {
      setPartFour({
        ...partFour,
        showMore: false
      })
    }
  }

  // code for add additional topic 
  const [fieldsAdditionalTopic, setFieldsAdditionalTopic] = useState([{ id: 1, topic: '', topicUrl: '' }]);
  const handleAddFieldAdditionalTopic = () => {
    const newFields = [...fieldsAdditionalTopic];
    newFields.push({ id: fieldsAdditionalTopic.length + 1, topic: '', topicUrl: '' });
    setFieldsAdditionalTopic(newFields);
  };
  const handleRemoveFieldAdditionalTopic = (id) => {
    const newFields = fieldsAdditionalTopic.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1
      };
    });
    setFieldsAdditionalTopic(renumberedFields);
  };
  const handleInputAdditionalTopic = (id, name, session) => {
    const newFields = [...fieldsAdditionalTopic];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = session;
    setFieldsAdditionalTopic(newFields);

  };


  // code for add books  
  const [fieldsBooks, setFieldsBooks] = useState([{ id: 1, BookName: '', AuthorName: '', PublisherName: '' }]);
  const handleAddFieldBooks = () => {
    const newFields = [...fieldsBooks];
    newFields.push({ id: fieldsBooks.length + 1, BookName: '', AuthorName: '', PublisherName: '' });
    setFieldsBooks(newFields);
  };
  const handleRemoveFieldBooks = (id) => {
    const newFields = fieldsBooks.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1
      };
    });
    setFieldsBooks(renumberedFields);
  };
  const handleInputBooks = (id, name, session) => {
    const newFields = [...fieldsBooks];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = session;
    setFieldsBooks(newFields);

  };

  // code for add chapter list 
  const [list, setList] = useState([]);
  const [value, setValue] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const newItem = { id: list.length + 1, value: value };
      setList([...list, newItem]);
      setValue('');
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // check btn is disabled or unabled

  const isBookFieldsEmpty = fieldsBooks.some((field) => (
    field.BookName === '' || field.AuthorName === '' || field.PublisherName === ''
  ));
  const isFieldsAdditionalTopic = fieldsAdditionalTopic.some((field) => (
    field.topic === '' || field.topicUrl === ''
  ));
  const isRemarkEmpty = partFour.remark === ''

  const btnValid = isBookFieldsEmpty || isFieldsAdditionalTopic || isRemarkEmpty




  // board drodown 
  const ClassOptions = [
    { value: 'CBSE', label: 'CBSE' },
    { value: 'ICSE', label: 'ICSE' },
    { value: ' State & UTs Board', label: 'State & UTs Board' },
  ]
  const DropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#99ADBB',
      minHeight: '30px',
      padding: '1px',
      width: '257px',
      borderRadius: ' 4px',
      boxShadow: state.isFocused ? null : null,
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  return (
    <div>
      <div className='infra_info'>Curriculum Information</div>

      <div className='container_fivepart mt-4'>
        <div className='d-flex justify-content-between'>
          <div className='class_fourpart'>Class 1</div>
          <div className='d-flex gap-3 '>
            {/* <div className='part4dropdownBoard '>
              <select className="form-select boardSelect_dropdown" >
                <option selected>Board Select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div> */}
            <Select
              options={ClassOptions}
              styles={DropdownStyle}
              placeholder='Board Select'
            />

            <div id='container_dropdownsPart4'>
              <MultiSelect
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                id='boardSelect_dropdown p-4'
                className=''
              />
            </div>
       

          </div>

        </div>
        <div className='d-flex gap-2 flex-wrap justify-content-end mt-2'>
          <div onClick={(e) => { setPartFour({ ...partFour, subject: 'subject1' }) }} className={` ${partFour.subject === "subject1" ? "active Subjects_part3_active" : "Subjects_part3"}`}>English</div>
          <div onClick={(e) => { setPartFour({ ...partFour, subject: 'subject2' }) }} className={` ${partFour.subject === "subject2" ? "active Subjects_part3_active" : "Subjects_part3"}`}>Maths</div>
          <div onClick={(e) => { setPartFour({ ...partFour, subject: 'subject3' }) }} className={` ${partFour.subject === "subject3" ? "active Subjects_part3_active" : "Subjects_part3"}`}>Hindi</div>
          <div onClick={(e) => { setPartFour({ ...partFour, subject: 'subject4' }) }} className={` ${partFour.subject === "subject4" ? "active Subjects_part3_active" : "Subjects_part3"}`}>EVS</div>
          <div onClick={(e) => { setPartFour({ ...partFour, subject: 'subject5' }) }} className={` ${partFour.subject === "subject5" ? "active Subjects_part3_active" : "Subjects_part3"}`}>General Knowledge</div>
        </div>

        {partFour.showMore ? <div className='ShowMore d-flex  justify-content-center mt-4 ' onClick={handleShowMore}>Show Less</div>
          : <div className='ShowMore d-flex  justify-content-center mt-4' onClick={handleShowMore}>Show More</div>}

        {partFour.showMore && <div>
          <div>
            <div className='d-flex  justify-content-between'>
              <div className='part4_books'>Books</div>
              <div>
                <button
                  onClick={handleAddFieldBooks}
                  className='FourPartAddBtn'><BsPlusCircle />&nbsp; Add Book</button>
              </div>
            </div>

            <div className='d-flex gap-4 AddedBooksContainer'>
              {fieldsBooks.map((field) => (
                <div key={field.id} className='BookDetail '>
                  <div className='d-flex justify-content-between'>
                    <div className='bookNo'>Book {field.id}</div>
                    <div>
                      <Popup
                        on="click"
                        trigger={
                          <div className='container_threedot'>
                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dots'></img>
                          </div>
                        }
                        content={
                          <div className='container_threedot'>
                            <div className='PopupThreedots'>Edit</div>
                            {field.id === 1 ? <></> : <div className='PopupThreedots' onClick={() => handleRemoveFieldBooks(field.id)}>Delete</div>}
                          </div>
                        }
                        position="bottom center"
                      />
                    </div>
                  </div>
                  <input className='booksInputs mt-4'
                    value={field.BookName}
                    onChange={(e) => handleInputBooks(field.id, 'BookName', e.target.value)}
                    placeholder='Book Name' />

                  <input className='booksInputs'
                    value={field.AuthorName}
                    onChange={(e) => handleInputBooks(field.id, 'AuthorName', e.target.value)}
                    placeholder='Author Name' />

                  <input className='booksInputs'
                    value={field.PublisherName}
                    onChange={(e) => handleInputBooks(field.id, 'PublisherName', e.target.value)}
                    placeholder='Publisher Name' />

                  {/* <textarea className='chapterNameTextArea'/> */}
                  <div className='chapterNameTextArea'>
                    <div className='containerForWidth'>
                      {list.map((item) => (
                        <div key={item.id} className='ChapterListContainer  d-flex justify-content-between'>
                          <span> {item.id} - {item.value}</span><img style={{ cursor: 'pointer' }} src='/OnBoard/AddChapterIcon.png' onClick={(e) => setPartFour({ ...partFour, addChapter: true })} alt='icon chapter' />
                        </div>))}
                    </div>

                    <div >
                      <input className='addChpterInput' placeholder='Add Chapter Name' value={value} onChange={handleChange} onKeyPress={handleKeyPress} />
                    </div>
                  </div>

                  <div className='text_hint mt-1'>Press enter to add new Chapter Name</div>
                </div>
              ))}
            </div>

          </div>

          <div className='part4_books mt-4'>Additional Topics</div>
          {fieldsAdditionalTopic.map((field) => (
            <div key={field.id} className='d-flex justify-content-between align-items-center mt-2'>
              <input className='Topic_books' value={field.topic}
                onChange={(e) => handleInputAdditionalTopic(field.id, 'topic', e.target.value)}
                placeholder='Topic' />
              <input className='Topic_url'
                value={field.topicUrl}
                onChange={(e) => handleInputAdditionalTopic(field.id, 'topicUrl', e.target.value)}
                placeholder='Topic URL' />
              {/* <img src='/OnBoard/plusIcon_part1.png' alt='plus icon ' className='plusImg_part4' /> */}

              {fieldsAdditionalTopic.length === field.id ?
                <div className='plusImg_part4' onClick={handleAddFieldAdditionalTopic}>
                  <img src='/OnBoard/plusIcon_part1.png' alt='plus Icon'></img>
                </div> :
                <div className='plusImg_part4' onClick={() => handleRemoveFieldAdditionalTopic(field.id)} >
                  <img src='/OnBoard/Dustbin.png' alt='dustbin Icon'></img>
                </div>
              }
            </div>
          ))}
          <div className='part4_books mt-4'> Add Remark</div>
          <input className='Remark_input mt-2'
            onChange={(e) => setPartFour({
              ...partFour,
              remark: e.target.value
            })}
            placeholder='Remark' />
        </div>}
      </div>






      <div className='d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container'>
        <div>
          <span className='footer_text1'>
            {/* For bulk upload please use this template */}
          </span>
          {/* <span className='footer_text2'>Download template</span> */}
        </div>

        <div className='d-flex flex-wrap gap-3'>
          {/* <div className='uploadFileBtn_MasterData'>
            <input type="file" id="attachFile" style={{ display: "none" }} />
            <label htmlFor="attachFile">
              <span><BsPlusCircle /> &nbsp;Upload file</span>
            </label>
          </div> */}
          <button id='NextBtn_school_masterData'
            onClick={handleClick}
            disabled={btnValid}
            type="submit" >Next</button>
        </div>
      </div>
      {partFour.addChapter && <ChapterContentPopup setPartFour={setPartFour} />}
    </div>
  )
}





