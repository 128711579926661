import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import "./Upload.css";

function Uploadfile(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [sucess, setSucess] = useState();
  // const [file, setFile] = useState("");
  const [sucessMsg, setSucessMsg] = useState(false);

  const handleFileUpload = () => {
    if (selectedFile.size > 4000000) {
      alert("The file size should be less than 4MB.");
    }

    if (selectedFile.size < 4000000) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      axios
        .post("/api/v1/timetable/upload", formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        })
        .then(() => {
          setSucess(true);
        })
        .catch((error) => {
          console.log(error);
          setSucess(false);
        });
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload();
    }
  });

  console.log(uploadProgress, "prgess");
  console.log(sucess, "sucess");
  console.log(selectedFile);

  return (
    <>
      <div className="Total_container_uploadFile">
        <div className="Total_Subcontainer_uploadFile p-4">
          <div className=" d-flex justify-content-between align-items-center">
            <div className="uploadFile_header">Upload File </div>
            {!sucessMsg && (
              <>
                {" "}
                {sucess && (
                  <button
                    onClick={(e) => {
                      setSucessMsg(true);
                    }}
                    className="SubmitBtn_upload"
                  >
                    Submit
                  </button>
                )}
              </>
            )}
          </div>

          {!sucessMsg && (
            <div>
              {sucess && (
                <div className="d-flex justify-content-end align-items-center mt-3 gap-2">
                  <img src="/OnBoard/SucessIcon.png" alt="sucess icon"></img>
                  <div className="sucessText">All fields verified.</div>
                </div>
              )}
            </div>
          )}

          {sucess === false && (
            <div className="d-flex justify-content-end align-items-center mt-3 gap-2">
              <img src="/OnBoard/Errorlogo.png" alt="sucess icon"></img>
              <div className="sucessText">
                Error in details, please check and upload again.
              </div>
            </div>
          )}

          {!selectedFile ? (
            <div className="selectFile_container ">
              <div>
                <input
                  type="file"
                  name="file"
                  accept=".xlsx,.xls"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                  id="attachFile"
                  hidden
                />
                <label
                  className=" d-flex justify-content-center"
                  htmlFor="attachFile"
                >
                  <div className="Select_btn  d-flex justify-content-center align-items-center">
                    Select file
                  </div>
                </label>
                <div className="dragDrop d-flex justify-content-center">
                  drag & drop here
                </div>
              </div>
            </div>
          ) : (
            <>
              {sucessMsg && (
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src="/OnBoard/sucessIconBig.png"
                      alt="sucess icon"
                    ></img>
                  </div>
                  <div className="d-flex justify-content-center SucessFullymsg">
                    {" "}
                    File submitted successfully.
                  </div>
                </div>
              )}

              <div className="persentageContainer mt-4 d-flex  align-items-center justify-content-between gap-4 p-2">
                <div className="d-flex  align-items-center gap-4">
                  <div className="name">
                    <img
                      src="/OnBoard/excelFileIcon.png"
                      alt="excel icon"
                    ></img>
                    &nbsp;{selectedFile.name}
                  </div>
                  {uploadProgress > 0 && (
                    <span className="percentage">{uploadProgress}%</span>
                  )}
                  {uploadProgress === 100 ? (
                    <div className="sucessText">Completed</div>
                  ) : (
                    <div className="sucessText">Uploding...</div>
                  )}
                </div>
                <img
                  onClick={(e) => {
                    setSelectedFile(null);
                  }}
                  src="/OnBoard/CrossIcon.png"
                  alt="cross icon"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Uploadfile;
