import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./Sidebar_header/sidebar/Sidebar";
import Header from "./Sidebar_header/header/Header";
import StudentSection from "./LandingPage/StudentSection/StudentSection";
import CreateAccount from "./OnBoarding/UseCaseFirstTime/CreateAccount";
import BasicInformation from "./OnBoarding/UseCaseSecondTime/BasicInformation";
import AddMasterData from "./OnBoarding/UseCaseSecondTime/MasterData/AddMasterData";
import Permission from "./OnBoarding/UseCaseSecondTime/CreateRole/Permission";
import AddClass from "./OnBoarding/UseCaseSecondTime/Class/AddClass";
import ClassList from "./OnBoarding/UseCaseSecondTime/Class/ClassList";
import AddTeacher from "./OnBoarding/UseCaseSecondTime/Teacher/AddTeacher";
import AddStudent from "./OnBoarding/UseCaseSecondTime/Student/AddStudent";
import FirstPart from "./OnBoarding/UseCaseSecondTime/MasterData/FirstPart";

import axios from "./axios";

import OverRightMD from "./MasterData/UploadfileMD/OverRightMD";

import Uploadfile from "./OnBoarding/UseCaseSecondTime/UploadScreens/Uploadfile";

import TeacherBoard from "./LandingPage/TeacherBoard/TeacherBoard";
// import FirstPart from "./MasterData/FirstPart";
import ClassInfoSA from "./LandingPage/SchoolAdmin/ClassInfo/ClassInfoSA";
import TeacherInfoSA from "./LandingPage/SchoolAdmin/teacherInfo/TeacherInfoSA";
import StudentInfoSA from "./LandingPage/SchoolAdmin/StudentInfo/StudentInfoSA";
import PermissionAcess from "./LandingPage/SchoolAdmin/Permission/PermissionAcess";
import Query from "./Engagement/Query/Query";
import { useEffect, useState } from "react";
import { SchoolContextProvider } from "./schoolContext";
import PasswordSuccess from "./OnBoarding/UseCaseFirstTime/PasswordSuccess";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigDataThunk } from "./Store/Store";
import OnBoardingSuccess from "./OnBoarding/UseCaseFirstTime/OnBoardingSuccess";
var lookup = require("country-data").lookup;
// import AddSurvey from "./Engagement/Survey/AddSurvey";
// import SurveyList from "./Engagement/Survey/SurveyList";
// import AddGroups from "./Engagement/Groups/AddGroups";

function App() {
  const [sidebarPath, setSidebarPath] = useState({
    component: "CreateAccount",
  });

  const dispatch = useDispatch();

  const schoolExtractedId = localStorage.getItem("schoolAuth");

  const dropdownToggler = useSelector(
    (state) => state.configuration.countryCodeReload
  );

  useEffect(() => {
    const fetchConfig = async () => {
      if (schoolExtractedId) {
        const getSchoolCountry = async () => {
          const response = await axios.get(
            `/api/v1/school/get/${schoolExtractedId}`
          );
          const country = response.data.data.address.country;
          var countryCode = lookup
            .countries({ name: country })[0]
            .countryCallingCodes[0].slice(1);
          return countryCode;
        };
        dispatch(fetchConfigDataThunk(await getSchoolCountry()));
      }
    };

    fetchConfig();
  }, [dispatch, schoolExtractedId, dropdownToggler]);

  return (
    <SchoolContextProvider>
      <div className="SchoolUI">
        {/* <Sidebar
          setSidebarPath={setSidebarPath}
          sidebarPath={sidebarPath.component}
        /> */}
        <div className="SchoolUI_Wrap">
          {/* <Header sidebarPath={sidebarPath.component} /> */}
          {/* /////// Main Container //////////// */}
          <div className="m-3 Main_Container_school">
            <Router>
             <Routes>
                <Route exact path="/" element={<CreateAccount />} />
                <Route
                  exact
                  path="/BasicInformation"
                  element={<BasicInformation />}
                />

                <Route
                  exact
                  path="/OnBoardingSuccess"
                  element={<OnBoardingSuccess />}
                />
                <Route
                  exact
                  path="/PasswordSuccess"
                  element={<PasswordSuccess />}
                />
                <Route
                  exact
                  path="/AddMasterData"
                  element={<AddMasterData />}
                />

                <Route exact path="/MasterData" element={<AddMasterData />} />
                <Route
                  exact
                  path="/FirstPartMasterData"
                  element={<FirstPart />}
                />
                <Route exact path="/Permision" element={<Permission />} />
                <Route exact path="/AddClass" element={<AddClass />} />
                <Route exact path="/ClassList" element={<ClassList />} />
                <Route exact path="/AddTeacher" element={<AddTeacher />} />
                <Route exact path="/AddStudent" element={<AddStudent />} />
                <Route exact path="/Uploadfile" element={<Uploadfile />} />

                {/* LandingPage */}

                <Route exact path="/OverRightMD" element={<OverRightMD />} />

                {/* required  LandingPage */}
                <Route
                  exact
                  path="/MasterDataFullfield"
                  element={<FirstPart />}
                />
                <Route
                  exact
                  path="/StudentSection"
                  element={<StudentSection />}
                />
                <Route exact path="/TeacherBoard" element={<TeacherBoard />} />
                <Route exact path="/ClassInfoSA" element={<ClassInfoSA />} />
                <Route
                  exact
                  path="/TeacherInfoSA"
                  element={<TeacherInfoSA />}
                />
                <Route
                  exact
                  path="/StudentInfoSA"
                  element={<StudentInfoSA />}
                />
                <Route
                  exact
                  path="/PermissionAcess"
                  element={<PermissionAcess />}
                />
                <Route exact path="/Query" element={<Query />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>

            {/* {sidebarPath.component==='CreateAccount'&&<CreateAccount />} 
          {sidebarPath.component==='StudentBoard'&&<StudentSection />} 
          {sidebarPath.component==='TeacherBoard'&&<TeacherBoard />} 
          {sidebarPath.component==='ClassInfo'&&<ClassInfoSA />} 
          {sidebarPath.component==='TeacherInfo'&& <TeacherInfoSA />} 
          {sidebarPath.component==='StudentInfo'&& <StudentInfoSA />} 
          {sidebarPath.component==='PermissionAcess'&& <PermissionAcess />} 
          {sidebarPath.component==='Query'&& <Query />} 
          {sidebarPath.component==='Survey'&& <SurveyList />} 
          {sidebarPath.component==='Group'&& <AddGroups/>} 
          {sidebarPath.component==='MasterFulfield'&& <FirstPart />}  */}
          </div>
        </div>
      </div>
    </SchoolContextProvider>
  );
}

export default App;
