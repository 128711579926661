import React from "react";

function ErrorRowPopup({ closePopup, errorRow }) {
  return (
    <div className="published_Deative_transprant_body">
      <div className="published_Deative_preview_container">
        <div className="col-12 deactivate_msg delInfo">Error!</div>
        <div className="col-12 deactivate_msg">
          <img
            alt=""
            src="/images/cross-circle3.svg"
            className="crossIconImgQue"
            onClick={(e) => closePopup()}
          />
          Please verify your excel these rows have issues:
        </div>
        {errorRow.map((obj) => (
          <div className="d-flex">
            <p className="mr-4">{obj}</p>
          </div>
        ))}
        <div className="row col-12 publish_deactive_btnBox">
          <button
            onClick={() => closePopup()}
            className="btn col-sm-5 my-1 deactive_sure_btn buttonHover"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorRowPopup;
