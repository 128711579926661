import React, { useEffect, useState } from "react";
// import axios from "../../../../../../../axios"
import "./DeletePopup.css";
import axios from "../../../axios";
import { Select } from "semantic-ui-react";
import { useSchoolContext } from "../../../schoolContext";

const UploadPopupScreen = ({
  handleUploadData,
  setUploadClick,
  formRejected,
  setFormRejected,
  isUploadSuccess,
  handleVerifyFile,
  academicSessionSelected,
  timetableSessionSelected,
  setAcademicSessionSelected,
  setTimetableSessionSelected,
  errorMessage,
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [delayInVerified, setDelayInverified] = useState(false);
  const [academicYearId, setAcademicYearId] = useState("");
  const [academicSession, setAcademicSession] = useState([]);
  const [timetableSession, setTimetableSession] = useState([]);

  const changePercentageValues = () => {
    if (uploadedFile) {
      const randomTimeout1 = Math.floor(Math.random() * 5) + 2;
      const randomTimeout2 = Math.floor(Math.random() * 5) + 2;

      setTimeout(() => {
        setPercentage(0);

        setTimeout(() => {
          const randomNumber = Math.floor(Math.random() * 100);
          console.log(randomNumber);
          setPercentage(randomNumber);

          setTimeout(() => {
            setPercentage(100);
          }, randomTimeout2 * 1000);
        }, randomTimeout1 * 1000);
      }, 500);
    }
  };

  const closePopup = () => {
    setUploadClick(false);
    setFormRejected(false);
  };

  useEffect(() => {
    if (percentage === 100) {
      handleVerifyFile(uploadedFile);
      setTimeout(() => setDelayInverified(true), 1000);
    }
  }, [uploadedFile, percentage]);

  useEffect(() => {
    setTimeout(() => {
      isUploadSuccess && isDataSubmitted && !formRejected && closePopup();
    }, 3000);
  }, [isDataSubmitted, formRejected, isUploadSuccess]);

  useEffect(() => {
    changePercentageValues();
  }, [uploadedFile]);

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }
  async function fetchAcademicYear() {
    try {
      const res = await axios.get(
        `/api/v1/school/academicYear/get/school/${schoolId}`
      );
      if (res?.data?.data?.id) {
        setAcademicYearId(res.data.data.id);
        const response = await axios.get(
          `/api/v1/school/academicYearSession/get/academicYear/${res?.data?.data?.id}`
        );
        if (response.data.data.length > 0) {
          const mappedAcademicSession = response.data.data.map((classItem) => {
            return {
              value: classItem.id,
              label: classItem.name,
            };
          });
          setAcademicSession(mappedAcademicSession);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchTimetableSession = async () => {
    let response = await axios.get(
      `/api/v1/school/schoolSession/getByAcademicSession?academicYearSession=${academicSessionSelected}`
    );
    const createdSessions = response.data.data.map((createdSession, index) => ({
      value: createdSession.timeTableSession,
      label: createdSession?.remark,
    }));
    setTimetableSession(createdSessions);
  };
  useEffect(() => {
    fetchTimetableSession();
  }, [academicSessionSelected]);
  useEffect(() => {
    fetchAcademicYear();
  }, []);
  console.log(academicSessionSelected);
  return (
    <div className="published_Deative_transprant_body">
      {!uploadedFile ? (
        <div className="preview_container">
          <div className="col-12 deactivate_msg delInfo">Upload File</div>
          <div className="dropdownUploadClass">
            <div>
              <select
                className="DropdownStyle-control"
                onChange={(e) => {
                  setAcademicSessionSelected(e.target.value);
                }}
              >
                <option>Academic Session</option>
                {academicSession.map((obj) => (
                  <option value={obj?.value}>{obj?.label}</option>
                ))}
              </select>
            </div>
            <div className="ml-4">
              <select
                className="DropdownStyle-control"
                onChange={(e) => {
                  setTimetableSessionSelected(e.target.value);
                  // setAcademicSessionSelected(e.target.value);
                }}
              >
                <option>Timetable Session</option>
                {timetableSession.map((obj) => (
                  <option value={obj?.value}>{obj?.label}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12 deactivate_msg">
            <img
              alt=""
              src="/images/cross-circle3.svg"
              className="crossIconImgQue"
              onClick={(e) => {
                setUploadClick(false);
                setFormRejected(false);
                setDelayInverified(false);
              }}
            />
            <div
              className="col-12 mt-3 p-0 boundary"
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                setUploadedFile(file);
              }}
            >
              <div className="selectButton" htmlFor="attachFile">
                <input
                  type="file"
                  id="attachFile"
                  // accept=".xlsx, .xls"
                  onChange={(e) => {
                    setUploadedFile(e.target.files[0]);
                  }}
                  style={{ display: "none" }}
                  disabled={
                    !(academicSessionSelected && timetableSessionSelected)
                  }
                />
                <label className="attach_categry" htmlFor="attachFile">
                  <div
                    className={
                      !(academicSessionSelected && timetableSessionSelected)
                        ? "fileButtonDisable"
                        : "fileButton"
                    }
                  >
                    Select File
                  </div>
                  <div className="dragAndDropText">drag & drop here</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="preview_container_AfterPuload"
          style={{
            height:
              isUploadSuccess && isDataSubmitted && !formRejected && "270px",
          }}
        >
          <div className="headerOfFile">
            <div className="deactivate_msg">Upload File</div>
            {!isUploadSuccess && (!isDataSubmitted || formRejected) ? (
              <div className="buttonPlusText">
                <div className="alignBtn">
                  <button
                    className="submitButton"
                    disabled={
                      percentage !== 100 || formRejected || !delayInVerified
                    }
                    onClick={() => {
                      handleUploadData(uploadedFile);
                      setIsDataSubmitted(true);
                    }}
                  >
                    Submit{" "}
                  </button>
                </div>

                {percentage === 100 && (
                  <>
                    {!formRejected ? (
                      <div style={{ textAlign: "right", margin: "5px" }}>
                        {delayInVerified && (
                          <>
                            <img src="/images/charm_tick.svg" alt="" />
                            <p style={{ display: "inline", marginLeft: "5px" }}>
                              All fields verified.
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: "right",
                          margin: "5px",
                          display: "block",
                        }}
                      >
                        <img src="/images/charm_tick_error.svg" alt="" />
                        {errorMessage ? (
                          <p
                            style={{
                              display: "inline",
                              marginLeft: "5px",
                              width: "100%",
                            }}
                          >
                            {errorMessage}{" "}
                          </p>
                        ) : (
                          <p style={{ display: "inline", marginLeft: "5px" }}>
                            Error in details, please check and upload again.{" "}
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : isUploadSuccess ? (
              <div className="formSubmissionSuccess">
                <img src="/images/charm_tick_big.svg" alt="" />
                <p>File submitted successfully.</p>
              </div>
            ) : null}
          </div>
          <div className="ShowFileDetails" style={{ position: "relative" }}>
            {percentage !== 100 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  backgroundColor: "#20744A",
                  opacity: "20%",
                  zIndex: "1",
                  width: `${percentage}%`,
                  borderRadius: "8px",
                }}
              ></div>
            )}
            <img alt="Excel Img" src="/images/Excel.svg" />
            <p className="fileName" title={uploadedFile.name}>
              {uploadedFile.name}
            </p>
            <p className="fileName" style={{ color: "#45BF55", width: "auto" }}>
              {percentage}%
            </p>
            <p className="fileName">
              {percentage === 100 ? "Completed." : "Uploading..."}
            </p>
            <img
              alt="Excel Img"
              src="/images/crossIconUpload.svg"
              onClick={() => {
                setUploadedFile(null);
                setPercentage(0);
                setFormRejected(false);
                setIsDataSubmitted(false);
                setDelayInverified(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadPopupScreen;
