import React, { useEffect, useState } from 'react'
import axios from "../../axios";

export default function UploadFile({ setOverRightPopUp, handleCloseUploadMDPopUP }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [sucess, setSucess] = useState()


    const handleFileUpload = () => {


        if (selectedFile.size > 4000000) {
            alert("The file size should be less than 4MB.");
        }

        if (selectedFile.size < 4000000) {

            const formData = new FormData();
            formData.append("file", selectedFile);

            axios.post("/api/v1/timetable/upload", formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setUploadProgress(progress);
                },
            }).then(() => {

                setSucess(true)
            }).catch((error) => {

                console.log(error)
                setSucess(true)
            });

        }
    };

    useEffect(() => {
        if (selectedFile) {
            handleFileUpload()
        }
    })

    console.log(uploadProgress, 'prgess')
    console.log(sucess, 'sucess')
    console.log(selectedFile)

    return (

        <div>
            {!selectedFile ?
                <div className='conatiner_chapterContent'>
                    <div className='subContainer_overRight'>
                        <div className='uploadHeading'>Upload </div>
                        <div className='selectFileContainer d-flex justify-content-center align-items-center'>
                            <div>
                                <div className='selectFileBtn_MasterData d-flex justify-content-center align-items-center'>
                                    <input
                                        type="file"
                                        name="file"
                                        accept=".xlsx,.xls"
                                        onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                                        id="attachFile"
                                        hidden
                                    />
                                    <label htmlFor="attachFile">
                                        <span>Select file</span>
                                    </label>
                                </div>
                                <div className='dragDrop d-flex justify-content-center'>drag & drop here</div>
                            </div>
                        </div>
                        <div className='downLoadTemplate d-flex justify-content-center pt-3'>Download template</div>
                        <div>
                            <img
                                onClick={(e) => setOverRightPopUp({
                                    uploadFile: false

                                })}
                                className='Crossicon_Overright' src='/OnBoard/CrossIcon.png' alt='cross icon'>
                            </img>
                        </div>
                    </div>
                </div>
                :
                <div className='conatiner_chapterContent'>
                    <div className='subContainer_upload'>
                        {!uploadProgress === 100 && <div>
                            <div className='d-flex justify-content-center'>
                                <img src='MasterImg/uploadProgress.png' alt='upload progress icon'></img>
                            </div>
                            <div className='persentage d-flex justify-content-center'>{uploadProgress}%</div>
                            <div className='uploadProgress d-flex justify-content-center'>Uploading in progress...</div>
                        </div>}

                        {sucess ?
                            <div className='p-3'>
                                <div className='d-flex justify-content-center'>
                                    <img src='MasterImg/uploadsucess.png' alt='upload sucess icon'></img>
                                </div>
                                <div className='UploadingSuceesErrorMsg d-flex justify-content-center'>Uploading successful</div>
                            </div>
                            :
                            <div className='p-3'>
                                <div className='d-flex justify-content-center'>
                                    <img src='MasterImg/uploadError.png' alt='upload error icon'></img>
                                </div>
                                <div className='UploadingSuceesErrorMsg d-flex justify-content-center'>Uploading Error!</div>
                                <div className='d-flex justify-content-center mt-2 tryAgain'>Try again later.</div>
                            </div>
                        }
                        <div>
                            <img
                                onClick={(e) => {
                                    setOverRightPopUp({
                                        uploadFile: false,
                                    });
                                    handleCloseUploadMDPopUP();
                                }}
                                className='Crossicon_Overright' src='/OnBoard/CrossIcon.png' alt='cross icon'>
                            </img>
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}
