import { createContext, useContext, useState } from "react";

const schoolContext = createContext();

const SchoolContextProvider = ({ children }) => {
  const [schoolAuthId, setSchoolAuthId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);

  return (
    <schoolContext.Provider
      value={{ schoolAuthId, setSchoolAuthId, schoolId, setSchoolId }}
    >
      {children}
    </schoolContext.Provider>
  );
};

const useSchoolContext = () => {
  const context = useContext(schoolContext);
  if (!context) {
    throw new Error("useYourContext must be used within a YourContextProvider");
  }
  return context;
};

export { useSchoolContext, SchoolContextProvider, schoolContext };
