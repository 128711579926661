import React, { useState } from "react";
import "./DeletePopup.css";
import axios from "../../../axios";
import { useSchoolContext } from "../../../schoolContext";

const InActivePopup = ({ setisActive, ElementId }) => {
  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  async function updateFunction() {
    try {
      const body = {
        schoolId,
        standard: ElementId.standard.value,
        section: ElementId.section.value,
        remarks: ElementId.remarks,
        room: ElementId.room,
        block: ElementId.block.value,
        floor: ElementId.floor.value,
        subjects: ElementId.subjects,
        active: ElementId?.active === false ? true : false,
        id: ElementId.id,
      };
      const res = await axios.post(
        `/api/v1/school/standardSection/update/${ElementId.id}`,
        body
      );
      res && setisActive(false);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="published_Deative_transprant_body">
      <div className="published_Deative_preview_container">
        <div className="col-12 deactivate_msg delInfo">
        {ElementId?.active === false ? "Re" : "De"}activate Information{" "}
        </div>
        <div className="col-12 deactivate_msg">
        <img
            alt=""
            src="/images/cross-circle3.svg"
            className="crossIconImgQue"
            onClick={(e) => setisActive(false)}
          />
          Are you sure you want to {ElementId?.active === false ? "re" : "de"}
          activate this information?{" "}
        </div>
        <div className="row col-12 publish_deactive_btnBox">
          <button
            onClick={() => updateFunction()}
            className="btn col-sm-5 my-1 deactive_sure_btn buttonHover"
          >
            Yes
          </button>
          <button
            className="btn col-sm-5 my-1 deactive_sure_btn buttonHover"
            onClick={(e) => setisActive(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default InActivePopup;
