import React, { useState } from 'react'
import { BsPlusCircle } from 'react-icons/bs';
//also some css from usecaseSecond.css , masterdata.css
import '../../../OnBoarding/UseCaseSecondTime/Class/ClassComp.css'
import Select from 'react-select';
import '../SchoolAdmin.css'

export default function EditClassSA({ setClassList }) {

    const ClassOptions = [
        { value: 'Class 1', label: 'Class 1' },
        { value: 'Class 2', label: 'Class 2' },
        { value: ' Class 3', label: 'Class 3' },
    ]
    const SectionOptions = [
        { value: 'Section A', label: 'Section A' },
        { value: 'Section B', label: 'Section B' },
        { value: 'Section C', label: 'Section C' },
    ]
    const BlockNameOptions = [
        { value: 'A-Block', label: 'A-Block' },
        { value: 'B-Block', label: 'B-Block' },
        { value: 'C-Block', label: 'C-Block' },
    ]
    const ClassFloorOptions = [
        { value: '1st Floor', label: '1st Floor' },
        { value: '2nd Floor', label: '2nd Floor' },
        { value: '3rd Floor', label: '3rd Floor' },
    ]
    const SubjectOptions = [
        { value: 'English', label: 'English' },
        { value: 'Maths', label: 'Maths' },
        { value: 'Science', label: 'Science' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Social Science', label: 'Social Science' },
    ]

    const DropdownStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#99ADBB',
            minHeight: '30px',
            padding: '11px',
            borderRadius: ' 8px',
            boxShadow: state.isFocused ? null : null,
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };


    // code for Academic Session add fields more 
    const [fieldsAddClass, setFieldsAddClass] = useState([{ id: 1, class: '', section: '', blockN: '', classF: '', subjects: '', remarks: '', roomN: '' }]);
    const handleAddFieldAddClass = () => {
        const newFields = [...fieldsAddClass];
        newFields.push({ id: fieldsAddClass.length + 1, value: '', class: '', section: '', blockN: '', classF: '', subjects: '', remarks: '', roomN: '' });
        setFieldsAddClass(newFields);
    };

    const handleRemoveFieldAddClass = (id) => {
        const newFields = fieldsAddClass.filter((field) => field.id !== id);
        // Re-count the field IDs
        const renumberedFields = newFields.map((field, index) => {
            return {
                ...field,
                id: index + 1
            };
        });
        setFieldsAddClass(renumberedFields);
    };
    const handleInputAddClass = (id, name, value) => {
        const newFields = [...fieldsAddClass];
        const index = newFields.findIndex((field) => field.id === id);
        newFields[index][name] = value;
        setFieldsAddClass(newFields);
    };

    const isFieldsaddclass = fieldsAddClass.some((field) => (

        field.class === '' || field.blockN === '' || field.classF === '' || field.section === '' || field.subjects === '' || field.remarks === '' || field.roomN === ''
    ));

    return (
        <>


            <div>
                <div className='d-flex gap-4  mt-4'>
                    {fieldsAddClass.map((field) => (
                        <div key={field.id} className='classInput_container  mr-2'>
                            <div className='d-flex justify-content-between '>
                                <div className='classinfo_subHeader '>Class info-0{field.id}</div>
                                {field.id !== 1 && <div className='remove' onClick={() => handleRemoveFieldAddClass(field.id)}>Remove</div>}
                            </div>
                            <div className='mt-4'>
                                <Select
                                    styles={DropdownStyle}
                                    options={ClassOptions}
                                    placeholder='Class'
                                    onChange={(selectedOption) => {
                                        handleInputAddClass(field.id, 'class', selectedOption);
                                    }} // update the state based on the selected option

                                />
                            </div>
                            <div className='mt-3'>
                                <Select
                                    options={SectionOptions}
                                    styles={DropdownStyle}
                                    placeholder='Section'
                                    value={field.section} // add value prop and set it based on the state
                                    onChange={(selectedOption) => {
                                        handleInputAddClass(field.id, 'section', selectedOption);
                                    }} // update the state based on the selected option
                                />
                            </div>
                            <div className='mt-3'>
                                <Select
                                    options={BlockNameOptions}
                                    styles={DropdownStyle}
                                    placeholder='Black Name'
                                    value={field.blockN} // add value prop and set it based on the state
                                    onChange={(selectedOption) => {
                                        handleInputAddClass(field.id, 'blockN', selectedOption);
                                    }} // update the state based on the selected option
                                />
                            </div>
                            <div className='mt-3'>
                                <Select
                                    options={ClassFloorOptions}
                                    styles={DropdownStyle}
                                    placeholder='Class Floor'
                                    value={field.classF} // add value prop and set it based on the state
                                    onChange={(selectedOption) => {
                                        handleInputAddClass(field.id, 'classF', selectedOption);
                                    }} // update the state based on the selected option
                                />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.roomN}
                                    onChange={(e) =>
                                        handleInputAddClass(field.id, "roomN", e.target.value)}
                                    placeholder='Room Number' />
                            </div>
                            <div className='mt-3'>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={SubjectOptions}
                                    styles={DropdownStyle}
                                    placeholder='Subject'
                                    value={field.subjects} // add value prop and set it based on the state
                                    onChange={(selectedOptions) => {
                                        handleInputAddClass(field.id, 'subjects', selectedOptions);
                                    }} // update the state based on the selected options
                                />
                            </div>
                            <div className='mt-3'>
                                <textarea className='AddRemark'
                                    value={field.remarks}
                                    onChange={(e) =>
                                        handleInputAddClass(field.id, "remarks", e.target.value)}
                                    placeholder='Add remarks' />
                            </div>
                        </div>
                    ))}

                    <div className='addClassContainer '>
                        <button onClick={handleAddFieldAddClass} className='AddClassBtn'><BsPlusCircle /> Add Class</button>
                    </div>
                </div>
            </div>






            <div className='d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container'>
                <button className='AddClass_school_ClassInfo_SA'><BsPlusCircle />&nbsp;Add Class</button>

                <div className='d-flex gap-3'>
                    <button
                        className='discardBtn'
                        onClick={(e) => {
                            setClassList({
                                edit: false
                            })
                        }}
                    >Discard
                    </button>

                    <button className='NextBtn_school_ClassInfo_SA'
                        onClick={(e) => {
                            setClassList({
                                edit: false,
                                historyBtn: 'classInfo'


                            })
                        }}
                        disabled={isFieldsaddclass}
                        type="submit" >Save
                    </button>
                </div>

            </div>



        </>
    )
}
