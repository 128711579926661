import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
//also some css from usecaseSecond.css , masterdata.css
import "../Class/ClassComp.css";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import axios from "../../../axios";
import { useSchoolContext } from "../../../schoolContext";
import DatePicker from "react-datepicker";
import ErrorPopup from "../../../ErrorPopup";
import RemoveErrorPopup from "../../../removePopUp";
export default function TeacherInfo({ setAddTeacherComp, editId }) {
  const [SubjectOptions, setSubjectOptions] = useState([]);
  const [classesData, setClassesData] = useState(new Map());
  const [ayDropdown, setAyDropdown] = useState([]);
  const [aysDropdown, setAysDropdown] = useState([]);
  const [standardSectionData, setStandardSectionData] = useState([]);
  const [AsignClassOptionsCheckbox, setAsignClassOptionsCheckbox] = useState(
    []
  );
  console.log(AsignClassOptionsCheckbox);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [createErrors, setCreateErrors] = useState(null);

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.code,
    label: country.name,
  }));

  const stateOptions = State.getStatesOfCountry("IN").map((state) => ({
    label: state.name,
    value: state.id,
  }));

  const cityOptions = City.getCitiesOfCountry("IN").map((city) => {
    return { value: city.name, label: city.name };
  });

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  useEffect(() => {
    async function fetchSections() {
      try {
        const standardsMap = new Map();

        const res = await axios.get(
          `/api/v1/school/standardSection/get/school/standards/${schoolId}`
        );
        const fetchedData = res.data.data;

        for (const data of fetchedData) {
          if (data?.active === false) {
            continue;
          }
          if (!standardsMap.has(data?.standard?.value)) {
            standardsMap.set(data?.standard?.value, [data]);
          } else {
            standardsMap.get(data?.standard?.value).push(data);
          }
        }
        setClassesData(standardsMap);

        const classDropdownValues = [...standardsMap.keys()].map((standard) => {
          const StandardName = standardsMap?.get(standard)[0]?.standard.label;
          const optionsArray = standardsMap?.get(standard)?.map((section) => {
            return {
              value: section.id,
              label:
                "Class " + section.standard.label + "_" + section.section.label,
              timetableSessions: section?.timetableSessions,
              academicYearSessions: section?.academicYearSessions,
            };
          });
          return {
            label: StandardName,
            options: optionsArray,
          };
        });

        const subjectValues = fetchedData.map((data) => {
          const value =
            "Class_" + data.standard.label + "_" + data.section.label;
          const options = data.subjects;
          return { label: value, options };
        });
        setSubjectOptions(subjectValues);
        setAsignClassOptionsCheckbox(classDropdownValues);
        setStandardSectionData(fetchedData);
      } catch (error) {
        handleError(error.response.data.message);
        console.log(error);
      }
    }
    fetchSections();
  }, [schoolId]);
  const handleInputAddressChange = (id, name, value) => {
    const newFields = [...fieldsAddTeacher];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index].address[name] = value;
    newFields[index].modified = true;
    setFieldsAddTeacher(newFields);
  };

  const DropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#99ADBB",
      minHeight: "30px",
      padding: "11px",
      borderRadius: " 8px",
      boxShadow: state.isFocused ? null : null,
    }),
    indicatorSeparator: () => ({ display: "none" }),
    group: (provided) => ({
      ...provided,
      fontWeight: 400,
      fontSize: "14px",
      color: "#333",
    }),
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (schoolId) {
          const response = await axios.get(
            `/api/v1/school/academicYear/list/get/school/${schoolId}`
          );
          const dataModified = response.data.data.map((data, index) => ({
            value: data.id,
            label: data.name,
          }));
          if (response.data.data.length > 0) {
            setAyDropdown(dataModified);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching sessions:", error);
      }
    };

    fetchData();
  }, [schoolId]);
  const fetchAcademicYearSession = async (value) => {
    const response = await axios.get(
      `/api/v1/school/academicYearSession/get/academicYear/${value?.value}`
    );
    if (response.data.data.length > 0) {
      const mappedAcademicSession = response.data.data.map((classItem) => {
        return {
          value: classItem.id,
          label: classItem.name,
        };
      });
      setAysDropdown(mappedAcademicSession);
    }
  };
  const fetchData = async (academicYearSession, id) => {
    try {
      if (academicYearSession.value) {
        let response = await axios.get(
          `/api/v1/school/schoolSession/getByAcademicSession?academicYearSession=${academicYearSession.value}`
        );
        const createdSessions = response.data.data.map(
          (createdSession, index) => ({
            value: createdSession?.timeTableSession,
            label: createdSession?.remark,
          })
        );
        if (createdSessions.length > 0) {
          const newFields = [...fieldsAddTeacher];
          const index = newFields.findIndex((field) => field.id === id);
          newFields[index].timeTableOptions = createdSessions;
          setFieldsAddTeacher(newFields);
          return createdSessions;
        }
        return [];
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error fetching sessions:", error);
    }
  };
  const fetchDataOptions = async (academicYearSession) => {
    try {
      if (academicYearSession.value) {
        let response = await axios.get(
          `/api/v1/school/schoolSession/getByAcademicSession?academicYearSession=${academicYearSession.value}`
        );
        const createdSessions = response.data.data.map((createdSession) => ({
          value: createdSession?.timeTableSession,
          label: createdSession?.remark,
        }));
        return createdSessions;
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error fetching sessions:", error);
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 56,
      width: "100%",
      borderRadius: "8px",
      background: "#FFFFFF",
      padding: " 5px",
      border: "1px solid #99ADBB",
      fontFamily: "MinecrafterReg.ttf",
    }),
  };

  // code for Academic Session add fields more
  const [fieldsAddTeacher, setFieldsAddTeacher] = useState([
    {
      id: 1,
      Fname: "",
      gender: "",
      SchoolId: "",
      SchoolEmail: "",
      PersonalEmail: "",
      Cnumber: "",
      Bgroup: "",
      academicYear: "",
      academicSession: "",
      timeTableSession: "",
      Position: "",
      EmergencyN: "",
      EmergencyC: "",
      birthD: "",
      JoiningD: "",
      profile_picture: "",
      AssignClass: [],
      officialEmail: "",
      AssignSubjects: [],
      remarks: "",
      address: {
        buildingNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
      classTeacher: false,
      classCordinator: false,
    },
  ]);

  const handleAddFieldAddTeacher = () => {
    const newFields = [...fieldsAddTeacher];
    newFields.push({
      id: fieldsAddTeacher.length + 1,
      Fname: "",
      SchoolId: "",
      SchoolEmail: "",
      profile_picture: "",
      PersonalEmail: "",
      Cnumber: "",
      Bgroup: "",
      gender: "",
      academicYear: "",
      academicSession: "",
      timeTableSession: "",
      Position: "",
      EmergencyN: "",
      EmergencyC: "",
      birthD: "",
      JoiningD: "",
      officialEmail: "",
      AssignClass: [],
      AssignSubjects: [],
      remarks: "",
      address: {
        buildingNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
      classTeacher: false,
      classCordinator: false,
    });
    setFieldsAddTeacher(newFields);
  };
  const [field, setField] = useState();
  const [field_id, setField_Id] = useState();
  const [showRemovePopUp, setShowRemovePopUp] = useState(false);
  const handleRemoveFieldAddTeacher = async () => {
    if (field.teacherId) {
      await axios.delete(`/api/v1/school/teacher/delete/${field.teacherId}`);
    }
    const newFields = fieldsAddTeacher.filter((field) => field.id !== field_id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsAddTeacher(renumberedFields);
  };
  const handleInputAddTeacher = (id, name, value) => {
    if (name === "academicYear") {
      fetchAcademicYearSession(value, id);
    }
    if (name === "academicSession") {
      fetchData(value, id);
    }
    const newFields = [...fieldsAddTeacher];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    newFields[index].modified = true;
    setFieldsAddTeacher(newFields);
  };
  const isfieldsAddTeacher = fieldsAddTeacher.some(
    (field) =>
      field.Fname === "" ||
      field.SchoolId === "" ||
      field.SchoolEmail === "" ||
      field.PersonalEmail === "" ||
      field.profile_picture === "" ||
      field.Cnumber === "" ||
      // field.Bgroup === "" ||
      field.Position === "" ||
      field.EmergencyN === "" ||
      field.EmergencyC === "" ||
      field.JoiningD === "" ||
      field.AssignClass.length === 0 ||
      field.AssignSubjects.length === 0 ||
      field.remarks === "" ||
      // field.address.buildingNumber === "" ||
      field.address.addressLine1 === "" ||
      field.address.addressLine2 === "" ||
      field.address.city === "" ||
      field.address.state === "" ||
      field.address.zipcode === "" ||
      field.address.country === "" ||
      !field.academicSession ||
      !field.timeTableSession ||
      fieldErrors.length > 0
  );

  useEffect(() => {
    async function fetchClassessData() {
      // if (standardSectionData.length > 0) {
      try {
        const res = await axios.get(
          `/api/v1/school/teacher/get/school/${schoolId}`
        );
        if (res && res.data && res.data.data) {
          if (res.data.data.length > 0) {
            const mappedFormsData = res.data.data
              .filter((data) => data?.active !== false)
              .map((data, index) => {
                const compareObjects = (obj1, obj2) => {
                  return obj1.label === obj2.label && obj1.value === obj2.value;
                };

                const uniqueRoles = Array.from(
                  new Set(
                    [
                      ...data.role_as_class_teacher,
                      ...data.role_as_co_ordinator,
                    ].filter((role, index, self) => {
                      return (
                        self.findIndex((obj) => compareObjects(obj, role)) ===
                        index
                      );
                    })
                  )
                );

                // const AssignClassArray = Array.from(
                //   new Set(
                //     data.general_role.length > 0
                //       ? data.general_role.map((genRole) => ({
                //           label:
                //             "Class " +
                //             genRole.standard_id.name +
                //             "_" +
                //             genRole.section_id.name,
                //           value: standardSectionData.find(
                //             (element) =>
                //               element.section.value === genRole.section_id.id &&
                //               element.standard.value === genRole.standard_id.id
                //           )?.id,
                //         }))
                //       : uniqueRoles.map((genRole) => ({
                //           label:
                //             "Class " +
                //             genRole.standard_id.name +
                //             "_" +
                //             genRole.section_id.name,
                //           value: standardSectionData.find(
                //             (element) =>
                //               element.section.value === genRole.section_id.id &&
                //               element.standard.value === genRole.standard_id.id
                //           )?.id,
                //         }))
                //   )
                // );

                const combinedRoles = data.general_role.concat(uniqueRoles);

                const AssignClassArray = Array.from(
                  new Set(
                    combinedRoles.map((genRole) => ({
                      label:
                        "Class " +
                        genRole.standard_id.name +
                        "_" +
                        genRole.section_id.name,
                      value: standardSectionData.find(
                        (element) =>
                          element.section.value === genRole.section_id.id &&
                          element.standard.value === genRole.standard_id.id
                      )?.id,
                    }))
                  )
                );

                const subjectsIDArray = [];

                if (data.role_as_class_teacher.length > 0) {
                  subjectsIDArray.push(
                    ...data.role_as_class_teacher
                      .map((element) => {
                        const standardSecId = standardSectionData.find(
                          (elem) => {
                            return (
                              elem.section.id === element.section_id.id &&
                              elem.standard.id === element.standard_id.id
                            );
                          }
                        )?.id;
                        return element.subjects_id?.map(
                          (subject) => `${subject.id},${standardSecId}`
                        );
                      })
                      .flat()
                      .filter((id) => id !== undefined)
                  );
                }

                if (data.general_role.length > 0) {
                  subjectsIDArray.push(
                    ...data.general_role
                      .map((element) => {
                        const standardSecId = standardSectionData.find(
                          (elem) => {
                            return (
                              elem.section.id === element.section_id.id &&
                              elem.standard.id === element.standard_id.id
                            );
                          }
                        )?.id;
                        return element.subjects_id?.map(
                          (subject) => `${subject.id},${standardSecId}`
                        );
                      })
                      .flat()
                      .filter((id) => id !== undefined)
                  );
                }

                if (data.role_as_co_ordinator.length > 0) {
                  subjectsIDArray.push(
                    ...data.general_role
                      .map((element) => {
                        const standardSecId = standardSectionData.find(
                          (elem) => {
                            return (
                              elem.section.id === element.section_id.id &&
                              elem.standard.id === element.standard_id.id
                            );
                          }
                        )?.id;
                        return element.subjects_id?.map(
                          (subject) => `${subject.id},${standardSecId}`
                        );
                      })
                      .flat()
                      .filter((id) => id !== undefined)
                  );
                }

                const uniqueSubjectsIDArray = [...new Set(subjectsIDArray)];
                console.log(uniqueSubjectsIDArray);

                // const subjectsWithDupArray = uniqueSubjectsIDArray.flatMap(
                //   (subjectId) => {
                //     const fetchedSubjects = combinedRoles.flatMap((genRole) => {
                //       const standardSection = standardSectionData?.find(
                //         (sectionData) =>
                //           sectionData.section.value === genRole.section_id.id &&
                //           sectionData.standard.value === genRole.standard_id.id
                //       );
                //       if (standardSection) {
                //         const filteredSub = standardSection.subjects
                //           .filter(
                //             (filterElement) => filterElement.value === subjectId
                //           )
                //           .map((obj) => ({
                //             label:
                //               "Class " +
                //               standardSection.standard.label +
                //               "_" +
                //               standardSection.section.label +
                //               "_" +
                //               obj.label,
                //             value: obj.value + "," + standardSection.id,
                //           }));
                //         return filteredSub;
                //       } else {
                //         return [];
                //       }
                //     });
                //     // data.general_role.length > 0
                //     //   ? data.general_role.flatMap((genRole) => {
                //     //       const standardSection = standardSectionData?.find(
                //     //         (sectionData) =>
                //     //           sectionData.section.value ===
                //     //             genRole.section_id.id &&
                //     //           sectionData.standard.value ===
                //     //             genRole.standard_id.id
                //     //       );
                //     //       if (standardSection) {
                //     //         const filterdSub =
                //     //           standardSection?.subjects
                //     //             .filter(
                //     //               (filterElement) =>
                //     //                 filterElement.value === subjectId
                //     //             )
                //     //             ?.map((obj) => {
                //     //               return {
                //     //                 label:
                //     //                   "Class " +
                //     //                   standardSection.standard.label +
                //     //                   "_" +
                //     //                   standardSection.section.label +
                //     //                   "_" +
                //     //                   obj.label,
                //     //                 value:
                //     //                   obj.value + "," + standardSection.id,
                //     //               };
                //     //             }) || [];

                //     //         return filterdSub;
                //     //       } else {
                //     //         return [];
                //     //       }
                //     //     })
                //     //   : uniqueRoles.flatMap((genRole) => {
                //     //       const standardSubject = standardSectionData?.find(
                //     //         (sectionData) =>
                //     //           sectionData.section.value ===
                //     //             genRole.section_id.id &&
                //     //           sectionData.standard.value ===
                //     //             genRole.standard_id.id
                //     //       );
                //     //       if (standardSubject) {
                //     //         const filteredSub =
                //     //           standardSubject?.subjects
                //     //             .filter(
                //     //               (filterElement) =>
                //     //                 filterElement.value === subjectId
                //     //             )
                //     //             ?.map((obj) => {
                //     //               return {
                //     //                 label:
                //     //                   "Class " +
                //     //                   standardSubject.standard.label +
                //     //                   "_" +
                //     //                   standardSubject.section.label +
                //     //                   "_" +
                //     //                   obj.label,
                //     //                 value:
                //     //                   obj.value + "," + standardSubject.id,
                //     //               };
                //     //             }) || [];
                //     //         return filteredSub;
                //     //       } else {
                //     //         return [];
                //     //       }
                //     //     }) || [];

                //     return fetchedSubjects;
                //   }
                // );
                const subArr = uniqueSubjectsIDArray.map((elem) => {
                  const standardSection = standardSectionData?.find(
                    (sectionData) => sectionData.id === elem.split(",")[1]
                  );
                  if (standardSection) {
                    const filteredSub = standardSection.subjects
                      .filter(
                        (filterElement) =>
                          filterElement.value === elem.split(",")[0]
                      )
                      .map((obj) => ({
                        label:
                          "Class " +
                          standardSection.standard.label +
                          "_" +
                          standardSection.section.label +
                          "_" +
                          obj.label,
                        value: obj.value + "," + standardSection.id,
                      }));
                    return filteredSub[0];
                  } else {
                    return;
                  }
                });
                const subjectsArray = subArr;
                // subjectsWithDupArray.filter(
                //   (current, index, self) =>
                //     index === self.findIndex((el) => el.value === current.value)
                // );

                let academicYearObject;
                if (data.academic_year) {
                  academicYearObject = {
                    value: data.academic_year.id,
                    label: data.academic_year.name,
                  };
                }
                let timeTableSession;
                if (data.timetable_session) {
                  timeTableSession = {
                    value: data.timetable_session.id,
                    label: data.timetable_session.name,
                  };
                }

                const resTransformedObj = {
                  id: index + 1,
                  teacherId: data.id,
                  Fname: data.name,
                  SchoolEmail: data.employee_id,
                  SchoolId: data.code,
                  JoiningD: data.date_of_joining
                    ? new Date(data.date_of_joining)
                    : "Invalid date format",
                  officialEmail: data.offical_email,
                  PersonalEmail: data.personal_email,
                  Cnumber: data.mobile_number,
                  Bgroup: data.BloodGroup,
                  birthD: data.date_of_birth
                    ? new Date(data.date_of_birth)
                    : "Invalid date format",
                  gender: data.gender,
                  remarks: data.remarks,
                  Position: data.position,
                  academicYear: data.academicYear,
                  profile_picture: data.profile_picture,
                  timeTableSession: data.timeTableSession,
                  EmergencyN: data.emergency_contact_name,
                  academicYear: academicYearObject,
                  timeTableSession,
                  academicSession: {
                    value: data?.academicYearSession?.id,
                    label: data?.academicYearSession?.name,
                  },
                  EmergencyC: data.emergency_contact_number,
                  AssignClass: AssignClassArray,
                  AssignSubjects: subjectsArray,
                  address: {
                    id: data.address.id,
                    buildingNumber: data.address.building_number,
                    addressLine1: data.address.line1,
                    addressLine2: data.address.line2,
                    city: {
                      value: data.address.city,
                      label: data.address.city,
                    },
                    state: {
                      value: data.address.state,
                      label: data.address.state,
                    },
                    zipcode: data.address.zipcode,
                    country: {
                      value: data.address.country,
                      label: data.address.country,
                    },
                  },
                  classTeacher: data.role_as_class_teacher.length > 0,
                  // data.role_as_co_ordinator.some(
                  //   (element) => element.is_class_co_ordinator
                  // ),
                  classCordinator: data.role_as_co_ordinator.length > 0,
                  // data.role_as_class_teacher.some(
                  //   (element) => element.is_class_teacher
                  // ),
                  CheckBoxTeacher: data.role_as_class_teacher.length > 0 && {
                    label:
                      data.role_as_class_teacher.length > 0 &&
                      "Class " +
                        data.role_as_class_teacher[0].standard_id.name +
                        "_" +
                        data.role_as_class_teacher[0].section_id.name,
                    value: standardSectionData.find(
                      (element) =>
                        element.section.value ===
                          data.role_as_class_teacher[0].section_id.id.toString() &&
                        element.standard.value ===
                          data.role_as_class_teacher[0].standard_id.id.toString()
                    )?.id,
                  },
                  CheckBoxCordinator:
                    data.role_as_co_ordinator.length > 0 &&
                    data.role_as_co_ordinator.map((coordinatorRole) => {
                      return {
                        label:
                          "Class " +
                          coordinatorRole.standard_id.name +
                          "_" +
                          coordinatorRole.section_id.name,
                        value: standardSectionData.find(
                          (element) =>
                            element.section.value ===
                              coordinatorRole.section_id.id &&
                            element.standard.value ===
                              coordinatorRole.standard_id.id
                        )?.id,
                      };
                    }),
                };
                return resTransformedObj;
              });
            let finalArray = [];
            if (editId) {
              finalArray.push(
                mappedFormsData.find((obj) => obj.teacherId === editId)
              );
            } else {
              finalArray = mappedFormsData;
            }
            await Promise.all(
              finalArray.map(async (obj, i) => {
                const timetableSessionOptions = await fetchDataOptions(
                  obj?.academicSession
                );
                finalArray[i].timeTableOptions = timetableSessionOptions;
                fetchAcademicYearSession(obj?.academicYear);
              })
            );
            setFieldsAddTeacher(finalArray);
          } else {
            setFieldsAddTeacher([
              {
                id: 1,
                Fname: "",
                SchoolId: "",
                SchoolEmail: "",
                PersonalEmail: "",
                Cnumber: "",
                Bgroup: "",
                gender: "",
                Position: "",
                EmergencyN: "",
                academicYear: "",
                academicSession: "",
                timeTableSession: "",
                EmergencyC: "",
                birthD: "",
                JoiningD: "",
                officialEmail: "",
                AssignClass: [],
                AssignSubjects: [],
                remarks: "",
                address: {
                  buildingNumber: "",
                  addressLine1: "",
                  addressLine2: "",
                  city: "",
                  state: "",
                  zipcode: "",
                  country: "",
                },
                classTeacher: false,
                classCordinator: false,
              },
            ]);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.log(error);
      }
      // }
    }
    fetchClassessData();
  }, [schoolId, standardSectionData]);

  const handleCreateTeacher = async () => {
    try {
      for (const teacher of fieldsAddTeacher) {
        let resultArray = [];
        if (teacher.classCordinator && teacher.classTeacher) {
          const classAssigned = teacher.AssignClass;

          const classCoordinator = teacher.CheckBoxCordinator;

          const classTeacher = teacher.CheckBoxTeacher;

          const coordinatorClasses = new Set(
            classCoordinator.map((coord) => coord.value)
          );

          const teacherClasses = new Set([classTeacher.value]);

          const mergedArray = [...classAssigned, ...classCoordinator];

          const uniqueClassValues = Array.from(
            new Set(mergedArray.map((item) => item.value))
          );

          resultArray = uniqueClassValues.map((value) => {
            const item = mergedArray.find((el) => el.value === value) || {
              value,
              label: "",
            };
            return {
              ...item,
              isClassTeacher: teacherClasses.has(item.value),
              isClassCoordinator: coordinatorClasses.has(item.value),
            };
          });

          if (!resultArray.some((item) => item.isClassTeacher)) {
            resultArray.push({
              value: classTeacher.value,
              label: classTeacher.label,
              isClassTeacher: true,
              isClassCoordinator: coordinatorClasses.has(classTeacher.value),
            });
          }
        } else if (teacher.classCordinator && !teacher.classTeacher) {
          const classAssigned = teacher.AssignClass;

          const classCoordinator = teacher.CheckBoxCordinator;

          const coordinatorClasses = new Set(
            classCoordinator.map((coord) => coord.value)
          );

          const mergedArray = [...classAssigned, ...classCoordinator];

          const uniqueClassValues = Array.from(
            new Set(mergedArray.map((item) => item.value))
          );

          resultArray = uniqueClassValues.map((value) => {
            const item = mergedArray.find((el) => el.value === value) || {
              value,
              label: "",
            };
            return {
              ...item,
              isClassTeacher: false,
              isClassCoordinator: coordinatorClasses.has(item.value),
            };
          });
        } else if (!teacher.classCordinator && teacher.classTeacher) {
          const classAssigned = teacher.AssignClass;

          const classTeacher = teacher.CheckBoxTeacher;

          const teacherClasses = new Set([classTeacher.value]);

          const mergedArray = [...classAssigned];

          const uniqueClassValues = Array.from(
            new Set(mergedArray.map((item) => item.value))
          );

          resultArray = uniqueClassValues.map((value) => {
            const item = mergedArray.find((el) => el.value === value) || {
              value,
              label: "",
            };
            return {
              ...item,
              isClassTeacher: teacherClasses.has(item.value),
              isClassCoordinator: false,
            };
          });

          if (!resultArray.some((item) => item.isClassTeacher)) {
            resultArray.push({
              value: classTeacher.value,
              label: classTeacher.label,
              isClassTeacher: true,
              isClassCoordinator: false,
            });
          }
        } else {
          resultArray = teacher.AssignClass.map((data) => {
            return {
              ...data,
              isClassTeacher: false,
              isClassCoordinator: false,
            };
          });
        }

        const bodyTeacherRoleInfoArray = resultArray.map((data) => {
          const standardSectionDataa = standardSectionData.find((element) => {
            return element.id === data.value;
          });

          const subjArray = standardSectionDataa.subjects.map(
            (obj) => `${obj.value},${data.value}`
          );

          const filteredSubjectsArray = subjArray.filter((FilterElement) => {
            return teacher.AssignSubjects.some(
              (element) => element?.value === FilterElement
            );
          });

          return {
            standard_id: standardSectionDataa.standard.value,
            section_id: standardSectionDataa.section.value,
            is_class_teacher: data.isClassTeacher,
            is_class_co_ordinator: data.isClassCoordinator,
            timetableSessions: data?.timetableSessions,
            subjects_id: filteredSubjectsArray.map(
              (data) => data.split(",")[0]
            ),
          };
        });

        const body = {
          schoolId,
          name: teacher.Fname,
          //SchoolEmail needs to be changed with employeeId
          employee_id: teacher.SchoolEmail,
          code: teacher.SchoolId,
          date_of_joining: teacher.JoiningD,
          official_email: teacher.officialEmail,
          personal_email: teacher.PersonalEmail,
          mobile_number: teacher.Cnumber,
          academicYear: teacher.academicYear && teacher.academicYear.value,
          academicYearSession:
            teacher.academicSession && teacher.academicSession.value,
          timeTableSession:
            teacher.timeTableSession && teacher.timeTableSession.value,
          BloodGroup: teacher.Bgroup,
          profile_picture: teacher.profile_picture,
          date_of_birth: teacher.birthD,
          gender: teacher.gender,
          remarks: teacher.remarks,
          position: teacher.Position,
          emergency_contact_name: teacher.EmergencyN,
          emergency_contact_number: teacher.EmergencyC,
          address: {
            building_number: teacher.address.buildingNumber,
            line_1: teacher.address.addressLine1,
            line_2: teacher.address.addressLine2,
            city: teacher.address.city.label,
            state: teacher.address.state.label,
            zipcode: teacher.address.zipcode,
            country: teacher.address.country.label,
          },
          role_information: bodyTeacherRoleInfoArray,
        };
        if (!teacher.teacherId) {
          await axios.post("/api/v1/school/teacher/create", body);
        } else {
          const reqBody = {
            ...body,
            id: teacher.teacherId,
            modified: teacher.modified ? true : false,
          };
          if (reqBody.modified) {
            await axios.put(
              `/api/v1/school/teacher/update/${teacher.teacherId}`,
              reqBody
            );
          } else {
            setAddTeacherComp({
              editableId: null,
              teacherList: true,
            });
          }
        }
      }
      setAddTeacherComp({
        editableId: null,
        teacherList: true,
      });
    } catch (error) {
      handleError(error.response.data.message);
      console.log(error);
      setCreateErrors(error?.response?.data?.message);
    }
  };

  const validateFields = () => {
    const errors = [];

    fieldsAddTeacher.forEach((field) => {
      const fullNameRegex = /^[a-zA-Z\s]+$/;
      if (!fullNameRegex.test(field.Fname.trim()) && field.Fname !== "") {
        errors.push({
          id: field.id,
          field: "Fname",
          message: "This field cannot contain special characters",
        });
      }

      if (!fullNameRegex.test(field.Position.trim()) && field.Position !== "") {
        errors.push({
          id: field.id,
          field: "Position",
          message: "This field cannot contain special characters",
        });
      }

      if (
        !fullNameRegex.test(field.EmergencyN.trim()) &&
        field.EmergencyN !== ""
      ) {
        errors.push({
          id: field.id,
          field: "EmergencyN",
          message: "This field cannot contain special characters",
        });
      }

      // if (
      //   !fullNameRegex.test(field?.address?.city?.trim()) &&
      //   field.address.city !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "city",
      //     message: "This field cannot contain special characters",
      //   });
      // }
      // if (
      //   !fullNameRegex.test(field.address.state.trim()) &&
      //   field.address.state !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "state",
      //     message: "This field cannot contain special characters",
      //   });
      // }
      // if (
      //   !fullNameRegex.test(field.address.country.trim()) &&
      //   field.address.country !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "country",
      //     message: "This field cannot contain special characters",
      //   });
      // }

      if (!isValidEmail(field.PersonalEmail) && field.PersonalEmail !== "") {
        errors.push({
          id: field.id,
          field: "PersonalEmail",
          message: "Invalid Email",
        });
      }

      if (!isValidEmail(field.officialEmail) && field.officialEmail !== "") {
        errors.push({
          id: field.id,
          field: "officialEmail",
          message: "Invalid Email",
        });
      }

      if (!isValidnumber(field.Cnumber) && field.Cnumber !== "") {
        errors.push({
          id: field.id,
          field: "Cnumber",
          message: "Invalid Number",
        });
      }

      if (!isValidnumber(field.EmergencyC) && field.EmergencyC !== "") {
        errors.push({
          id: field.id,
          field: "EmergencyC",
          message: "Invalid Number",
        });
      }

      // const schoolEmailRegex = /^teach\d{4}-\d{1,4}$/;
      // if (
      //   !schoolEmailRegex.test(field.SchoolEmail.trim()) &&
      //   field.SchoolEmail !== ""
      // ) {
      //   errors.push({
      //     id: field.id,
      //     field: "SchoolEmail",
      //     message: "Invalid format",
      //   });
      // }
      if (field?.Bgroup) {
        const bloodGroupRegex = /^(A|B|AB|O)([+-]VE)?$/i;

      const match = field.Bgroup?.match(bloodGroupRegex);

        if (!match && field.Bgroup !== "") {
          errors.push({
            id: field.id,
            field: "Bgroup",
            message: "Invalid blood group format",
          });
        }
      }

      const genderRegex = /^[MFO]$/;
      if (!genderRegex.test(field.gender) && field.gender !== "") {
        errors.push({
          id: field.id,
          field: "gender",
          message: 'Gender should be "M", "F", or "O"',
        });
      }

      // const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
      // if (!dateRegex.test(field.birthD) && field.birthD !== "") {
      //   errors.push({
      //     id: field.id,
      //     field: "birthD",
      //     message: "Invalid date format. Please use DD/MM/YYYY",
      //   });
      // }

      // if (!dateRegex.test(field.JoiningD) && field.JoiningD !== "") {
      //   errors.push({
      //     id: field.id,
      //     field: "JoiningD",
      //     message: "Invalid date format. Please use DD/MM/YYYY",
      //   });
      // }
    });

    setFieldErrors(errors);
    return errors.length === 0;
  };

  const isValidEmail = (email) => {
    if (email.indexOf("@") !== -1 && email.indexOf(".") !== -1) {
      const atIndex = email.indexOf("@");
      if (atIndex > 0) {
        const dotIndex = email.indexOf(".", atIndex);
        if (dotIndex > atIndex + 1) {
          if (dotIndex < email.length - 1) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const today = new Date();

  const isValidnumber = (number) => {
    const digitRegex = /^\d{10}$/;
    return digitRegex.test(number);
  };

  useEffect(() => {
    validateFields();
  }, [fieldsAddTeacher]);

  const validateFileType = (filePath) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(filePath.name)) {
      return false;
    }
    return true;
  };

  const handleProfileAddImage = async (id, e) => {
    let profile_picture;
    let profile = e.target.files[0];
    if (profile.size > 10 * 1024 * 1024 || !validateFileType(profile)) {
      window.alert(
        "The File size should be Less than 10 MB And Type must be JPG, JPEG, PNG"
      );
      return;
    }
    if (profile) {
      const formData = new FormData();
      formData.append("name", profile.name);
      formData.append("uploadTeacherProfileImage", profile);
      try {
        const res = await axios.post(
          `/api/v1/teacher/uploadAttachment/schoolId/${schoolId}`,
          formData
        );
        profile_picture = res.data.data;
        const newFields = [...fieldsAddTeacher];
        const index = newFields.findIndex((field) => field.id === id);
        newFields[index].profile_picture = profile_picture;
        newFields[index].modified = true;
        setFieldsAddTeacher(newFields);
      } catch (error) {
        handleError(error.response.data.message);
        console.log(error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let employeeId = [];
      fieldsAddTeacher.forEach((obj) => {
        if (!obj.teacherId && obj?.modified) {
          employeeId.push(obj?.SchoolEmail);
        }
      });
      if (employeeId.length > 0) {
        let duplicateBody = {
          persona: "teacher",
          schoolId: schoolId,
          userIds: employeeId,
        };
        let response = await axios.post(
          `/api/v1/school/verifyTeacherStudentEmployeeId`,
          duplicateBody
        );
        if (response.data.data.length === 0) {
          handleCreateTeacher();
        } else {
          setErrorOccurred(true);
          setErrorMessage(
            `You have entered the duplicate teacherId: ${response.data.data}`
          );
        }
      } else {
        handleCreateTeacher();
      }
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  const DOBRef = useRef([]);
  const dateOfAdmissionRef = useRef([]);

  const handleDOBClick = (index) => {
    if (DOBRef.current[index]) {
      DOBRef.current[index].setOpen(true);
    }
  };

  const handleDateOfAdmissionRefClick = (index) => {
    if (dateOfAdmissionRef.current[index]) {
      dateOfAdmissionRef.current[index].setOpen(true);
    }
  };
  console.log(AsignClassOptionsCheckbox);
  return (
    <>
      <div>
        <div className="schoolInfo_head">Teacher infomation</div>
        <div className="heading_belowText">
          Provide below details to update class information.
        </div>

        <div className="d-flex gap-4 MainContainer_addClassInputs mt-4">
          {fieldsAddTeacher.map((field, index) => (
            <div key={field.id} className="classInput_container  mr-2">
              <div className="d-flex justify-content-between ">
                <div className="classinfo_subHeader ">
                  Teacher info- 0{field.id}
                </div>
                {fieldsAddTeacher.length !== 1 && (
                  <div
                    className="remove"
                    onClick={() => {
                      setField(field);
                      setField_Id(field?.id);
                      setShowRemovePopUp(true);
                    }}
                  >
                    Remove
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center AddCertificate_Container mt-3">
                <div className="CertificateName">
                  {field.profile_picture
                    ? field.profile_picture.match(/_(\w+\.\w+)$/) &&
                      field.profile_picture.match(/_(\w+\.\w+)$/)[1]
                    : "Add Profile Picture *"}
                </div>
                <div>
                  <input
                    type="file"
                    id={"attachFile" + field.id}
                    // disabled={!field.SpecialAbled}
                    onChange={(e) => handleProfileAddImage(field.id, e)}
                    style={{ display: "none" }}
                  />
                  <label htmlFor={"attachFile" + field.id}>
                    <span className="uploadLabel">
                      {field.profile_picture
                        ? "Change Profile Picture"
                        : "Upload"}{" "}
                    </span>
                  </label>
                </div>
              </div>
              <div className="mt-4">
                <input
                  className="inputSchool_blocks"
                  value={field.Fname}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "Fname", e.target.value)
                  }
                  placeholder="Full Name *"
                  maxLength={50}
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Fname" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-4">
                <input
                  className="inputSchool_blocks"
                  value={field.gender}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "gender", e.target.value)
                  }
                  placeholder="Gender *"
                  maxLength={1}
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "gender" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.SchoolEmail}
                  onChange={(e) =>
                    handleInputAddTeacher(
                      field.id,
                      "SchoolEmail",
                      e.target.value
                    )
                  }
                  maxLength={14}
                  placeholder="School assigned ID (EX: teach000-0000) *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "SchoolEmail" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.SchoolId}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "SchoolId", e.target.value)
                  }
                  maxLength={10}
                  placeholder="Teacher Code *"
                />
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.officialEmail}
                  onChange={(e) =>
                    handleInputAddTeacher(
                      field.id,
                      "officialEmail",
                      e.target.value
                    )
                  }
                  maxLength={50}
                  placeholder="School email id *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "officialEmail" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.PersonalEmail}
                  onChange={(e) =>
                    handleInputAddTeacher(
                      field.id,
                      "PersonalEmail",
                      e.target.value
                    )
                  }
                  maxLength={50}
                  placeholder="Personal email id *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "PersonalEmail" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Cnumber}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "Cnumber", e.target.value)
                  }
                  maxLength={10}
                  placeholder="Contact number *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Cnumber" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Bgroup}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "Bgroup", e.target.value)
                  }
                  maxLength={5}
                  placeholder="Blood Group (EX: A+ve)"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Bgroup" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.Position}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "Position", e.target.value)
                  }
                  maxLength={50}
                  placeholder="Position/Title *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "Position" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.EmergencyN}
                  onChange={(e) =>
                    handleInputAddTeacher(
                      field.id,
                      "EmergencyN",
                      e.target.value
                    )
                  }
                  maxLength={50}
                  placeholder="Emergency Contact Name *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "EmergencyN" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.EmergencyC}
                  onChange={(e) =>
                    handleInputAddTeacher(
                      field.id,
                      "EmergencyC",
                      e.target.value
                    )
                  }
                  maxLength={10}
                  placeholder="Emergency Contact Number *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "EmergencyC" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              {/* <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.JoiningD}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "JoiningD", e.target.value)
                  }
                  maxLength={10}
                  placeholder="Joining Date "
                />
              </div> */}
              <div className="d-flex container_dateselect_holidays mt-3">
                <DatePicker
                  selected={field.JoiningD}
                  onChange={(date) =>
                    handleInputAddTeacher(field.id, "JoiningD", date)
                  }
                  dateFormat="dd/MM/yyyy"
                  maxDate={today}
                  endDate={today}
                  className="SelectDateStudent"
                  ref={(ref) => (dateOfAdmissionRef.current[index] = ref)}
                  placeholderText="Joining Date *"
                />
                <img
                  src="/OnBoard/BlackcalenderIcon.png"
                  alt="calender Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDateOfAdmissionRefClick(index)}
                ></img>
              </div>
              {/* {fieldErrors.map((error) =>
                error.id === field.id && error.field === "JoiningD" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )} */}
              {/* <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.birthD}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "birthD", e.target.value)
                  }
                  maxLength={10}
                  placeholder="DOB"
                />
              </div> */}
              <div className="d-flex container_dateselect_holidays mt-3">
                <DatePicker
                  selected={field.birthD}
                  onChange={(date) =>
                    handleInputAddTeacher(field.id, "birthD", date)
                  }
                  dateFormat="dd/MM/yyyy"
                  maxDate={today}
                  endDate={today}
                  showYearDropdown
                  scrollableYearDropdown
                  className="SelectDateStudent"
                  ref={(ref) => (DOBRef.current[index] = ref)}
                  placeholderText="DOB *"
                />
                <img
                  src="/OnBoard/BlackcalenderIcon.png"
                  alt="calender Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDOBClick(index)}
                ></img>
              </div>
              {/* {fieldErrors.map((error) =>
                error.id === field.id && error.field === "birthD" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )} */}

              <div className="mt-3">
                <Select
                  options={ayDropdown}
                  styles={DropdownStyle}
                  placeholder="Academic Year"
                  value={field.academicYear}
                  onChange={(selectedOptions) => {
                    handleInputAddTeacher(
                      field.id,
                      "academicYear",
                      selectedOptions
                    );
                  }}
                />
              </div>
              <div className="mt-3">
                <Select
                  value={field.academicSession}
                  //This needs to be comes from options part like above one.
                  options={aysDropdown}
                  styles={DropdownStyle}
                  placeholder="Academic Session *"
                  onChange={(selectedOptions) => {
                    handleInputAddTeacher(
                      field.id,
                      "academicSession",
                      selectedOptions
                    );
                  }}
                />
              </div>
              <div className="mt-3">
                <Select
                  options={field.academicYear ? field.timeTableOptions : []}
                  styles={DropdownStyle}
                  placeholder="Time Table Session *"
                  value={field.timeTableSession}
                  onChange={(selectedOptions) => {
                    handleInputAddTeacher(
                      field.id,
                      "timeTableSession",
                      selectedOptions
                    );
                  }}
                />
              </div>

              <div className="mt-3">
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={AsignClassOptionsCheckbox.map((obj) => {
                    return {
                      ...obj,
                      options: obj.options.filter(
                        (option) =>
                          option?.timetableSessions?.id ===
                          field?.timeTableSession.value
                      ),
                    };
                  })}
                  styles={DropdownStyle}
                  placeholder="Assign Class *"
                  value={field.AssignClass}
                  onChange={(selectedOptions) => {
                    handleInputAddTeacher(
                      field.id,
                      "AssignClass",
                      selectedOptions
                    );
                  }}
                />
              </div>
              <div className="mt-3">
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={standardSectionData
                    .filter((element) =>
                      field.AssignClass.some(
                        (innerElement) => innerElement.value === element.id
                      )
                    )
                    .map((data) => {
                      const value =
                        "Class_" +
                        data.standard.label +
                        "_" +
                        data.section.label;
                      const options = data.subjects.map((obj) => {
                        return {
                          value: obj.value + "," + data.id,
                          label:
                            "Class " +
                            data.standard.label +
                            "_" +
                            data.section.label +
                            "_" +
                            obj.label,
                        };
                      });
                      return { label: value, options };
                    })}
                  styles={DropdownStyle}
                  placeholder="Assign subject *"
                  value={field.AssignSubjects} // add value prop and set it based on the state
                  onChange={(selectedOptions) => {
                    handleInputAddTeacher(
                      field.id,
                      "AssignSubjects",
                      selectedOptions
                    );
                  }} // update the state based on the selected options
                />
              </div>
              <div className="mt-3">
                <textarea
                  className="AddRemark"
                  value={field.remarks}
                  onChange={(e) =>
                    handleInputAddTeacher(field.id, "remarks", e.target.value)
                  }
                  maxLength={250}
                  placeholder="Add remarks *"
                />
              </div>
              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.buildingNumber}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "buildingNumber",
                      e.target.value
                    )
                  }
                  maxLength={30}
                  placeholder="Building Number "
                />
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.addressLine1}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "addressLine1",
                      e.target.value
                    )
                  }
                  maxLength={150}
                  placeholder="Address Line 1 *"
                />
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.addressLine2}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "addressLine2",
                      e.target.value
                    )
                  }
                  maxLength={150}
                  placeholder="Address Line 2 *"
                />
              </div>

              <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.zipcode}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "zipcode",
                      e.target.value
                    )
                  }
                  maxLength={10}
                  placeholder="Zipcode *"
                />
              </div>
              {field.address.zipcode && field.address.zipcode.length < 6 ? (
                <div style={{ color: "red" }}>Enter a valid Zipcode</div>
              ) : null}

              <div className="mt-3">
                {/* <input
                  className="inputSchool_blocks"
                  value={field.address.state}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "state", e.target.value)
                  }
                  maxLength={20}
                  placeholder="State *"
                /> */}
                <Select
                  options={stateOptions}
                  value={field.address.state}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "state", e)
                  }
                  placeholder="State *"
                  styles={customStyles}
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "state" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              {/* <div className="mt-3">
                <input
                  className="inputSchool_blocks"
                  value={field.address.city}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "city", e.target.value)
                  }
                  maxLength={20}
                  placeholder="City *"
                />
              </div> */}
              <div className="mt-3">
                <Select
                  options={cityOptions}
                  value={field.address.city}
                  onChange={(selectedOption) =>
                    handleInputAddressChange(field.id, "city", selectedOption)
                  }
                  styles={customStyles}
                  placeholder="City *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "city" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}

              <div className="mt-3">
                {/* <input
                  className="inputSchool_blocks"
                  value={field.address.country}
                  onChange={(e) =>
                    handleInputAddressChange(
                      field.id,
                      "country",
                      e.target.value
                    )
                  }
                  maxLength={20}
                  placeholder="Country *"
                /> */}
                <Select
                  options={countryOptions}
                  value={field.address.country}
                  onChange={(e) =>
                    handleInputAddressChange(field.id, "country", e)
                  }
                  styles={customStyles}
                  placeholder="Country *"
                />
              </div>
              {fieldErrors.map((error) =>
                error.id === field.id && error.field === "country" ? (
                  <div key={error.field} style={{ color: "red" }}>
                    {error.message}
                  </div>
                ) : null
              )}
              <div className="d-flex justify-content-between mt-3">
                <div className="text_switchCheckbox">
                  Assign as class teacher
                </div>
                <div class="form-switch">
                  <input
                    class="form-check-input"
                    checked={field.classTeacher}
                    onChange={(event) =>
                      handleInputAddTeacher(
                        field.id,
                        "classTeacher",
                        event.target.checked
                      )
                    }
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
              {field.classTeacher && (
                <div className="mt-3">
                  {/* <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={AsignClassOptionsCheckbox}
                    styles={DropdownStyle}
                    placeholder="Class"
                    value={field.CheckBoxTeacher} // add value prop and set it based on the state
                    onChange={(selectedOptions) => {
                      handleInputAddTeacher(
                        field.id,
                        "CheckBoxTeacher",
                        selectedOptions
                      );
                    }} // update the state based on the selected options
                  /> */}
                  <Select
                    // isMulti
                    options={AsignClassOptionsCheckbox.map((obj) => {
                      return {
                        ...obj,
                        options: obj.options.filter(
                          (option) =>
                            option?.timetableSessions?.id ===
                            field?.timeTableSession.value
                        ),
                      };
                    })}
                    styles={DropdownStyle}
                    placeholder="Class"
                    value={field.CheckBoxTeacher}
                    onChange={(selectedOptions) => {
                      handleInputAddTeacher(
                        field.id,
                        "CheckBoxTeacher",
                        selectedOptions
                      );
                    }}
                  />
                </div>
              )}

              <div className="d-flex justify-content-between mt-3 mb-3">
                <div className="text_switchCheckbox">
                  Assign as co-ordinator
                </div>
                <div class="form-switch">
                  <input
                    class="form-check-input"
                    name=""
                    checked={field.classCordinator}
                    onChange={(event) =>
                      handleInputAddTeacher(
                        field.id,
                        "classCordinator",
                        event.target.checked
                      )
                    }
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
              {field.classCordinator && (
                <div className="mt-3">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={AsignClassOptionsCheckbox.map((obj) => {
                      return {
                        ...obj,
                        options: obj.options.filter(
                          (option) =>
                            option?.timetableSessions?.id ===
                            field?.timeTableSession.value
                        ),
                      };
                    })}
                    styles={DropdownStyle}
                    placeholder="Class"
                    value={field.CheckBoxCordinator} // add value prop and set it based on the state
                    onChange={(selectedOptions) => {
                      handleInputAddTeacher(
                        field.id,
                        "CheckBoxCordinator",
                        selectedOptions
                      );
                    }} // update the state based on the selected options
                  />
                </div>
              )}
            </div>
          ))}

          {!editId && (
            <div className="addClassContainer ">
              <button
                onClick={handleAddFieldAddTeacher}
                className="AddClassBtn"
              >
                <BsPlusCircle /> Add Teacher
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
        <div>
          {createErrors && (
            <span style={{ color: "red" }}>*{createErrors}</span>
          )}
          {/* <span className="footer_text1">
            For bulk upload please use this template
          </span>
          <span className="footer_text2">Download template</span> */}
        </div>

        <div className="d-flex flex-wrap gap-3">
          {/* <div className="uploadFileBtn_MasterData">
            <input type="file" id="attachFile" style={{ display: "none" }} />
            <label htmlFor="attachFile">
              <span>
                <BsPlusCircle /> &nbsp;Upload file
              </span>
            </label>
          </div> */}
          <button
            id="NextBtn_school_masterData"
            onClick={handleSubmit}
            disabled={isfieldsAddTeacher}
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
      {showRemovePopUp && (
        <RemoveErrorPopup
          closePopup={() => {
            setShowRemovePopUp(false);
          }}
          handleDelete={handleRemoveFieldAddTeacher}
        />
      )}
    </>
  );
}
