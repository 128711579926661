import React from "react";
import TeacherTableSchool from "./TeacherTableSchool";

const TeacherTablePropsSchool = () => {
  return (
    <>
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
      <TeacherTableSchool
        profile_pic="/StudentImages/table pic1.svg"
        Name="Subham Kumar"
        School_Email="subham.k@xploresense.com"
        Personal_Email="sd.k@9011.com"
        Mobile="9010801292"
        Co_ordinator="2A"
        Class_teacher="English"
      />
    </>
  );
};

export default TeacherTablePropsSchool;
