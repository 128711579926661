import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import "../MasterData/MasterData.css"; //also some css from usecaseSecond.css
import StudentInfo from "./StudentInfo";
import StudentList from "./StudentList";
import { Link } from "react-router-dom";
import { IP } from "../../../internalServer";
import axios from "../../../axios";
import UploadPopupScreen from "../Class/UploadPopupScreen";
import LogOutButton from "../../UseCaseFirstTime/LogOutButton";
import { useSchoolContext } from "../../../schoolContext";
import ErrorRowPopup from "../../../ErrorRow";

export default function AddStudent() {
  const [addStudentComp, setAddStudentComp] = useState({
    Studentinfo: false,
    StudentList: false,
    editableId: null,
    // addTeacher: false,
  });

  const [formRejected, setFormRejected] = useState(false);
  const [uploadClick, setUploadClick] = useState(false);
  const [isUploadSuccess, setIsuploadSuccess] = useState(false);
  const [academicSessionSelected, setAcademicSessionSelected] = useState(null);
  const [errorRow, setErrorRow] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [timetableSessionSelected, setTimetableSessionSelected] =
    useState(null);
  const validateFileType = (filePath) => {
    var allowedExtensions = /(\.xls|\.xlsx)$/i;

    if (!allowedExtensions.exec(filePath.name)) {
      return false;
    }
    return true;
  };
  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }
  const [accYear, setAccYear] = useState(null);
  const handlefetchAcademicYear = async () => {
    const res = await axios.get(
      `/api/v1/school/academicYear/get/school/${schoolId}`
    );
    setAccYear(res.data.data.id);
  };
  useEffect(() => {
    handlefetchAcademicYear();
  }, []);
  const handleUploadData = async (file) => {
    let document;
    // let doc = e.target.files[0];
    let doc = file;
    if (doc.size > 10 * 1024 * 1024 || !validateFileType(doc)) {
      window.alert(
        "The File size should be Less than 10 MB and File type must be EXCEL"
      );
      setFormRejected(true);

      return;
    }
    if (doc) {
      const formData = new FormData();
      formData.append("name", doc.name);
      formData.append("uploadStudent", doc);
      try {
        const res = await axios.post(
          `/api/v1/school/student/upload?schoolId=${schoolId}&academicSessionId=${academicSessionSelected}&timetableSessionId=${timetableSessionSelected}&academicYearId=${accYear}`,
          formData
        );
        document = res.data.data;
        setErrorRow(document);
        document && setIsuploadSuccess(true);

        document &&
          setAddStudentComp({
            editableId: null,

            StudentList: true,
          });
      } catch (error) {
        if (error?.response?.data?.message === "Duplicate employeeid") {
          setErrorMessage(
            `Duplicate StudentIds:${error?.response?.data?.data}`
          );
        }
        console.log(error);
        setFormRejected(true);
      }
    }
  };

  const handleVerifyFile = async (file) => {
    try {
      let doc = file;
      let document;
      if (doc) {
        const formData = new FormData();
        formData.append("name", doc.name);
        formData.append("uploadStudent", doc);
        const res = await axios.post(
          `/api/v1/school/student/upload/verifyFields`,
          formData
        );
        document = res.data.data;
        !document && setFormRejected(true);
      }
    } catch (error) {
      console.log(error);
      setFormRejected(true);
    }
  };

  const handleDownload = () => {
    const downloadUrl = `${IP}/public/upload/school/template/StudentInfo/Template.xlsx`;
    const downloadAnchor = document.createElement("a");
    downloadAnchor.style.display = "none";
    document.body.appendChild(downloadAnchor);
    downloadAnchor.href = downloadUrl;
    downloadAnchor.setAttribute("download", "StudentInfo_template.xlsx");
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  };

  return (
    <>
      {/* {!addclassComp.addTeacher &&
                ( */}
      <div className="Total_container_BasicInfo">
        <div className="d-flex justify-content-between align-items-center">
          <img
            className="SchoolIcon_basicInfo mb-2"
            src="/OnBoard/SchoolIcon.png"
            alt="banner img"
          />
          <LogOutButton />
        </div>
        <div className="steps mt-4">Steps 5/5</div>
        <div className="d-flex flex-wrap justify-content-between">
          <div>
            <div className="d-flex  mt-4">
              <span className="d-flex  justify-content-center">
                <Link to="/BasicInformation" className="Completed_circle">
                  <span className=""></span>
                </Link>
              </span>
              <span className="CompletedDiv"></span>

              <div className="d-flex justify-content-center">
                <Link to="/AddMasterData" className="Completed_circle">
                  <div className=""></div>
                </Link>
              </div>
              {/* <span className="CompletedDiv"></span>

                <div className="d-flex justify-content-center">
                  <Link to="/Permision" className="Completed_circle">
                    <div className=""></div>
                  </Link>
                </div> */}
              <span className="CompletedDiv"></span>

              <div className="d-flex justify-content-center">
                <Link to="/AddClass" className="Completed_circle">
                  <div className=""></div>
                </Link>
              </div>
              <span className="CompletedDiv"></span>

              <div className="d-flex justify-content-center">
                <Link to="/AddTeacher" className="Completed_circle">
                  <div className=""></div>
                </Link>
              </div>
              <span className="CompletedDiv"></span>

              <div className="d-flex justify-content-center">
                <div className="Active_circle"></div>
              </div>
              {/* <span className='innerDiv'></span>

                            <div className='d-flex justify-content-center '>
                                <div className='NonActive_circle'></div>
                            </div>
                            <span className='innerDiv'></span>

                            <div className='d-flex justify-content-center '>
                                <div className='NonActive_circle'></div>
                            </div> */}
            </div>

            <div className="d-flex">
              <span className="d-flex justify-content-center">
                <span className="Active_circle_name ">Basic</span>
              </span>

              <span className="innerDiv_name"></span>
              <div className="d-flex justify-content-center">
                <div className="Active_circle_name">Master Data</div>
              </div>
              {/* <span className='innerDiv_name'></span>
                            <div className='d-flex justify-content-center'>
                                <div className='Active_circle_name' >Permission</div>
                            </div> */}
              <span className="innerDiv_name"></span>
              <div className="d-flex justify-content-center">
                <div className="Active_circle_name">Class</div>
              </div>
              <span className="innerDiv_name"></span>
              <div className="d-flex justify-content-center">
                <div className=" Active_circle_name ml-2 pl-3">Teachers</div>
              </div>
              <span className="innerDiv_name"></span>
              <div className="d-flex justify-content-center">
                <div className="Active_circle_name ml-4">Students</div>
              </div>
              {/* <span className='innerDiv_name'></span>
                            <div className='d-flex justify-content-center'>
                                <div className='ml-2' >Engagement</div>
                            </div>

                            <span className='innerDiv_name'></span>
                            <div className='d-flex justify-content-center'>
                                <div className='' >Query</div>
                            </div> */}
            </div>
          </div>
          {addStudentComp.StudentList && (
            <div className="d-flex align-items-center">
              <button
                className="addClassBlueBtn"
                onClick={(e) =>
                  setAddStudentComp({
                    ...addStudentComp,
                    editableId: null,

                    Studentinfo: true,
                    StudentList: false,
                  })
                }
              >
                <BsPlusCircle />
                &nbsp; Add Student
              </button>
            </div>
          )}
        </div>

        {!addStudentComp.StudentList && (
          <>
            {!addStudentComp.Studentinfo && (
              <>
                <div className="AddData_container">
                  <div>
                    <div className="addMasterData_heading mb-2">
                      Add student information
                    </div>
                    <p className="heading_belowText">
                      Add class information with either a pre-filled template
                      file or add individually.
                    </p>

                    <div className="d-flex flex-wrap gap-4 justify-content-center ">
                      <button
                        id="NextBtn_school_masterData"
                        type="submit"
                        onClick={(e) =>
                          setAddStudentComp({
                            editableId: null,

                            ...addStudentComp,
                            Studentinfo: true,
                          })
                        }
                      >
                        Feed Manually
                      </button>
                      {/* <div className="uploadFileBtn_MasterData">
                        <input
                          type="file"
                          id="attachFile"
                          onChange={handleUploadData}
                          style={{ display: "none" }}
                        />
                        <label htmlFor="attachFile">
                          <span>
                            <BsPlusCircle /> &nbsp;Upload file
                          </span>
                        </label>
                      </div> */}
                      <div
                        className="uploadFileBtn_MasterData"
                        onClick={() => setUploadClick(true)}
                      >
                        {/* <label htmlFor="attachFile"> */}
                        <span style={{ marginBottom: "10px" }}>
                          <BsPlusCircle /> &nbsp;Upload file
                        </span>
                        {/* </label> */}
                      </div>
                    </div>
                    {formRejected && (
                      <span className="errorMsgUpload mt-2">
                        Data did not get uploaded.
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
                  <div>
                    <span className="footer_text1">
                      For bulk upload please use this template
                    </span>
                    <span className="footer_text2">Download template</span>
                  </div>

                  <div className="d-flex flex-wrap gap-3">
                    <div className="uploadFileBtn_MasterData">
                      <input
                        type="file"
                        id="attachFile"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="attachFile">
                        <span>
                          <BsPlusCircle /> &nbsp;Upload file
                        </span>
                      </label>
                    </div>
                    <button id="NextBtn_school_masterData" type="submit">
                      Next
                    </button>
                  </div>
                </div> */}
                <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container template">
                  <div>
                    <span className="footer_text1">
                      For bulk upload please use this template
                    </span>
                    <span className="footer_text2" onClick={handleDownload}>
                      Download template
                    </span>
                  </div>
                </div>
              </>
            )}

            {addStudentComp.Studentinfo && (
              <StudentInfo
                setAddStudentComp={setAddStudentComp}
                editId={addStudentComp.editableId}
              />
            )}
          </>
        )}
        {uploadClick && (
          <UploadPopupScreen
            handleUploadData={handleUploadData}
            setUploadClick={setUploadClick}
            formRejected={formRejected}
            setFormRejected={setFormRejected}
            isUploadSuccess={isUploadSuccess}
            handleVerifyFile={handleVerifyFile}
            academicSessionSelected={academicSessionSelected}
            timetableSessionSelected={timetableSessionSelected}
            setAcademicSessionSelected={setAcademicSessionSelected}
            setTimetableSessionSelected={setTimetableSessionSelected}
            errorMessage={errorMessage}
          />
        )}
        {addStudentComp.StudentList && (
          <StudentList setAddStudentComp={setAddStudentComp} />
        )}
      </div>
      {/* )
         }
             {addclassComp.addTeacher && <AddTeacher />} */}
      {errorRow.length > 0 && (
        <ErrorRowPopup
          closePopup={() => {
            setErrorRow([]);
          }}
          errorRow={errorRow}
        />
      )}
    </>
  );
}
