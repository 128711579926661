import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useSchoolContext } from "../../../schoolContext";
import axios from "../../../axios";
import { Link } from "react-router-dom";
import ErrorPopup from "../../../ErrorPopup";

export default function FivePart({ closeAllPrev }) {
  const [items, setItems] = useState({
    Domain: [],
    Skills: [],
    Types: [],
    HWTypes: [],
    PreReadTypes: [],
    // Types2: [],
  });

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  const [fieldsSchoolB, setFieldsSchoolB] = useState([
    { id: 1, floor: "", numOfRooms: "", roomTypes: "" },
  ]);

  const isFieldSchoolB = fieldsSchoolB.some(
    (field) => !field.floor || !field.roomTypes
  );

  const handleAddFieldScoolBlock = () => {
    const newFields = [...fieldsSchoolB];
    newFields.push({
      id: fieldsSchoolB.length + 1,
      floor: "",
      numOfRooms: "",
      roomTypes: "",
    });
    setFieldsSchoolB(newFields);
  };

  const handleRemoveFieldSchoolBlock = async (field, id) => {
    if (field.QTId) {
      await axios.delete(`/api/v1/questionType/delete/${field.QTId}`);
    }
    const newFields = fieldsSchoolB.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsSchoolB(renumberedFields);
  };

  const handleInputSchoolBlock = (id, name, value) => {
    const newFields = [...fieldsSchoolB];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    setFieldsSchoolB(newFields);
  };

  const addItem = async (property, value) => {
    let createdItemId;

    if (property === "Domain") {
      const url = "/api/v1/school/domain/create";
      const body = {
        schoolId,
        name: value,
      };
      const response = await axios.post(url, body);
      createdItemId = response.data.data.id;
    } else if (property === "Skills") {
      const url = "/api/v1/school/skill/create";
      const body = {
        schoolId,
        name: value,
      };
      const response = await axios.post(url, body);
      createdItemId = response.data.data.id;
    } else if (property === "Types") {
      const url = "/api/v1/assessment/assessmentType/create";
      const body = {
        schoolId,
        name: value,
      };
      const response = await axios.post(url, body);
      createdItemId = response.data.data.id;
    } else if (property === "HWTypes") {
      const url = "/api/v1/homework/homeworkType/create";
      const body = {
        schoolId,
        name: value,
      };
      const response = await axios.post(url, body);
      createdItemId = response.data.data.id;
    } else if (property === "PreReadTypes") {
      const url = "/api/v1/homework/preReadType/create";
      const body = {
        schoolId,
        name: value,
        display_name: value,
      };
      const response = await axios.post(url, body);
      createdItemId = response.data.data.id;
    }
    setItems((prevItems) => ({
      ...prevItems,
      [property]: [...prevItems[property], { name: value, id: createdItemId }],
    }));
  };

  const deleteItem = async (property, index, id) => {
    if (property === "Domain") {
      const url = `/api/v1/school/domain/delete/${id}`;
      await axios.delete(url);
    } else if (property === "Skills") {
      const url = `/api/v1/school/skill/delete/${id}`;
      await axios.delete(url);
    } else if (property === "Types") {
      const url = `/api/v1/assessment/assessmentType/delete/${id}`;
      await axios.delete(url);
    } else if (property === "HWTypes") {
      const url = `/api/v1/homework/homeworkType/delete/${id}`;
      await axios.delete(url);
    } else if (property === "PreReadTypes") {
      const url = `/api/v1/homework/preReadType/delete/${id}`;
      await axios.delete(url);
    }
    setItems((prevItems) => ({
      ...prevItems,
      [property]: prevItems[property].filter((_, i) => i !== index),
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Education domains
        const domainResponse = await axios.get(
          `/api/v1/school/domain/get/school/${schoolId}`
        );
        const domainData = domainResponse.data.data.map((domain) => ({
          name: domain.name,
          id: domain.id,
        }));

        const skillResponse = await axios.get(
          `/api/v1/school/skill/get/school/${schoolId}`
        );
        const skillData = skillResponse.data.data.map((skill) => ({
          name: skill.name,
          id: skill.id,
        }));

        const ATResponse = await axios.get(
          `/api/v1/assessment/assessmentType/get/school/${schoolId}`
        );
        const ATData = ATResponse.data.data.map((AT) => ({
          name: AT.name,
          id: AT.id,
        }));

        const HWTResponse = await axios.get(
          `/api/v1/homework/homeworkType/get/school/${schoolId}`
        );
        const HWTData = HWTResponse.data.data.map((AT) => ({
          name: AT.name,
          id: AT.id,
        }));

        const PRTResponse = await axios.get(
          `/api/v1/homework/preReadType/getBySchool/${schoolId}`
        );
        const PRTData = PRTResponse.data.data.map((AT) => ({
          name: AT.name,
          id: AT.id,
        }));

        setItems({
          ...items,
          Domain: domainData,
          Skills: skillData,
          Types: ATData,
          HWTypes: HWTData,
          PreReadTypes: PRTData,
        });
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [schoolId]);

  const handleAdd = (property, value) => {
    const alphanumericWithSpaceRegex = /^[a-zA-Z0-9\s]+$/;

    if (alphanumericWithSpaceRegex.test(value)) {
      addItem(property, value);
      document.getElementById(property).value = "";
    } else {
      alert(
        "Invalid characters. Please enter alphanumeric characters and spaces only."
      );
    }
  };

  const handleAddItem = (property, event) => {
    if (event.key === "Enter" || event.type === "click") {
      const inputElement = document.getElementById(property);
      const value = inputElement.value.trim();
      handleAdd(property, value);
    }
  };

  const isBtnValid =
    // items.Domain.length === 0 ||
    // items.Skills.length === 0 ||
    items.Types.length === 0 || items.PreReadTypes.length === 0;

  useEffect(() => {
    const fetchQuestionTypeData = async () => {
      try {
        const response = await axios.get(
          `/api/v1/questionType/get/school/${schoolId}`
        );
        const createdSessions = response.data.data.map(
          (createdSession, index) => ({
            id: index + 1,
            QTId: createdSession.id,
            floor: createdSession.name,
            numOfRooms: createdSession.max_limit,
            roomTypes: createdSession.type,
          })
        );
        if (response.data.data.length > 0) {
          setFieldsSchoolB(createdSessions);
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching data:", error);
      }
    };

    fetchQuestionTypeData();
  }, [schoolId]);

  const createQT = async () => {
    const url = "/api/v1/questionType/create";
    try {
      for (const QT of fieldsSchoolB) {
        console.log(QT.roomTypes === "descriptive");
        const requestBody = {
          name: QT.floor,
          display_name: QT.floor,
          is_manually_graded: QT.roomTypes === "descriptive",
          schoolId: schoolId,
          max_limit: QT.numOfRooms,
          type: QT.roomTypes,
        };

        if (QT.QTId) {
          const updateUrl = `/api/v1/questionType/update/${QT.QTId}`;
          const requestBodyUpdate = {
            ...requestBody,
            id: QT.QTId,
          };

          await axios.put(updateUrl, requestBodyUpdate);
        } else {
          await axios.post(url, requestBody);
        }
      }
    } catch (error) {
      console.error("Error creating :", error);
      handleError(error.response.data.message);
      throw error;
    }
  };

  // close  master all comp
  const handleClick = () => {
    try {
      createQT();
      closeAllPrev(true);
    } catch (error) {
      handleError(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="infra_info">Performance Evaluation Information</div>

      {/* <div className="subHeading_part5 mt-4">
        Update Supported Knowledge Domain
      </div>
      <div className="part5_container mt-2 p-4">
        <div className="d-flex justify-content-between  align-items-center containerInsideBorder">
          <input id="Domain" className="insides_inputs" maxLength={50} />
          <div>
            <button
              className="ThirdPartAddBtn"
              onClick={() => handleAdd("Domain")}
            >
              <BsPlusCircle />
              &nbsp; Add Domains
            </button>
          </div>
        </div>
        {items.Domain.length === 0 && (
          <div className="addField_belowtext">
            Type in the box and click on ‘Add Domains’ to create database.
          </div>
        )}
        <div className="d-flex gap-2 flex-wrap add_sujectContainer">
          {items.Domain.map((item, index) => (
            <div className="Subjects_part3" key={index}>
              {item.name}
              <img
                onClick={() => deleteItem("Domain", index, item.id)}
                id="cross_inputs"
                src="/OnBoard/CrossIcon.png"
                alt="cross icon"
              />
            </div>
          ))}
        </div>
      </div> */}

      {/* <div className="subHeading_part5 mt-4">Update Supported Skills</div>
      <div className="part5_container mt-2 p-4">
        <div className="d-flex justify-content-between  align-items-center containerInsideBorder">
          <input id="Skills" className="insides_inputs" maxLength={50} />
          <div>
            <button
              className="ThirdPartAddBtn"
              onClick={() => handleAdd("Skills")}
            >
              <BsPlusCircle />
              &nbsp; Add Skills
            </button>
          </div>
        </div>
        {items.Skills.length === 0 && (
          <div className="addField_belowtext">
            Type in the box and click on 'Add Skills' to create database.
          </div>
        )}
        <div className="d-flex gap-2 flex-wrap add_sujectContainer">
          {items.Skills.map((item, index) => (
            <div className="Subjects_part3" key={index}>
              {item.name}
              <img
                onClick={() => deleteItem("Skills", index, item.id)}
                id="cross_inputs"
                src="/OnBoard/CrossIcon.png"
                alt="cross icon"
              />
            </div>
          ))}
        </div>
      </div> */}

      <div className="subHeading_part5 mt-4">
        Update Assessment type <span className="important_input">*</span>
      </div>
      <div className="part5_container mt-2 p-4">
        <div className="d-flex justify-content-between  align-items-center containerInsideBorder">
          <input
            id="Types"
            className="insides_inputs"
            maxLength={50}
            onKeyDown={(event) => handleAddItem("Types", event)}
          />
          <div>
            <button
              className="ThirdPartAddBtn"
              onClick={(event) =>
                handleAddItem("Types", { key: "Enter", type: "click" })
              }
            >
              <BsPlusCircle />
              &nbsp; Add Types
            </button>
          </div>
        </div>

        {items.Types.length === 0 && (
          <div className="addField_belowtext">
            Type in the box and click on 'Add Types' to create database.
          </div>
        )}
        <div className="d-flex gap-2 flex-wrap add_sujectContainer">
          {items.Types.map((item, index) => (
            <div className="Subjects_part3" key={index}>
              {item.name}
              <img
                onClick={() => deleteItem("Types", index, item.id)}
                id="cross_inputs"
                src="/OnBoard/CrossIcon.png"
                alt="cross icon"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="subHeading_part5 mt-4">
        Update Homework type <span className="important_input">*</span>
      </div>
      <div className="part5_container mt-2 p-4">
        <div className="d-flex justify-content-between  align-items-center containerInsideBorder">
          <input
            id="HWTypes"
            className="insides_inputs"
            maxLength={50}
            onKeyDown={(event) => handleAddItem("HWTypes", event)}
          />
          <div>
            <button
              className="ThirdPartAddBtn"
              onClick={(event) =>
                handleAddItem("HWTypes", { key: "Enter", type: "click" })
              }
            >
              <BsPlusCircle />
              &nbsp; Add Types
            </button>
          </div>
        </div>
        {items.Types.length === 0 && (
          <div className="addField_belowtext">
            Type in the box and click on 'Add Types' to create database.
          </div>
        )}
        <div className="d-flex gap-2 flex-wrap add_sujectContainer">
          {items.HWTypes.map((item, index) => (
            <div className="Subjects_part3" key={index}>
              {item.name}
              <img
                onClick={() => deleteItem("HWTypes", index, item.id)}
                id="cross_inputs"
                src="/OnBoard/CrossIcon.png"
                alt="cross icon"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="subHeading_part5 mt-4">
        Update Pre-Read type <span className="important_input">*</span>
      </div>
      <div className="part5_container mt-2 p-4">
        <div className="d-flex justify-content-between  align-items-center containerInsideBorder">
          <input
            id="PreReadTypes"
            className="insides_inputs"
            maxLength={50}
            onKeyDown={(event) => handleAddItem("PreReadTypes", event)}
          />
          <div>
            <button
              className="ThirdPartAddBtn"
              onClick={(event) =>
                handleAddItem("PreReadTypes", { key: "Enter", type: "click" })
              }
            >
              <BsPlusCircle />
              &nbsp; Add Types
            </button>
          </div>
        </div>
        {items.PreReadTypes.length === 0 && (
          <div className="addField_belowtext">
            Type in the box and click on 'Add Types' to create database.
          </div>
        )}
        <div className="d-flex gap-2 flex-wrap add_sujectContainer">
          {items.PreReadTypes.map((item, index) => (
            <div className="Subjects_part3" key={index}>
              {item.name}
              <img
                onClick={() => deleteItem("PreReadTypes", index, item.id)}
                id="cross_inputs"
                src="/OnBoard/CrossIcon.png"
                alt="cross icon"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="subHeading_part5 mt-4">
        Update Question type <span className="important_input">*</span>
      </div>
      {/* <div className="part5_container mt-2 p-4">
        <div className="d-flex justify-content-between  align-items-center containerInsideBorder">
          <input id="Types2" className="insides_inputs" />
          <div>
            <button
              className="ThirdPartAddBtn"
              onClick={() => handleAdd("Types2")}
            >
              <BsPlusCircle />
              &nbsp; Add Types
            </button>
          </div>
        </div>
        {items.Types2.length === 0 && (
          <div className="addField_belowtext">
            Type in the box and click on ‘Add Types to create database.
          </div>
        )}
        <div className="d-flex gap-2 flex-wrap add_sujectContainer">
          {items.Types2.map((item, index) => (
            <div className="Subjects_part3" key={index}>
              {item.name}
              <img
                onClick={() => deleteItem("Types2", index)}
                id="cross_inputs"
                src="/OnBoard/CrossIcon.png"
                alt="cross icon"
              />
            </div>
          ))}
        </div>
      </div> */}
      <div className="part5_container mt-2 p-4">
        {fieldsSchoolB.map((field) => (
          <div
            key={field.id}
            className="d-flex container_schoolBlock_Inputs justify-content-between flex-wrap mt-3"
          >
            {/* <div className="subInputs_container2">
              <select
                className="form-select inputSchool_blocks"
                value={field.floor}
                onChange={(e) =>
                  handleInputSchoolBlock(field.id, "floor", e.target.value)
                }
              >
                <option value="">Select an option</option>
                <option value="Very Short Answer">Very Short Answer</option>
                <option value="Short Answer">Short Answer</option>
                <option value="Long Answer">Long Answer</option>
                <option value="MCQ">MCQ</option>
              </select>
            </div> */}
            
            <div className="subInputs_container2">
              <select
                className="form-select inputSchool_blocks"
                value={field.roomTypes}
                onChange={(e) => {
                  handleInputSchoolBlock(field.id, "roomTypes", e.target.value);
                  field.roomTypes === "multiple choice" &&
                    handleInputSchoolBlock(field.id, "numOfRooms", 0);
                }}
              >
                <option selected value="">
                  Select Type <span className="important_input">*</span>
                </option>
                <option value="descriptive">Descriptive</option>
                <option value="multiple choice">MCQ</option>
              </select>
            </div>

            <div className="subInputs_container2">
              <input
                className="inputSchool_blocks"
                value={field.floor}
                onChange={(e) =>
                  handleInputSchoolBlock(field.id, "floor", e.target.value)
                }
                placeholder="Enter Name *"
              />
            </div>

            <div className="subInputs_container2">
              <input
                className="inputSchool_blocks"
                value={
                  field.roomTypes === "multiple choice" ? 0 : field.numOfRooms
                }
                disabled={field.roomTypes === "multiple choice"}
                onChange={(e) => {
                  const value =
                    field.roomTypes === "multiple choice" ? 0 : e.target.value;
                  const isValidNumber = /^\d+$/.test(value);

                  if (
                    (isValidNumber || value === "") &&
                    (value <= 20000 || value === "")
                  ) {
                    handleInputSchoolBlock(field.id, "numOfRooms", value);
                  }
                }}
                placeholder="Enter Limit *"
              />
            </div>

            {field.id === 1 ? (
              <div className="plusIcon_blocks"></div>
            ) : (
              <div
                className="plusIcon_blocks"
                onClick={() => handleRemoveFieldSchoolBlock(field, field.id)}
              >
                <img src="/OnBoard/Dustbin.png" alt="dustbin Icon" />
              </div>
            )}
          </div>
        ))}
        {!isFieldSchoolB && (
          <div className="total_plusDelete">
            <img
              src="/OnBoard/plusIcon_part1.png"
              alt="plus Icon"
              className="plusDimensions"
              onClick={handleAddFieldScoolBlock}
            ></img>
            <p className="AddRowText">Add Row</p>
          </div>
        )}
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
        <div>
          <span className="footer_text1">
            {/* For bulk upload please use this template */}
          </span>
          {/* <span className="footer_text2">Download template</span> */}
        </div>

        <div className="d-flex flex-wrap gap-3">
          {/* <div className="uploadFileBtn_MasterData">
            <input type="file" id="attachFile" style={{ display: "none" }} />
            <label htmlFor="attachFile">
              <span>
                <BsPlusCircle /> &nbsp;Upload file
              </span>
            </label>
          </div> */}
          <Link to="/AddClass">
            <button
              id="NextBtn_school_masterData"
              onClick={handleClick}
              disabled={isBtnValid}
              type="submit"
            >
              Next
            </button>
          </Link>
        </div>
      </div>
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
    </div>
  );
}
