import React, { useState } from "react";
// import axios from "../../../../../../../axios"
import "./DeletePopup.css";
import axios from "../../../axios";

const DeletePopup = ({ setDeletePopupOpen, ElementId }) => {
  async function deleteFunction() {
    try {
      const res = await axios.delete(
        `/api/v1/school/standardSection/delete/${ElementId}`
      );
      res && setDeletePopupOpen(false);
    } catch (error) {}
  }
  return (
    <div className="published_Deative_transprant_body">
      <div className="published_Deative_preview_container">
        <div className="col-12 deactivate_msg delInfo">Delete Information</div>
        <div className="col-12 deactivate_msg">
          <img
            alt=""
            src="/images/cross-circle3.svg"
            className="crossIconImgQue"
            onClick={(e) => setDeletePopupOpen(false)}
          />
          Are you sure you want to delete this information?{" "}
        </div>
        <div className="row col-12 publish_deactive_btnBox">
          <button
            onClick={() => deleteFunction()}
            className="btn col-sm-5 my-1 deactive_sure_btn buttonHover"
          >
            Yes
          </button>
          <button
            className="btn col-sm-5 my-1 deactive_sure_btn buttonHover"
            onClick={(e) => setDeletePopupOpen(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
