
import React, { useState } from 'react'
import { BsPlusCircle } from 'react-icons/bs';
//also some css from usecaseSecond.css , masterdata.css
import '../../../OnBoarding/UseCaseSecondTime/Class/ClassComp.css'
import Select from 'react-select';
import '../SchoolAdmin.css'


export default function TeacherEditSA({ setTeacherList }) {

    const AsignClassOptions = [
        { value: 'Class 1', label: 'Class 1' },
        { value: 'Class 2', label: 'Class 2' },
        { value: ' Class 3', label: 'Class 3' },
    ]

    const SubjectOptions = [
        { value: 'English', label: 'English' },
        { value: 'Maths', label: 'Maths' },
        { value: 'Science', label: 'Science' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Social Science', label: 'Social Science' },
    ]
    const AsignClassOptionsCheckbox = [
        { value: 'Class 1', label: 'Class 1' },
        { value: 'Class 2', label: 'Class 2' },
        { value: ' Class 3', label: 'Class 3' },
    ]
    const AsignCordintorOptionsCheckbox = [
        { value: 'English', label: 'English' },
        { value: 'Maths', label: 'Maths' },
        { value: 'Science', label: 'Science' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Social Science', label: 'Social Science' },
    ]

    const DropdownStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#99ADBB',
            minHeight: '30px',
            padding: '11px',
            borderRadius: ' 8px',
            boxShadow: state.isFocused ? null : null,
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };


    // code for Academic Session add fields more 
    const [fieldsAddTeacher, setFieldsAddTeacher] = useState([{ id: 1, Fname: '', SchoolId: '', SchoolEmail: '', PersonalEmail: '', Cnumber: '', Bgroup: '', Position: '', EmergencyN: '', EmergencyC: '', JoiningD: '', AssignClass: '', AssignSubjects: '', remarks: '' }]);
    const handleAddFieldAddTeacher = () => {
        const newFields = [...fieldsAddTeacher];
        newFields.push({ id: fieldsAddTeacher.length + 1, Fname: '', SchoolId: '', SchoolEmail: '', PersonalEmail: '', Cnumber: '', Bgroup: '', Position: '', EmergencyN: '', EmergencyC: '', JoiningD: '', AssignClass: '', AssignSubjects: '', remarks: '' });
        setFieldsAddTeacher(newFields);
    };

    const handleRemoveFieldAddTeacher = (id) => {
        const newFields = fieldsAddTeacher.filter((field) => field.id !== id);
        // Re-count the field IDs
        const renumberedFields = newFields.map((field, index) => {
            return {
                ...field,
                id: index + 1
            };
        });
        setFieldsAddTeacher(renumberedFields);
    };
    const handleInputAddTeacher = (id, name, value) => {
        const newFields = [...fieldsAddTeacher];
        const index = newFields.findIndex((field) => field.id === id);
        newFields[index][name] = value;
        setFieldsAddTeacher(newFields);
    };

    const isfieldsAddTeacher = fieldsAddTeacher.some((field) => (
        field.Fname === '' || field.SchoolId === '' || field.SchoolEmail === '' || field.PersonalEmail === '' || field.Cnumber === '' || field.Bgroup === '' || field.Position === '' || field.EmergencyN === '' || field.EmergencyC === '' || field.JoiningD === '' || field.AssignClass === '' || field.AssignSubjects === '' || field.remarks === ''
    ));

    const [isChecked, setIsChecked] = useState({
        classTeacher: false,
        classSubject: false
    });

    return (
        <>


            <div>
                <div className='d-flex gap-4  mt-4'>
                    {fieldsAddTeacher.map((field) => (
                        <div key={field.id} className='classInput_container  mr-2'>
                            <div className='d-flex justify-content-between '>
                                <div className='classinfo_subHeader '>Teacher info- 0{field.id}</div>
                                {field.id !== 1 && <div className='remove' onClick={() => handleRemoveFieldAddTeacher(field.id)}>Remove</div>}
                            </div>
                            <div className='mt-4'>
                                <input className='inputSchool_blocks'
                                    value={field.Fname}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "Fname", e.target.value)}
                                    placeholder='Full Name' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.SchoolId}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "SchoolId", e.target.value)}
                                    placeholder='School assigned ID' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.SchoolEmail}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "SchoolEmail", e.target.value)}
                                    placeholder='School email id' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.PersonalEmail}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "PersonalEmail", e.target.value)}
                                    placeholder='Personal email id' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Cnumber}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "Cnumber", e.target.value)}
                                    placeholder='Contact number' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Bgroup}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "Bgroup", e.target.value)}
                                    placeholder='Blood Group' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.Position}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "Position", e.target.value)}
                                    placeholder='Position/Title' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.EmergencyN}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "EmergencyN", e.target.value)}
                                    placeholder='Emergency Number' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.EmergencyC}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "EmergencyC", e.target.value)}
                                    placeholder='Emergency Contact' />
                            </div>
                            <div className='mt-3'>
                                <input className='inputSchool_blocks'
                                    value={field.JoiningD}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "JoiningD", e.target.value)}
                                    placeholder='Joining Date ' />
                            </div>
                            <div className='mt-3'>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={AsignClassOptions}
                                    styles={DropdownStyle}
                                    placeholder='Assign class'
                                    value={field.AssignClass} // add value prop and set it based on the state
                                    onChange={(selectedOptions) => {
                                        handleInputAddTeacher(field.id, 'AssignClass', selectedOptions);
                                    }} // update the state based on the selected options
                                />
                            </div>
                            <div className='mt-3'>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={SubjectOptions}
                                    styles={DropdownStyle}
                                    placeholder='Assign subject'
                                    value={field.AssignSubjects} // add value prop and set it based on the state
                                    onChange={(selectedOptions) => {
                                        handleInputAddTeacher(field.id, 'AssignSubjects', selectedOptions);
                                    }} // update the state based on the selected options
                                />
                            </div>
                            <div className='mt-3'>
                                <textarea className='AddRemark'
                                    value={field.remarks}
                                    onChange={(e) =>
                                        handleInputAddTeacher(field.id, "remarks", e.target.value)}
                                    placeholder='Add remarks' />
                            </div>
                            <div className='d-flex justify-content-between mt-3'>
                                <div className='text_switchCheckbox'>Assign class teacher</div>
                                <div class="form-switch">
                                    <input class="form-check-input"
                                        checked={isChecked.classTeacher}
                                        onChange={(event) => setIsChecked({
                                            ...isChecked,
                                            classTeacher: event.target.checked
                                        })}
                                        type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                </div>
                            </div>
                            {isChecked.classTeacher && <div className='mt-3'>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={AsignClassOptionsCheckbox}
                                    styles={DropdownStyle}
                                    placeholder='Class'
                                    value={field.CheckBoxTeacher} // add value prop and set it based on the state
                                    onChange={(selectedOptions) => {
                                        handleInputAddTeacher(field.id, 'CheckBoxTeacher', selectedOptions);
                                    }} // update the state based on the selected options
                                />
                            </div>}

                            <div className='d-flex justify-content-between mt-3 mb-3'>
                                <div className='text_switchCheckbox'>Assign as co-ordinatorr</div>
                                <div class="form-switch">
                                    <input class="form-check-input"
                                        checked={isChecked.classSubject}
                                        onChange={(event) => setIsChecked({
                                            ...isChecked,
                                            classSubject: event.target.checked
                                        })}
                                        type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                </div>
                            </div>
                            {isChecked.classSubject && <div className='mt-3'>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={AsignCordintorOptionsCheckbox}
                                    styles={DropdownStyle}
                                    placeholder='Class'
                                    value={field.CheckBoxCordinator} // add value prop and set it based on the state
                                    onChange={(selectedOptions) => {
                                        handleInputAddTeacher(field.id, 'CheckBoxCordinator', selectedOptions);
                                    }} // update the state based on the selected options
                                />
                            </div>}
                        </div>
                    ))}

                    <div className='addClassContainer '>
                        <button onClick={handleAddFieldAddTeacher} className='AddClassBtn'><BsPlusCircle /> Add Class</button>
                    </div>
                </div>
            </div>






            <div className='d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container'>
                <div>
                    <span className='footer_text1'>For bulk upload please use this template</span>
                    <span className='footer_text2'>Download template</span>
                </div>

                <div className='d-flex flex-wrap gap-3'>
                    <div className='uploadFileBtn_MasterData'>
                        <input type="file" id="attachFile" style={{ display: "none" }} />
                        <label htmlFor="attachFile">
                            <span><BsPlusCircle /> &nbsp;Upload file</span>
                        </label>
                    </div>
                    <button id='NextBtn_school_masterData'
                        onClick={(e) => setTeacherList({
                            editTeacher: false,
                            historyBtn:'classInfo'

                        })}
                        disabled={isfieldsAddTeacher}
                        type="submit" >Next</button>
                </div>
            </div>


        </>
    )
}
