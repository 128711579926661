import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import { useSchoolContext } from "../../../schoolContext";
import axios from "../../../axios";
import DeletePopupTeacher from "./DeletePopupTeacher";
import ActivePopupteacher from "./ActivePopupteacher";
import { Link } from "react-router-dom";
import { IP } from "../../../internalServer";
import ErrorPopup from "../../../ErrorPopup";

export default function TeacherList({ setAddTeacherComp }) {
  const [classList, setClassList] = useState({
    historyBtn: "classInfo",
  });
  const [teacherData, setTeacherData] = useState([]);
  const [islistUpdated, setIslistUpdated] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const [ElementId, setElementId] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  const handleDeleteClick = (id) => {
    console.log(id);
    setDeletePopupOpen(true);
    setElementId(id);
    setIslistUpdated(true);
  };

  const handleInActiveClick = (item) => {
    setisActive(true);
    setElementId(item);
    setIslistUpdated(true);
  };

  useEffect(() => {
    async function fetchteacherList() {
      const sortQueryParam = (() => {
        switch (selectedOption) {
          case "LastModified":
            return "Modified";
          case "InactiveState":
            return "InActive";
          case "ActiveState":
            return "Active";
          case "Alphabetically":
            return "Alphabetically";
          default:
            return "";
        }
      })();
      const res = await axios.get(
        `/api/v1/school/teacher/get/school/filter/${schoolId}?sort=${sortQueryParam}`
      );
      const fetchedData = res.data.data;
      setTeacherData(fetchedData);
    }
    fetchteacherList();
  }, [
    schoolId,
    islistUpdated,
    selectedOption,
    ElementId,
    isDeletePopupOpen,
    isActive,
  ]);

  useEffect(() => {
    async function fetchHistory() {
      try {
        const res = await axios.get(
          `/api/v1/school/teacher/getUploadHistory/school/${schoolId}`
        );
        res.data.data && setHistoryData(res.data.data);
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchHistory();
  }, [schoolId]);

  const handleDownload = (path) => {
    const downloadUrl = `${IP}${path.substring(path.indexOf("/"))}`;
    const downloadAnchor = document.createElement("a");
    downloadAnchor.style.display = "none";
    document.body.appendChild(downloadAnchor);
    downloadAnchor.href = downloadUrl;
    downloadAnchor.setAttribute("download", "Timetable_template.xlsx");
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  };

  return (
    <div>
      <div className="schoolInfo_head">Teacher infomation </div>

      <div className="heading_belowText"></div>

      <div className="d-flex justify-content-between">
        <div className="mt-4 ">
          <button
            onClick={(e) =>
              setClassList({ ...classList, historyBtn: "classInfo" })
            }
            className={` ${
              classList.historyBtn === "classInfo"
                ? "active classInfoBtn"
                : "uploadHistoryBtn"
            }`}
          >
            Teacher information
          </button>
          <button
            onClick={(e) =>
              setClassList({ ...classList, historyBtn: "HistoryBtn" })
            }
            className={` ${
              classList.historyBtn === "HistoryBtn"
                ? "active classInfoBtn"
                : "uploadHistoryBtn"
            }`}
          >
            Upload history
          </button>
        </div>
        <Popup
          on="click"
          closeOnDocumentClick={true}
          trigger={
            // <div>
            //   <span className="sortByText">Sort by</span> &nbsp;
            //   <img src="/OnBoard/DownWardArrow.png" alt="down arrow" />
            // </div>
            <div className=" filter_button filterIcon">
              <img src="/images/filter_icon.svg" alt="" />
              <span>Filter</span>
            </div>
          }
          content={
            <div className="container_threedot">
              <div
                className={`PopupThreedots ${
                  selectedOption === "All" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("All")}
              >
                All
              </div>
              <div
                className={`PopupThreedots ${
                  selectedOption === "LastModified" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("LastModified")}
              >
                Sort by Last Modified
              </div>
              <div
                className={`PopupThreedots ${
                  selectedOption === "ActiveState" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("ActiveState")}
              >
                Active State
              </div>
              <div
                className={`PopupThreedots ${
                  selectedOption === "Alphabetically" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("Alphabetically")}
              >
                Sort by Alphabetically
              </div>

              <div
                className={`PopupThreedots ${
                  selectedOption === "InactiveState" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("InactiveState")}
              >
                In-Active State
              </div>
            </div>
          }
          position="bottom center"
        />
      </div>

      {classList.historyBtn === "classInfo" && (
        <div className="mt-4">
          <table class="table table-borderless">
            <thead>
              <tr className="Heading_tableContainer">
                <th className="Name">Name</th>
                <th>Employee ID</th>
                <th>Academic Session</th>
                <th>Timetable Session</th>
                <th>Personal email</th>
                <th>Mobile</th>
                <th>Co-ordinator</th>
                <th>Class Teacher</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {teacherData.length > 0 &&
                teacherData.map((teacher) => (
                  <tr
                    style={{
                      ...(teacher?.active === false && {
                        backgroundColor: "rgba(225, 29, 52, 0.2)",
                        padding: "10px",
                        paddingRight: "0px",
                        borderRadius: "8px",
                      }),
                    }}
                  >
                    <td className="TeacherTableDetails">
                      <img
                        src={
                          teacher.profile_picture
                            ? `${
                                IP + teacher.profile_picture?.replace(/^\./, "")
                              }`
                            : "/OnBoard/TeacherTableImg.png"
                        }
                        className="imagesSize"
                        alt="profile img"
                      />{" "}
                      &nbsp;
                      <span>{teacher?.name}</span>
                    </td>
                    <td className="TeacherTableDetails">
                      {teacher.employee_id}
                    </td>
                    <td className="TeacherTableDetails">
                      {teacher.academicYearSession?.name}
                    </td>
                    <td className="TeacherTableDetails">
                      {teacher?.timetable_session?.name}
                    </td>
                    <td className="TeacherTableDetails">
                      {teacher.personal_email}
                    </td>
                    <td className="TeacherTableDetails">
                      {teacher.mobile_number}
                    </td>
                    <td
                      className="TeacherTableDetails"
                      title={
                        teacher.role_as_co_ordinator.length === 0
                          ? "N/A"
                          : teacher.role_as_co_ordinator
                              .map(
                                (element) =>
                                  "Class " +
                                  element.standard_id.name +
                                  " " +
                                  element.section_id.name
                              )
                              .join(", ")
                      }
                    >
                      {teacher.role_as_co_ordinator.length === 0
                        ? "N/A"
                        : teacher.role_as_co_ordinator
                            .map((element, index) =>
                              index < 2
                                ? "Class " +
                                  element.standard_id.name +
                                  " " +
                                  element.section_id.name
                                : null
                            )
                            .filter((item) => item !== null)
                            .join(", ") +
                          (teacher.role_as_co_ordinator.length > 2
                            ? ` +${teacher.role_as_co_ordinator.length - 2}`
                            : "")}
                    </td>

                    <td className="TeacherTableDetails">
                      {/* <div className="subjectListContainer d-flex justify-content-center">
                      English
                    </div> */}
                      {teacher.role_as_class_teacher.length === 0
                        ? "N/A"
                        : teacher.role_as_class_teacher
                            .map(
                              (element) =>
                                "Class " +
                                element.standard_id.name +
                                " " +
                                element.section_id.name
                            )
                            .join(", ")}
                    </td>
                    <td>
                      <Popup
                        on="click"
                        trigger={
                          <img
                            style={{ cursor: "pointer" }}
                            src="/OnBoard/HorizontalThreedot.png"
                            alt="three dot"
                          />
                        }
                        content={
                          <div className="container_threedot">
                            {teacher?.active && (
                              <div
                                className="PopupThreedots"
                                onClick={() => {
                                  setAddTeacherComp((prev) => ({
                                    ...prev,
                                    editableId: teacher.id,

                                    teacherInfo: true,
                                    teacherList: false,
                                  }));
                                }}
                              >
                                Edit
                              </div>
                            )}
                            <div
                              className="PopupThreedots"
                              onClick={() => handleDeleteClick(teacher.id)}
                            >
                              Delete
                            </div>
                            <div
                              className="PopupThreedots"
                              onClick={() => handleInActiveClick(teacher)}
                            >
                              {teacher?.active === false
                                ? "Activate"
                                : "In-Active"}
                            </div>
                          </div>
                        }
                        position="bottom center"
                      />
                    </td>
                  </tr>
                ))}
              {/* <tr>
              <td className="TeacherTableDetails">
                <img src="/OnBoard/TeacherTableImg.png" alt="profile img" />{" "}
                &nbsp;
                <span>Dilip Kumar</span>
              </td>
              <td className="TeacherTableDetails">
                dilip@kend_vidyala-teacher.com
              </td>
              <td className="TeacherTableDetails">dilip_kumar@gmail.com</td>
              <td className="TeacherTableDetails">98121 11921</td>
              <td className="TeacherTableDetails">NA</td>
              <td className="d-flex gap-1">
                <div className="subjectListContainer d-flex justify-content-center">
                  English
                </div>
                <div className="subjectListContainer d-flex justify-content-center">
                  Math
                </div>
              </td>
              <td>
                <Popup
                  on="click"
                  trigger={
                    <img
                      style={{ cursor: "pointer" }}
                      src="/OnBoard/HorizontalThreedot.png"
                      alt="three dot"
                    />
                  }
                  content={
                    <div className="container_threedot">
                      <div className="PopupThreedots">Edit</div>
                      <div className="PopupThreedots">Delete</div>
                      <div className="PopupThreedots">In-Active</div>
                    </div>
                  }
                  position="bottom center"
                />
              </td>
            </tr>
            <tr>
              <td className="TeacherTableDetails">
                <img src="/OnBoard/TeacherTableImg.png" alt="profile img" />{" "}
                &nbsp;
                <span>Dilip Kumar</span>
              </td>
              <td className="TeacherTableDetails">
                dilip@kend_vidyala-teacher.com
              </td>
              <td className="TeacherTableDetails">dilip_kumar@gmail.com</td>
              <td className="TeacherTableDetails">98121 11921</td>
              <td className="TeacherTableDetails">NA</td>
              <td className="d-flex gap-1">
                <div className="subjectListContainer d-flex justify-content-center">
                  English
                </div>
                <div className="subjectListContainer d-flex justify-content-center">
                  Math
                </div>
              </td>
              <td>
                <Popup
                  on="click"
                  trigger={
                    <img
                      style={{ cursor: "pointer" }}
                      src="/OnBoard/HorizontalThreedot.png"
                      alt="three dot"
                    />
                  }
                  content={
                    <div className="container_threedot">
                      <div className="PopupThreedots">Edit</div>
                      <div className="PopupThreedots">Delete</div>
                      <div className="PopupThreedots">In-Active</div>
                    </div>
                  }
                  position="bottom center"
                />
              </td>
            </tr>
            <tr>
              <td className="TeacherTableDetails">
                <img src="/OnBoard/TeacherTableImg.png" alt="profile img" />{" "}
                &nbsp;
                <span>Dilip Kumar</span>
              </td>
              <td className="TeacherTableDetails">
                dilip@kend_vidyala-teacher.com
              </td>
              <td className="TeacherTableDetails">dilip_kumar@gmail.com</td>
              <td className="TeacherTableDetails">98121 11921</td>
              <td className="TeacherTableDetails">NA</td>
              <td className="d-flex gap-1">
                <div className="subjectListContainer d-flex justify-content-center">
                  English
                </div>
                <div className="subjectListContainer d-flex justify-content-center">
                  Math
                </div>
              </td>
              <td>
                <Popup
                  on="click"
                  trigger={
                    <img
                      style={{ cursor: "pointer" }}
                      src="/OnBoard/HorizontalThreedot.png"
                      alt="three dot"
                    />
                  }
                  content={
                    <div className="container_threedot">
                      <div className="PopupThreedots">Edit</div>
                      <div className="PopupThreedots">Delete</div>
                      <div className="PopupThreedots">In-Active</div>
                    </div>
                  }
                  position="bottom center"
                />
              </td>
            </tr> */}
            </tbody>
          </table>
        </div>
      )}

      {classList.historyBtn === "HistoryBtn" && (
        <div className="mt-4">
          <table class="table table-borderless">
            <thead>
              <tr className="Heading_tableContainer">
                <th className="Name">Document Name</th>
                <th>Date</th>
                <th>Total Teachers</th>
                <th>Accepted</th>
                <th>Rejected</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {historyData.length > 0 &&
                historyData.map((data, index) => (
                  <tr key={index}>
                    <td className="TeacherTableDetails">
                      {data.document_name}
                    </td>
                    <td className="TeacherTableDetails">
                      {new Date(data.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </td>
                    <td className="TeacherTableDetails">{data.total}</td>
                    <td className="TeacherTableDetails">{data.accepted}</td>
                    <td className="TeacherTableDetails">{data.rejected}</td>
                    <td
                      className="TeacherTableDetails"
                      onClick={() => handleDownload(data.document_url)}
                    >
                      <img src="/images/DownloadButton.svg" alt="xyz"></img>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {isDeletePopupOpen && (
        <DeletePopupTeacher
          setDeletePopupOpen={setDeletePopupOpen}
          deleteElementId={ElementId}
        />
      )}
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
      {isActive && (
        <ActivePopupteacher setisActive={setisActive} ElementId={ElementId} />
      )}
      <div className="d-flex flex-wrap justify-content-end align-items-center mt-4 footer_container">
        <Link to="/AddStudent">
          <button
            id="NextBtn_school_masterData"
            className="NextButtonPosition"
            // onClick={(e) =>
            //   setAddTeacherComp({
            //     addStudent: true,
            //   })
            // }
            type="submit"
          >
            Next
          </button>
        </Link>
      </div>
    </div>
  );
}
