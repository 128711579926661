import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import "../MasterData/MasterData.css"; //also some css from usecaseSecond.css
import AddTeacher from "../Teacher/AddTeacher";
import ClassInfo from "./ClassInfo";
import ClassList from "./ClassList";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import { IP } from "../../../internalServer";
import UploadPopupScreen from "./UploadPopupScreen";
import LogOutButton from "../../UseCaseFirstTime/LogOutButton";
import { useSchoolContext } from "../../../schoolContext";
import ErrorRowPopup from "../../../ErrorRow";

export default function AddClass() {
  const [addclassComp, setAddClassComp] = useState({
    classinfo: false,
    classList: false,
    addTeacher: false,
    editableId: null,
    isLoading: false,
  });
  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }
  const [formRejected, setFormRejected] = useState(false);
  const [uploadClick, setUploadClick] = useState(false);
  const [isUploadSuccess, setIsuploadSuccess] = useState(false);
  const [academicSessionSelected, setAcademicSessionSelected] = useState(null);
  const [errorRow, setErrorRow] = useState([]);
  const [timetableSessionSelected, setTimetableSessionSelected] =
    useState(null);
  const validateFileType = (filePath) => {
    var allowedExtensions = /(\.xls|\.xlsx)$/i;

    if (!allowedExtensions.exec(filePath.name)) {
      return false;
    }
    return true;
  };

  const handleUploadData = async (file) => {
    let document;
    // let doc = e.target.files[0];
    let doc = file;
    if (doc.size > 10 * 1024 * 1024 || !validateFileType(doc)) {
      window.alert(
        "The File size should be Less than 10 MB and File type must be EXCEL"
      );
      setFormRejected(true);
      return;
    }
    if (doc) {
      const formData = new FormData();
      formData.append("name", doc.name);
      formData.append("uploadStandardSection", doc);
      try {
        const res = await axios.post(
          `/api/v1/school/standardSection/upload?schoolId=${schoolId}&academicSessionId=${academicSessionSelected}&timetableSessionId=${timetableSessionSelected}`,
          formData
        );
        document = res.data.data;
        setErrorRow(document);
        document && setIsuploadSuccess(true);

        document &&
          setAddClassComp({
            editableId: null,
            classList: true,
          });
      } catch (error) {
        console.log(error);
        setFormRejected(true);
      }
    }
  };

  const handleVerifyFile = async (file) => {
    try {
      let doc = file;
      let document;
      if (doc) {
        const formData = new FormData();
        formData.append("name", doc.name);
        formData.append("uploadStandardSection", doc);
        const res = await axios.post(
          `/api/v1/school/standardSection/upload/verifyFields`,
          formData
        );
        document = res.data.data;
        !document && setFormRejected(true);
      }
    } catch (error) {
      console.log(error);
      setFormRejected(true);
    }
  };

  const handleDownload = () => {
    const downloadUrl = `${IP}/public/upload/school/template/ClassInfo/Template.xlsx`;
    const downloadAnchor = document.createElement("a");
    downloadAnchor.style.display = "none";
    document.body.appendChild(downloadAnchor);
    downloadAnchor.href = downloadUrl;
    downloadAnchor.setAttribute("download", "ClassInfo_template.xlsx");
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  };

  return (
    <>
      {!addclassComp.addTeacher && (
        <div className="Total_container_BasicInfo">
          <div className="d-flex justify-content-between align-items-center">
            <img
              className="SchoolIcon_basicInfo mb-2"
              src="/OnBoard/SchoolIcon.png"
              alt="banner img"
            />
            <LogOutButton />
          </div>
          <div className="steps mt-4">Steps 3/5</div>
          <div className="d-flex flex-wrap justify-content-between">
            <div>
              <div className="d-flex  mt-4">
                <span className="d-flex  justify-content-center">
                  <Link to="/BasicInformation" className="Completed_circle">
                    <span className=""></span>
                  </Link>
                </span>
                <span className="CompletedDiv"></span>

                <div className="d-flex justify-content-center">
                  <Link to="/AddMasterData" className="Completed_circle">
                    <div className=""></div>
                  </Link>
                </div>
                {/* <span className="CompletedDiv"></span>

                <div className="d-flex justify-content-center">
                  <Link to="/Permision" className="Completed_circle">
                    <div className=""></div>
                  </Link>
                </div> */}
                <span className="CompletedDiv"></span>

                <div className="d-flex justify-content-center">
                  <div className="Active_circle"></div>
                </div>
                <span className="innerDiv"></span>

                <div className="d-flex justify-content-center">
                  <div className="NonActive_circle"></div>
                </div>
                <span className="innerDiv"></span>

                <div className="d-flex justify-content-center">
                  <div className="NonActive_circle"></div>
                </div>
                {/* <span className="innerDiv"></span>

                <div className="d-flex justify-content-center ">
                  <div className="NonActive_circle"></div>
                </div>
                <span className="innerDiv"></span>

                <div className="d-flex justify-content-center ">
                  <div className="NonActive_circle"></div>
                </div> */}
              </div>

              <div className="d-flex">
                <span className="d-flex justify-content-center">
                  <span className="Active_circle_name ">Basic</span>
                </span>

                <span className="innerDiv_name"></span>
                <div className="d-flex justify-content-center">
                  <div className="Active_circle_name">Master Data</div>
                </div>
                {/* <span className="innerDiv_name"></span>
                <div className="d-flex justify-content-center">
                  <div className="Active_circle_name">Permission</div>
                </div> */}
                <span className="innerDiv_name"></span>
                <div className="d-flex justify-content-center">
                  <div className="Active_circle_name">Class</div>
                </div>
                <span className="innerDiv_name"></span>
                <div className="d-flex justify-content-center">
                  <div className="ml-4 pl-3">Teachers</div>
                </div>
                <span className="innerDiv_name"></span>
                <div className="d-flex justify-content-center">
                  <div className="ml-4">Students</div>
                </div>
                {/* <span className="innerDiv_name"></span>
                <div className="d-flex justify-content-center">
                  <div className="ml-3">Engagement</div>
                </div>

                <span className="innerDiv_name"></span>
                <div className="d-flex justify-content-center">
                  <div className="ml-2">Query</div>
                </div> */}
              </div>
            </div>
            {addclassComp.classList && (
              <div className="d-flex align-items-center">
                <button
                  className="addClassBlueBtn"
                  type="button"
                  onClick={() =>
                    setAddClassComp({
                      editableId: null,

                      classinfo: true,
                    })
                  }
                >
                  <BsPlusCircle />
                  &nbsp; Add Class
                </button>
              </div>
            )}
          </div>

          {!addclassComp.classList && (
            <>
              {!addclassComp.classinfo && (
                <>
                  <div className="AddData_container">
                    <div>
                      <div className="addMasterData_heading mb-2">
                        Add class information
                      </div>
                      <p className="heading_belowText">
                        Add class information with either a pre-filled template
                        file or add individually.
                      </p>

                      <div className="d-flex flex-wrap gap-4 justify-content-center ">
                        <button
                          id="NextBtn_school_masterData"
                          type="submit"
                          onClick={(e) =>
                            setAddClassComp({
                              ...addclassComp,
                              editableId: null,
                              classinfo: true,
                            })
                          }
                        >
                          Feed Manually
                        </button>
                        <div
                          className="uploadFileBtn_MasterData"
                          onClick={() => setUploadClick(true)}
                        >
                          {/* <label htmlFor="attachFile"> */}
                          <span style={{ marginBottom: "10px" }}>
                            <BsPlusCircle /> &nbsp;Upload file
                          </span>
                          {/* </label> */}
                        </div>
                      </div>
                      {formRejected && (
                        <span className="errorMsgUpload mt-2">
                          Data did not get uploaded.
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-wrap justify-content-center align-items-center mt-4 footer_container template">
                    <div>
                      <span className="footer_text1">
                        For bulk upload please use this template
                      </span>
                      <span className="footer_text2" onClick={handleDownload}>
                        Download template
                      </span>
                    </div>
                  </div>
                </>
              )}

              {addclassComp.classinfo && (
                <ClassInfo
                  setAddClassComp={setAddClassComp}
                  editId={addclassComp.editableId}
                />
              )}
            </>
          )}
          {addclassComp.classList && (
            <ClassList
              setAddClassComp={setAddClassComp}
              addclassComp={addclassComp}
            />
          )}
          {uploadClick && (
            <UploadPopupScreen
              handleUploadData={handleUploadData}
              setUploadClick={setUploadClick}
              formRejected={formRejected}
              setFormRejected={setFormRejected}
              isUploadSuccess={isUploadSuccess}
              handleVerifyFile={handleVerifyFile}
              academicSessionSelected={academicSessionSelected}
              timetableSessionSelected={timetableSessionSelected}
              setAcademicSessionSelected={setAcademicSessionSelected}
              setTimetableSessionSelected={setTimetableSessionSelected}
            />
          )}
        </div>
      )}
      {addclassComp.addTeacher && <AddTeacher />}
      {errorRow.length > 0 && (
        <ErrorRowPopup
          closePopup={() => {
            setErrorRow([]);
          }}
          errorRow={errorRow}
        />
      )}
    </>
  );
}
