import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import Select from 'react-select';
import { Popup } from "semantic-ui-react";
import DeleteQueryPopup from './DeleteQueryPopup';
// import ResolvedQueryPopup from './ResolvedQueryPopup';

export default function QueryResonse({ setQuery }) {
    const [queryResponse, setQueryResponse] = useState({
        likeCount: 0,
        likedByUser: false,
        deleteQuery: false,
        replayQuery: false,
        editQueryResponse: false,
        deletePopup:false,
        responseMsg: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
    })

    const TeacherOptions = [
        { value: 'Active', label: 'Active' },
        { value: 'Resolved', label: 'Resolved' },
    ];
    const [selectedOption, setSelectedOption] = useState('Active');

    const DropdownStyle = {
        control: (provided, state) => ({
            ...provided,
            background: 'white',
            borderColor: '#668598',
            height: '44px',
            borderRadius: ' 8px',
            fontSize: '14px',
            fontWeight: '600',

            boxShadow: state.isFocused ? null : null,
        }),
        menu: (provided, state) => ({
            ...provided,
            // width: state.selectProps.menuWidth || 'auto', // Set the width to 10px when menuWidth is set
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: ' #F4F8FB;',
            fontSize: '12px',
            color: 'black', // Change the background color when the option is hovered over
            '&:hover': {
                boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
                backgroundColor: ' White;',
                fontSize: '11px',
                color: '#003354',
                borderRadius: '5px',
                fontWeight: '600'

            },
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };



    // fun for like count 
    const handleLikeClick = () => {
        if (!queryResponse.likedByUser) {
            setQueryResponse({
                ...queryResponse,
                likeCount: queryResponse.likeCount + 1,
                likedByUser: true
            });
        } else {
            setQueryResponse({
                ...queryResponse,
                likeCount: queryResponse.likeCount - 1,
                likedByUser: false
            });
        }
    };

    
    return (
        <div className='TotalQuerResponseConatiner'>
            <div className='d-flex justify-content-between flex-wrap' >
                <div className='d-flex gap-3'>
                    <div className='SBoard' onClick={(e) => setQuery({ queryResonse: false, queryBtn: 'all' })}>
                        <IoIosArrowBack />
                    </div>
                    <div className='SBoard' onClick={(e) => setQuery({ queryResonse: false, queryBtn: 'all' })}>Query</div>
                    <div> /</div>
                    <div className='QueryStudentName'>Dilip Kumar</div>
                </div>
                <div className='selectContainerOpenClose'>
                    <Select
                        styles={DropdownStyle}
                        Value={selectedOption}
                        onChange={setSelectedOption}
                        options={TeacherOptions}
                        placeholder="Select Query Type"
                    />

                </div>
            </div>
            <div className='d-flex justify-content-between mt-3 '>
                <div>
                    <div className='d-flex align-items-center gap-2'>
                        {selectedOption.value === 'Resolved' && <img src='/Engagement/QueryResovledTick.png' alt='QueryResovledTick' />}
                        <div className='schoolQuryName'>School Bill</div>
                        <div className='repliesCount'>3 replies</div>
                        <div className='repliesTime'>11:32 AM</div>
                    </div>
                    <div className='QueryType mt-2'>Plesae send the november month school bill</div>
                </div>
                <div className='d-flex gap-3 align-items-center'>
                    <button className='schoolAdminqueryBtn'>School admin</button>
                    <button className='schoolAdminqueryBtn'>School Fees Query</button>
                </div>
            </div>

            <div className="horizontalLine_Container mt-4">
                <span className="horizontalLine_text">3 replies</span>
            </div>

            <div className= {selectedOption.value === 'Resolved' ? ' commentContainerTotal disabled p-1' : ''}>
                <div className={queryResponse.editQueryResponse ? ' QuerySubContainerEdit p-1' : ''} >
                    <div className={queryResponse.deleteQuery ? 'disabled QuerySubContainer p-1' : ''}>
                        <div className='d-flex justify-content-between mt-4 '>
                            <div className='d-flex gap-2 '>
                                <div className='FirstLetter'>J</div>
                                <div className='NameRepliers'>John Deo</div>
                                <div className='repliesTime'>12:12 PM</div>
                            </div>
                            <div className='d-flex align-items-center gap-3'>
                                <div className='d-flex align-items-center'>
                                    <img style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            setQueryResponse({
                                                ...queryResponse,
                                                replayQuery: true
                                            })
                                        }}
                                        src='/Engagement/Replie.png' alt='replie icon' />
                                </div>
                                <span>Reply</span>
                                <div className='d-flex align-items-center'>
                                    <img
                                        onClick={handleLikeClick}
                                        style={{ cursor: 'pointer' }}
                                        src={queryResponse.likedByUser ? '/Engagement/LikeQuery.png' : '/Engagement/Unlike.png'}
                                        alt={queryResponse.likedByUser ? 'Liked icon' : 'Unlike icon'}
                                    />
                                </div>
                                <span >Like</span>
                                <span className='likeCount'>{queryResponse.likeCount}</span>

                                <Popup
                                    on="click"
                                    className="popUpFilter"
                                    trigger={
                                        <div className='d-flex align-items-center '>
                                            <img style={{ cursor: 'pointer' }} src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        </div>
                                    }
                                    content={
                                        <div className='container_threedot'>
                                            <div className='PopupThreedots'
                                                onClick={(e) => {
                                                    setQueryResponse({
                                                        ...queryResponse,
                                                        editQueryResponse: true
                                                    })
                                                }}
                                            >Edit</div>
                                            <div className='PopupThreedots'
                                                onClick={(e) => {
                                                    setQueryResponse({
                                                        ...queryResponse,
                                                        deletePopup: true
                                                    })
                                                }}
                                            >Delete</div>
                                        </div>
                                    }
                                    position="bottom center"
                                />
                            </div>
                        </div>
                        {queryResponse.deleteQuery ?
                            <div className='QueryMsgDelete ml-4 pl-2 mt-1'>You can no longer see this message</div>
                            :
                            <div>
                                <div className='d-flex editReplyContianer mt-2 gap-3'>
                                    <input className='QueryType ml-4 pl-2 '
                                        value={queryResponse.responseMsg}
                                        onChange={(e) => {
                                            setQueryResponse({
                                                ...queryResponse,
                                                responseMsg: e.target.value
                                            })
                                        }}
                                        disabled={!queryResponse.editQueryResponse}
                                    />
                                    {queryResponse.editQueryResponse &&
                                        <div className='d-flex gap-3'>
                                            <div className='d-flex gap-2'
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    setQueryResponse({
                                                        ...queryResponse,
                                                        editQueryResponse: false
                                                    })
                                                }}
                                            >
                                                <div>
                                                    <img style={{ cursor: 'pointer' }}

                                                        src='/Engagement/EditDone.png' alt='replie icon' />
                                                </div>
                                                <span>Done</span>
                                            </div>
                                            <div className='d-flex gap-2'>
                                                <div>
                                                    <img style={{ cursor: 'pointer' }}

                                                        src='/Engagement/EditDiscard.png' alt='replie icon' />
                                                </div>
                                                <span>Discard</span>
                                            </div>
                                        </div>}
                                </div>
                                {queryResponse.editQueryResponse &&
                                    <div className='d-flex justify-content-between editReplyContianer mt-2'>
                                        <div className='PdfQueryContainer d-flex justify-content-between  ml-4'>
                                            <div>
                                                <img style={{ cursor: 'pointer' }} src='/Engagement/XlsQueryIcon.png' alt='Xls icon' />
                                            </div>
                                            <div className='xlsFileName'>Nov 2021 <span className='filesize'>(44kb)</span></div>
                                            <div>
                                                <img style={{ cursor: 'pointer' }} src='/Engagement/DownloadQuery.png' alt='DownloadQuery icon' />
                                            </div>
                                        </div>
                                        <div >
                                            <input type="file" id="attachFile" style={{ display: "none" }} />
                                            <label htmlFor="attachFile">
                                                <img style={{ cursor: 'pointer' }} src='/Engagement/AttachFileQueryResp.png' alt='Attachment icon' />&nbsp;
                                                <span className='ml-1'>Attach File</span>
                                            </label>
                                        </div>
                                    </div>

                                }

                            </div>

                        }
                    </div>

                </div>
                <div>
                    <div className='d-flex justify-content-between '>
                        <div className='d-flex gap-2 mt-4'>
                            <div className='FirstLetter'>A</div>
                            <div className='NameRepliers'>Albert</div>
                            <div className='repliesTime'>12:12 PM</div>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <div className='d-flex align-items-center'>
                                <img style={{ cursor: 'pointer' }} src='/Engagement/Replie.png' alt='replie icon' />
                            </div>
                            <span>Reply</span>
                            <div className='d-flex align-items-center'>
                                <img style={{ cursor: 'pointer' }} src='/Engagement/Unlike.png' alt='Unlike icon' />

                            </div>
                            <span >Like</span>
                            <span className='likeCount'>3</span>

                            <Popup
                                on="click"
                                className="popUpFilter"
                                trigger={
                                    <div className='d-flex align-items-center '>
                                        <img style={{ cursor: 'pointer' }} src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                    </div>
                                }
                                content={
                                    <div className='container_threedot'>
                                        <div className='PopupThreedots'
                                        // onClick={(e) => {
                                        //     setQuery({
                                        //         ...query,
                                        //         editQuery: true
                                        //     })
                                        // }}
                                        >Edit</div>
                                        <div className='PopupThreedots'>Delete</div>
                                    </div>
                                }
                                position="bottom center"
                            />
                        </div>
                    </div>
                    <div className='QueryType ml-4 pl-2 mt-1'>Thank You</div>

                </div>
                <div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex gap-2 mt-4'>
                            <div className='FirstLetter'>J</div>
                            <div className='NameRepliers'>Jhon D</div>
                            <div className='repliesTime'>12:12 PM</div>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <div className='d-flex align-items-center'>
                                <img style={{ cursor: 'pointer' }} src='/Engagement/Replie.png' alt='replie icon' />
                            </div>
                            <span>Reply</span>
                            <div className='d-flex align-items-center'>
                                <img style={{ cursor: 'pointer' }} src='/Engagement/Unlike.png' alt='Unlike icon' />
                            </div>
                            <span >Like</span>
                            <span className='likeCount'>3</span>

                            <Popup
                                on="click"
                                className="popUpFilter"
                                trigger={
                                    <div className='d-flex align-items-center '>
                                        <img style={{ cursor: 'pointer' }} src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                    </div>
                                }
                                content={
                                    <div className='container_threedot'>
                                        <div className='PopupThreedots'
                                        >Edit</div>
                                        <div className='PopupThreedots'>Delete</div>
                                        <div className='PopupThreedots'>In-Active</div>
                                    </div>
                                }
                                position="bottom center"
                            />
                        </div>
                    </div>
                    <div className='QueryType1 ml-4 pl-2 mt-1'>
                        <div className='PdfQueryContainer d-flex justify-content-between'>
                            <div>
                                <img style={{ cursor: 'pointer' }} src='/Engagement/XlsQueryIcon.png' alt='Xls icon' />
                            </div>
                            <div className='xlsFileName'>Nov 2021 <span className='filesize'>(44kb)</span></div>
                            <div>
                                <img style={{ cursor: 'pointer' }} src='/Engagement/DownloadQuery.png' alt='DownloadQuery icon' />
                            </div>
                        </div>
                    </div>
                </div>
                {selectedOption.value === 'Resolved' && <div className=' SBoard d-flex justify-content-center'>This Query has been resolved.</div>}

            </div>




















            <div className='ToatalContainerQueryInputs p-2'>
                {queryResponse.replayQuery && <div className='ReplayContainerName p-2 mb-2'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex gap-2'>
                            <div className='FirstLetter'>J</div>
                            <div className='NameRepliers'>Jhon Deo</div>
                            <div className='repliesTime'>12:12 PM</div>
                        </div>
                        <div>
                            <img
                                onClick={(e) => {
                                    setQueryResponse({
                                        ...queryResponse,
                                        replayQuery: false
                                    })
                                }}
                                style={{ cursor: 'pointer' }} src='/Engagement/crossIconQuery.png' alt='crossIconQuery icon' />
                        </div>
                    </div>
                    <div className='QueryMsgDelete ml-4 pl-2 mt-1'>Sure, will share the updated School Bill in couple of hours.</div>
                </div>}

                <div className='d-flex align-items-center  gap-4'>
                    <div className='inputQuerySubContainer'>
                        <input className='queryInput' placeholder='Type message' />
                    </div>
                    <div className='mr-3'>
                        <div>
                            <input type="file" id="attachFile" style={{ display: "none" }} />
                            <label htmlFor="attachFile">
                                <img style={{ cursor: 'pointer' }} src='/Engagement/Attachment.png' alt='Attachment icon' />
                            </label>
                        </div>
                    </div>
                    <div className='mr-3'>
                        <img style={{ cursor: 'pointer' }} src='/Engagement/microphone.png' alt='microphone icon' />
                    </div>
                    <div className='mr-3'>
                        <img style={{ cursor: 'pointer' }} src='/Engagement/camera.png' alt='camera icon' />
                    </div>
                    <div className='mr-3'>
                        <img style={{ cursor: 'pointer' }} src='/Engagement/send.png' alt='send icon' />
                    </div>
                </div>
            </div>

            {queryResponse.deletePopup && <DeleteQueryPopup setQueryResponse={setQueryResponse}/>}
            {/* {selectedOption.value === 'Resolved' &&  <ResolvedQueryPopup  setSelectedOption={setSelectedOption}/> } */}

        </div>
    )
}
