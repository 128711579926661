import React, { useState } from 'react';

export default function AddRole({ setPermision }) {
  const [formData, setFormData] = useState({
    name: '',
  
  });

  const [errors, setErrors] = useState({
    name: '',
   
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Validate input fields
    validateInput(name, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  const validateInput = (name, value) => {
    const regex = {
      name: /^[a-zA-Z\s]*$/,

    };

    const errorMessages = {
      name: 'Name is required and can only contain alphabets and spaces',
    };

    const isValid = regex[name].test(value);
    const errorMessage = isValid ? '' : errorMessages[name];
    setErrors({ ...errors, [name]: errorMessage });
  };

  const isFormValid = errors.name === '' && Object.values(formData).every((value) => value !== '');
  const { name } = errors;
  console.log(isFormValid)
  return (
    <div className="TotalContainerAddRole">
      <div className="TotalSubContainerAddRole">
        <div className="addauthHeading">Add New Authority</div>
        <form onSubmit={handleSubmit}>
          <div className="p-4 mt-4">
            <div className="AddRole">Add Role</div>
            <div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                maxLength={50}
                className="TopManagment_inputs mt-4"
                placeholder="Enter Role"
                autoComplete="off"
              />
              {name && <span className="error">{name}</span>}
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button type="submit" disabled={!isFormValid} className="AddRoleSaveBtn">
                Save
              </button>
            </div>
          </div>
        </form>

        <img
          onClick={(e) => setPermision({ addRole: false })}
          className="CrossIconAddRole"
          src="/OnBoard/CrossIcon.png"
          alt="cross icon"
        />
      </div>
    </div>
  );
}

