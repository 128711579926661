import { configureStore, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

const configurationSlice = createSlice({
  name: "configuration",
  initialState: { configData: null, countryCodeReload: false },
  reducers: {
    fetchData(state, action) {
      state.configData = action.payload;
    },
    setCountryCode(state){
      console.log(state.countryCodeReload)
      state.countryCodeReload = !state.countryCodeReload;
    }
  },
});

export const { fetchData, setCountryCode } = configurationSlice.actions;

const store = configureStore({
  reducer: {
    configuration: configurationSlice.reducer,
  },
});

export default store;

export const fetchConfigDataThunk = (countryCode) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/v1/cm/library/book/getB4SConfigurationForRegionAndSegment?b4sRegionalCode=${countryCode}&b4sEdtechSegment=school`
      );
      if (res) {
        dispatch(fetchData(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
