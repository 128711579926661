import React, { useState } from 'react'
import './UploadMasterD.css'
import UploadFile from './UploadFile'

export default function OverRightMD({handleCloseUploadMDPopUP}) {
    const [overRightPopUp, setOverRightPopUp] = useState({
        proceed: false,
        uploadFile: false
    })
    return (
        <div>
            {!overRightPopUp.uploadFile &&
                <>
                    {overRightPopUp.proceed ?

                        <div>
                            <div className='conatiner_chapterContent'>
                                <div className='subContainer_overRight'>
                                    <div className='uploadHeading'>Upload </div>
                                    <div className='mt-4 overightMsgText'>By over-writing, all your current information in Master Data - Part 5 will be replaced with your new uploaded document. Do you wish to proceed?</div>
                                    <div className='gap-4 d-flex flex-wrap justify-content-center mt-4 pt-4 '>
                                        <button className='OverRightBtns'
                                            onClick={(e) => setOverRightPopUp({
                                                ...overRightPopUp,
                                                uploadFile: true
                                            })}
                                        >Yes</button>
                                        <button className='OverRightBtns'
                                            onClick={(e) => setOverRightPopUp({
                                                ...overRightPopUp,
                                                proceed: false
                                            })}
                                        >No</button>
                                    </div>
                                    <div>
                                        <img
                                            onClick={(e) => setOverRightPopUp({
                                                ...overRightPopUp,
                                                proceed: false
                                            })}
                                            className='Crossicon_Overright' src='/OnBoard/CrossIcon.png' alt='cross icon'>
                                        </img>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :

                        <div className='conatiner_chapterContent'>
                            <div className='subContainer_overRight'>
                                <div className='uploadHeading'>Upload </div>
                                <div className='mt-4 overightMsgText'>Do you wish to add to the current data or delete current data and over-write it?</div>
                                <div className='gap-4 d-flex flex-wrap  justify-content-center mt-4 pt-4 '>
                                    <button className='OverRightBtns'>Add to current</button>
                                    <button className='OverRightBtns'
                                        onClick={(e) => setOverRightPopUp({
                                            ...overRightPopUp,
                                            proceed: true
                                        })}
                                    >Over-write</button>
                                </div>
                                <div>
                                    <img
                                       onClick={handleCloseUploadMDPopUP}
                                        className='Crossicon_Overright' src='/OnBoard/CrossIcon.png' alt='cross icon'>
                                    </img>
                                </div>
                            </div>

                        </div>



                    }
                </>}
            {overRightPopUp.uploadFile && <UploadFile setOverRightPopUp={setOverRightPopUp} handleCloseUploadMDPopUP={handleCloseUploadMDPopUP} />}
        </div>
    )
}


// import React, { useState } from 'react';
// import Dropzone from 'react-dropzone';
// import * as XLSX from 'xlsx';


// export default function ExcelUpload() {
//     const [data, setData] = useState([]);
//     const [formValues, setFormValues] = useState([]);

//     const readExcelFile = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();

//             reader.onload = (event) => {
//                 const data = new Uint8Array(event.target.result);
//                 const workbook = XLSX.read(data, { type: 'array' });
//                 const worksheet = workbook.Sheets[workbook.SheetNames[0]];
//                 const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//                 const headers = rows[0];
//                 const rowData = rows.slice(1).map((row) =>
//                     headers.reduce((obj, header, i) => {
//                         obj[header] = row[i];
//                         return obj;
//                     }, {})
//                 );
//                 resolve(rowData);
//             };

//             reader.onerror = reject;

//             reader.readAsArrayBuffer(file);
//         });
//     };

//     const handleFileUpload = async (files) => {
//         const fileData = await readExcelFile(files[0]);
//         setData(fileData);
//         setFormValues(fileData);
//     };

//     const handleInputChange = (event, rowIndex, columnName) => {
//         const newValue = event.target.value;
//         setFormValues((prevValues) => {
//             const newValues = [...prevValues];
//             newValues[rowIndex][columnName] = newValue;
//             return newValues;
//         });
//     };

//     return (
//         <div>
//             <Dropzone onDrop={handleFileUpload}>
//                 {({ getRootProps, getInputProps }) => (
//                     <div {...getRootProps()}>
//                         <input {...getInputProps()} />
//                         <p>Drag and drop an Excel file here, or click to select a file</p>
//                     </div>
//                 )}
//             </Dropzone>
//             {formValues.length > 0 && (
//                 <form>
//                     {formValues.map((row, rowIndex) => (
//                         <div key={rowIndex}>
//                             {Object.keys(row).map((columnName, columnIndex) => (
//                                 <div key={`${rowIndex}-${columnIndex}`}>
//                                     <label>{columnName}</label>
//                                     <input
//                                         type="text"
//                                         value={row[columnName]}
//                                         onChange={(event) =>
//                                             handleInputChange(event, rowIndex, columnName)
//                                         }
//                                     />
//                                 </div>
//                             ))}
//                         </div>
//                     ))}
//                 </form>
//             )}
//         </div>
//     );
// }