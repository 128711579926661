import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useSchoolContext } from "../../../schoolContext";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import { Label } from "semantic-ui-react";
import Select from "react-select";
import ErrorPopup from "../../../ErrorPopup";

export default function SecondPart({ setActivePart }) {
  // const [blockName, setBlockName] = useState("");
  const [standardDropDownOpts, setStandardDropDownOpts] = useState([]);

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  // add School block code
  // const [fieldsSchoolB, setFieldsSchoolB] = useState([
  //   { id: 1, floor: "", numOfRooms: "", roomTypes: "" },
  // ]);

  // const handleAddFieldScoolBlock = () => {
  //   const newFields = [...fieldsSchoolB];
  //   newFields.push({
  //     id: fieldsSchoolB.length + 1,
  //     floor: "",
  //     numOfRooms: "",
  //     roomTypes: "",
  //   });
  //   setFieldsSchoolB(newFields);
  // };

  // const handleRemoveFieldSchoolBlock = (id) => {
  //   const newFields = fieldsSchoolB.filter((field) => field.id !== id);
  //   // Re-count the field IDs
  //   const renumberedFields = newFields.map((field, index) => {
  //     return {
  //       ...field,
  //       id: index + 1,
  //     };
  //   });
  //   setFieldsSchoolB(renumberedFields);
  // };

  // const handleInputSchoolBlock = (id, name, value) => {
  //   const newFields = [...fieldsSchoolB];
  //   const index = newFields.findIndex((field) => field.id === id);
  //   newFields[index][name] = value;
  //   setFieldsSchoolB(newFields);
  // };

  const b4sConfigData = useSelector((state) => state.configuration.configData);

  useEffect(() => {
    if (b4sConfigData?.b4sStandard) {
      const mappedStandards = b4sConfigData?.b4sStandard?.map((obj) => ({
        label: obj.b4sName,
        value: obj.id,
      }));

      setStandardDropDownOpts(mappedStandards);
    }
  }, [b4sConfigData?.b4sStandard]);

  const DropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#99ADBB",
      minHeight: "30px",
      padding: "11px",
      borderRadius: " 8px",
      boxShadow: state.isFocused ? null : null,
    }),
    indicatorSeparator: () => ({ display: "none" }),
    group: (provided) => ({
      ...provided,
      fontWeight: 400,
      fontSize: "14px",
      color: "#333",
    }),
  };

  const [blocks, setBlocks] = useState([
    {
      id: 1,
      blockName: "",
      fields: [{ id: 1, floor: "", numOfRooms: "", roomTypes: "" }],
    },
  ]);

  const handleAddBlock = () => {
    const newBlocks = [...blocks];
    newBlocks.push({
      id: blocks.length + 1,
      blockName: "",
      fields: [{ id: 1, floor: "", numOfRooms: "", roomTypes: "" }],
    });
    setBlocks(newBlocks);
  };

  const handleRemoveBlock = (blockId) => {
    const newBlocks = blocks.filter((block) => block.id !== blockId);
    setBlocks(newBlocks);
  };

  const handleAddField = (blockId) => {
    const newBlocks = [...blocks];
    const blockIndex = newBlocks.findIndex((block) => block.id === blockId);
    const newFields = newBlocks[blockIndex].fields;

    newFields.push({
      id: newFields.length + 1,
      floor: "",
      numOfRooms: "",
      roomTypes: "",
    });

    setBlocks(newBlocks);
  };

  const handleRemoveField = async (blockId, fieldId, field, block) => {
    try {
      if (field.floorId && block.BlockId) {
        await axios.delete(
          `/api/v1/school/block/delete/${block.BlockId}/${field.floorId}`
        );
      }

      const newBlocks = [...blocks];
      const blockIndex = newBlocks.findIndex((block) => block.id === blockId);
      const newFields = newBlocks[blockIndex].fields.filter(
        (field) => field.id !== fieldId
      );

      const renumberedFields = newFields.map((field, index) => ({
        ...field,
        id: index + 1,
      }));

      newBlocks[blockIndex].fields = renumberedFields;
      setBlocks(newBlocks);
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error removing field:", error);
    }
  };

  const handleInput = (blockId, fieldId, name, value) => {
    const newBlocks = [...blocks];
    const blockIndex = newBlocks.findIndex((block) => block.id === blockId);
    const fieldIndex = newBlocks[blockIndex].fields.findIndex(
      (field) => field.id === fieldId
    );

    newBlocks[blockIndex].fields[fieldIndex][name] = value;
    setBlocks(newBlocks);
  };

  // add Supported Classes code
  const [fieldsSuportedClass, setFieldsSuportedClass] = useState([
    { id: 1, floor: "", roomTypes: "", b4sStandardId: "" },
  ]);

  const handleAddFieldSuportedClass = () => {
    const newFields = [...fieldsSuportedClass];
    newFields.push({
      id: fieldsSuportedClass.length + 1,
      floor: "",
      roomTypes: "",
      b4sStandardId: "",
    });
    setFieldsSuportedClass(newFields);
  };

  const handleRemoveFieldSuportedClass = async (field, id) => {
    if (field.standardId) {
      try {
        await axios.delete(
          `/api/v1/school/standard/delete/${field.standardId}`
        );
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    const newFields = fieldsSuportedClass.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsSuportedClass(renumberedFields);
  };

  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (standardDropDownOpts.length > 0 && fieldsSuportedClass.length > 0) {
      const filtered = standardDropDownOpts.filter(
        (item) =>
          !fieldsSuportedClass.some(
            (field) => field.b4sStandardId.value === item.value
          )
      );
      setFilteredOptions(filtered);
    }
  }, [standardDropDownOpts, fieldsSuportedClass]);

  const handleInputSuportedClass = (id, name, value) => {
    const newFields = [...fieldsSuportedClass];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    setFieldsSuportedClass(newFields);
  };

  // add Supported Section code
  const [fieldsSuportedSection, setFieldsSuportedSection] = useState([
    { id: 1, floor: "", roomTypes: "" },
  ]);

  const handleAddFieldSuportedSection = () => {
    const newFields = [...fieldsSuportedSection];
    newFields.push({
      id: fieldsSuportedSection.length + 1,
      floor: "",
      roomTypes: "",
    });
    setFieldsSuportedSection(newFields);
  };

  const handleRemoveFieldSuportedSection = async (field, id) => {
    if (field.sectionId) {
      try {
        await axios.delete(`/api/v1/school/section/delete/${field.sectionId}`);
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    const newFields = fieldsSuportedSection.filter((field) => field.id !== id);
    // Re-count the field IDs
    const renumberedFields = newFields.map((field, index) => {
      return {
        ...field,
        id: index + 1,
      };
    });
    setFieldsSuportedSection(renumberedFields);
  };

  const handleInputSuportedSection = (id, name, value) => {
    const newFields = [...fieldsSuportedSection];
    const index = newFields.findIndex((field) => field.id === id);
    newFields[index][name] = value;
    setFieldsSuportedSection(newFields);
  };

  const isFieldsSchoolBEmpty = blocks.some((block) =>
    block.fields.some((field) => !field.floor || !field.numOfRooms)
  );

  const isFieldSuportedClass = fieldsSuportedClass.some(
    (field) => !field.floor
  );
  const isFieldSuportedSection = fieldsSuportedSection.some(
    (field) => !field.floor
  );
  const isBlockNameEmpty = blocks.some((block) => !block.blockName);
  const classPrefixString = "class";
  const sectionPrefixString = "section";
  const isClassPrefixPresent = fieldsSuportedClass.some((element) =>
    element.floor.trim().toLowerCase().includes(classPrefixString)
  );
  const isSectionPrefixPresent = fieldsSuportedSection.some((element) =>
    element.floor.trim().toLowerCase().includes(sectionPrefixString)
  );

  const isButtonDisabled =
    isFieldsSchoolBEmpty ||
    isFieldSuportedClass ||
    isFieldSuportedSection ||
    isBlockNameEmpty;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (schoolId) {
          const response = await axios.get(
            `/api/v1/school/standard/get/school/${schoolId}`
          );

          const mappedData = response.data.data.map((standard, index) => ({
            id: index + 1,
            standardId: standard.id,
            floor: standard.name,
            b4sStandardId: {
              value: standard.b4sStandardId,
              label: b4sConfigData?.b4sStandard.find(
                (obj) => obj.id === standard.b4sStandardId
              )?.b4sName,
            },
            roomTypes: standard.description,
          }));

          if (response.data.data.length > 0) {
            setFieldsSuportedClass(mappedData);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching standard data:", error);
      }
    };

    fetchData();
  }, [schoolId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (schoolId) {
          const response = await axios.get(
            `/api/v1/school/section/get/school/${schoolId}`
          );

          const mappedData = response.data.data.map((section, index) => ({
            id: index + 1,
            sectionId: section.id,
            floor: section.name,
            roomTypes: section.description,
          }));

          if (response.data.data.length > 0) {
            setFieldsSuportedSection(mappedData);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching section data:", error);
      }
    };

    fetchData();
  }, [schoolId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (schoolId) {
          const response = await axios.get(
            `/api/v1/school/block/get/school/${schoolId}`
          );

          const mappedData = response.data.data.map((block, index) => ({
            id: index + 1,
            BlockId: block.id,
            blockName: block.name,
            fields: block.floors.map((floor, floorIndex) => ({
              id: floorIndex + 1,
              floorId: floor.id,
              floor: floor.name,
              numOfRooms: floor.numberOfRooms,
              roomTypes: floor.typeOfRooms,
            })),
          }));

          if (response.data.data.length > 0) {
            setBlocks(mappedData);
          }
        }
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching block data:", error);
      }
    };

    fetchData();
  }, [schoolId]);

  const createStandard = async () => {
    const url = "/api/v1/school/standard/create";
    try {
      for (const standard of fieldsSuportedClass) {
        const requestBody = {
          name: standard.floor,
          description: standard.roomTypes,
          schoolId: schoolId,
          b4sStandardId: standard.b4sStandardId.value,
        };

        if (standard.standardId) {
          const updateUrl = `/api/v1/school/standard/update/${standard.standardId}`;
          const requestBodyUpdate = {
            ...requestBody,
            id: standard.standardId,
          };

          await axios.put(updateUrl, requestBodyUpdate);
        } else {
          await axios.post(url, requestBody);
        }
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error creating AYS:", error);
      throw error;
    }
  };

  const createSection = async () => {
    const url = "/api/v1/school/section/create";
    try {
      for (const section of fieldsSuportedSection) {
        const requestBody = {
          name: section.floor,
          description: section.roomTypes,
          schoolId: schoolId,
        };

        if (section.sectionId) {
          const updateUrl = `/api/v1/school/section/update/${section.sectionId}`;
          const requestBodyUpdate = {
            ...requestBody,
            id: section.sectionId,
          };

          await axios.put(updateUrl, requestBodyUpdate);
        } else {
          await axios.post(url, requestBody);
        }
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error creating AYS:", error);
      throw error;
    }
  };

  const createBlocks = async () => {
    const url = "/api/v1/school/block/create";
    try {
      for (const block of blocks) {
        const requestBody = {
          name: block.blockName,
          schoolId: schoolId,
          floors: block.fields.map((field) => ({
            ...(field.floorId && { id: field.floorId }),
            name: field.floor,
            numberOfRooms: field.numOfRooms,
            typeOfRooms: field.roomTypes,
          })),
        };

        if (block.BlockId) {
          const updateUrl = `/api/v1/school/block/update`;
          const requestBodyUpdate = {
            ...requestBody,
            id: block.BlockId,
          };

          await axios.put(updateUrl, requestBodyUpdate);
        } else {
          console.log(requestBody);
          await axios.post(url, requestBody);
        }
      }
    } catch (error) {
      handleError(error.response.data.message);
      console.error("Error creating blocks:", error);
      throw error;
    }
  };

  const handleClick = () => {
    try {
      createStandard();
      createSection();
      createBlocks();
      handleCreateSubjects();
      setActivePart("fivePart");
    } catch (error) {
      handleError(error.response.data.message);
    }
  };
  //From here I am pasting logic from ThirdPart.js for subjects
  const { b4sSubject } = useSelector((state) => state.configuration.configData);
  const [items, setItems] = useState({
    Board: [],
    Subject1: [],
    Subject2: [],
  });
  const subjects = b4sSubject?.map((obj) => ({
    label: obj.b4sName,
    value: obj.id,
  }));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const subject1Response = await axios.get(
          `/api/v1/school/subject/get/school/${schoolId}`
        );
        const subject1Data = subject1Response.data.data.map((subject) => ({
          label: subject.name,
          value: subject.b4sSubjectId,
          id: subject.id,
        }));

        setItems({
          ...items,
          Subject1: subject1Data,
        });
      } catch (error) {
        handleError(error.response.data.message);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [schoolId]);
  function generateRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const handleCreateSubjects = async () => {
    try {
      if (items.Subject1.length > 0) {
        for (const subject of items.Subject1) {
          if (!subject?.id) {
            const url = "/api/v1/school/subject/create";
            const body = {
              schoolId,
              name: subject.label,
              subject_color: generateRandomColor(),
              b4sSubjectId: subject.value,
            };
            const response = await axios.post(url, body);
          }
        }
        setActivePart("fivePart");
      }
    } catch (error) {
      console.log(error);
      handleError(error.response.data.message);
    }
  };
  const handleDeleteSubjects = async (subject) => {
    try {
      const url = `/api/v1/school/subject/delete/${subject?.id}`;
      await axios.delete(url);
    } catch (error) {
      console.log(error);
      handleError(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="infra_info">Infrastructure Information</div>
      <div className="partFirst_subContainer">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="school_block">School Blocks</div>
          <button className="addBlock_btn" onClick={handleAddBlock}>
            <BsPlusCircle /> &nbsp;Add Block
          </button>
        </div>
        {/* School Block */}
        <div>
          {blocks.map((block) => (
            <div className="Container_Academic_Session mt-4">
              <p className="blockN_haeading">
                School Block Name <span className="important_input">*</span>
              </p>
              <input
                className="Block_name_input"
                placeholder="Block Name 1"
                value={block.blockName}
                maxLength={50}
                onChange={(e) => {
                  const newBlocks = [...blocks];
                  const blockIndex = newBlocks.findIndex(
                    (b) => b.id === block.id
                  );
                  newBlocks[blockIndex].blockName = e.target.value;
                  setBlocks(newBlocks);
                }}
              />

              <div className="d-flex container_schoolBlock_Inputs justify-content-between flex-wrap mt-3">
                <div className="subInputs_container1">
                  <div className="input_block_hedding">
                    Floor <span className="important_input">*</span>
                  </div>
                </div>

                <div className="subInputs_container2">
                  <div className="input_block_hedding">
                    Number of Rooms <span className="important_input">*</span>
                  </div>
                </div>

                <div className="subInputs_container3">
                  <div className="d-flex justify-content-between ">
                    <div className="input_block_hedding">Types of Rooms</div>
                    <div className="input_block_hedding">
                      Separate names with a comma
                    </div>
                  </div>
                </div>
                <div className="plusIcon_blocks"></div>
              </div>

              {block.fields.map((field) => (
                <div
                  key={field.id}
                  className="d-flex container_schoolBlock_Inputs justify-content-between flex-wrap mt-3"
                >
                  <div className="subInputs_container1">
                    <input
                      className="inputSchool_blocks"
                      value={field.floor}
                      onChange={(e) =>
                        handleInput(block.id, field.id, "floor", e.target.value)
                      }
                      maxLength={50}
                      placeholder="Enter Floor"
                    />
                  </div>

                  <div className="subInputs_container2">
                    <input
                      className="inputSchool_blocks"
                      value={field.numOfRooms}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numberRegex = /^\d+$/;

                        if (
                          (numberRegex.test(inputValue) && inputValue <= 999) ||
                          inputValue === ""
                        ) {
                          handleInput(
                            block.id,
                            field.id,
                            "numOfRooms",
                            inputValue
                          );
                        }
                      }}
                      placeholder="Enter Number"
                    />
                  </div>

                  <div className="subInputs_container3">
                    <input
                      className="inputSchool_blocks"
                      maxLength={300}
                      value={field.roomTypes}
                      onChange={(e) =>
                        handleInput(
                          block.id,
                          field.id,
                          "roomTypes",
                          e.target.value
                        )
                      }
                      placeholder="Enter Name"
                    />
                  </div>

                  {field.id === 1 ? (
                    <div className="plusIcon_blocks"></div>
                  ) : (
                    <div
                      className="plusIcon_blocks"
                      onClick={() =>
                        handleRemoveField(block.id, field.id, field, block)
                      }
                    >
                      <img src="/OnBoard/Dustbin.png" alt="dustbin Icon" />
                    </div>
                  )}
                </div>
              ))}
              {!block.fields.some(
                (field) => !field.floor || !field.numOfRooms || !field.roomTypes
              ) && (
                <div className="total_plusDelete">
                  <img
                    src="/OnBoard/plusIcon_part1.png"
                    alt="plus Icon"
                    className="plusDimensions"
                    onClick={() => handleAddField(block.id)}
                  ></img>
                  <p className="AddRowText">Add Row</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="infra_info mt-3">Supported Classes and Sections</div>

        {/* Supported Class  */}
        <div className="Container_Academic_Session mt-4">
          <p className="blockN_haeading">Supported Classes</p>

          <div className="d-flex justify-content-between flex-wrap">
            <div className="subInputs_container_suported1">
              <div className="input_block_hedding">
                Class Name <span className="important_input">*</span>
              </div>
            </div>

            <div className="subInputs_container_suported2">
              <div className="input_block_hedding">
                Class Description <span className="important_input">*</span>
              </div>
            </div>
            <div className="plusIcon_blocks"></div>
          </div>

          {fieldsSuportedClass.map((field) => (
            <div
              key={field.id}
              className="d-flex container_schoolBlock_Inputs justify-content-between flex-wrap mt-3"
            >
              <div className="subInputs_container1">
                <Select
                  options={filteredOptions}
                  styles={DropdownStyle}
                  placeholder="Select Standard"
                  value={field.b4sStandardId}
                  onChange={(selectedOptions) => {
                    handleInputSuportedClass(
                      field.id,
                      "b4sStandardId",
                      selectedOptions
                    );
                  }}
                />
              </div>

              <div className="subInputs_container2">
                <input
                  className="inputSchool_blocks"
                  value={field.floor}
                  maxLength={50}
                  onChange={(e) =>
                    handleInputSuportedClass(field.id, "floor", e.target.value)
                  }
                  placeholder="Name (e.g., 1, I, one, etc)"
                />
              </div>

              <div className="subInputs_container3">
                <input
                  className="inputSchool_blocks"
                  value={field.roomTypes}
                  maxLength={100}
                  onChange={(e) =>
                    handleInputSuportedClass(
                      field.id,
                      "roomTypes",
                      e.target.value
                    )
                  }
                  placeholder="Description"
                />
              </div>

              {field.id === 1 ? (
                <div className="plusIcon_blocks"></div>
              ) : (
                <div
                  className="plusIcon_blocks"
                  onClick={() =>
                    handleRemoveFieldSuportedClass(field, field.id)
                  }
                >
                  <img src="/OnBoard/Dustbin.png" alt="dustbin Icon" />
                </div>
              )}
            </div>
          ))}
          {isClassPrefixPresent && (
            <span className="error">
              Do not use the keyword 'class' for creating a class name.
            </span>
          )}
          {!isFieldSuportedClass && (
            <div className="total_plusDelete">
              <img
                src="/OnBoard/plusIcon_part1.png"
                alt="plus Icon"
                className="plusDimensions"
                onClick={handleAddFieldSuportedClass}
              ></img>
              <p className="AddRowText">Add Row</p>
            </div>
          )}
        </div>

        {/* Supported section  */}
        <div className="Container_Academic_Session mt-4">
          <p className="blockN_haeading">Supported Section</p>

          <div className="d-flex justify-content-between flex-wrap">
            <div className="subInputs_container_suported1">
              <div className="input_block_hedding">
                Section Name <span className="important_input">*</span>
              </div>
            </div>

            <div className="subInputs_container_suported2">
              <div className="input_block_hedding">Section Description</div>
            </div>
            <div className="plusIcon_blocks"></div>
          </div>

          {fieldsSuportedSection.map((field) => (
            <div
              key={field.id}
              className="d-flex container_schoolBlock_Inputs justify-content-between flex-wrap mt-3"
            >
              <div className="subInputs_container_suported1">
                <input
                  maxLength={50}
                  className="inputSchool_blocks"
                  value={field.floor}
                  onChange={(e) =>
                    handleInputSuportedSection(
                      field.id,
                      "floor",
                      e.target.value
                    )
                  }
                  placeholder="Name (e.g., A, B, C, a, b, etc)"
                />
              </div>

              <div className="subInputs_container_suported2">
                <input
                  className="inputSchool_blocks"
                  value={field.roomTypes}
                  onChange={(e) =>
                    handleInputSuportedSection(
                      field.id,
                      "roomTypes",
                      e.target.value
                    )
                  }
                  maxLength={100}
                  placeholder="Description"
                />
              </div>

              {field.id === 1 ? (
                <div className="plusIcon_blocks"></div>
              ) : (
                <div
                  className="plusIcon_blocks"
                  onClick={() =>
                    handleRemoveFieldSuportedSection(field, field.id)
                  }
                >
                  <img src="/OnBoard/Dustbin.png" alt="dustbin Icon" />
                </div>
              )}
            </div>
          ))}
          {isSectionPrefixPresent && (
            <span className="error">
              Do not use the keyword 'section' for creating a section name.
            </span>
          )}

          {!isFieldSuportedSection && (
            <div className="total_plusDelete">
              <img
                src="/OnBoard/plusIcon_part1.png"
                alt="plus Icon"
                className="plusDimensions"
                onClick={handleAddFieldSuportedSection}
              ></img>
              <p className="AddRowText">Add Row</p>
            </div>
          )}
        </div>
        {/* This is for subject dropdown. */}
        <div className="Container_Academic_Session mt-4">
          <div className="subHeading_part5 mt-4">
            List of all supported Subjects
            <span className="important_input">*</span>
          </div>
          <div className="part5_container mt-2 p-4">
            <Select
              closeMenuOnSelect={false}
              options={subjects}
              styles={DropdownStyle}
              placeholder="Select Subjects"
              value={items.Subject1}
              onChange={(selectedOptions) => {
                const removedOptions = items.Subject1.filter(
                  (option) => !selectedOptions.includes(option)
                );
                setItems((prev) => ({ ...prev, Subject1: selectedOptions }));
                removedOptions.forEach((option) => {
                  handleDeleteSubjects(option);
                });
              }}
              isMulti
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center mt-4 footer_container">
        <div>
          <span className="footer_text1">
            {/* For bulk upload please use this template */}
          </span>
          {/* <span className="footer_text2">Download template</span> */}
        </div>

        <div className="d-flex flex-wrap gap-3">
          {/* <div className="uploadFileBtn_MasterData">
            <input type="file" id="attachFile" style={{ display: "none" }} />
            <label htmlFor="attachFile">
              <span>
                <BsPlusCircle /> &nbsp;Upload file
              </span>
            </label>
          </div> */}
          <button
            id="NextBtn_school_masterData"
            onClick={handleClick}
            disabled={isButtonDisabled}
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
    </div>
  );
}
