import React, { useState } from 'react'
import Select from 'react-select';

// all css coes fro teacher Board css 

export default function ChangeTeacher({ setTeacherDetail }) {
    const [changeTeacher, setChangeTeacher] = useState({
        saveChanges: false
    })
    const ClassTeacher1 = [
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Corey Vetrovs', label: 'Corey Vetrovs' },
        { value: ' Angel Weste', label: 'Angel Weste' },
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Lincoin d', label: 'Lincoin d' },
    ]
    const ClassTeacher2 = [
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Corey Vetrovs', label: 'Corey Vetrovs' },
        { value: ' Angel Weste', label: 'Angel Weste' },
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Lincoin d', label: 'Lincoin d' },
    ]
    const ClassTeacher3 = [
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Corey Vetrovs', label: 'Corey Vetrovs' },
        { value: ' Angel Weste', label: 'Angel Weste' },
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Lincoin d', label: 'Lincoin d' },
    ]
    const ClassTeacher4 = [
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Corey Vetrovs', label: 'Corey Vetrovs' },
        { value: ' Angel Weste', label: 'Angel Weste' },
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Lincoin d', label: 'Lincoin d' },
    ]

    const ClassTeacher5 = [
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Corey Vetrovs', label: 'Corey Vetrovs' },
        { value: ' Angel Weste', label: 'Angel Weste' },
        { value: 'Carla Dorwart', label: 'Carla Dorwart' },
        { value: 'Lincoin d', label: 'Lincoin d' },
    ]
    const DropdownStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#99ADBB',
            minHeight: '56px',
            borderRadius: ' 8px',
            boxShadow: state.isFocused ? null : null,
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };


    // disable save btn 
    const [selectedValue, setSelectedValue] = useState(null);

    const handleDropdownChange = (selectedOption) => {
        setSelectedValue(selectedOption);
    };
    const isDisabled = selectedValue === null;
    return (
        <div className="TotalContainerChangeTeacher ">
            {!changeTeacher.saveChanges ?
                <div className="TotalSubContainerChangeTeacher p-4">
                    <>
                        <div className='changeTeacherHeading'>Assign alternative class teacher</div>
                        <div className='row mt-4 pt-2'>
                            <div className='col-md-5'>
                                <div className='subHeaderChangeTeacher'>Today’s class</div>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center gap-3 p-3 mt-3'>
                                    <div>
                                        <img src='/LandingPageImg/ScienceLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Science</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center gap-3 p-3 mt-2'>
                                    <div>
                                        <img src='/LandingPageImg/SocialScienceLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Social Science</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center gap-3 p-3 mt-2'>
                                    <div>
                                        <img src='/LandingPageImg/MathLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Maths</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center gap-3 p-3 mt-2'>
                                    <div>
                                        <img src='/LandingPageImg/ScienceLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Science</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>
                                <div className='Container_upcominLec d-flex justify-cotent-center align-items-center gap-3 p-3 mt-2'>
                                    <div>
                                        <img src='/LandingPageImg/SocialScienceLogo.png' alt='sciencLogo'></img>
                                    </div>
                                    <div>
                                        <div className='subjectName'>Social Science</div>
                                        <div className='subjectClassTime'>10:00am - 11:00 am</div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-7'>
                                <div className='subHeaderChangeTeacher'>
                                    Alternative class teacher
                                </div>


                                <div className='mt-4'>
                                    <Select
                                        styles={DropdownStyle}
                                        options={ClassTeacher1}
                                        placeholder='Select alternative class teacher'
                                        value={selectedValue}
                                        onChange={handleDropdownChange}
                                    />
                                </div>
                                <div className='mt-4'>
                                    <Select
                                        styles={DropdownStyle}
                                        options={ClassTeacher2}
                                        placeholder='Select alternative class teacher'
                                    />
                                </div>
                                <div className='mt-4'>
                                    <Select
                                        styles={DropdownStyle}
                                        options={ClassTeacher3}
                                        placeholder='Select alternative class teacher'
                                    />
                                </div>
                                <div className='mt-4'>
                                    <Select
                                        styles={DropdownStyle}
                                        options={ClassTeacher4}
                                        placeholder='Select alternative class teacher'
                                    />
                                </div>
                                <div className='mt-4'>
                                    <Select
                                        styles={DropdownStyle}
                                        options={ClassTeacher5}
                                        placeholder='Select alternative class teacher'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mt-4 d-flex justify-content-end'>
                            <button disabled={isDisabled}
                                onClick={(e) => setChangeTeacher({
                                    ...changeTeacher,
                                    saveChanges: true
                                })}
                                className='saveBtnChangeT'>Save</button>
                        </div>
                    </>

                    <img
                        onClick={(e) => setTeacherDetail({ changeTeacher: false })}
                        className="CrossIconAddRole"
                        src="/OnBoard/CrossIcon.png"
                        alt="cross icon"
                    />

                </div>
                :
                <div className='sucessMsgContainer '>
                    <div className='d-flex justify-content-center'>
                        <img src='/LandingPageImg/SucessFullyTick.png' alt='sucess logo'></img>
                    </div>
                    <div className='d-flex justify-content-center sucessMsg' >You Have successfully changed<br />the teachers</div>
                    <div className='mt-4 d-flex justify-content-center'>
                        <button
                            onClick={(e) => setTeacherDetail({ changeTeacher: false })}
                            className='saveBtnChangeT'>Okay</button>
                    </div>
                    <img
                        onClick={(e) => setTeacherDetail({ changeTeacher: false })}
                        className="CrossIconAddRole"
                        src="/OnBoard/CrossIcon.png"
                        alt="cross icon"
                    />
                </div>
            }
        </div>
    )
}
