import React from 'react'

export default function DeleteQueryPopup({ setQueryResponse }) {
    return (
        <div className='DeleteQueryPopUPContainer'>
            <div className='SubDeleteQueryPopUPContainer'>
                <div className='DeleteQueryMsg d-flex align-item-center justify-content-center mb-4'>Are you sure you want to delete this message?</div>
                <div className='d-flex gap-4 mt-4 pt-4'>
                    <button className='BtnDeleteQyeryYes'
                      onClick={(e) => setQueryResponse({
                        deleteQuery: true
                    })}
                    >Yes</button>
                    <button className='BtnDeleteQyeryNo'
                        onClick={(e) => setQueryResponse({
                            deletePopup: false
                        })}
                    >No</button>
                </div>

                <img
                    className='crossIconQuey'
                    onClick={(e) => setQueryResponse({
                        deletePopup: false
                    })}
                    src='/OnBoard/CrossIcon.png' alt='cross icon'
                />

            </div>
        </div>
    )
}
