import React, { useState } from 'react'
import { BsPlusCircle } from 'react-icons/bs';
import '../SchoolAdmin.css'
import { Popup } from "semantic-ui-react";
import EditClassSA from './EditClassSA';


// some css cmes form ClassComp.css in useCaseSecondTime/class

export default function ClassInfoSA() {
    const [classList, setClassList] = useState({
        historyBtn: 'classInfo',
        edit: false
    })

    return (
        <div className='total_container_classInfoSA'>
            <div className='d-flex flex-wrap justify-content-between align-items-center'>
                <div>
                    <div className='ClassInfo_heading'>Class infomation</div>
                    <div className='heading_belowText'>Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão.</div>
                </div>
                {!classList.edit && <div>
                    <button className='UploadBtnClassInfo mr-2'>Upload</button>
                    <button className='AddClassBtnClassInfo'><BsPlusCircle />&nbsp;Add Class</button>
                </div>}
            </div>

            {!classList.edit &&
                <>
                    <div className='d-flex justify-content-between mt-4'>
                        <div>
                            <button onClick={(e) => setClassList({ ...classList, historyBtn: 'classInfo' })} className={` ${classList.historyBtn === "classInfo" ? "active classInfoBtn" : "uploadHistoryBtn"}`}>Class information</button>
                            <button onClick={(e) => setClassList({ ...classList, historyBtn: 'HistoryBtn' })} className={` ${classList.historyBtn === "HistoryBtn" ? "active classInfoBtn" : "uploadHistoryBtn"}`} >Upload history</button>
                        </div>
                        {classList.historyBtn === 'HistoryBtn' && <div>
                            <span className='historySortTable'>Sort by</span> &nbsp;
                            <img src='/OnBoard/DownWardArrow.png' alt='down arrow' />
                        </div>}
                    </div>

                    {classList.historyBtn === 'classInfo' && <div className='ClassesContainer mt-4'>

                        <div className='classListDetail mt-4 p-4'>
                            <div className='classNumber'>Class 9</div>
                            <div className='w-100 d-flex flex-wrap  mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>A - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>A - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'
                                                    onClick={(e) => {
                                                        setClassList({
                                                            ...classList,
                                                            edit: true
                                                        })
                                                    }}
                                                >Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>B - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>B - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex  flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>C - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>C - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap  mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>D - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>D - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>E - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>E - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>


                        </div>

                        <div className='classListDetail mt-4 p-4'>
                            <div className='classNumber'>Class 10</div>
                            <div className='w-100 d-flex flex-wrap  mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>A - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>A - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>B - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>B - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex  flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>C - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>C - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap  mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>D - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>D - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>
                            <div className='w-100 d-flex flex-wrap mt-3'>
                                <div className='detail_containers1'>
                                    <div className='listHeading'>Section</div>
                                    <div className='listDetail'>E - Section</div>
                                </div>
                                <div className='detail_containers2'>
                                    <div className='listHeading'>Block</div>
                                    <div className='listDetail'>E - Block</div>
                                </div>
                                <div className='detail_containers3'>
                                    <div className='listHeading'>Subjects</div>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <div className='subjectListContainer'>Math</div>
                                        <div className='subjectListContainer'>Hindi</div>
                                        <div className='subjectListContainer'>Science</div>
                                        <div className='subjectListContainer'>+4</div>
                                    </div>
                                </div>
                                <div className='detail_containers4'>
                                    <div className='listHeading'>Class teacher</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers5'>
                                    <div className='listHeading'>Class coordinator</div>
                                    <div className='listDetail'>--</div>
                                </div>
                                <div className='detail_containers6'>
                                    <div className='listHeading'>Remarks</div>
                                    <div className='listDetail'>Lorem Ipsum is simply dummy text of the printing and...</div>
                                </div>
                                <div className='detail_containers7 d-flex justify-content-center align-items-center'>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </div>

                            </div>


                        </div>

                    </div>
                    }

                    {classList.historyBtn === 'HistoryBtn' &&
                        <div className='mt-4'>
                            <table class="table table-borderless">
                                <thead>
                                    <tr className='Heading_tableContainer'>
                                        <th className='tableHeaderText' >Document name</th>
                                        <th className='tableHeaderText' >Date</th>
                                        <th className='tableHeaderText' >Total students</th>
                                        <th className='tableHeaderText'>Accepted</th>
                                        <th className='tableHeaderText'>Rejected</th>
                                        <th className='tableHeaderText'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='TeacherTableDetails align-items-center'>
                                            <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                            <span className='TeacherTableDetails'>Class data_01</span>
                                        </td>
                                        <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                        <td className='TeacherTableDetails'>280</td>
                                        <td className='TeacherTableDetails'>250</td>
                                        <td className='TeacherTableDetails'>30</td>
                                        <td  style={{cursor:'pointer'}} className='d-flex align-items-center gap-1'>

                                            <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                            <div className='TeacherTableDetails'>Download</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='TeacherTableDetails align-items-center'>
                                            <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                            <span className='TeacherTableDetails'>Class data_01</span>
                                        </td>
                                        <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                        <td className='TeacherTableDetails'>280</td>
                                        <td className='TeacherTableDetails'>250</td>
                                        <td className='TeacherTableDetails'>30</td>
                                        <td className='d-flex align-items-center gap-1'>

                                            <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                            <div className='TeacherTableDetails'>Download</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='TeacherTableDetails align-items-center'>
                                            <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                            <span className='TeacherTableDetails'>Class data_01</span>
                                        </td>
                                        <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                        <td className='TeacherTableDetails'>280</td>
                                        <td className='TeacherTableDetails'>250</td>
                                        <td className='TeacherTableDetails'>30</td>
                                        <td className='d-flex align-items-center gap-1'>

                                            <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                            <div className='TeacherTableDetails'>Download</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='TeacherTableDetails align-items-center'>
                                            <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                            <span className='TeacherTableDetails'>Class data_01</span>
                                        </td>
                                        <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                        <td className='TeacherTableDetails'>280</td>
                                        <td className='TeacherTableDetails'>250</td>
                                        <td className='TeacherTableDetails'>30</td>
                                        <td className='d-flex align-items-center gap-1'>

                                            <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                            <div className='TeacherTableDetails'>Download</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='TeacherTableDetails align-items-center'>
                                            <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                            <span className='TeacherTableDetails'>Class data_01</span>
                                        </td>
                                        <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                        <td className='TeacherTableDetails'>280</td>
                                        <td className='TeacherTableDetails'>250</td>
                                        <td className='TeacherTableDetails'>30</td>
                                        <td className='d-flex align-items-center gap-1'>

                                            <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                            <div className='TeacherTableDetails'>Download</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='TeacherTableDetails align-items-center'>
                                            <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                            <span className='TeacherTableDetails'>Class data_01</span>
                                        </td>
                                        <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                        <td className='TeacherTableDetails'>280</td>
                                        <td className='TeacherTableDetails'>250</td>
                                        <td className='TeacherTableDetails'>30</td>
                                        <td className='d-flex align-items-center gap-1'>
                                            <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                            <div className='TeacherTableDetails'>Download</div>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    }
                </>
            }
            {classList.edit && <EditClassSA setClassList={setClassList} />}







            {/* <div className='footer_container_classInfo mt-3'>
                <div className='mt-4 footer_container_classInfo'>
                    <button id='NextBtn_school_masterData'
                        // onClick={(e) => setAddClassComp({
                        //     addTeacher: true
                        // })}
                        type="submit" >Next</button>
                </div>
            </div> */}

        </div>
    )
}
