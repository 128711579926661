import React, { useState } from "react";
import "./Permission.css";
import { BsPlusCircle } from "react-icons/bs";
import AddClass from "../Class/AddClass";
import BasicInformation from "../BasicInformation";
import AddMasterData from "../MasterData/AddMasterData";
import { Link } from "react-router-dom";

export default function Permission() {
  const [permision, setPermision] = useState({
    staf: "Principal",
    addClass: false,
  });
  return (
    <>
      {!permision.addClass && (
        <div className="Total_container_BasicInfo">
          <div className="d-flex justify-content-start">
            <img
              className="SchoolIcon_basicInfo"
              src="/OnBoard/SchoolIcon.png"
              alt="banner img"
            />
          </div>
          <div className="steps mt-4">Steps 3/8</div>
          <div className="d-flex  mt-4">
            <span className="d-flex  justify-content-center">
              <Link to="/BasicInformation" className="Completed_circle">
                <span className=""></span>
              </Link>
            </span>
            <span className="CompletedDiv"></span>

            <div className="d-flex justify-content-center">
              <Link to="/AddMasterData" className="Completed_circle">
                {" "}
                <div className=""></div>
              </Link>
            </div>
            <span className="CompletedDiv"></span>

            <div className="d-flex justify-content-center">
              <div className="Active_circle"></div>
            </div>
            <span className="innerDiv"></span>

            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>

            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>

            <div className="d-flex justify-content-center">
              <div className="NonActive_circle"></div>
            </div>
            {/* <span className="innerDiv"></span>

            <div className="d-flex justify-content-center ">
              <div className="NonActive_circle"></div>
            </div>
            <span className="innerDiv"></span>

            <div className="d-flex justify-content-center ">
              <div className="NonActive_circle"></div>
            </div> */}
          </div>

          <div className="d-flex">
            <span className="d-flex justify-content-center">
              <span className="Active_circle_name ">Basic</span>
            </span>

            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="Active_circle_name">Master Data</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="Active_circle_name">Permission</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="">Class</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4 pl-3">Teachers</div>
            </div>
            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-4">Students</div>
            </div>
            {/* <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-3">Engagement</div>
            </div>

            <span className="innerDiv_name"></span>
            <div className="d-flex justify-content-center">
              <div className="ml-2">Query</div>
            </div> */}
          </div>

          <div className="PermissionHeading mt-4">Permission access</div>

          <div className="d-flex flex-wrap gap-2 mt-4 pt-2">
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "Principal" });
              }}
              className={` ${
                permision.staf === "Principal"
                  ? "active Staf_buttonsActive"
                  : "Staf_buttons"
              }`}
            >
              Principal
            </div>
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "VicePrincipal" });
              }}
              className={` ${
                permision.staf === "VicePrincipal"
                  ? "active Staf_buttonsActive"
                  : "Staf_buttons"
              }`}
            >
              Vice - Principal
            </div>
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "Director" });
              }}
              className={` ${
                permision.staf === "Director"
                  ? "active Staf_buttonsActive"
                  : "Staf_buttons"
              }`}
            >
              Director
            </div>
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "HeadAdminitrative" });
              }}
              className={` ${
                permision.staf === "HeadAdminitrative"
                  ? "active Staf_buttonsActive"
                  : "Staf_buttons"
              }`}
            >
              Head Adminitrative
            </div>
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "Coodinator" });
              }}
              className={` ${
                permision.staf === "Coodinator"
                  ? "active Staf_buttonsActive"
                  : "Staf_buttons"
              }`}
            >
              Coodinator
            </div>
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "ClassTeacher" });
              }}
              className={` ${
                permision.staf === "ClassTeacher"
                  ? "active Staf_buttonsActive"
                  : "Staf_buttons"
              }`}
            >
              Class Teacher
            </div>
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "SubjectTeacher" });
              }}
              className={` ${
                permision.staf === "SubjectTeacher"
                  ? "active Staf_buttonsActive"
                  : "Staf_buttons"
              }`}
            >
              Subject Teacher
            </div>
            <div
              onClick={(e) => {
                setPermision({ ...permision, staf: "addStaf" });
              }}
              className={` ${
                permision.staf === "addStaf"
                  ? "active AddStaf_buttonsActive gap-2"
                  : "Staf_buttons_add gap-2"
              }`}
            >
              <BsPlusCircle /> Add Role
            </div>
          </div>

          <div className="d-flex justify-content-between modules_container pb-2 mt-4">
            <div className="moduleNames d-flex justify-content-start">
              Modules
            </div>
            <div className="d-flex">
              <div className="moduleNames d-flex justify-content-center gap-2 ">
                All
                <div>
                  <img src="/OnBoard/CreateModule.png" alt="module icon" />
                </div>
              </div>
              <div className="moduleNames d-flex justify-content-center gap-2">
                Create
                <div>
                  <img src="/OnBoard/CreateModule.png" alt="module icon" />
                </div>
              </div>
              <div className="moduleNames d-flex justify-content-center gap-2">
                Read
                <div>
                  <img src="/OnBoard/CreateModule.png" alt="module icon" />
                </div>
              </div>
              <div className="moduleNames  d-flex justify-content-center gap-2">
                Update{" "}
                <div>
                  <img src="/OnBoard/CreateModule.png" alt="module icon" />
                </div>
              </div>
              <div className="moduleNames  d-flex justify-content-center gap-2">
                Delete{" "}
                <div>
                  <img src="/OnBoard/CreateModule.png" alt="module icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex justify-content-between mt-4">
              <div className="SubmoduleNames d-flex justify-content-start">
                Student Information
              </div>
              <div className="d-flex">
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="SubmoduleNames d-flex justify-content-start">
                Class Information
              </div>
              <div className="d-flex">
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="SubmoduleNames d-flex justify-content-start">
                Teacher Information
              </div>
              <div className="d-flex">
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="SubmoduleNames d-flex justify-content-start">
                Timetable
              </div>
              <div className="d-flex">
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="SubmoduleNames d-flex justify-content-start">
                Content Library
              </div>
              <div className="d-flex">
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
                <div className="moduleNames d-flex justify-content-center gap-2 ">
                  <input type="checkbox" />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end gap-4 mt-3">
            <button className="resetBtn">Reset</button>
            <Link to="/AddClass">
              <button
                className="nextBtn"
                onClick={(e) =>
                  setPermision({
                    ...permision,
                    addClass: true,
                  })
                }
              >
                Next
              </button>
            </Link>
          </div>
        </div>
      )}
      {/* {permision.addClass && <AddClass />} */}
    </>
  );
}
