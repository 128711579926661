import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { useSchoolContext } from "../../../schoolContext";
import axios from "../../../axios";
import DeletePopupStudent from "./DeletePopupStudent";
import ActivePopupStudent from "./ActivePopupStudent";
import { IP } from "../../../internalServer";
import ErrorPopup from "../../../ErrorPopup";

export default function TeacherList({ setAddStudentComp }) {
  const [studentTable, setStudentTable] = useState({
    historyBtn: "classInfo",
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [students, setStudents] = useState([]);
  const [islistUpdated, setIslistUpdated] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [ElementId, setElementId] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  let { schoolId } = useSchoolContext();
  if (!schoolId) {
    const schoolExtractedId = localStorage.getItem("schoolId");
    schoolId = JSON.parse(schoolExtractedId);
  }

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorOccurred(true);
    setErrorMessage(message);
  };

  const handleCloseError = () => {
    setErrorOccurred(false);
    setErrorMessage("");
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleDeleteClick = (stud) => {
    const id = stud.id;
    setDeletePopupOpen(true);
    setElementId(id);
    setIslistUpdated(true);
  };

  const handleInActiveClick = (item) => {
    setisActive(true);
    setElementId(item);
    setIslistUpdated(true);
  };

  useEffect(() => {
    async function fetchSections() {
      try {
        const sortQueryParam = (() => {
          switch (selectedOption) {
            case "LastModified":
              return "Modified";
            case "InactiveState":
              return "InActive";
            case "ActiveState":
              return "Active";
            case "Alphabetically":
              return "Alphabetically";
            default:
              return "";
          }
        })();
        const res = await axios.get(
          `/api/v1/school/student/get/filter/school/${schoolId}?sort=${sortQueryParam}`
        );
        const fetchedData = res.data.data;
        setStudents(fetchedData);
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchSections();
  }, [
    schoolId,
    islistUpdated,
    selectedOption,
    ElementId,
    isDeletePopupOpen,
    isActive,
  ]);

  useEffect(() => {
    async function fetchHistory() {
      try {
        const res = await axios.get(
          `/api/v1/school/student/getUploadHistory/school/${schoolId}`
        );
        res.data.data && setHistoryData(res.data.data);
      } catch (error) {
        handleError(error.response.data.message);
      }
    }
    fetchHistory();
  }, [schoolId]);

  const handleDownload = (path) => {
    const downloadUrl = `${IP}${path.substring(path.indexOf("/"))}`;
    const downloadAnchor = document.createElement("a");
    downloadAnchor.style.display = "none";
    document.body.appendChild(downloadAnchor);
    downloadAnchor.href = downloadUrl;
    downloadAnchor.setAttribute("download", "Timetable_template.xlsx");
    downloadAnchor.click();
    document.body.removeChild(downloadAnchor);
  };

  return (
    <div>
      <div className="schoolInfo_head">Student infomation </div>

      <div className="heading_belowText"></div>

      <div className="d-flex justify-content-between">
        <div className="mt-4 ">
          <button
            onClick={(e) =>
              setStudentTable({ ...studentTable, historyBtn: "classInfo" })
            }
            className={` ${
              studentTable.historyBtn === "classInfo"
                ? "active classInfoBtn"
                : "uploadHistoryBtn"
            }`}
          >
            Student information
          </button>
          <button
            onClick={(e) =>
              setStudentTable({ ...studentTable, historyBtn: "HistoryBtn" })
            }
            className={` ${
              studentTable.historyBtn === "HistoryBtn"
                ? "active classInfoBtn"
                : "uploadHistoryBtn"
            }`}
          >
            Upload history
          </button>
        </div>
        <Popup
          on="click"
          closeOnDocumentClick={true}
          trigger={
            <div className=" filter_button filterIcon">
              <img src="/images/filter_icon.svg" alt="" />
              <span>Filter</span>
            </div>
          }
          content={
            <div className="container_threedot">
              <div
                className={`PopupThreedots ${
                  selectedOption === "All" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("All")}
              >
                All
              </div>
              <div
                className={`PopupThreedots ${
                  selectedOption === "LastModified" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("LastModified")}
              >
                Sort by Last Modified
              </div>
              <div
                className={`PopupThreedots ${
                  selectedOption === "ActiveState" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("ActiveState")}
              >
                Active State
              </div>
              <div
                className={`PopupThreedots ${
                  selectedOption === "Alphabetically" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("Alphabetically")}
              >
                Sort by Alphabetically
              </div>

              <div
                className={`PopupThreedots ${
                  selectedOption === "InactiveState" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("InactiveState")}
              >
                In-Active State
              </div>
            </div>
          }
          position="bottom center"
        />
      </div>

      {studentTable.historyBtn === "classInfo" && (
        <div className="mt-4">
          <table class="table table-borderless">
            <thead>
              <tr className="Heading_tableContainer">
                <th className="Name">Name</th>
                <th>School email</th>
                <th>Academic Session</th>
                <th>Timetable Session</th>
                <th>Standard</th>
                <th>Section</th>
                <th>Mobile</th>
                <th>Co-ordinator</th>
                <th>Class Teacher</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {students.length > 0 &&
                students.map((student) => (
                  <tr
                    style={{
                      ...(student?.active === false && {
                        backgroundColor: "rgba(225, 29, 52, 0.2)",
                        padding: "10px",
                        paddingRight: "0px",
                        borderRadius: "8px",
                      }),
                    }}
                  >
                    <td className="TeacherTableDetails">
                      <img
                        src={
                          student.profile_picture
                            ? `${
                                IP + student.profile_picture?.replace(/^\./, "")
                              }`
                            : "/OnBoard/TeacherTableImg.png"
                        }
                        className="imagesSize"
                        alt="profile img"
                      />{" "}
                      &nbsp;
                      <span>{student.name}</span>
                    </td>
                    <td className="TeacherTableDetails">
                      {student.schoolEmail}
                    </td>
                    <td className="TeacherTableDetails">
                      {student?.academicYearSession?.name}
                    </td>
                    <td className="TeacherTableDetails">
                      {student?.timetableSession?.name}
                    </td>
                    <td className="TeacherTableDetails">
                      {student?.standard?.name}
                    </td>
                    <td className="TeacherTableDetails">
                      {student?.section?.name}
                    </td>
                    <td className="TeacherTableDetails">
                      {student.mobileNumber}
                    </td>
                    <td
                      className="TeacherTableDetails"
                      title={
                        student.teacherInformation.length > 0
                          ? student.teacherInformation
                              .map(
                                (obj) =>
                                  obj.isClassCoOrdinator && obj?.teacher?.name
                              )
                              .join(", ")
                          : "N/A"
                      }
                    >
                      {student.teacherInformation.length > 0
                        ? student.teacherInformation
                            .filter((obj) => obj.isClassCoOrdinator === true)
                            .map((obj, index, arr) => (
                              <span key={obj?.teacher?.name}>
                                {index === 0 ? obj?.teacher?.name : "+"}
                                {index === arr.length - 1 && arr.length > 1
                                  ? "+"
                                  : ""}
                              </span>
                            ))
                        : "N/A"}
                    </td>
                    <td
                      className="TeacherTableDetails"
                      title={
                        student.teacherInformation.length > 0
                          ? student.teacherInformation
                              .map(
                                (obj) =>
                                  obj?.isClassTeacher && obj?.teacher?.name
                              )
                              .join(", ")
                          : "N/A"
                      }
                    >
                      {student.teacherInformation.length > 0
                        ? student.teacherInformation
                            .filter((obj) => obj.isClassTeacher === true)
                            .map((obj, index, arr) => (
                              <span key={obj?.teacher?.name}>
                                {index === 0 ? obj?.teacher?.name : "+"}
                                {index === arr.length - 1 && arr.length > 1
                                  ? "+"
                                  : ""}
                              </span>
                            ))
                        : "N/A"}
                    </td>

                    <td>
                      <Popup
                        on="click"
                        trigger={
                          <img
                            style={{ cursor: "pointer" }}
                            src="/OnBoard/HorizontalThreedot.png"
                            alt="three dot"
                          />
                        }
                        content={
                          <div className="container_threedot">
                            {student?.active && (
                              <div
                                className="PopupThreedots"
                                onClick={() => {
                                  setAddStudentComp((prev) => ({
                                    ...prev,
                                    editableId: student.id,
                                    Studentinfo: true,
                                    StudentList: false,
                                  }));
                                }}
                              >
                                Edit
                              </div>
                            )}
                            <div
                              className="PopupThreedots"
                              onClick={() => handleDeleteClick(student)}
                            >
                              Delete
                            </div>
                            <div
                              className="PopupThreedots"
                              onClick={() => handleInActiveClick(student)}
                            >
                              {student?.active === false
                                ? "Activate"
                                : "In-Active"}
                            </div>
                          </div>
                        }
                        position="bottom center"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {studentTable.historyBtn === "HistoryBtn" && (
        <div className="mt-4">
          <table class="table table-borderless">
            <thead>
              <tr className="Heading_tableContainer">
                <th className="Name">Document Name</th>
                <th>Date</th>
                <th>Total Students</th>
                <th>Accepted</th>
                <th>Rejected</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {historyData.length > 0 &&
                historyData.map((data, index) => (
                  <tr key={index}>
                    <td className="TeacherTableDetails">
                      {data.document_name}
                    </td>
                    <td className="TeacherTableDetails">
                      {new Date(data.date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </td>
                    <td className="TeacherTableDetails">{data.total}</td>
                    <td className="TeacherTableDetails">{data.accepted}</td>
                    <td className="TeacherTableDetails">{data.rejected}</td>
                    <td
                      className="TeacherTableDetails"
                      onClick={() => handleDownload(data.document_url)}
                    >
                      <img src="/images/DownloadButton.svg"></img>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {isDeletePopupOpen && (
        <DeletePopupStudent
          setDeletePopupOpen={setDeletePopupOpen}
          ElementId={ElementId}
        />
      )}
      {isActive && (
        <ActivePopupStudent setisActive={setisActive} ElementId={ElementId} />
      )}
      {errorOccurred && (
        <ErrorPopup errorMessage={errorMessage} closePopup={handleCloseError} />
      )}
      <div className="d-flex flex-wrap justify-content-end align-items-center mt-4 footer_container">
        <Link to="/OnBoardingSuccess">
          <button
            id="NextBtn_school_masterData"
            className="NextButtonPosition"
            // onClick={(e) =>
            //   setAddStudentComp({
            //     editableId:null,

            //     addStudent: true,
            //   })
            // }
            type="submit"
          >
            Done
          </button>
        </Link>
      </div>
    </div>
  );
}
