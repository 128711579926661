import React from 'react'

function PasswordSuccess() {
  const handleRedirect = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('LoginNow', 'true');
    window.location.href = `${"/"}?${urlParams.toString()}`;
}

  return (
    <div className='completeContainer'>
      <div className='overallData'>
        <img src='/images/LoginSuccess.svg' className='successImage'/>
        <h1 className='bigText'>You have successfully setup your password</h1>
        <p className='underTecxt'>Now you can login with your username and password.</p>
        <button onClick={handleRedirect}>Login now</button>
      </div>
    </div>
  )
}

export default PasswordSuccess
