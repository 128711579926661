import React, { useState } from 'react'
import { Popup } from "semantic-ui-react";
import { BsPlusCircle } from 'react-icons/bs';
import TeacherEditSA from './TeacherEditSA';

export default function TeacherInfoSA({ setAddTeacherComp }) {
    const [teacherList, setTeacherList] = useState({
        historyBtn: 'classInfo',
        editTeacher: false
    })

    return (
        <div>

            <div className='d-flex  flex-wrap justify-content-between align-items-center'>
                <div>
                    <div className='SA_Teacher_heading'>Teacher infomation </div>
                    <div className='heading_belowText'>Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão.</div>
                </div>
                {!teacherList.editTeacher &&
                    <div>
                        <button className='UploadBtnClassInfo mr-2'>Upload</button>
                        <button className='AddClassBtnClassInfo'><BsPlusCircle />&nbsp;Add Teacher</button>
                    </div>
                }
            </div>

            {!teacherList.editTeacher && <>
                <div className='d-flex justify-content-between align-items-center mt-4'>
                    <div>
                        <button onClick={(e) => setTeacherList({ ...teacherList, historyBtn: 'classInfo' })} className={` ${teacherList.historyBtn === "classInfo" ? "active classInfoBtn" : "uploadHistoryBtn"}`}>Teacher information</button>
                        <button onClick={(e) => setTeacherList({ ...teacherList, historyBtn: 'HistoryBtn' })} className={` ${teacherList.historyBtn === "HistoryBtn" ? "active classInfoBtn" : "uploadHistoryBtn"}`} >Upload history</button>
                    </div>
                    <div >
                        <span className='sortByText'>Sort by</span> &nbsp;
                        <img src='/OnBoard/DownWardArrow.png' alt='down arrow' />
                    </div>
                </div>


                {teacherList.historyBtn === 'classInfo' && <div className='mt-4'>
                    <table class="table table-borderless">
                        <thead>
                            <tr className='Heading_tableContainer'>
                                <th className='tableHeaderText' >Name</th>
                                <th className='tableHeaderText'>School email</th>
                                <th className='tableHeaderText'>Personal email</th>
                                <th className='tableHeaderText'>Mobile</th>
                                <th className='tableHeaderText'>Co-ordinator</th>
                                <th className='tableHeaderText'>Class Teacher</th>
                                <th className='tableHeaderText'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='TeacherTableDetails'>
                                    <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                    <span>Dilip Kumar</span>
                                </td>
                                <td className='TeacherTableDetails'>dilip@kend_vidyala-teacher.com</td>
                                <td className='TeacherTableDetails'>dilip_kumar@gmail.com</td>
                                <td className='TeacherTableDetails'>98121 11921</td>
                                <td className='TeacherTableDetails'>3A</td>
                                <td className='d-flex gap-1'>
                                    <div className='subjectListContainer d-flex justify-content-center'>English</div>
                                    <div className='subjectListContainer d-flex justify-content-center'>Math</div>
                                </td>
                                <td>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img style={{ cursor: 'pointer' }} src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'
                                                    onClick={(e) => {
                                                        setTeacherList({
                                                            ...teacherList,
                                                            editTeacher: true
                                                        })
                                                    }}
                                                >Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='TeacherTableDetails' >
                                    <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                    <span>Dilip Kumar</span>
                                </td>
                                <td className='TeacherTableDetails'>dilip@kend_vidyala-teacher.com</td>
                                <td className='TeacherTableDetails'>dilip_kumar@gmail.com</td>
                                <td className='TeacherTableDetails'>98121 11921</td>
                                <td className='TeacherTableDetails'>4A</td>
                                <td className='d-flex gap-1'>
                                    <div className='subjectListContainer d-flex justify-content-center'>English</div>
                                    <div className='subjectListContainer d-flex justify-content-center'>Math</div>
                                </td>
                                <td>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img style={{ cursor: 'pointer' }} src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='TeacherTableDetails' >
                                    <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                    <span>Dilip Kumar</span>
                                </td>
                                <td className='TeacherTableDetails'>dilip@kend_vidyala-teacher.com</td>
                                <td className='TeacherTableDetails'>dilip_kumar@gmail.com</td>
                                <td className='TeacherTableDetails'>98121 11921</td>
                                <td className='TeacherTableDetails'>2A</td>
                                <td className='d-flex gap-1'>
                                    <div className='subjectListContainer d-flex justify-content-center'>English</div>
                                    <div className='subjectListContainer d-flex justify-content-center'>Math</div>
                                </td>
                                <td>
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img style={{ cursor: 'pointer' }} src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='TeacherTableDetails'>
                                    <img src='/OnBoard/TeacherTableImg.png' alt='profile img' /> &nbsp;
                                    <span>Dilip Kumar</span>
                                </td>
                                <td className='TeacherTableDetails'>dilip@kend_vidyala-teacher.com</td>
                                <td className='TeacherTableDetails'>dilip_kumar@gmail.com</td>
                                <td className='TeacherTableDetails'>98121 11921</td>
                                <td className='TeacherTableDetails'>2A</td>
                                <td className='d-flex gap-1'>
                                    <div className='subjectListContainer d-flex justify-content-center'>English</div>
                                    <div className='subjectListContainer d-flex justify-content-center'>Math</div>
                                </td>
                                <td >
                                    <Popup
                                        on="click"
                                        trigger={
                                            <img style={{ cursor: 'pointer' }} src='/OnBoard/HorizontalThreedot.png' alt='three dot' />
                                        }
                                        content={
                                            <div className='container_threedot'>
                                                <div className='PopupThreedots'>Edit</div>
                                                <div className='PopupThreedots'>Delete</div>
                                                <div className='PopupThreedots'>In-Active</div>
                                            </div>
                                        }
                                        position="bottom center"
                                    />
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>}
                {teacherList.historyBtn === 'HistoryBtn' &&
                    <div className='mt-4'>
                        <table class="table table-borderless">
                            <thead>
                                <tr className='Heading_tableContainer'>
                                    <th className='tableHeaderText' >Document name</th>
                                    <th className='tableHeaderText' >Date</th>
                                    <th className='tableHeaderText' >Total students</th>
                                    <th className='tableHeaderText'>Accepted</th>
                                    <th className='tableHeaderText'>Rejected</th>
                                    <th className='tableHeaderText'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='TeacherTableDetails align-items-center'>
                                        <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                        <span className='TeacherTableDetails'>Class data_01</span>
                                    </td>
                                    <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                    <td className='TeacherTableDetails'>280</td>
                                    <td className='TeacherTableDetails'>250</td>
                                    <td className='TeacherTableDetails'>30</td>
                                    <td style={{ cursor: 'pointer' }} className='d-flex align-items-center gap-1'>

                                        <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                        <div className='TeacherTableDetails'>Download</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='TeacherTableDetails align-items-center'>
                                        <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                        <span className='TeacherTableDetails'>Class data_01</span>
                                    </td>
                                    <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                    <td className='TeacherTableDetails'>280</td>
                                    <td className='TeacherTableDetails'>250</td>
                                    <td className='TeacherTableDetails'>30</td>
                                    <td className='d-flex align-items-center gap-1'>

                                        <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                        <div className='TeacherTableDetails'>Download</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='TeacherTableDetails align-items-center'>
                                        <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                        <span className='TeacherTableDetails'>Class data_01</span>
                                    </td>
                                    <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                    <td className='TeacherTableDetails'>280</td>
                                    <td className='TeacherTableDetails'>250</td>
                                    <td className='TeacherTableDetails'>30</td>
                                    <td className='d-flex align-items-center gap-1'>

                                        <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                        <div className='TeacherTableDetails'>Download</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='TeacherTableDetails align-items-center'>
                                        <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                        <span className='TeacherTableDetails'>Class data_01</span>
                                    </td>
                                    <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                    <td className='TeacherTableDetails'>280</td>
                                    <td className='TeacherTableDetails'>250</td>
                                    <td className='TeacherTableDetails'>30</td>
                                    <td className='d-flex align-items-center gap-1'>

                                        <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                        <div className='TeacherTableDetails'>Download</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='TeacherTableDetails align-items-center'>
                                        <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                        <span className='TeacherTableDetails'>Class data_01</span>
                                    </td>
                                    <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                    <td className='TeacherTableDetails'>280</td>
                                    <td className='TeacherTableDetails'>250</td>
                                    <td className='TeacherTableDetails'>30</td>
                                    <td className='d-flex align-items-center gap-1'>

                                        <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                        <div className='TeacherTableDetails'>Download</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='TeacherTableDetails align-items-center'>
                                        <img src='/LandingPageimg/xlsLogoSA.png' alt='xls Logo' /> &nbsp;
                                        <span className='TeacherTableDetails'>Class data_01</span>
                                    </td>
                                    <td className='TeacherTableDetails'>Jan 22, 2022</td>
                                    <td className='TeacherTableDetails'>280</td>
                                    <td className='TeacherTableDetails'>250</td>
                                    <td className='TeacherTableDetails'>30</td>
                                    <td className='d-flex align-items-center gap-1'>
                                        <div >  <img src='/LandingPageimg/downloadLogo.png' alt='downloadLogo ' /></div>
                                        <div className='TeacherTableDetails'>Download</div>
                                    </td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                }
            </>}

            {teacherList.editTeacher && <TeacherEditSA setTeacherList={setTeacherList} />}
        </div>
    )
}
