import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { TextField, IconButton } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function TimeClockValue({ selectedTime, onChange, ref }) {
  const selectedTimeConverted = dayjs(selectedTime, "HH:mm");
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileTimePicker
        ref={ref}
        className="timePicker"
        value={selectedTimeConverted}
        onChange={onChange}
        renderInput={(props) => <TextField variant="standard" {...props} />}
        minutesStep={5}
        slotProps={{ textField: { variant: "standard" } }}
        InputProps={{
          disableUnderline: true,
        }}
        open={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
      />
      <IconButton
        style={{ color: "orange" }}
        onClick={() => setIsPickerOpen(!isPickerOpen)}
      >
        <AccessTimeIcon />
      </IconButton>
    </LocalizationProvider>
  );
}
