import React from 'react'
import { BsPlusCircle } from 'react-icons/bs';


export default function ChapterContentPopup({ setPartFour }) {
    return (
        <div className='conatiner_chapterContent'>
            <div className='Subconatiner_chapterContent row'>

                <div className='col-md-7'>
                    <div className='popupHeading'>Add Chapter Contents</div>
                    <div className='Topic_container mt-4  p-3'>
                        <div>Topics</div>

                        <div className='container_PreferredEducationBoard'>
                            {/* <input/> */}
                            <div className='d-flex justify-content-end'>
                                <button className='ThirdPartAddBtn'><BsPlusCircle />&nbsp; Add Topics</button>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap mt-2'>
                            <div className='Subjects_part3 '>Good Habits </div>
                            <div className='Subjects_part3'> Morning Ettiquettes</div>
                            <div className='Subjects_part3'> Good Habits</div>
                            <div className='Subjects_part3'> Morning Ettiquettes</div>
                        </div>
                    </div>

                    <div className='SupportedContentType mt-4  p-3'>
                        <div>Add all supported content types</div>
                        <input className='booksInputs' placeholder='Content Name' />
                        <input className='booksInputs' placeholder='Content Type' />
                        <input className='booksInputs' placeholder='Content URL' />
                    </div>
                </div>



                <div className='col-md-5'>
                    <div className='RightSidePopupContainer p-3'>
                        <div>
                            <select className="form-select dropdownPopup " >
                                <option selected>Boook 1</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div className='d-flex justify-content-between conatainer_contentBelow_active mt-4 pr-2 pl-2'>
                            <div>1 - Good Morning</div>
                            <img src='/OnBoard/TwoLineIcon.png' alt='icon'></img>
                        </div>
                        <div className='d-flex justify-content-between conatainer_contentBelow mt-2 pr-2 pl-2'>
                            <div>2 - The Magic Garden</div>
                            <img src='/OnBoard/TwoLineIcon.png' alt='icon'></img>
                        </div>
                        <div className='d-flex justify-content-between conatainer_contentBelow mt-2 pr-2 pl-2'>
                            <div>3 - Bird Talk</div>
                            <img src='/OnBoard/TwoLineIcon.png' alt='icon'></img>
                        </div>

                        <div className='d-flex justify-content-between conatainer_contentBelow mt-2 pr-2 pl-2'>
                            <div>4 - Nina and the Baby Sparrows</div>
                            <img src='/OnBoard/TwoLineIcon.png' alt='icon'></img>
                        </div>



                    </div>
                </div>
                <div className='d-flex justify-content-end mt-3'>
                    <button className='saveBtnPopUp'>Save</button>
                </div>
                <div>
                    <img
                        onClick={(e) => setPartFour({
                            addChapter: false
                        })}
                        className='Crossicon_chapter' src='/OnBoard/CrossIcon.png' alt='cross icon'>
                    </img>
                </div>
            </div>

        </div>
    )
}
