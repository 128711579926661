import React from "react";
import { BiLogOut } from "react-icons/bi";

function LogOutButton() {
  const handleLogout = () => {
    window.location.pathname = "/";
    localStorage.removeItem("schoolId");
    localStorage.removeItem("schoolAuth");
  };
  return (
    <div onClick={handleLogout} className="LogOutButtonSize">
      <BiLogOut color="#F77F00" style={{ width: "32px", height: "32px" }} />
    </div>
  );
}

export default LogOutButton;
