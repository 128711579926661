import React, { useState } from 'react'
import '../StudentSection/StudentSection.css'
import { IoIosArrowBack } from "react-icons/io";
import { ResponsiveBar } from '@nivo/bar';

export default function StudentDetailSchool({ setStudentDetail }) {
     const [progress, setProgress] = useState({
        progress1: 80,
        progress2: 40,
        progress3: 72,
        recomended: false,
        barChart: false,
    })


    const handelCLick = () => {
        if (progress.recomended) {
            setProgress({
                ...progress,
                recomended: false
            })

        } else {
            setProgress({
                ...progress,
                recomended: true
            })
        }
    }

    const handelCLickBarChart = () => {
        if (progress.barChart) {
            setProgress({
                ...progress,
                barChart: false
            })

        } else {
            setProgress({
                ...progress,
                barChart: true
            })
        }
    }

     // two bar chart 
    const data = [
        { Subject: 'English', Strengths: 40, Weakness: 78 },
        { Subject: 'Kannada', Strengths: 35, Weakness: 60 },
        { Subject: 'Science', Strengths: 100, Weakness: 88 },
        { Subject: 'Social', Strengths: 93, Weakness: 52 },
        { Subject: 'Math', Strengths: 80, Weakness: 45 },
        { Subject: 'EVS', Strengths: 58, Weakness: 90 },
    ];

    return (
        <div>
            <div className='d-flex gap-3 h-20'>
                <div className='SBoard'>
                    <IoIosArrowBack />
                </div>
                <div className='SBoard' onClick={(e) => setStudentDetail(false)}>Student board</div>
                <div> /</div>
                <div className='StudentInformationPath'>Corey Vetrovs</div>
            </div>
            <div className='container_persentage_block'>
                <div className='d-flex flex-wrap  justify-content-between mt-2  ' >
                    <div className='d-flex justify-content-between align-items-center p-3 container_StudentB_block mt-2'>

                        <div>
                            <div className='persentage_SB'>72%</div>
                            <div className='assesment_text_Sb'>Assessment</div>
                        </div>
                        <img className='img_assesment_SB' src='/StudentImages/AssesmentStudentB.svg' alt='assement student board'></img>
                    </div>
                    <div className='d-flex justify-content-between align-items-center p-3 container_StudentB_block mt-2'>
                        <div>
                            <div className='persentage_SB'>76%</div>
                            <div className='assesment_text_Sb'>Social Learning</div>
                        </div>
                        <img className='img_assesment_SB' src='/StudentImages/SocialLearning.svg' alt='assement student board'></img>
                    </div>
                    <div className='d-flex justify-content-between align-items-center p-3 container_StudentB_block mt-2'>
                        <div>
                            <div className='persentage_SB'>87%</div>
                            <div className='assesment_text_Sb'>Leacture Engagement</div>
                        </div>
                        <img className='img_assesment_SB' src='/StudentImages/LeactureEngage.svg' alt='assement student board'></img>
                    </div>
                    <div className='d-flex justify-content-between align-items-center p-3 container_StudentB_block mt-2'>
                        <div>
                            <div className='persentage_SB'>60%</div>
                            <div className='assesment_text_Sb'>Home work</div>
                        </div>
                        <img className='img_assesment_SB' src='/StudentImages/HomeWorkSB.png' alt='assement student board'></img>
                    </div>
                    <div className='d-flex justify-content-between align-items-center p-3 container_StudentB_block mt-2'>
                        <div>
                            <div className='persentage_SB'>80%</div>
                            <div className='assesment_text_Sb'>Behavioural</div>
                        </div>
                        <img className='img_assesment_SB' src='/StudentImages/Behavioural.png' alt='assement student board'></img>
                    </div>
                </div>



                <div className='row total_container_detailbox mt-1 '>
                    <div className='col-md-6'>
                        <div className='container_info_student row p-4'>
                            <div className='col-md-7'>
                                <div className='detail_head'>ID</div>
                                <p className='detail_subhead'>XS847489</p>

                                <div className='detail_head'>Division</div>
                                <p className='detail_subhead'>Division A</p>

                                <div className='detail_head'> Gender</div>
                                <p className='detail_subhead'>Male</p>

                                <div className='detail_head'> Parents Alternate Contact</div>
                                <p className='detail_subhead'>9474778484</p>

                                <div className='detail_head'>Father Name</div>
                                <p className='detail_subhead'>Cooper Franci</p>

                                <div className='detail_head'>Parents Email ID</div>
                                <p className='detail_subhead'>cooperfran@gmail.com</p>

                                <div className='detail_head'>MDM ID</div>
                                <p className='detail_subhead'>990002703176428</p>
                            </div>
                            <div className='col-md-5'>
                                <div className='detail_head'>Clss</div>
                                <p className='detail_subhead'>2 nd</p>

                                <div className='detail_head'> Age</div>
                                <p className='detail_subhead'>9</p>

                                <div className='detail_head'> Parents Contact</div>
                                <p className='detail_subhead'>9474778484</p>

                                <div className='detail_head'>School Email ID</div>
                                <p className='detail_subhead'>angel@kend_vidyala.com</p>

                                <div className='detail_head'>Mother Name</div>
                                <p className='detail_subhead'>Marley Westervelt</p>

                                <div className='detail_head'>Class Teacher Name</div>
                                <p className='detail_subhead'>Gretchen Donin</p>
                            </div>
                        </div>
                        <div>
                            <div className='subjectTeacher_school mt-3'>Subject teacher</div>
                        </div>
                        <div className='d-flex justify-content-between flex-wrap m-3'>
                            <div className='d-flex gap-3'>
                                <div>
                                    <img src='/StudentImages/table pic2.svg' alt='profile teacher'></img>
                                </div>
                                <div>
                                    <div className='teacher_name'>Kianna Herwitz</div>
                                    <div className='teacher_subject'>English</div>
                                </div>
                            </div>
                            <div className='d-flex gap-3'>
                                <div>
                                    <img src='/StudentImages/table pic1.svg' alt='profile teacher'></img>
                                </div>
                                <div>
                                    <div className='teacher_name'>Davis Korsgaard</div>
                                    <div className='teacher_subject'>Math</div>
                                </div>
                            </div>
                            <div className='d-flex gap-3'>
                                <div>
                                    <img src='/StudentImages/table pic1.svg' alt='profile teacher'></img>
                                </div>
                                <div>
                                    <div className='teacher_name'>Adison Botosh</div>
                                    <div className='teacher_subject'>Science</div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap justify-content-between m-3'>
                            <div className='d-flex gap-3'>
                                <div>
                                    <img src='/StudentImages/table pic1.svg' alt='profile teacher'></img>
                                </div>
                                <div>
                                    <div className='teacher_name'>Kianna Herwitz</div>
                                    <div className='teacher_subject'>Social Science</div>
                                </div>
                            </div>
                            <div className='d-flex gap-3'>
                                <div>
                                    <img src='/StudentImages/table pic2.svg' alt='profile teacher'></img>
                                </div>
                                <div>
                                    <div className='teacher_name'>Davis Korsgaard</div>
                                    <div className='teacher_subject'>Hindi</div>
                                </div>
                            </div>
                            <div className='d-flex gap-3'>
                                <div>
                                    <img src='/StudentImages/table pic1.svg' alt='profile teacher'></img>
                                </div>
                                <div>
                                    <div className='teacher_name'>Adison Botosh</div>
                                    <div className='teacher_subject'>History</div>
                                </div>
                            </div>
                        </div>

                        <div className='recomandation_container  p-3'>
                            <div className='d-flex justify-content-between main_recomandation' onClick={handelCLick}>
                                <div className='Recommendations'>Recommendations</div>
                                <div className='d-flex gap-1' >
                                    <div className='all_Recommendations mr-2'>All</div>
                                    <div >
                                        {progress.recomended ? <img src='/StudentImages/UpwordArrow.svg' alt='UpwordArrow'></img> : <img src='/StudentImages/DownArrow.svg' alt='DownArrow'></img>}
                                    </div>
                                </div>
                            </div>

                            {progress.recomended && <div className='recomdaded_sub_container' >
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>Jocelyn Dias</span>
                                    <span className='recomended_sub mr-2'>English Teacher</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>John Deo</span>
                                    <span className='recomended_sub mr-2'>Hindi Teacher</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>Lee James</span>
                                    <span className='recomended_sub mr-2'>Math Teacher</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>Jocelyn Dias</span>
                                    <span className='recomended_sub mr-2'>English Teacher</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                                <div className=' recomdaded_sub_containerSecond'>
                                    <span className='recomended_teacher mr-2'>Jocelyn Dias</span>
                                    <span className='recomended_sub mr-2'>English Teacher</span>
                                    <span className='recomended_date'>Feb 22/2021 - 13:34 pm</span>
                                    <p className='text_Recommended mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>}
                        </div>




                    </div>
                    <div className='col-md-6'>

                        <div className='bar_chart p-3'>

                            <div className='d-flex justify-content-between '>
                                <span className='streghtTitle'>Strengths / weakness/ improvement</span>
                                <div onClick={handelCLickBarChart} className='d-flex gap-2'>
                                    <div className='all_Recommendations'>Monthly</div>
                                    {progress.barChart ? <img src='/StudentImages/UpwordArrow.svg' alt='UpwordArrow'></img> : <img src='/StudentImages/DownArrow.svg' alt='DownArrow'></img>}
                                </div>
                            </div>
                            <div style={{ height: '400px', width: "100%" }}>
                                <ResponsiveBar
                                    data={data}
                                    keys={['Strengths', 'Weakness']}
                                    indexBy="Subject"
                                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                                    padding={0.3}
                                    borderRadius={10}
                                    innerPadding={15}
                                    groupMode="grouped"
                                    colors={['#477297', '#91BED4']}
                                    axisTop={null}
                                    axisRight={null}
                                    enableGridX={true}
                                    enableGridY={true}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    axisLeft={{
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        tickSize: 5,
                                        legend: '',
                                        tickValues: [0, 20, 40, 60, 80, 100],
                                        format: (value) => `${value}%`, // change the format to show the percentage symbol
                                    }}

                                />
                            </div>
                            <div className='d-flex gap-4 color_container'>
                                <div>
                                    <span className='strength_color'>.....</span>
                                    <span className='Strengths_colorTitle'>Strengths </span>
                                </div>
                                <div>
                                    <span className='Weakness_color'>.....</span>
                                    <span className='Strengths_colorTitle'>Weakness </span>
                                </div>
                            </div>

                        </div>

                        <div className='container_softSkill mt-4'>
                            <div className='softSkill_header'>Soft Skills</div>
                            <div className='d-flex gap-2 mt-4'>
                                <img src='/StudentImages/Communication.png' alt='comunication icon' />
                                <div className='softSkill_subContainer'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <div className='skills_SubHeader'>Communication</div>
                                            <p className='skills_Subtext'>Lorem Ipsum é um texto modelo</p>
                                        </div>
                                        <div className='skills_Subtext'>  {`${progress.progress1}%`}</div>
                                    </div>
                                    <div id="progress" >
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress.progress1}%`, backgroundColor: ' #335C76' }}
                                            aria-valuenow={progress.progress1}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex gap-2 mt-4'>
                                <img src='/StudentImages/Presentation.png' alt='comunication icon' />
                                <div className='softSkill_subContainer'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <div className='skills_SubHeader'>Presentation</div>
                                            <p className='skills_Subtext'>Lorem Ipsum é um texto modelo</p>
                                        </div>
                                        <div className='skills_Subtext'>  {`${progress.progress2}%`}</div>
                                    </div>
                                    <div id="progress" >
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress.progress2}%`, backgroundColor: ' #335C76' }}
                                            aria-valuenow={progress.progress2}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex gap-2 mt-4'>
                                <img src='/StudentImages/Pronounciation.png' alt='comunication icon' />
                                <div className='softSkill_subContainer'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <div className='skills_SubHeader'>Pronounciation</div>
                                            <p className='skills_Subtext'>Lorem Ipsum é um texto modelo</p>
                                        </div>
                                        <div className='skills_Subtext'>  {`${progress.progress3}%`}</div>
                                    </div>
                                    <div id="progress" >
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress.progress3}%`, backgroundColor: ' #335C76' }}
                                            aria-valuenow={progress.progress3}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='conatainer_ExtraCurriculare p-4'>
                            <div className='ExtraCurriculare'>Extra curriculare</div>
                            <div className='d-flex gap-3 mt-3 flex-wrap'>
                                <div className='Music'>Music</div>
                                <div className='Music'>Dance</div>
                                <div className='Music'>Reading</div>
                                <div className='Music'>Clubs</div>
                                <div className='Music'>Swimming</div>
                                <div className='Music'>Cricket</div>
                            </div>
                        </div>
                        <div className='conatiner_award p-3 mt-4 '>
                            <div className='Awards'>Awards</div>
                            <div className='d-flex justify-content-between flex-wrap mt-3'>
                                <img src='/StudentImages/award1.png' alt='Awards icon' />
                                <img src='/StudentImages/award2.png' alt='Awards icon' />
                                <img src='/StudentImages/award3.png' alt='Awards icon' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
