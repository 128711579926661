import { Component } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { BsDot } from "react-icons/bs";
import BasicInformation from "../UseCaseSecondTime/BasicInformation";
import axios from "../../axios";
import { schoolContext, useSchoolContext } from "../../schoolContext";

class SetPassWordSchool extends Component {
  constructor() {
    super();
    this.state = {
      value1: "",
      value2: "",
      continueBtn: false,
      proceedBtn: true,
      password: "",
      showPassword: false,
      basicInfo: false,
    };

    this.handleChange1 = (event) => {
      this.setState({
        value1: event.target.value,
      });
    };

    this.handleChange2 = (event) => {
      this.setState({
        value2: event.target.value,
      });
    };
  }

  render() {
    const { setSchoolAuthId } = this.props;
    let colour1 = "#666666",
      colour2 = "#666666",
      colour3 = "#666666",
      colour4 = "#666666",
      colour5 = "#666666",
      colour6 = "#666666",
      colour7 = false;

    if (this.state.value1.length >= "8") {
      colour1 = "#45BF55";
    }
    if (this.state.value1.match(/[A-Z]/)) {
      colour2 = "#45BF55";
    }
    if (this.state.value1.match(/[a-z]/)) {
      colour3 = "#45BF55";
    }
    if (this.state.value1.match(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/)) {
      colour4 = "#45BF55";
    }
    if (this.state.value1 === this.state.value2 && this.state.value1 !== "") {
      colour5 = "#45BF55";
    }
    if (this.state.value1.match(/[0-9]/)) {
      colour6 = "#45BF55";
    }

    if (
      this.state.value1 === this.state.value2 &&
      this.state.value1 !== "" &&
      this.state.value2.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])[A-Za-z\d-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]{8,}$/
      )
    ) {
      colour7 = true;
    }

    function myFunction() {
      var x = document.getElementById("myId");
      var y = document.getElementById("close_eye");
      var Z = document.getElementById("open_eye");

      if (x.type === "password") {
        x.type = "text";
        y.style.display = "none";
        Z.style.direction = "block";
      } else {
        x.type = "password";
        y.style.display = "block";
        Z.style.direction = "none";
      }
    }

    function myFunction2() {
      var x = document.getElementById("myId2");
      var y = document.getElementById("close_eye2");
      var Z = document.getElementById("open_eye2");

      if (x.type === "password") {
        x.type = "text";
        y.style.display = "none";
        Z.style.direction = "block";
      } else {
        x.type = "password";
        y.style.display = "block";
        Z.style.direction = "none";
      }
    }

    const handlePasswordChange = async (e) => {
      const { userId } = this.props;
      const body = {
        username: userId,
        newPassword: this.state.value1 || this.state.value2,
      };
      try {
        const res = await axios.post("/api/v1/schoolID/setpassword", body);
        setSchoolAuthId(res?.data?.data);
        // res && this.setState({ basicInfo: true });
        if (res) {
          window.location.pathname = "/PasswordSuccess";
        }
      } catch (error) {}
    };

    return (
      <>
        {!this.state.basicInfo && (
          <div className="Total_container_CreateAcc row">
            <div className="col-lg-3 col-md-5 leftSide_container_createAcc">
              <img
                className="BannerImgSchool"
                src="/OnBoard/SchoolBanner.png"
                alt="banner img"
              />
            </div>
            <div className="col-lg-9 col-md-7 RightSide_container_createAcc">
              <div>
                <form>
                  <div className="d-flex justify-content-center">
                    <img
                      className="SchoolIcon"
                      src="/OnBoard/SchoolIcon.png"
                      alt="banner img"
                    />
                  </div>
                  <div className="form_heading d-flex justify-content-center">
                    Xploresense SMS
                  </div>

                  <div className="label_inputs mt-3 mb-2">
                    Enter new password
                  </div>
                  <div className="total_containerFor_passcode">
                    <input
                      id="myId"
                      className="Passcode"
                      autoComplete="off"
                      type="Password"
                      required
                      name="Password"
                      value={this.state.value1}
                      onChange={this.handleChange1}
                    ></input>
                    <span onClick={myFunction} className="Eye_login">
                      <IoEyeOffOutline id="close_eye" />
                      <IoEyeOutline id="open_eye" />
                    </span>
                  </div>

                  <div className="mt-3  label_inputs mb-2">
                    Confirm Password
                  </div>
                  <div className="total_containerFor_passcode">
                    <input
                      id="myId2"
                      className="Passcode"
                      autoComplete="off"
                      type="Password"
                      required
                      name="Password"
                      value={this.state.value2}
                      onChange={this.handleChange2}
                    ></input>
                    <span onClick={myFunction2} className="Eye_login">
                      <IoEyeOutline id="open_eye2" />
                      <IoEyeOffOutline id="close_eye2" />
                    </span>
                  </div>
                </form>

                {this.state.value1 && (
                  <div className=" d-flex p-3 justify-content-between">
                    <div>
                      <div
                        style={{
                          color: colour1,
                          fontSize: "12px",
                          fontFamily: "open Sans",
                        }}
                      >
                        {" "}
                        <BsDot /> minimum 8 characters&nbsp;
                      </div>
                      <div
                        style={{
                          color: colour4,
                          fontSize: "12px",
                          fontFamily: "open Sans",
                        }}
                      >
                        <BsDot />
                        one special character
                      </div>
                      <div
                        style={{
                          color: colour6,
                          fontSize: "12px",
                          fontFamily: "open Sans",
                        }}
                      >
                        <BsDot /> one number{" "}
                      </div>
                      <div
                        style={{
                          color: colour5,
                          fontSize: "12px",
                          fontFamily: "open Sans",
                        }}
                      >
                        <BsDot /> Password & Confirm Password Match
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          color: colour2,
                          fontSize: "12px",
                          fontFamily: "open Sans",
                        }}
                      >
                        {" "}
                        <BsDot /> one uppercase character
                      </div>
                      <div
                        style={{
                          color: colour3,
                          fontSize: "12px",
                          fontFamily: "open Sans",
                        }}
                      >
                        {" "}
                        <BsDot /> one lowercase character
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <button
                    class="btn btn"
                    id="Register_login_btn"
                    type="button"
                    disabled={!colour7}
                    onClick={handlePasswordChange}
                  >
                    Proceed{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.basicInfo && <BasicInformation />}
      </>
    );
  }
}
export default SetPassWordSchool;
